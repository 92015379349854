import React, { ReactNode, useState } from "react";

import { Group, Image, Rect, Text } from "react-konva";
import useImage from "use-image";

import { useTranslation } from "react-i18next";
import BestandFile from "../../../../assets/images/prozessmapping/Bestand.png";
import BestandEDVFile from "../../../../assets/images/prozessmapping/Bestand_EDV.png";
import BestandEDVPapierFile from "../../../../assets/images/prozessmapping/Bestand_EDV_Papier.png";
import BestandPapierFile from "../../../../assets/images/prozessmapping/Bestand_Papier.png";
import DienstleisterFile from "../../../../assets/images/prozessmapping/Dienstleister.png";
import KaizenFile from "../../../../assets/images/prozessmapping/Kaizen.png";
import KundeFile from "../../../../assets/images/prozessmapping/Kunde.png";
import ProzessFile from "../../../../assets/images/prozessmapping/Prozess.png";
import ProzessSimpleFile from "../../../../assets/images/prozessmapping/Prozesssimple.png";
import {
  ProzessBereich,
  Prozessschritt,
  Prozessschritttype,
} from "../../../../utils/LeanAdmin/LeanAdmin.types";
import {
  getCurrentXOffset,
  getCurrentYOffset,
  isMovementIsAboveTolerance,
  isRightClick,
  mapTimeUnitToText,
} from "../../../../utils/LeanAdmin/ProzessMappingUtil";
import ProzessschrittTooltip from "./ProzessschrittTooltip";

interface ProzessschrittImageProps {
  actualWidth: number;
  actualHeight: number;
  prozessSchritt: Prozessschritt;
  prozessBereich: ProzessBereich;
  yChange: number;
  onClick(prozessSchritt: Prozessschritt): void;
  onDblClick(prozessSchritt: Prozessschritt): void;
  onDragEnd(prozessSchritt: Prozessschritt): void;
  draggable: boolean;
  selected: boolean;
  isValid: boolean;
}

const ProzessschrittImage: React.FC<ProzessschrittImageProps> = ({
  prozessSchritt,
  actualWidth,
  actualHeight,
  onClick,
  onDblClick,
  onDragEnd,
  draggable,
  yChange,
  isValid,
  selected,
  prozessBereich,
}) => {
  const [ProzessIcon] = useImage(ProzessFile);
  const [ProzessSimpleIcon] = useImage(ProzessSimpleFile);
  const [KundeIcon] = useImage(KundeFile);
  const [KaizenIcon] = useImage(KaizenFile);
  const [DienstleisterIcon] = useImage(DienstleisterFile);
  const [BestandIcon] = useImage(BestandFile);
  const [BestandPapierIcon] = useImage(BestandPapierFile);
  const [BestandEDVIcon] = useImage(BestandEDVFile);
  const [BestandEDVPapierIcon] = useImage(BestandEDVPapierFile);
  const [showTooltip, toggleTooltip] = useState<boolean>(false);
  // for longpress detection
  const [wasLongTouch, setWasLongTouch] = useState<boolean>(false);
  const [timeoutCounter, setTimeoutCounter] = useState<any>(undefined);
  const { t } = useTranslation();

  /**
   * @returns the correct data for a possible Tooltip
   */
  const getCorrectTooltipConfig = (): {
    text: string;
    pointerDirection: "up" | "down";
    coords: number[];
  } => {
    switch (prozessSchritt.type) {
      case Prozessschritttype.BESTAND:
      case Prozessschritttype.BESTANDEDV:
      case Prozessschritttype.BESTANDPHYSISCH:
      case Prozessschritttype.BESTANDPHYSISCHEDV:
        return {
          text:
            (prozessSchritt.vorgaenge === 0
              ? ""
              : "V: " + prozessSchritt.vorgaenge + "\n") +
            (prozessSchritt.durchlaufZeit.value === 0
              ? ""
              : "DZ: " +
                prozessSchritt.durchlaufZeit.value /
                  prozessSchritt.durchlaufZeit.unit +
                mapTimeUnitToText(prozessSchritt.durchlaufZeit.unit)),
          pointerDirection: "down",
          coords: [40, 0],
        };
      case Prozessschritttype.KAIZEN:
        return {
          text: prozessSchritt.beschreibung || "",
          pointerDirection: "down",
          coords: [40, 0],
        };
      case Prozessschritttype.PROCESS:
        return {
          text:
            (prozessSchritt.name ? prozessSchritt.name + "\n" : "") +
            (prozessSchritt.workerAmount === 0
              ? ""
              : "MA: " + prozessSchritt.workerAmount + "\n") +
            (prozessSchritt.prozessSchritte === 0
              ? ""
              : "PS: " + prozessSchritt.prozessSchritte + "\n") +
            (prozessSchritt.prozessZeit.value === 0
              ? ""
              : "PZ: " +
                prozessSchritt.prozessZeit.value /
                  prozessSchritt.prozessZeit.unit +
                mapTimeUnitToText(prozessSchritt.prozessZeit.unit) +
                "\n") +
            (prozessSchritt.minProzessZeit.value === 0
              ? ""
              : "min. PZ: " +
                prozessSchritt.minProzessZeit.value /
                  prozessSchritt.minProzessZeit.unit +
                mapTimeUnitToText(prozessSchritt.minProzessZeit.unit) +
                "\n") +
            (prozessSchritt.maxProzessZeit.value === 0
              ? ""
              : "max. PZ: " +
                prozessSchritt.maxProzessZeit.value /
                  prozessSchritt.maxProzessZeit.unit +
                mapTimeUnitToText(prozessSchritt.maxProzessZeit.unit) +
                "\n") +
            (prozessSchritt.verweilZeit.value === 0
              ? ""
              : "VZ: " +
                prozessSchritt.verweilZeit.value /
                  prozessSchritt.verweilZeit.unit +
                mapTimeUnitToText(prozessSchritt.verweilZeit.unit) +
                "\n") +
            (prozessSchritt.rueckfrageQuote === 0
              ? ""
              : "RQ: " + prozessSchritt.rueckfrageQuote + "\n") +
            (prozessSchritt.qualitaetsQuote === 0
              ? ""
              : "QQ: " + prozessSchritt.qualitaetsQuote),
          pointerDirection: "down",
          coords: [40, 0],
        };
      case Prozessschritttype.PROCESSSIMPLE:
        return {
          text:
            (prozessSchritt.name ? prozessSchritt.name + "\n" : "") +
            (prozessSchritt.workerAmount === 0
              ? ""
              : "MA: " + prozessSchritt.workerAmount + "\n") +
            (prozessSchritt.prozessSchritte === 0
              ? ""
              : "PS: " + prozessSchritt.prozessSchritte + "\n") +
            (prozessSchritt.prozessZeit.value === 0
              ? ""
              : "PZ: " +
                prozessSchritt.prozessZeit.value /
                  prozessSchritt.prozessZeit.unit +
                mapTimeUnitToText(prozessSchritt.prozessZeit.unit)),
          pointerDirection: "down",
          coords: [40, 0],
        };
      case Prozessschritttype.KUNDEN:
        return {
          text:
            (prozessSchritt.name ? prozessSchritt.name + "\n" : "") +
            (prozessSchritt.auftrag ? prozessSchritt.auftrag : ""),
          pointerDirection: "up",
          coords: [40, 80],
        };
      case Prozessschritttype.EXTERN:
        return {
          text:
            (prozessSchritt.bezeichnung
              ? prozessSchritt.bezeichnung + "\n"
              : "") +
            (prozessSchritt.dauer.value === 0
              ? ""
              : t("modules.prozessMapping.prozessSchritte.metaTag.duration") +
                ": " +
                prozessSchritt.dauer.value / prozessSchritt.dauer.unit +
                mapTimeUnitToText(prozessSchritt.dauer.unit)),
          pointerDirection: "down",
          coords: [40, 0],
        };
      default:
        return { text: "", pointerDirection: "down", coords: [0, 0] };
    }
  };

  /**
   * Helper to fetch the coirrect Image to display
   *
   * @returns correct image for type
   */
  const getCorrectImageForProzessschritt = (): HTMLImageElement | undefined => {
    switch (prozessSchritt.type) {
      case Prozessschritttype.PROCESS:
        return ProzessIcon;
      case Prozessschritttype.KUNDEN:
        return KundeIcon;
      case Prozessschritttype.KAIZEN:
        return KaizenIcon;
      case Prozessschritttype.EXTERN:
        return DienstleisterIcon;
      case Prozessschritttype.BESTAND:
        return BestandIcon;
      case Prozessschritttype.BESTANDEDV:
        return BestandEDVIcon;
      case Prozessschritttype.BESTANDPHYSISCH:
        return BestandPapierIcon;
      case Prozessschritttype.BESTANDPHYSISCHEDV:
        return BestandEDVPapierIcon;
      case Prozessschritttype.PROCESSSIMPLE:
        return ProzessSimpleIcon;
      default:
        break;
    }
  };

  /**
   * Helper return the all time visible text
   *
   * @returns text for displaying in stage
   */
  const getVisibleText = (): string => {
    switch (prozessSchritt.type) {
      case Prozessschritttype.BESTANDEDV:
      case Prozessschritttype.BESTANDPHYSISCH:
      case Prozessschritttype.BESTANDPHYSISCHEDV:
        return (
          prozessSchritt.vorgaenge +
          " " +
          t("modules.prozessMapping.prozessSchritte.metaTag.operations") +
          "\n" +
          prozessSchritt.durchlaufZeit.value /
            prozessSchritt.durchlaufZeit.unit +
          mapTimeUnitToText(prozessSchritt.durchlaufZeit.unit)
        );
      case Prozessschritttype.PROCESSSIMPLE:
      case Prozessschritttype.PROCESS:
      case Prozessschritttype.KAIZEN:
      case Prozessschritttype.KUNDEN:
      case Prozessschritttype.BESTAND:
      case Prozessschritttype.EXTERN:
        return "";
    }
  };

  /**
   * Generates the text to display over image
   *
   * @returns kanva data for displaying
   */
  const fillImageTextData = (): ReactNode => {
    switch (prozessSchritt.type) {
      case Prozessschritttype.PROCESSSIMPLE:
        return (
          <>
            <Text
              x={5}
              y={4}
              padding={5}
              fill="black"
              text={prozessSchritt.displayName || ""}
              fontSize={10}
            />
            <Text
              x={20}
              y={38}
              padding={5}
              fill="black"
              text={
                prozessSchritt.workerAmount
                  ? prozessSchritt.workerAmount.toString()
                  : ""
              }
              fontSize={10}
            />
            <Text
              x={54}
              y={38}
              padding={5}
              fill="black"
              text={
                prozessSchritt.process.items.length === 0
                  ? ""
                  : prozessSchritt.process.items.length.toString()
              }
              fontSize={10}
            />
          </>
        );
      case Prozessschritttype.PROCESS:
        return (
          <>
            <Text
              x={5}
              y={2}
              padding={5}
              fill="black"
              text={prozessSchritt.displayName || ""}
              fontSize={10}
            />
            <Text
              x={20}
              y={16}
              padding={5}
              fill="black"
              text={
                prozessSchritt.workerAmount
                  ? prozessSchritt.workerAmount.toString()
                  : ""
              }
              fontSize={10}
            />
            <Text
              x={54}
              y={16}
              padding={5}
              fill="black"
              text={
                prozessSchritt.process.items.length === 0
                  ? ""
                  : prozessSchritt.process.items.length.toString()
              }
              fontSize={10}
            />
            <Text
              x={5}
              y={29}
              padding={5}
              fill="black"
              text={
                "PZ: " +
                prozessSchritt.prozessZeit.value /
                  prozessSchritt.prozessZeit.unit +
                mapTimeUnitToText(prozessSchritt.prozessZeit.unit)
              }
              fontSize={10}
            />
            <Text
              x={5}
              y={55}
              padding={5}
              fill="black"
              text={
                "RQ: " +
                (prozessSchritt.rueckfrageQuote
                  ? prozessSchritt.rueckfrageQuote!.toString()
                  : "")
              }
              fontSize={10}
            />
          </>
        );
      case Prozessschritttype.KAIZEN:
        return (
          <Text
            width={80}
            align="center"
            y={28}
            padding={5}
            fill="black"
            text={prozessSchritt.displayName}
            fontSize={12}
          />
        );
      case Prozessschritttype.KUNDEN:
        return (
          <Text
            width={70}
            align="center"
            y={32}
            padding={5}
            fill="black"
            text={prozessSchritt.displayName}
            fontSize={12}
          />
        );
      case Prozessschritttype.BESTAND:
      case Prozessschritttype.BESTANDEDV:
      case Prozessschritttype.BESTANDPHYSISCH:
      case Prozessschritttype.EXTERN:
      case Prozessschritttype.BESTANDPHYSISCHEDV:
        return <></>;
    }
  };

  // for intern coords
  const [localyCoord, setLocalyCoord] = useState<number>(
    getCurrentYOffset(prozessBereich, prozessSchritt)
  );
  const [localyDragCoord, setLocalyDragCoord] = useState<number>(0);
  const [localxDragCoord, setLocalxDragCoord] = useState<number>(0);
  const [localxCoord, setLocalxCoord] = useState<number>(
    getCurrentXOffset(prozessSchritt)
  );

  /**
   * generate the right background rect
   *
   * @returns Background Rect for better presentation
   */
  const getCorrectBackgroundRect = (): ReactNode => {
    // dont show a background if not needed
    if (prozessSchritt.type === Prozessschritttype.KAIZEN) return <></>;
    // in case we needed it
    return !isValid || selected ? (
      <Rect
        width={76}
        height={76}
        cornerRadius={3}
        stroke={selected ? "#0DAB94" : "#ee4f4b"}
        fill={selected ? "#0DAB9440" : "#ee4f4b40"}
        strokeWidth={2}
      />
    ) : (
      <Rect
        width={76}
        height={76}
        fill={
          Math.trunc((localyCoord - yChange) / 100) % 2 === 1
            ? "#D3D3D390"
            : "#ffffff90"
        }
      />
    );
  };

  return (
    <Group
      dragBoundFunc={(pos) => {
        let x: number = pos.x;
        let y: number = pos.y;

        // set dragbounc function
        if (x < 10) x = 10;
        if (x > actualWidth) x = actualWidth - 10;
        if (y < 10) y = 10;
        if (y + 90 > actualHeight) y = actualHeight - 90;

        if (prozessSchritt.type === Prozessschritttype.KAIZEN)
          return {
            x: x,
            y: y,
          };
        // change role swimmlane with drag and drop
        let currentRoleIndex: number = Math.trunc(
          (localyCoord - yChange) / 100
        );
        let possibleNewRoleIndex: number = Math.trunc((y - yChange + 40) / 100);
        if (
          currentRoleIndex !== possibleNewRoleIndex &&
          possibleNewRoleIndex > 0 &&
          prozessSchritt.type !== Prozessschritttype.KUNDEN
        ) {
          if (prozessBereich.version! >= 2) {
            const foundIndex: number = prozessBereich.bereichRoles.findIndex(
              (item) => item.position === possibleNewRoleIndex - 1
            );
            prozessSchritt.role = prozessBereich.bereichRoles[foundIndex].id;
          } else
            prozessSchritt.role = [
              t("modules.prozessMapping.roles.customer"),
              ...prozessBereich.roles,
            ][possibleNewRoleIndex];
          setLocalyCoord(getCurrentYOffset(prozessBereich, prozessSchritt));
        }
        // x-movement
        const newxIndex: number = Math.trunc(x / 100);
        prozessSchritt.position = newxIndex;
        setLocalxCoord(getCurrentXOffset(prozessSchritt));
        return {
          x: localxCoord,
          y: localyCoord + yChange,
        };
      }}
      onMouseOver={() => toggleTooltip(true)}
      onMouseOut={() => toggleTooltip(false)}
      draggable={draggable}
      onTouchStart={() => {
        // not when drag is enabled (Viewerstate)
        if (draggable) {
          setTimeoutCounter(
            setTimeout(function () {
              setWasLongTouch(true);
            }, 2000)
          );
        }
      }}
      onDblTap={() => onDblClick(prozessSchritt)}
      onTouchEnd={() => {
        if (draggable) {
          clearTimeout(timeoutCounter);
          if (!wasLongTouch) {
            onClick(prozessSchritt);
          }
          setWasLongTouch(false);
        } else {
          // onviewersate toggle tooltip
          toggleTooltip(!showTooltip);
          onClick(prozessSchritt);
        }
      }}
      onDragStart={() => {
        setLocalyDragCoord(localyCoord);
        setLocalxDragCoord(localxCoord);
      }}
      onDragMove={(event) => {
        event.target.preventDefault();
        // Tolerance for the movement
        if (
          isMovementIsAboveTolerance(
            localxDragCoord,
            localyDragCoord,
            event.target.getPosition().x,
            event.target.getPosition().y
          )
        ) {
          clearTimeout(timeoutCounter);
        }
      }}
      onDragEnd={(event) => {
        if (prozessSchritt.type === Prozessschritttype.KAIZEN)
          prozessSchritt.coords = [
            event.target.getPosition().x,
            event.target.getPosition().y,
          ];
        onDragEnd(prozessSchritt);
      }}
      id={prozessSchritt.id}
      x={
        prozessSchritt.type === Prozessschritttype.KAIZEN
          ? prozessSchritt.coords![0]
          : localxCoord
      }
      y={
        prozessSchritt.type === Prozessschritttype.KAIZEN
          ? prozessSchritt.coords![1]
          : localyCoord
      }
      width={80}
      height={80}
    >
      {showTooltip && (
        <ProzessschrittTooltip
          align="left"
          coords={getCorrectTooltipConfig().coords}
          text={getCorrectTooltipConfig().text}
          pointerDirection={getCorrectTooltipConfig().pointerDirection}
        />
      )}
      {getCorrectBackgroundRect()}
      {fillImageTextData()}
      <Image
        onClick={(event) => {
          if (isRightClick(event)) return;
          onClick(prozessSchritt);
        }}
        onDblClick={(event) => {
          if (isRightClick(event)) return;
          onDblClick(prozessSchritt);
        }}
        perfectDrawEnabled
        image={getCorrectImageForProzessschritt()}
        width={70}
        height={70}
        x={3}
        y={3}
      />
      {!!getVisibleText() && (
        <Group x={-20} y={58}>
          <Text align="center" width={120} text={getVisibleText()} />
        </Group>
      )}
    </Group>
  );
};

export default ProzessschrittImage;
