import { useKeycloak } from "@react-keycloak/web";
import { useState, useEffect } from "react";
import axios, { AxiosInstance } from "axios";

/**
 * Helper to check if given value is an iso date string
 *
 * @param value any form of value to check
 * @returns true if value is iso date string
 * @tested
 */
export const isIsoDateString = (value: any): boolean => {
  //regex to check for iso date YYYY-MM-DDThh:mm:ss.mmm+hh:mm from firebase
  const isoDateFormat =
    /\d{4}-[01]\d-[0-3]\d(?:T[0-2]\d:[0-5]\d:[0-5]\d(?:\.\d{0,6})?)?$/;
  return value && typeof value === "string" && isoDateFormat.test(value);
};

/**
 * converts server date and returns local date
 *
 * @returns local Date
 */
const getCorrectDateForServerDate = (date: string | Date): Date | null => {
  let givenDate: Date = new Date(date);
  if (isNaN(givenDate.getTime())) return null;
  let utcDate: Date = new Date(
    Date.UTC(
      givenDate.getFullYear(),
      givenDate.getMonth(),
      givenDate.getDate(),
      givenDate.getHours(),
      givenDate.getMinutes(),
      givenDate.getSeconds()
    )
  );
  return utcDate;
};

/**
 * Helper to find all iso dates in an object after receiving it from request
 * and parsing them
 *
 * @param body request body to check for dates and parse them
 */
const handleDates = (body: any): void => {
  if (body === null || body === undefined || typeof body !== "object")
    return body;
  for (const key of Object.keys(body)) {
    const value = body[key];
    if (isIsoDateString(value)) body[key] = getCorrectDateForServerDate(value);
    else if (typeof value === "object") handleDates(value);
  }
};

/**
 * Creates a prefconfigured axios wrapper that also talks with
 * the keycloak instance.
 */
export const useAxios = () => {
  const { keycloak, initialized } = useKeycloak();
  const [axiosInstance, setAxiosInstance] = useState({});
  const baseURL = process.env.REACT_APP_SERVICE_URL;

  useEffect(() => {
    const instance = axios.create({
      baseURL,
      headers: {
        Authorization: initialized ? `Bearer ${keycloak.token}` : undefined,
        "Content-Type": "application/json;charset=UTF-8",
      },
    });
    instance.interceptors.response.use((originalResponse) => {
      handleDates(originalResponse.data);
      return originalResponse;
    });
    setAxiosInstance({ instance });

    return () => {
      setAxiosInstance({});
    };
  }, [baseURL, initialized, keycloak, keycloak.token]);

  return (axiosInstance as any).instance as AxiosInstance;
};
