import {
  BoxComponent,
  ConfirmPopupComponent,
  PopupComponent,
  TableComponent,
  TableRow,
} from "beelean-component-library";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as BlockedIcon } from "../../assets/icons/blocked.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as CompanyEditIcon } from "../../assets/icons/company-edit.svg";
import { ReactComponent as CompanyIcon } from "../../assets/icons/company.svg";
import { ReactComponent as DumpsterIcon } from "../../assets/icons/dumpster.svg";
import { ReactComponent as UsersAddIcon } from "../../assets/icons/user-add.svg";
import { ReactComponent as UsersAdminIcon } from "../../assets/icons/user-admin.svg";
import { ReactComponent as UserEditIcon } from "../../assets/icons/user-edit.svg";
import { CompanyContext, KeycloakUserContext } from "../../pages/App";
import { useAxios } from "../../utils/AxiosUtil";
import {
  addCompanyToList,
  deleteCompany,
  fetchAllCompanies,
  getNameOfCompany,
  updateCompanyOnServer,
} from "../../utils/CompanyUtil";
import { LicenseType, ModuleShowType, UserRole } from "../../utils/Enums";
import {
  Company,
  User,
  createEmptyCompany,
  createEmptyUser,
} from "../../utils/Interfaces";
import { isLicenseValid } from "../../utils/LicenseUtil";
import { generateNotification } from "../../utils/NotificationUtil";
import {
  addUserToList,
  createNewUser,
  deleteUserOnServer,
  fetchAllUser,
  isUserAllowedToDoThis,
  isUserValid,
  toggleStatusOfUser,
  updateUserOnServer,
} from "../../utils/UserUtil";
import "./AccountListStyle.scss";
import { CompanyCreateWorkflow, CompanyMetatag } from "./CompanyMetatag";
import LogoAdministration from "./LogoAdministration";
import UserMetatag from "./UserMetatag";

const AccountList: React.FC<{}> = () => {
  const { keycloakUser } = useContext(KeycloakUserContext);
  const { setCompanyUser, companyUser } = useContext(CompanyContext);
  const { t } = useTranslation();
  const axios = useAxios();
  const [
    toBeCreatedIsUserIsPlatformAdmin,
    toggleToBeCreatedIsUserIsPlatformAdmin,
  ] = useState<boolean>(false);

  // needed for rerender the tables
  // check if better way exists
  const [version, setVersion] = useState<number>(0);

  // States for the user data
  const [showEditUserPopup, setShowEditUserPopup] = useState<boolean>(false);
  const [isUpdateUser, setIsUpdateUser] = useState<boolean>(false);
  const [loadedServerUsers, setLoadedServerUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>(createEmptyUser);
  const [loadedUsers, setLoadedUsers] = useState<TableRow[]>([]);

  // States for the company
  const [loadedServerCompanies, setLoadedServerCompanies] = useState<Company[]>(
    []
  );
  const [loadedCompanies, setLoadedCompanies] = useState<TableRow[]>([]);
  const [selectedCompany, setSelectedCompany] =
    useState<Company>(createEmptyCompany);
  const [showEditCompanyPopup, setShowEditCompanyPopup] =
    useState<boolean>(false);
  const [isUpdateCompany, setIsUpdateCompany] = useState<boolean>(false);

  const [showUserDeleteConfirmPopup, setShowUserDeleteConfirmPopup] =
    useState<boolean>(false);
  const [showCompanyDeleteConfirmPopup, setShowCompanyDeleteConfirmPopup] =
    useState<boolean>(false);
  const [isKeyCloakUserAllowedToDoThis, setIsKeyCloakUserAllowedToDoThis] =
    useState<boolean>(false);

  /**
   * Updates the global variable if user is allowed to do this
   */
  useEffect(() => {
    if (keycloakUser)
      setIsKeyCloakUserAllowedToDoThis(
        isUserAllowedToDoThis(keycloakUser.userRole)
      );
  }, [keycloakUser]);

  /**
   * Generates the company table rows from the server
   */
  const generateCompanyTableRows = () => {
    let localCompanies: TableRow[] = [];

    if (loadedServerCompanies) {
      loadedServerCompanies.forEach((companyValue: Company) =>
        localCompanies.push({
          id: companyValue.id,
          content: [
            companyValue.name,
            <>
              {companyValue.address}
              <br />
              {companyValue.zipCode} {companyValue.city}
            </>,
            <>
              {(companyValue.license.type === LicenseType.ADMINISTRATION_USER ||
                companyValue.license.type === LicenseType.ALL) && (
                <>
                  {t("company.list.user")}{" "}
                  {
                    loadedServerUsers.filter(
                      (item) => item.companyId === companyValue.id
                    ).length
                  }
                  /{companyValue.license.licensedUsers} -{" "}
                  {t("company.list.validUntil")}{" "}
                  {new Date(
                    companyValue.license.administrationValidUntil!
                  ).toLocaleDateString("de-DE")}
                  <br />
                </>
              )}
              {(companyValue.license.type === LicenseType.PRODUCTION ||
                companyValue.license.type === LicenseType.ALL) && (
                <>
                  {t("company.list.stations")} {companyValue.createdStations}/
                  {companyValue.license.licensedStationAmount} -{" "}
                  {t("company.list.validUntil")}{" "}
                  {new Date(
                    companyValue.license.productionValidUntil!
                  ).toLocaleDateString("de-DE")}
                  <br />
                </>
              )}
              {(companyValue.license.type === LicenseType.ADMINISTRATION ||
                companyValue.license.type === LicenseType.ALL) && (
                <>
                  {t("company.list.processes")} {companyValue.createdProcesses}/
                  {companyValue.license.licensedProcessAmount} -{" "}
                  {t("company.list.validUntil")}{" "}
                  {new Date(
                    companyValue.license.administrationValidUntil!
                  ).toLocaleDateString("de-DE")}
                  {companyValue.license.activatePdca && " - PDCA"}
                  <br />
                </>
              )}
              {(companyValue.license.type ===
                LicenseType.ADMINISTRATION_PROJEKTAKTE ||
                companyValue.license.type === LicenseType.ALL) && (
                <>
                  {t("company.list.prozessBereicheAndProjektakte")}{" "}
                  {companyValue.createdProjektakte +
                    companyValue.createdProzessBereiche}
                  /{companyValue.license.licensedProjektakteAndProzessbereiche}{" "}
                  - {t("company.list.validUntil")}{" "}
                  {new Date(
                    companyValue.license.administrationValidUntil!
                  ).toLocaleDateString("de-DE")}
                  <br />
                </>
              )}
              {t("company.list.storage")}{" "}
              {(companyValue.usedStorage / 1000000).toFixed(2)} MB/
              {(companyValue.license.licensedStorage / 1000000).toFixed(2)}MB
              <br />
              {t(`licenseType.${companyValue.license.type}`)}
            </>,
            isLicenseValid(
              companyValue.license,
              companyValue.createdStations,
              companyValue.createdProcesses,
              companyValue.createdProzessBereiche,
              companyValue.createdProjektakte
            )
              ? t("company.list.license.active")
              : t("company.list.license.inactive"),
            <div className="user-list-actions-table-wrapper">
              <div
                title={t("general.buttons.edit")}
                onClick={() => {
                  setSelectedCompany(companyValue);
                  setShowEditCompanyPopup(true);
                }}
                className="user-list-edit-wrapper"
              >
                <CompanyEditIcon />
              </div>
              <div
                title={t("general.buttons.delete")}
                onClick={() => {
                  setSelectedCompany(companyValue);
                  setShowCompanyDeleteConfirmPopup(true);
                }}
                className="user-list-delete-wrapper"
              >
                <DumpsterIcon />
              </div>
            </div>,
          ],
        })
      );
      setLoadedCompanies(localCompanies);
    }
  };

  /**
   * Generates the Table rows for the user table
   */
  const generateUserTableRows = (): void => {
    let localUsers: TableRow[] = [];

    if (loadedServerUsers) {
      loadedServerUsers.forEach((userValue: User, index: number) => {
        let listContent: any[] = [];

        listContent.push(userValue.username);
        listContent.push(userValue.firstname);
        listContent.push(userValue.lastname);
        if (isKeyCloakUserAllowedToDoThis)
          listContent.push(
            isUserAllowedToDoThis(userValue.userRole)
              ? t("general.productName")
              : getNameOfCompany(userValue.companyId, loadedServerCompanies)
          );
        listContent.push(userValue.mail);
        listContent.push(t(`groups.${userValue.userRole}`));
        if (isKeyCloakUserAllowedToDoThis)
          listContent.push(
            userValue.lastLogin
              ? `(${
                  userValue.loginCounter
                }) ${userValue.lastLogin?.toLocaleDateString()}`
              : t("user.list.lastLoginDefault")
          );
        listContent.push(
          <div
            title={
              userValue.enabled
                ? t("user.list.status.active")
                : t("user.list.status.inactive")
            }
            onClick={() => {
              toggleStatusOfUser(userValue.id!, axios).then(
                (status: number) => {
                  if (status === 200) {
                    let localTempUsers: User[] = loadedServerUsers;
                    localTempUsers[index].enabled =
                      !localTempUsers[index].enabled;

                    setLoadedServerUsers([...localTempUsers]);

                    generateNotification(
                      t("notifications.userToggle.successTitle"),
                      localTempUsers[index].enabled
                        ? t("notifications.userToggle.successContentFree")
                        : t("notifications.userToggle.successContentBlock"),
                      "success"
                    );
                  } else {
                    generateNotification(
                      t("notifications.userToggle.errorTitle"),
                      t("notifications.userToggle.errorContent"),
                      "danger",
                      -1
                    );
                  }
                }
              );
            }}
            className={[
              "user-list-status-wrapper",
              userValue.enabled
                ? "user-list-status-check"
                : "user-list-status-blocked",
            ].join(" ")}
          >
            {userValue.enabled ? <CheckIcon /> : <BlockedIcon />}
          </div>
        );
        listContent.push(
          <div className="user-list-actions-table-wrapper">
            <div
              title={t("general.buttons.edit")}
              onClick={() => {
                setSelectedUser(userValue);
                setShowEditUserPopup(true);
              }}
              className="user-list-edit-wrapper"
            >
              <UserEditIcon />
            </div>
            {keycloakUser!.serviceId !== userValue.id && (
              <div
                title={t("general.buttons.delete")}
                onClick={() => {
                  setSelectedUser(userValue);
                  setShowUserDeleteConfirmPopup(true);
                }}
                className="user-list-delete-wrapper"
              >
                <DumpsterIcon />
              </div>
            )}
          </div>
        );
        localUsers.push({
          id: userValue.id,
          content: listContent,
        });
      });
      setLoadedUsers(localUsers);
    }
  };

  /**
   * This useEffect builds the TableRow data for the company and/or user
   * list.
   */
  useEffect(() => {
    if (!keycloakUser) return;

    generateCompanyTableRows();
    generateUserTableRows();

    setVersion(version + 1);
    // eslint-disable-next-line
  }, [loadedServerCompanies, loadedServerUsers, keycloakUser]);

  /**
   * Retrieve the needed data from the server
   */
  useEffect(() => {
    if (axios) {
      if (keycloakUser!.userRole === UserRole.COMPANYADMIN) {
        fetchAllUser(axios, keycloakUser!.companyId).then(
          (userResponse: User[]) => {
            if (userResponse) {
              setLoadedServerUsers(userResponse);
            }
          }
        );
      } else {
        // fetch all data for admin
        fetchAllCompanies(axios).then((companyResponse: Company[]) => {
          if (companyResponse) {
            setLoadedServerCompanies(companyResponse);

            // fetch User, but with company name
            fetchAllUser(axios, undefined).then((userResponse: User[]) => {
              if (userResponse) {
                setLoadedServerUsers(userResponse);
              }
            });
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [axios]);

  /**
   * delete a company from the server
   */
  const deleteCompanyOnServer = (): void => {
    deleteCompany(selectedCompany.id!, axios).then(
      (listOfdeleteUsers: string[]) => {
        if (listOfdeleteUsers) {
          // remove user from list too
          setLoadedServerUsers(
            loadedServerUsers.filter(
              (value: User) => !listOfdeleteUsers.includes(value.id!)
            )
          );

          setLoadedServerCompanies(
            loadedServerCompanies.filter(
              (value: Company) => value.id !== selectedCompany.id
            )
          );

          setShowCompanyDeleteConfirmPopup(false);
          generateNotification(
            t("notifications.companyDelete.successTitle"),
            t("notifications.companyDelete.successContent", {
              count: listOfdeleteUsers.length,
            }),
            "success"
          );
        } else {
          generateNotification(
            t("notifications.companyDelete.errorTitle"),
            t("notifications.companyDelete.serverDeletion"),
            "danger",
            -1
          );
        }
      }
    );
  };

  /**
   * Deletes a user on the server
   */
  const deleteUserOnServerFromPopup = (): void => {
    deleteUserOnServer(selectedUser.id!, axios).then((status: number) => {
      if (status === 200) {
        // remove user from list
        setLoadedServerUsers(
          loadedServerUsers.filter(
            (value: User) => value.id !== selectedUser.id
          )
        );
        setCompanyUser([
          ...companyUser.filter((item) => item.id !== selectedUser.id),
        ]);
        setShowUserDeleteConfirmPopup(false);
        generateNotification(
          t("notifications.userDelete.successTitle"),
          t("notifications.userDelete.successContent", {
            mail: selectedUser.mail,
          }),
          "success"
        );
      } else {
        generateNotification(
          t("notifications.userDelete.errorTitle"),
          t("notifications.userDelete.errorContent", {
            status: status,
          }),
          "danger",
          -1
        );
      }
    });
  };

  /**
   * Helper to update user on the server an set needed new data
   * @param updateUser new data from user
   */
  const updateUser = (updateUser: User): void => {
    if (!isUserValid(updateUser)) return;

    setIsUpdateUser(true);
    updateUserOnServer(updateUser, axios).then((status: number) => {
      if (status === 200) {
        let localTempUsers: User[] = loadedServerUsers;
        let foundIndex: number = localTempUsers.findIndex(
          (value: User) => value.id === selectedUser.id
        );
        localTempUsers[foundIndex].firstname = updateUser.firstname;
        localTempUsers[foundIndex].lastname = updateUser.lastname;
        localTempUsers[foundIndex].mail = updateUser.mail;
        localTempUsers[foundIndex].username = updateUser.username;
        localTempUsers[foundIndex].userRole = updateUser.userRole;

        setLoadedServerUsers([...localTempUsers]);
        setShowEditUserPopup(false);

        generateNotification(
          t("notifications.userEdit.successTitle"),
          t("notifications.userEdit.successContent", {
            mail: updateUser.mail,
          }),
          "success"
        );
      } else {
        generateNotification(
          t("notifications.userEdit.errorTitle"),
          t("notifications.userEdit.errorContent", {
            status: status,
          }),
          "danger",
          -1
        );
      }
      setIsUpdateUser(false);
    });
  };

  /**
   * Helper to generate a new User on the server a set it to the context
   *
   * @param user new user to create
   */
  const createNewUserOnServer = (user: User): void => {
    toggleToBeCreatedIsUserIsPlatformAdmin(true);
    createNewUser(user, axios).then((newUser: User) => {
      if (newUser) {
        setLoadedServerUsers([...addUserToList(newUser, loadedServerUsers)]);
        companyUser.push(newUser);
        setCompanyUser([...companyUser]);
        generateNotification(
          t("notifications.userCreate.successTitle"),
          t("notifications.userCreate.successContent", {
            mail: newUser.mail,
          }),
          "success"
        );
      }
      toggleToBeCreatedIsUserIsPlatformAdmin(false);
    });
  };

  return (
    <>
      {showUserDeleteConfirmPopup && (
        <ConfirmPopupComponent
          title={t("general.buttons.delete")}
          yesText={t("general.buttons.yes")}
          noText={t("general.buttons.no")}
          closeFunction={() => setShowUserDeleteConfirmPopup(false)}
          content={t("popup.user.delete", {
            name: `${selectedUser.firstname} ${selectedUser.lastname}`,
          })}
          onYesFunction={deleteUserOnServerFromPopup}
        />
      )}

      {showCompanyDeleteConfirmPopup && (
        <ConfirmPopupComponent
          content={t("popup.company.delete", {
            name: selectedCompany.name,
          })}
          title={t("general.buttons.delete")}
          yesText={t("general.buttons.yes")}
          noText={t("general.buttons.no")}
          closeFunction={() => setShowCompanyDeleteConfirmPopup(false)}
          onYesFunction={deleteCompanyOnServer}
        />
      )}

      {showEditUserPopup && (
        <PopupComponent
          closeFunction={() => setShowEditUserPopup(false)}
          title={`${selectedUser.firstname} ${selectedUser.lastname}`}
          closeText={t("general.buttons.close")}
        >
          <p>
            <Trans i18nKey="popup.user.edit.description">
              Hier können Sie den jeweiligen Nutzer aktualisieren.
            </Trans>
          </p>
          <UserMetatag
            showRoleSelect={selectedUser.userRole !== UserRole.PLATFORMADMIN}
            isLoading={isUpdateUser}
            updateUser={selectedUser}
            onAdd={updateUser}
          />
        </PopupComponent>
      )}

      {showEditCompanyPopup && (
        <PopupComponent
          closeFunction={() => setShowEditCompanyPopup(false)}
          title={selectedCompany.name}
          closeText={t("general.buttons.close")}
        >
          <p>
            <Trans i18nKey="popup.user.edit.description">
              Hier könne Sie die jeweilige Firmendaten aktualisieren.
            </Trans>
          </p>
          <CompanyMetatag
            isLoading={isUpdateCompany}
            updateCompany={selectedCompany}
            onSubmit={(updateCompany: Company) => {
              setIsUpdateCompany(true);
              updateCompanyOnServer(axios, updateCompany).then(
                (status: number) => {
                  if (status === 200) {
                    let localTempCompanies: Company[] = loadedServerCompanies;
                    let foundIndex: number = localTempCompanies.findIndex(
                      (value: Company) => value.id === selectedCompany.id
                    );
                    localTempCompanies[foundIndex].name = updateCompany.name;
                    localTempCompanies[foundIndex].address =
                      updateCompany.address;
                    localTempCompanies[foundIndex].zipCode =
                      updateCompany.zipCode;
                    localTempCompanies[foundIndex].city = updateCompany.city;
                    localTempCompanies[foundIndex].license =
                      updateCompany.license;

                    setLoadedServerCompanies([...localTempCompanies]);
                    setShowEditCompanyPopup(false);

                    generateNotification(
                      t("notifications.companyEdit.successTitle"),
                      t("notifications.companyEdit.successContent", {
                        name: updateCompany.name,
                      }),
                      "success"
                    );
                  } else {
                    generateNotification(
                      t("notifications.companyEdit.errorTitle"),
                      t("notifications.companyEdit.errorContent", {
                        status: status,
                      }),
                      "danger",
                      -1
                    );
                  }
                  setIsUpdateCompany(false);
                }
              );
            }}
          />
        </PopupComponent>
      )}

      <div className="user-list-functions-wrapper">
        {isUserAllowedToDoThis(
          keycloakUser!.userRole,
          ModuleShowType.LOGO_ADMINISTRATION
        ) && <LogoAdministration />}
        {isKeyCloakUserAllowedToDoThis && (
          <CompanyCreateWorkflow
            onCreateUser={(newUser: User) =>
              setLoadedServerUsers([
                ...addUserToList(newUser, loadedServerUsers),
              ])
            }
            onCreateCompany={(newCompany: Company) =>
              setLoadedServerCompanies([
                ...addCompanyToList(newCompany, loadedServerCompanies),
              ])
            }
          />
        )}
        <BoxComponent
          subtext={
            <p>
              <Trans i18nKey="user.createBox.subText">Benutzer anlegen</Trans>
            </p>
          }
          title={
            isKeyCloakUserAllowedToDoThis
              ? t("user.createBox.platfromAdminTitle")
              : t("user.createBox.companyAdminTitle")
          }
          icon={<UsersAddIcon />}
        >
          <p>
            {isKeyCloakUserAllowedToDoThis
              ? t("user.createBox.platfromAdminDescription")
              : t("user.createBox.companyAdminDescription")}
          </p>
          <UserMetatag
            showRoleSelect
            companyList={loadedServerCompanies}
            isAdmin={isKeyCloakUserAllowedToDoThis}
            isLoading={toBeCreatedIsUserIsPlatformAdmin}
            onAdd={createNewUserOnServer}
            predefinedUserRole={
              isKeyCloakUserAllowedToDoThis
                ? UserRole.PLATFORMADMIN
                : UserRole.WORKER
            }
          />
        </BoxComponent>
      </div>

      <BoxComponent
        icon={<UsersAdminIcon />}
        subtext={
          <p>
            <Trans i18nKey="user.list.subText">
              Benutzerübersicht mit Sortierfunktion
            </Trans>
          </p>
        }
        title={t("user.list.title")}
      >
        <TableComponent
          filter
          headers={
            isKeyCloakUserAllowedToDoThis
              ? t("user.list.table-header", {
                  returnObjects: true,
                })
              : t("user.list.companyadmin-table-header", {
                  returnObjects: true,
                })
          }
          key={version}
          rows={loadedUsers}
        />
      </BoxComponent>

      {isKeyCloakUserAllowedToDoThis && (
        <BoxComponent
          subtext={
            <p>
              <Trans i18nKey="company.list.subText">
                Firmenübersicht mit Sortierfunktion
              </Trans>
            </p>
          }
          icon={<CompanyIcon />}
          title={t("company.title")}
        >
          <TableComponent
            filter
            key={version}
            headers={t("company.table-header", {
              returnObjects: true,
            })}
            rows={loadedCompanies}
          />
        </BoxComponent>
      )}
    </>
  );
};

export default AccountList;
