import { ProjektAkte } from "../../../utils/LeanAdmin/ProjektAkte.types";
import { doesSearchStringApply } from "../../../utils/LeanAdmin/ProjektAkteUtils";
import { ProjektAkteOverviewProps } from "./ProjektAkteOverview.types";
import ProjektAkteOverviewEntry from "./ProjektAkteOverviewEntry";

const ProjektAkteOverview: React.FC<ProjektAkteOverviewProps> = ({
  isViewerState,
  projektAkten,
  setProjektAkten,
  userCompany,
  setUserCompany,
  searchString,
  keycloakUser,
}) => {
  return (
    <>
      {projektAkten.length > 0 &&
        projektAkten
          .filter((projektAkte: ProjektAkte) =>
            searchString === ""
              ? true
              : doesSearchStringApply(projektAkte, searchString)
          )
          .map((projektAkte, projektAkteIndex) => (
            <ProjektAkteOverviewEntry
              key={`overview-entry-${projektAkte.title}-${projektAkteIndex}`}
              isViewerState={isViewerState}
              projektAkte={projektAkte}
              userCompany={userCompany}
              keycloakUser={keycloakUser}
              projektAkten={projektAkten}
              setProjektAkten={setProjektAkten}
              setUserCompany={setUserCompany}
            />
          ))}
    </>
  );
};

export default ProjektAkteOverview;
