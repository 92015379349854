import React, { Suspense, useContext, useEffect, useState } from "react";
import {
  LayoutComponent,
  LoaderComponent,
  TreeConfig,
} from "beelean-component-library";
import {
  getCorrectLanguageImage,
  toggleLanguage,
  useLayoutConfiguration,
} from "../utils/NavigationUtil";
import {
  CompanyContext,
  GlobaleApplicationSettings,
  KeycloakUserContext,
  TreeDataContext,
} from "./App";
import { Company, KeycloakUser } from "../utils/Interfaces";
import { getNameOfCompany } from "../utils/CompanyUtil";
import { isModuleValidInLicense } from "../utils/LicenseUtil";
import LeanAdminModul from "../components/modules/leanadminmodule/LeanAdminModul";
import {
  removeProzessBereichFromList,
  addLoadedProzessBereich,
  updateProzessBereichInList,
} from "../utils/LeanAdmin/LeanAdminModulUtils";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { LicenseModuleType } from "../utils/Enums";
import { ProzessBereich } from "../utils/LeanAdmin/LeanAdmin.types";

interface LeanAdminProzessBereichePageProps {
  keycloakUser: KeycloakUser;
  userCompany: Company;
  allCompanies: Company[];
  leanAdminProzessbereiche: ProzessBereich[];
  leanAdminTreeData?: TreeConfig;
  pdcaTreeData?: TreeConfig;
  projektAkteTreeData?: TreeConfig;
  leanProdTreeData?: TreeConfig;
  isViewerState: boolean;
  toggleViewerState(viewState: boolean): void;
  isDesktopMenuOpen: boolean;
  toggleIsDesktopMenuOpen(desktopOpenState: boolean): void;
  setLeanAdminProzessbereiche(prozessBereiche: ProzessBereich[]): void;
}

const Page: React.FC<LeanAdminProzessBereichePageProps> = ({
  keycloakUser,
  userCompany,
  allCompanies,
  leanAdminProzessbereiche,
  leanAdminTreeData,
  pdcaTreeData,
  leanProdTreeData,
  isViewerState,
  toggleViewerState,
  isDesktopMenuOpen,
  toggleIsDesktopMenuOpen,
  setLeanAdminProzessbereiche,
  projektAkteTreeData,
}) => {
  const history = useHistory();
  const [localProzessBereiche, setLocalProzessBereiche] = useState<
    ProzessBereich[]
  >(leanAdminProzessbereiche);
  const {
    text,
    isAdmin,
    username,
    homeFunction,
    logoutFunction,
    isPlatformAdmin,
    profileFunction,
    accountListFunction,
    showToggleViewEdit,
    isTest,
    lng,
  } = useLayoutConfiguration(keycloakUser);
  const { t } = useTranslation();

  // Location for expanding or collapse tree
  const [openNodes, setOpenNodes] = useState<string[]>([]);
  const [lastKey, setLastKey] = useState<string>("");
  const [localCompanyId, setLocalCompanyId] = useState<string>("");
  const [version, setVersion] = useState<number>(0);
  const location = useLocation<{
    openNodes: string[];
    lastKey: string;
    companyId: string;
  }>();
  useEffect(() => {
    if (location?.state !== undefined) {
      setOpenNodes(location.state.openNodes);
      setLastKey(location.state.lastKey);
      setLocalCompanyId(location.state.companyId);
      setVersion(version + 1);
    } else {
      history.push(`/`);
    }
    // eslint-disable-next-line
  }, [location]);

  /**
   * Keeps the Lean Admin Tree up to date
   */
  useEffect(() => {
    // update the loaded Prozessbereiche, because if not
    // the original loaded will be used
    setLeanAdminProzessbereiche([...localProzessBereiche]);
    // eslint-disable-next-line
  }, [localProzessBereiche]);

  return (
    <LayoutComponent
      noDataInTree={t("tree.noData")}
      isTest={isTest}
      adminProjektAkteTreeData={projektAkteTreeData}
      showToggleViewEdit={showToggleViewEdit}
      isDesktopMenuOpen={isDesktopMenuOpen}
      onToogleDesktopMenuOpen={() =>
        toggleIsDesktopMenuOpen(!isDesktopMenuOpen)
      }
      viewEditStatus={isViewerState}
      onToggleViewEditFunction={() => toggleViewerState(!isViewerState)}
      showLeanAdminTree={
        isPlatformAdmin ||
        isModuleValidInLicense(
          LicenseModuleType.ADMINISTRATION,
          userCompany.license
        )
      }
      showLeanProdTree={
        isPlatformAdmin ||
        isModuleValidInLicense(
          LicenseModuleType.PRODUCTION,
          userCompany.license
        )
      }
      text={text}
      title={t("modules.leanAdminModul.headline")}
      leanProdTreeData={leanProdTreeData}
      // integrate the current location state
      leanAdminTreeData={{
        ...leanAdminTreeData!,
        openNodes: openNodes,
        lastSelectedKey: lastKey,
      }}
      adminPdcaTreeData={pdcaTreeData}
      username={username}
      homeFunction={() => homeFunction()}
      logoutFunction={() => logoutFunction()}
      onUserAdminFunction={() => accountListFunction()}
      onCreateLineFunction={() => console.log("Cretae Line!")}
      isAdmin={isAdmin}
      profileFunction={() => profileFunction()}
      key={localCompanyId}
      version={t("general.version")}
      languageImage={getCorrectLanguageImage(lng)}
      onChangeLanguage={toggleLanguage}
    >
      <LeanAdminModul
        onDeleteSuccess={(id: string) => {
          // update the Prozessbereiche too
          setLocalProzessBereiche(
            removeProzessBereichFromList(id, localProzessBereiche)
          );
        }}
        onUpdate={(prozessBereich: ProzessBereich) =>
          setLocalProzessBereiche(
            updateProzessBereichInList(prozessBereich, localProzessBereiche)
          )
        }
        onCreateSuccess={(prozessBereich: ProzessBereich) => {
          // update the Prozessbereiche too
          setLocalProzessBereiche(
            addLoadedProzessBereich(prozessBereich, localProzessBereiche)
          );
        }}
        keycloakUser={keycloakUser}
        companyId={localCompanyId}
        companyName={
          isPlatformAdmin
            ? getNameOfCompany(localCompanyId, allCompanies)
            : userCompany.name
        }
      />
    </LayoutComponent>
  );
};

const LeanAdminProzessBereichePage = () => {
  // needed context
  const { keycloakUser } = useContext(KeycloakUserContext);
  const { userCompany, allCompanies } = useContext(CompanyContext);
  const {
    leanAdminTreeData,
    leanProdTreeData,
    pdcaTreeData,
    leanAdminProzessbereiche,
    setLeanAdminProzessbereiche,
    projektAkteTreeData,
  } = useContext(TreeDataContext);
  const {
    isViewerState,
    toggleViewerState,
    isDesktopMenuOpen,
    toggleIsDesktopMenuOpen,
  } = useContext(GlobaleApplicationSettings);
  const { t } = useTranslation();

  return keycloakUser ? (
    <Suspense
      fallback={
        <LoaderComponent inFront showText text={t("general.loading")} />
      }
    >
      <Page
        setLeanAdminProzessbereiche={setLeanAdminProzessbereiche}
        isDesktopMenuOpen={isDesktopMenuOpen}
        toggleIsDesktopMenuOpen={toggleIsDesktopMenuOpen}
        isViewerState={isViewerState}
        toggleViewerState={toggleViewerState}
        keycloakUser={keycloakUser}
        userCompany={userCompany}
        allCompanies={allCompanies}
        leanAdminProzessbereiche={leanAdminProzessbereiche}
        pdcaTreeData={pdcaTreeData}
        leanAdminTreeData={leanAdminTreeData}
        leanProdTreeData={leanProdTreeData}
        projektAkteTreeData={projektAkteTreeData}
      />
    </Suspense>
  ) : (
    <LoaderComponent inFront showText text={t("general.loading")} />
  );
};

export default LeanAdminProzessBereichePage;
