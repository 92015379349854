import {
  BoxComponent,
  CheckboxComponent,
  GaugeComponent,
  InputComponent,
} from "beelean-component-library";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ReactComponent as LeanAdminIcon } from "../../assets/icons/leanadmin.svg";
import { useAxios } from "../../utils/AxiosUtil";
import { getNameOfCompany } from "../../utils/CompanyUtil";
import { UserRole } from "../../utils/Enums";
import { getFormattedDateTimeString } from "../../utils/GeneralUtil";
import {
  HistoryEntry,
  ProzessOverview,
} from "../../utils/LeanAdmin/LeanAdmin.types";
import {
  getAllLatestVersionsOfProzessBereichForCompany,
  getProzessOverviewFulfillment,
} from "../../utils/LeanAdmin/LeanAdminModulUtils";
import { getProzessOverviewByID } from "../../utils/LeanAdmin/ProzessOverview.axios";
import { isUserAllowedForLeanAdminCheck } from "../../utils/LicenseUtil";
import { getNameForUserId } from "../../utils/UserUtil";
import {
  IntroLeanAdminCheckProps,
  ProzessBereichWithCompanyName,
} from "./Intro.types";
import "./IntroStyle.scss";

const IntroLeanAdminCheck: React.FC<IntroLeanAdminCheckProps> = ({
  keycloakUser,
  company,
  prozessBereiche,
  loadedServerCompanies,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [searchString, setSearchString] = useState<string>("");
  const [
    listOfProzessBereichWithCompanyName,
    setListOfProzessBereichWithCompanyName,
  ] = useState<ProzessBereichWithCompanyName[]>([]);
  const axios = useAxios();
  const [uidNameMap, setUidNameMap] = useState<Map<string, string>>(
    new Map<string, string>()
  );
  const [prozessOverviews, setProzessOverviews] = useState<ProzessOverview[]>(
    []
  );

  /**
   * Helper to fill list of prozessbereiche with company names and load latest release versions for them
   */
  const buildListOfLocalProzessBereicheWithCompanyName =
    async (): Promise<void> => {
      let localListOfProzessBereicheWithCompanyName: ProzessBereichWithCompanyName[] =
        [];
      //in case of plattform admin the company id is differing so we create a map to contain the releases in
      const companyReleasesMap: Map<string, HistoryEntry[]> = new Map<
        string,
        HistoryEntry[]
      >();
      const localUidNameMap: Map<string, string> = uidNameMap;
      for (const prozessBereich of prozessBereiche) {
        //get latest history entry and name for last updater and add it to bereich
        const companyIdForLatestRelease: string =
          prozessBereich.companyId || company.id || "";
        let releasesForCompany: HistoryEntry[] | undefined =
          companyReleasesMap.get(companyIdForLatestRelease);
        if (!releasesForCompany && companyIdForLatestRelease) {
          releasesForCompany =
            await getAllLatestVersionsOfProzessBereichForCompany(
              axios,
              companyIdForLatestRelease
            );
          if (releasesForCompany)
            companyReleasesMap.set(
              companyIdForLatestRelease,
              releasesForCompany
            );
        }
        let latestReleaseForBereich: HistoryEntry | undefined =
          releasesForCompany?.find(
            (entry) => entry.bereichSnapshot.id === prozessBereich.id
          );
        if (latestReleaseForBereich) {
          let nameForLatestHitory: string =
            localUidNameMap.get(latestReleaseForBereich.lastUpdatedBy) || "";
          if (!nameForLatestHitory) {
            nameForLatestHitory = await getNameForUserId(
              latestReleaseForBereich.lastUpdatedBy,
              axios
            );
            if (nameForLatestHitory)
              localUidNameMap.set(
                latestReleaseForBereich.lastUpdatedBy,
                nameForLatestHitory
              );
          }
          latestReleaseForBereich.lastUpdatedBy =
            nameForLatestHitory || t("modules.SIPOC.unknown");
        }

        //get name of last updater of bereich
        let loadedName: string =
          localUidNameMap.get(prozessBereich.lastUpdatedBy) || "";
        if (!loadedName) {
          loadedName = await getNameForUserId(
            prozessBereich.lastUpdatedBy,
            axios
          );
          if (loadedName)
            localUidNameMap.set(prozessBereich.lastUpdatedBy, loadedName);
        }
        localListOfProzessBereicheWithCompanyName.push({
          prozessBereich: prozessBereich,
          companyName: getNameOfCompany(
            prozessBereich.companyId,
            loadedServerCompanies
          ),
          lastUpdaterName: loadedName || t("modules.SIPOC.unknown"),
          latestRelease: latestReleaseForBereich,
        });
      }
      setUidNameMap(localUidNameMap);
      setListOfProzessBereichWithCompanyName(
        localListOfProzessBereicheWithCompanyName
      );
    };

  /**
   * fetches all needed prozessOverviews for loaded bereiche
   */
  const fetchAllNeededProzessOverviews = async (): Promise<void> => {
    let overviewCalls = [];
    for (const bereich of prozessBereiche) {
      if (!bereich.prozessOverviewId) continue;
      overviewCalls.push(
        getProzessOverviewByID(axios, bereich.prozessOverviewId)
      );
    }
    const overviewsForBereiche: ProzessOverview[] = (
      await Promise.all(overviewCalls)
    ).filter((overview) => !!overview);
    setProzessOverviews(overviewsForBereiche);
  };

  /**
   * This UseEffect fills up the list of
   * Prozessbereiche with the company name in it and fetches all needed prozessOverviews
   */
  useEffect(() => {
    if (!axios) return;
    buildListOfLocalProzessBereicheWithCompanyName();
    fetchAllNeededProzessOverviews();
    // eslint-disable-next-line
  }, [loadedServerCompanies, prozessBereiche, axios]);

  return (
    <>
      {isUserAllowedForLeanAdminCheck(company, keycloakUser.userRole) && (
        <BoxComponent
          icon={<LeanAdminIcon />}
          subtext={
            <p>
              <Trans i18nKey="intro.adminCheck.subText">
                Hier können Sie Ihre Lizenzdaten einsehen
              </Trans>
            </p>
          }
          title={t("intro.adminCheck.title")}
        >
          <InputComponent
            className="lean-admin-check-entry-search"
            onChange={(value: string) => setSearchString(value)}
            value={searchString}
            placeholder={t("general.buttons.search")}
          />

          {listOfProzessBereichWithCompanyName
            .filter((filteredProzessBereich: ProzessBereichWithCompanyName) =>
              searchString === ""
                ? true
                : filteredProzessBereich.prozessBereich.bereich
                    .toUpperCase()
                    .indexOf(searchString.toUpperCase()) !== -1 ||
                  filteredProzessBereich.companyName
                    .toUpperCase()
                    .indexOf(searchString.toUpperCase()) !== -1
            )
            .map((prozess, prozessIndex) => {
              const overviewFulfillment: number = getProzessOverviewFulfillment(
                prozess.prozessBereich,
                prozessOverviews
              );
              return (
                <div
                  className="lean-admin-check-entry-wrapper"
                  key={`lean-admin-check-entry-wrapper-${prozessIndex}`}
                >
                  <div className="lean-admin-check-entry">
                    <p>
                      <b
                        className="lean-admin-check-entry-link-button"
                        onClick={() =>
                          keycloakUser.userRole !== UserRole.PLATFORMADMIN &&
                          history.push(`/administration/company/prozessmap`, {
                            lastKey: `lean-admin-root/${company.id}/${prozess.prozessBereich.id}`,
                            openNodes: [
                              "lean-admin-root",
                              company.id,
                              prozess.prozessBereich.id,
                            ],
                            companyId: company.id,
                            prozessBereichId: prozess.prozessBereich.id,
                          })
                        }
                      >
                        {prozess.prozessBereich.bereich}
                        {keycloakUser.userRole === UserRole.PLATFORMADMIN && (
                          <>
                            <br />
                            {prozess.companyName}
                          </>
                        )}
                      </b>
                      <br />
                      <br />
                      <div>
                        <div>{t("intro.adminCheck.released")}</div>
                        <CheckboxComponent
                          checked={!!prozess.latestRelease}
                          label={
                            prozess.latestRelease
                              ? `v${
                                  prozess.latestRelease.version
                                } ${getFormattedDateTimeString(
                                  prozess.latestRelease.lastUpdated
                                )} ${prozess.latestRelease.lastUpdatedBy}`
                              : t("intro.adminCheck.notReleased")
                          }
                          disabled
                          onClick={() => {}}
                        />
                      </div>
                    </p>
                    <div className="lean-admin-check-entry-status">
                      <GaugeComponent
                        percent={
                          prozess.prozessBereich.totalEgProzessMapping > 0
                            ? prozess.prozessBereich.correcEgProzessMapping /
                              prozess.prozessBereich.totalEgProzessMapping
                            : 0.0
                        }
                        title={t("intro.adminCheck.prozessMapping")}
                        subtitle={`${prozess.prozessBereich.correcEgProzessMapping}/${prozess.prozessBereich.totalEgProzessMapping}`}
                      />
                      <GaugeComponent
                        percent={
                          prozess.prozessBereich.totalEgSipoc > 0
                            ? prozess.prozessBereich.correcEgSipoc /
                              prozess.prozessBereich.totalEgSipoc
                            : 0.0
                        }
                        title={t("intro.adminCheck.sipoc")}
                        subtitle={`${prozess.prozessBereich.correcEgSipoc}/${prozess.prozessBereich.totalEgSipoc}`}
                      />
                      <GaugeComponent
                        percent={overviewFulfillment / 5}
                        title={t("intro.adminCheck.overview")}
                        subtitle={`${overviewFulfillment}/5`}
                      />
                    </div>
                  </div>
                  <div className="lean-admin-check-entry-last-update">
                    <p>
                      {t("modules.SIPOC.lastUpdate", {
                        replace: {
                          date: getFormattedDateTimeString(
                            prozess.prozessBereich.lastUpdated ||
                              prozess.prozessBereich.createDate
                          ),
                          by: prozess.lastUpdaterName,
                        },
                      })}
                    </p>
                  </div>
                </div>
              );
            })}
        </BoxComponent>
      )}
    </>
  );
};

export default IntroLeanAdminCheck;
