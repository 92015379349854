import { BoxComponent } from "beelean-component-library";
import React, { ReactNode, RefObject, useEffect, useState } from "react";
import { ReactComponent as ProzessMappingIcon } from "../../../assets/icons/license.svg";

import BestandImage from "../../../assets/icons/prozessmapping/Bestand.svg";
import DienstleisterImage from "../../../assets/icons/prozessmapping/Dienstleister.svg";
import KaizenImage from "../../../assets/icons/prozessmapping/Kaizen.svg";
import KundeImage from "../../../assets/icons/prozessmapping/Kunde.svg";
import ProzessImage from "../../../assets/icons/prozessmapping/Prozess.svg";
import ProzessSimpleImage from "../../../assets/images/prozessmapping/ProzesssimpleToolbox.png";

import { Trans, useTranslation } from "react-i18next";
import { Prozessschritttype } from "../../../utils/LeanAdmin/LeanAdmin.types";

interface ProzessmappingToolboxProps {
  onSelect(type: Prozessschritttype): void;
  stageRefObject: RefObject<any>;
  onDropFunction(event: React.TouchEvent<HTMLImageElement>): void;
}

const ProzessmappingToolbox: React.FC<ProzessmappingToolboxProps> = ({
  onSelect,
  stageRefObject,
  onDropFunction: dropFunction,
}) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState<Prozessschritttype>(
    Prozessschritttype.PROCESS
  );

  useEffect(() => {
    onSelect(selectedType);
    // eslint-disable-next-line
  }, [selectedType]);

  /**
   * Generate image entry in toolbox
   *
   * @param type
   * @param image
   * @returns
   */
  const generateImageOfType = (
    type: Prozessschritttype,
    image: string
  ): ReactNode => {
    return (
      <img
        onDragStart={() => setSelectedType(type)}
        onTouchStart={() => {
          setSelectedType(type);
        }}
        onTouchEnd={(event) => {
          stageRefObject.current.setPointersPositions(event);
          event.preventDefault();
          dropFunction(event);
        }}
        draggable
        alt={t(`modules.prozessMapping.prozessSchritte.${type}`)}
        src={image}
        title={t(`modules.prozessMapping.prozessSchritte.${type}`)}
        onClick={() => setSelectedType(type)}
        className={selectedType === type ? "selected" : ""}
      />
    );
  };

  return (
    <div className="process-mapping-drawing-toolbox-wrapper">
      <BoxComponent
        icon={<ProzessMappingIcon />}
        title={t("modules.prozessMapping.toolbox.title")}
        subtext={
          <p>
            <Trans i18nKey="modules.prozessMapping.toolbox.choose">
              Bitte wählen Sie
            </Trans>
          </p>
        }
      >
        <div className="process-mapping-drawing-toolbox-item">
          {generateImageOfType(Prozessschritttype.PROCESS, ProzessImage)}
          {generateImageOfType(
            Prozessschritttype.PROCESSSIMPLE,
            ProzessSimpleImage
          )}
        </div>
        <div className="process-mapping-drawing-toolbox-item">
          {generateImageOfType(Prozessschritttype.KAIZEN, KaizenImage)}
          {generateImageOfType(Prozessschritttype.EXTERN, DienstleisterImage)}
        </div>
        <div className="process-mapping-drawing-toolbox-item">
          {generateImageOfType(Prozessschritttype.KUNDEN, KundeImage)}
          {generateImageOfType(Prozessschritttype.BESTAND, BestandImage)}
        </div>
      </BoxComponent>
    </div>
  );
};

export default ProzessmappingToolbox;
