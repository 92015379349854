import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import BeeLeanLogo from "../assets/images/logo.png";
import { getCurrentCompanyLogo } from "./CompanyUtil";
import { AxiosInstance } from "axios";
import { TableRow } from "beelean-component-library";
import "jspdf-autotable";
import i18next from "i18next";
import { convertPdcaTableToPdfArray } from "./Pdca/PdcaUtil";

/**
 * generates export png for prozessmapping
 *
 * @param prozessBereichName
 */
export const generateProzessMappingExportAsPng = async (
  prozessBereichName: string,
  returnImage: boolean = false
): Promise<void | File> => {
  const dateString: string = new Date().toLocaleDateString("de-DE");
  const prozessMappingOverviewWrapper = document.getElementsByClassName(
    "konvajs-content"
  )[0] as HTMLElement;
  // stop it is data is missing
  if (!prozessMappingOverviewWrapper) {
    return;
  }
  return html2canvas(prozessMappingOverviewWrapper, {
    allowTaint: true,
  }).then((sipocOverviewCanvas: HTMLCanvasElement) => {
    if (returnImage) {
      return canvasToFile(sipocOverviewCanvas, "prozessmap.png");
    }
    // export as png
    else
      savePngFile(
        sipocOverviewCanvas,
        prozessBereichName + "_prozessmapping_export_" + dateString
      );
  });
};

/**
 * Helper to generate a file from a canvas image
 *
 * @param dataurl
 * @param filename
 * @returns File
 */
export const canvasToFile = (
  dataurl: HTMLCanvasElement,
  filename: string
): File => {
  let blobBin = atob(dataurl.toDataURL("image/png").split(",")[1]);
  let array = [];
  for (let index = 0; index < blobBin.length; index++) {
    array.push(blobBin.charCodeAt(index));
  }
  let generatedBlob: any = new Blob([new Uint8Array(array)], {
    type: "image/png",
  });
  generatedBlob.lastModifiedDate = new Date();
  generatedBlob.name = filename;
  return generatedBlob;
};

/**
 * generates export pdf for prozessmapping
 *
 * @param prozessBereichName
 */
export const generateProzessMappingExportAsPdf = async (
  prozessBereichName: string,
  companyName: string,
  companyLogoName: string,
  axios: AxiosInstance
): Promise<void> => {
  // configure jsPDF file
  let pdfDoc = new jsPDF({
    unit: "px",
    orientation: "landscape",
    format: "a4",
    compress: true,
  });
  const pageWidth: number = pdfDoc.internal.pageSize.getWidth();
  const pageHeight: number = pdfDoc.internal.pageSize.getHeight();
  const dateString: string = new Date().toLocaleDateString("de-DE");
  const prozessMappingOverviewWrapper = document.getElementsByClassName(
    "konvajs-content"
  )[0] as HTMLElement;
  // stop it is data is missing
  if (!prozessMappingOverviewWrapper) {
    return;
  }
  await html2canvas(prozessMappingOverviewWrapper, {
    allowTaint: true,
  }).then((sipocOverviewCanvas: HTMLCanvasElement) => {
    // add overview
    // export as pdf
    const ratioWidth: number = (pageWidth - 60) / sipocOverviewCanvas.width;
    const ratioHeight: number = (pageHeight - 100) / sipocOverviewCanvas.height;
    const isHeightRatio: boolean = ratioWidth > ratioHeight;

    pdfDoc.addImage(
      sipocOverviewCanvas,
      "JPG",
      30,
      50,
      isHeightRatio ? sipocOverviewCanvas.width * ratioHeight : pageWidth - 60,
      isHeightRatio
        ? pageHeight - 100
        : sipocOverviewCanvas.height * ratioWidth,
      undefined,
      "FAST" as any
    );
  });

  // adds the company logo image
  const tempCompanyImageResponse: { doc: jsPDF; imgWidth: number } =
    await addCompanyImage(pdfDoc, companyLogoName, axios);
  pdfDoc = tempCompanyImageResponse.doc;
  // add footer and header to pages
  pdfDoc = addHeaderAndFooter(
    pdfDoc,
    dateString,
    prozessBereichName,
    companyName,
    tempCompanyImageResponse.imgWidth
  );
  // save it to disk
  savePDFFile(
    pdfDoc,
    prozessBereichName + "_prozessmapping_export_" + dateString
  );
};

/**
 * saves the data as png locally
 *
 * @param sipocOverviewCanvas
 * @param filename
 */
const savePngFile = (
  sipocOverviewCanvas: HTMLCanvasElement,
  filename: string
): void => {
  const createdATagElement = document.createElement("a");
  createdATagElement.href = sipocOverviewCanvas
    .toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
  createdATagElement.download = `${filename}.png`;
  createdATagElement.click();
};

/**
 * generates .pdf file for saving it on the disk of the sipoc evaluation
 *
 * @param prozessschrittAmount
 * @param prozessBereichName
 */
export const generateSipocExport = async (
  prozessschrittAmount: number,
  prozessBereichName: string,
  companyName: string,
  companyLogoName: string,
  axios: AxiosInstance,
  checkListName?: string
): Promise<void> => {
  // configure jsPDF file
  let pdfDoc = new jsPDF({
    unit: "px",
    orientation: "landscape",
    format: "a4",
    compress: true,
  });
  const pageWidth: number = pdfDoc.internal.pageSize.getWidth();
  const pageHeight: number = pdfDoc.internal.pageSize.getHeight();
  const dateString: string = new Date().toLocaleDateString("de-DE");

  // export overview
  const sipocOverviewWrapper = document.getElementById(
    "sipoc-overview-export-wrapper"
  );
  // top it is data is missing
  if (!sipocOverviewWrapper) return;
  // convert it to image
  await html2canvas(sipocOverviewWrapper, {
    allowTaint: true,
  }).then((sipocOverviewCanvas: HTMLCanvasElement) => {
    const ratioWidth: number = (pageWidth - 60) / sipocOverviewCanvas.width;
    const ratioHeight: number = (pageHeight - 100) / sipocOverviewCanvas.height;
    const isHeightRatio: boolean = ratioWidth > ratioHeight;
    // add overview
    pdfDoc.addImage(
      sipocOverviewCanvas,
      "JPG",
      30,
      50,
      isHeightRatio ? sipocOverviewCanvas.width * ratioHeight : pageWidth - 60,
      isHeightRatio
        ? pageHeight - 100
        : sipocOverviewCanvas.height * ratioWidth,
      undefined,
      "FAST" as any
    );
  });

  // add every detailview image to pdf
  for (let index = 0; index < prozessschrittAmount; index++) {
    // get one detailview section
    const currentSipocDetailView = document.getElementById(
      `sipoc-detail-export-wrapper-${index}`
    );
    if (!currentSipocDetailView) continue;

    // add a new page
    pdfDoc.addPage();
    await html2canvas(currentSipocDetailView, {
      allowTaint: true,
      ignoreElements: (element: HTMLElement) => {
        if (
          element.classList.contains("sipoc-detailview-checkbox") &&
          element.classList.length === 1
        ) {
          return true;
        }
        // if element should be displayed
        return false;
      },
      // eslint-disable-next-line
    }).then((sipocDetailviewCanvas: HTMLCanvasElement) => {
      const ratioWidth: number = (pageWidth - 60) / sipocDetailviewCanvas.width;
      const ratioHeight: number =
        (pageHeight - 100) / sipocDetailviewCanvas.height;
      const isHeightRatio: boolean = ratioWidth > ratioHeight;

      // add overview
      pdfDoc.addImage(
        sipocDetailviewCanvas,
        "JPG",
        30,
        50,
        isHeightRatio
          ? sipocDetailviewCanvas.width * ratioHeight
          : pageWidth - 60,
        isHeightRatio
          ? pageHeight - 100
          : sipocDetailviewCanvas.height * ratioWidth,
        undefined,
        "FAST" as any
      );
    });
  }

  // adds the company logo image
  const tempCompanyImageResponse: { doc: jsPDF; imgWidth: number } =
    await addCompanyImage(pdfDoc, companyLogoName, axios);
  pdfDoc = tempCompanyImageResponse.doc;
  // add footer and header to pages
  pdfDoc = addHeaderAndFooter(
    pdfDoc,
    dateString,
    checkListName
      ? `${checkListName} (${prozessBereichName})`
      : prozessBereichName,
    companyName,
    tempCompanyImageResponse.imgWidth
  );
  const modifiedPdfFileName: string = (
    checkListName
      ? `${checkListName}_Checkliste`
      : `${prozessBereichName}_sipoc_export`
  ).replace(" ", "-");
  // save it to disk
  savePDFFile(pdfDoc, `${modifiedPdfFileName}_${dateString}`);
};

/**
 * Util method to export the overview of the process
 * @param prozessschrittAmount - amount of schritte
 * @param prozessBereichName - name of the given Prozessbereich
 * @param companyName  - name of the company
 * @param companyLogoName  - name of the company logo
 * @param axios  - axios instance
 * @param checkListName  - name of the checklist
 */
export const generateOverviewExport = async (
  prozessschrittAmount: number,
  prozessBereichName: string,
  companyName: string,
  companyLogoName: string,
  axios: AxiosInstance,
  checkListName?: string
): Promise<void> => {
  // configure jsPDF file
  let pdfDoc = new jsPDF({
    unit: "px",
    orientation: "landscape",
    format: "a4",
    compress: true,
  });
  const pageWidth: number = pdfDoc.internal.pageSize.getWidth();
  const pageHeight: number = pdfDoc.internal.pageSize.getHeight();
  const dateString: string = new Date().toLocaleDateString("de-DE");

  // export overview
  const overviewWrapper = document.getElementById("prozess-overview");
  const exportBody = document.getElementById("export-content");
  // top it is data is missing

  if (!overviewWrapper || !exportBody) return;
  // convert it to image
  exportBody.style.display = "block";
  await html2canvas(overviewWrapper, {
    allowTaint: true,
  }).then((overviewCanvas: HTMLCanvasElement) => {
    const ratioWidth: number = (pageWidth - 60) / overviewCanvas.width;
    const ratioHeight: number = (pageHeight - 100) / overviewCanvas.height;
    const isHeightRatio: boolean = ratioWidth > ratioHeight;
    // add overview
    pdfDoc.addImage(
      overviewCanvas,
      "JPG",
      30,
      50,
      isHeightRatio ? overviewCanvas.width * ratioHeight : pageWidth - 60,
      isHeightRatio ? pageHeight - 100 : overviewCanvas.height * ratioWidth,
      undefined,
      "FAST" as any
    );
  });

  // adds the company logo image
  const tempCompanyImageResponse: { doc: jsPDF; imgWidth: number } =
    await addCompanyImage(pdfDoc, companyLogoName, axios);
  pdfDoc = tempCompanyImageResponse.doc;
  // add footer and header to pages
  pdfDoc = addHeaderAndFooter(
    pdfDoc,
    dateString,
    checkListName
      ? `${checkListName} (${prozessBereichName})`
      : prozessBereichName,
    companyName,
    tempCompanyImageResponse.imgWidth
  );
  const modifiedPdfFileName: string = (
    checkListName
      ? `${checkListName}_Checkliste`
      : `${prozessBereichName}_sipoc_export`
  ).replace(" ", "-");
  // save it to disk
  savePDFFile(pdfDoc, `${modifiedPdfFileName}_${dateString}`);
  exportBody.style.display = "none";
};

/**
 * add footer and header to document
 *
 * @param pdfDoc
 * @param dateString
 * @returns the pdf file
 */
const addHeaderAndFooter = (
  pdfDoc: jsPDF,
  dateString: string,
  name: string,
  companyName: string,
  imgWidth: number
): jsPDF => {
  // Footer and Header for each Page
  for (let i = 1; i < pdfDoc.internal.pages.length; i++) {
    pdfDoc.setPage(i);

    // Header
    pdfDoc.addImage(BeeLeanLogo, "PNG", 550, 0, 50, 50);
    pdfDoc.setFontSize(10);
    pdfDoc.text(name, 30 + imgWidth, 20);
    pdfDoc.text(companyName, 30 + imgWidth, 30);

    // Footer
    pdfDoc.setFontSize(8);
    pdfDoc.text(`Bee Lean (${dateString})`, 25, 420);
    pdfDoc.text(`${i}`, 600, 420);
  }
  return pdfDoc;
};

/**
 * creates a fake link tag for all devices download
 *
 * @param pdfDoc
 */
const savePDFFile = (pdfDoc: jsPDF, filename: string) => {
  const pdfData = new Blob([pdfDoc.output("blob")], {
    type: "application/pdf",
  });
  const pdfUrl = URL.createObjectURL(pdfData);
  const createdATagElement = document.createElement("a");
  createdATagElement.setAttribute("download", `${filename}.pdf`);
  createdATagElement.href = pdfUrl;
  // add, click and remove the button
  document.body.appendChild(createdATagElement);
  createdATagElement.click();
  createdATagElement.remove();
};

/**
 * Helper to add an image to a pdf file from the company
 *
 * @param pdfDoc
 * @param logoName
 * @param axios
 * @returns pdfDoc and width of the current company image
 */
const addCompanyImage = (
  pdfDoc: jsPDF,
  logoName: string,
  axios: AxiosInstance
): Promise<{ doc: jsPDF; imgWidth: number }> => {
  return getCurrentCompanyLogo(logoName, axios).then(async (logoResponse) => {
    if (logoResponse) {
      let convertedImageTag: HTMLImageElement = await convertBlobToImageTag(
        logoResponse
      );
      const imgHeight: number = convertedImageTag.height;
      const imgWidth: number = convertedImageTag.width;
      const maxHeight: number = 30;
      const convertedHeightRatio: number = maxHeight / imgHeight;
      for (let i = 1; i < pdfDoc.internal.pages.length; i++) {
        pdfDoc.setPage(i);

        pdfDoc.addImage(
          convertedImageTag,
          "JPG",
          23,
          15,
          imgWidth * convertedHeightRatio,
          imgHeight * convertedHeightRatio
        );
      }
      return { doc: pdfDoc, imgWidth: imgWidth * convertedHeightRatio };
    } else {
      return { doc: pdfDoc, imgWidth: 0 };
    }
  });
};

/**
 * Helper to convert a given blob to an existing HTML image tag
 *
 * @param blob
 * @returns HTML image tag
 */
const convertBlobToImageTag = (blob: Blob): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    let generatedImageTag: HTMLImageElement = new Image();
    generatedImageTag.onload = () => {
      resolve(generatedImageTag);
    };
    generatedImageTag.onerror = reject;
    generatedImageTag.src = URL.createObjectURL(blob);
  });
};

/**
 * generates .pdf file for saving it on the disk of the pdca table
 *
 * @param tableEntries
 * @param companyName
 * @param companyLogoName
 * @param axios
 */
export const generatePdcaExportTable = async (
  tableEntries: TableRow[],
  companyName: string,
  companyLogoName: string,
  axios: AxiosInstance
): Promise<void> => {
  // configure jsPDF file
  let pdfDoc = new jsPDF({
    unit: "px",
    orientation: "landscape",
    format: "a4",
    compress: true,
  });
  const dateString: string = new Date().toLocaleDateString("de-DE");

  // @ts-ignore
  pdfDoc.autoTable({
    styles: { overflow: "linebreak" },
    headStyles: { textColor: [255, 255, 255], fillColor: [13, 171, 148] },
    alternateRowStyles: {
      fillColor: [212, 222, 221],
    },
    bodyStyles: { minCellHeight: 20 },
    margin: { top: 50, bottom: 50 },
    head: [
      i18next.t("pdca.exportTableHeader", {
        returnObjects: true,
      }),
    ],
    body: convertPdcaTableToPdfArray(tableEntries),
    didParseCell: (data: any) => {
      // clear the text before drawing. Otherwise the base64 String is shown
      if (data.column.index === 7 && data.cell.section === "body") {
        data.cell.text = [];
      }
    },
    didDrawCell: (data: any) => {
      if (data.column.index === 7 && data.cell.section === "body") {
        pdfDoc.addImage(
          data.cell.raw,
          data.cell.x + 5,
          data.cell.y + 3,
          20,
          20
        );
      }
    },
  });

  // adds the company logo image
  const tempCompanyImageResponse: { doc: jsPDF; imgWidth: number } =
    await addCompanyImage(pdfDoc, companyLogoName, axios);
  pdfDoc = tempCompanyImageResponse.doc;
  // add footer and header to pages
  pdfDoc = addHeaderAndFooter(
    pdfDoc,
    dateString,
    i18next.t("modules.pdca.headline"),
    companyName,
    tempCompanyImageResponse.imgWidth
  );

  // save it to disk
  savePDFFile(pdfDoc, `PDCA`);
};
