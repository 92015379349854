import {
  ButtonComponent,
  GaugeComponent,
  InputComponent,
  Option,
  TextAreaComponent,
} from "beelean-component-library";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ProjektAkteMetatagStyle.scss";
import { ProjektAkte } from "../../../utils/LeanAdmin/ProjektAkte.types";
import {
  updateProjektAkte,
  validateMilestones,
} from "../../../utils/LeanAdmin/ProjektAkteUtils";
import {
  getSelectOptionsForUser,
  isUserAllowedToDoThis,
} from "../../../utils/UserUtil";
import { ProjektAkteMetatagProps } from "./ProjektAkteMetatag.types";
import ProjektAkteUserRoleMapping from "./ProjektAkteUserRoleMapping";
import ProjektAkteProzessSchrittEndDateMapping from "./ProjektAkteProzessSchrittEndDateMapping";
import { useAxios } from "../../../utils/AxiosUtil";
import { KeycloakUserContext } from "../../../pages/App";
import { generateNotification } from "../../../utils/NotificationUtil";
import { ModuleShowType } from "../../../utils/Enums";
import "./ProjektAkteOverviewEntryStyle.scss";
import {
  getAmountOfCheckedElementsForProzessschritte,
  getTotalAmountOfElementsToCheck,
} from "../../../utils/LeanAdmin/SipocChecklistUtils";
import { getFormattedDateTimeString } from "../../../utils/GeneralUtil";

const ProjektAkteMetatag: React.FC<ProjektAkteMetatagProps> = ({
  companyUser,
  projektAkte,
  setProjektAkte,
  isViewerState,
  sipocChecklist,
  updateProjektAkteInContext,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { keycloakUser } = useContext(KeycloakUserContext);
  const axios = useAxios();
  useState<Option[]>([]);
  const [userSelectOptions, setUserSelectOptions] = useState<Option[]>([]);
  const [checkedElements, setCheckedElements] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);

  const [localProjektAkte, setLocalProjektAkte] =
    useState<ProjektAkte>(projektAkte);

  //prepares user select options
  useEffect(() => {
    if (companyUser) {
      let currentUserSelectOptions: Option[] =
        getSelectOptionsForUser(companyUser);
      setUserSelectOptions(currentUserSelectOptions);
    }
    // eslint-disable-next-line
  }, [companyUser]);

  //changes loaded ProjektAkte, when another one is chosen from tree
  useEffect(() => {
    setLocalProjektAkte(projektAkte);
    // eslint-disable-next-line
  }, [projektAkte]);

  // sets checked and total elements initially
  useEffect(() => {
    if (!sipocChecklist || !projektAkte.prozessMapping) return;
    if (sipocChecklist.checklistItems.length > 0) {
      setCheckedElements(
        getAmountOfCheckedElementsForProzessschritte(
          sipocChecklist,
          projektAkte.prozessMapping!.prozessSchritte
        )
      );
    }

    setTotalElements(
      getTotalAmountOfElementsToCheck(projektAkte.prozessMapping!)
    );
    // eslint-disable-next-line
  }, [sipocChecklist, projektAkte.prozessMapping]);

  /**
   * Save the updateded Projektakte in the database and local
   */
  const saveAndUpdateProjektakte = (): void => {
    //validate Milestones
    if (!validateMilestones(localProjektAkte)) {
      generateNotification(
        t("notifications.projektAkteCreate.errorTitle"),
        t("notifications.projektAkteCreate.milestoneDateInvalid"),
        "warning",
        3000
      );
      return;
    }
    //update
    setIsLoading(true);
    updateProjektAkte(axios, localProjektAkte).then(
      (updatedProjektAkteStatusCode) => {
        if (updatedProjektAkteStatusCode === 200) {
          setProjektAkte(localProjektAkte);
          updateProjektAkteInContext(localProjektAkte);
        }
        setIsLoading(false);
      }
    );
  };

  return (
    <form onSubmit={(event) => event.preventDefault()}>
      <div className="projekt-akte-section-title-first">
        {t("projektAkte.update.general")}
      </div>
      <InputComponent
        required
        disabled={
          isViewerState ||
          !isUserAllowedToDoThis(
            keycloakUser!.userRole,
            ModuleShowType.PROJEKTAKTE_CHANGE,
            localProjektAkte.projectManager,
            keycloakUser!.serviceId
          )
        }
        onChange={(value: string) =>
          setLocalProjektAkte({ ...localProjektAkte, title: value })
        }
        value={localProjektAkte.title}
        placeholder={t("projektAkte.create.nameTitle")}
        type="text"
      />
      <div className="projekt-akte-info-gauge-data-wrapper">
        <div className="projekt-akte-info-data-wrapper">
          <div className="projekt-akte-info-label">
            <label>{t("projektAkte.update.prozessBereichLabel")}</label>
            <p>{localProjektAkte.prozessBereich?.bereich}</p>
          </div>
          <div className="projekt-akte-info-label">
            <label>{t("projektAkte.update.projectManagerLabel")}</label>
            <p>
              {userSelectOptions &&
                userSelectOptions.find(
                  (user) => user.value === localProjektAkte.projectManager
                )?.label}
            </p>
          </div>
          <div className="projekt-akte-info-label">
            <label>{t("projektAkte.update.creatorLabel")}</label>
            <p>{localProjektAkte.creator}</p>
          </div>
          <div className="projekt-akte-info-label">
            <label>{t("projektAkte.update.createDateLabel")}</label>
            <p>
              {new Date(localProjektAkte.createDate!).toLocaleDateString(
                "de-DE"
              )}
            </p>
          </div>
        </div>
        <div className="projekt-akte-info-data-wrapper">
          <div className="projekt-akte-info-label">
            <label>{t("projektAkte.update.lastUpdateMapLabel")}</label>
            <p>{`${getFormattedDateTimeString(
              localProjektAkte.prozessMapping?.lastUpdated ||
                localProjektAkte.prozessMapping?.createDate!
            )}`}</p>
          </div>
          <div className="projekt-akte-info-label">
            <label>{t("projektAkte.update.lastUpdateBereichLabel")}</label>
            <p>{`${getFormattedDateTimeString(
              localProjektAkte.prozessBereich?.lastUpdated ||
                localProjektAkte.prozessBereich?.createDate!
            )}`}</p>
          </div>
        </div>
        <div className="projekt-akte-info-gauge-component">
          <GaugeComponent
            title={t("projektAkte.overview.progress")}
            subtitle={`${checkedElements}/${totalElements}`}
            percent={checkedElements / totalElements || 0}
          />
        </div>
      </div>
      <div className="projekt-akte-info-date-wrapper">
        <p>{t("projektAkte.update.dateFrom")}</p>
        <InputComponent
          disabled={
            isViewerState ||
            !isUserAllowedToDoThis(
              keycloakUser!.userRole,
              ModuleShowType.PROJEKTAKTE_CHANGE,
              localProjektAkte.projectManager,
              keycloakUser!.serviceId
            )
          }
          onChange={(newDate: Date) =>
            setLocalProjektAkte({
              ...localProjektAkte,
              startDate: newDate,
            })
          }
          value={
            localProjektAkte.startDate
              ? new Date(localProjektAkte.startDate)
              : ""
          }
          placeholder={t("projektAkte.create.startDate")}
          type="date"
          required
        />
        <p>{t("projektAkte.update.dateTo")}</p>
        <InputComponent
          disabled={
            isViewerState ||
            !isUserAllowedToDoThis(
              keycloakUser!.userRole,
              ModuleShowType.PROJEKTAKTE_CHANGE,
              localProjektAkte.projectManager,
              keycloakUser!.serviceId
            )
          }
          onChange={(newDate: Date) =>
            setLocalProjektAkte({
              ...localProjektAkte,
              endDate: newDate,
            })
          }
          value={
            localProjektAkte.endDate ? new Date(localProjektAkte.endDate) : ""
          }
          placeholder={t("projektAkte.create.endDate")}
          type="date"
          required
        />
      </div>
      <div className="projekt-akte-info-section-wrapper">
        <div className="projekt-akte-info-role-section">
          <div className="projekt-akte-section-title ">
            {t("projektAkte.update.roles")}
          </div>
          <ProjektAkteUserRoleMapping
            userSelectOptions={userSelectOptions}
            isViewerState={
              isViewerState ||
              !isUserAllowedToDoThis(
                keycloakUser!.userRole,
                ModuleShowType.PROJEKTAKTE_CHANGE,
                localProjektAkte.projectManager,
                keycloakUser!.serviceId
              )
            }
            projektAkte={localProjektAkte}
            setProjektAkte={setLocalProjektAkte}
          />
        </div>
        <div className="projekt-akte-info-milestone-section">
          <div className="projekt-akte-section-title">
            {t("projektAkte.update.milestones")}
          </div>
          <ProjektAkteProzessSchrittEndDateMapping
            isViewerState={
              isViewerState ||
              !isUserAllowedToDoThis(
                keycloakUser!.userRole,
                ModuleShowType.PROJEKTAKTE_CHANGE,
                localProjektAkte.projectManager,
                keycloakUser!.serviceId
              )
            }
            projektAkte={localProjektAkte}
            setProjektAkte={setLocalProjektAkte}
            sipocChecklist={sipocChecklist}
            withMilestoneBar
          />
        </div>
      </div>
      <TextAreaComponent
        placeholder={t("projektAkte.update.note")}
        disabled={
          isViewerState ||
          !isUserAllowedToDoThis(
            keycloakUser!.userRole,
            ModuleShowType.PROJEKTAKTE_CHANGE,
            localProjektAkte.projectManager,
            keycloakUser!.serviceId
          )
        }
        value={localProjektAkte.note || ""}
        onChange={(newValue: string) =>
          setLocalProjektAkte({
            ...localProjektAkte,
            note: newValue,
          })
        }
      />
      {!isViewerState &&
        isUserAllowedToDoThis(
          keycloakUser!.userRole,
          ModuleShowType.PROJEKTAKTE_CHANGE,
          localProjektAkte.projectManager,
          keycloakUser!.serviceId
        ) && (
          <div className="flex-inline-box-component-wrapper">
            <ButtonComponent
              title={t("general.buttons.save")}
              type="button"
              isLoading={isLoading}
              onClick={() => saveAndUpdateProjektakte()}
            />
          </div>
        )}
    </form>
  );
};

export default ProjektAkteMetatag;
