import { useKeycloak } from "@react-keycloak/web";
import { TreeConfig } from "beelean-component-library";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { Router } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import withClearCache from "../ClearCache";
import i18n from "../i18n";
import { useAxios } from "../utils/AxiosUtil";
import { fetchAllCompanies, fetchCompany } from "../utils/CompanyUtil";
import { LicenseModuleType, UserRole } from "../utils/Enums";
import history from "../utils/history";
import {
  Company,
  createEmptyCompany,
  KeycloakUser,
  User,
} from "../utils/Interfaces";
import { ProzessBereich } from "../utils/LeanAdmin/LeanAdmin.types";
import {
  getAllSimpleProzessBereichForAdmin,
  getAllSimpleProzessBereichForCompany,
  sortListProzessBereiche,
} from "../utils/LeanAdmin/LeanAdminModulUtils";
import { ProjektAkte } from "../utils/LeanAdmin/ProjektAkte.types";
import {
  fetchAllProjektAktenForCompany,
  fetchAllProjektAktenForAdmin,
} from "../utils/LeanAdmin/ProjektAkteUtils";
import { isModuleValidInLicense } from "../utils/LicenseUtil";
import { generateNotification } from "../utils/NotificationUtil";
import PrivateRoute from "../utils/PrivateRoute";
import { updateTreeData } from "../utils/TreeUtils";
import {
  addLoginToServer,
  createUserInstance,
  fetchAllSimpleUser,
} from "../utils/UserUtil";
import AccountListPage from "./AccountListPage";
import DashboardPage from "./DashboardPage";
import LeanAdminProzessBereichePage from "./LeanAdminProzessBereichePage";
import LeanAdminProzessMappingPage from "./LeanAdminProzessMappingPage";
import PdcaPage from "./PdcaPage";
import ProfilePage from "./ProfilePage";
import ProjektAkteDetailPage from "./ProjektAkteDetailPage";
import ProjektAktePage from "./ProjektAktePage";

/**
 * Context for the laoded keycloak user data
 */
export const KeycloakUserContext = createContext<{
  keycloakUser?: KeycloakUser;
  updateKeycloakUser: Function;
}>({ updateKeycloakUser: () => {} });

/**
 * Context for the user company data
 */
export const CompanyContext = createContext<{
  userCompany: Company;
  setUserCompany(userCompany: Company): void;
  allCompanies: Company[];
  setAllCompanies(allCompanies: Company[]): void;
  companyUser: User[];
  setCompanyUser(user: User[]): void;
}>({
  userCompany: createEmptyCompany(),
  setUserCompany: () => {},
  allCompanies: [],
  setAllCompanies: () => {},
  companyUser: [],
  setCompanyUser: () => {},
});

/**
 * Context for the tree data with update function
 */
export const TreeDataContext = createContext<{
  // Lean Admin
  leanAdminTreeData?: TreeConfig;
  updateLeanAdminTreeData(treeData: TreeConfig): void;
  pdcaTreeData?: TreeConfig;
  updatePdcaTreeData(treeData: TreeConfig): void;
  projektAkteTreeData?: TreeConfig;
  updateProjektAkteTreeData(treeData: TreeConfig): void;
  leanAdminProzessbereiche: ProzessBereich[];
  setLeanAdminProzessbereiche(prozessBereiche: ProzessBereich[]): void;
  // Lean Prod
  leanProdTreeData?: TreeConfig;
  updateLeanProdTreeData(treeData: TreeConfig): void;
  projektAkten: ProjektAkte[];
  setProjektAkten(projektAkten: ProjektAkte[]): void;
}>({
  updateLeanAdminTreeData: () => {},
  leanAdminProzessbereiche: [],
  setLeanAdminProzessbereiche: () => {},
  updateLeanProdTreeData: () => {},
  updatePdcaTreeData: () => {},
  updateProjektAkteTreeData: () => {},
  projektAkten: [],
  setProjektAkten: () => {},
});

/**
 * global settings which are needed to configure the webapp
 */
export const GlobaleApplicationSettings = createContext<{
  isViewerState: boolean;
  toggleViewerState(viewState: boolean): void;
  isDesktopMenuOpen: boolean;
  toggleIsDesktopMenuOpen(desktopOpenState: boolean): void;
}>({
  isViewerState: false,
  toggleViewerState: () => {},
  isDesktopMenuOpen: true,
  toggleIsDesktopMenuOpen: () => {},
});

const App = () => {
  const { t } = useTranslation();
  const { initialized, keycloak } = useKeycloak();
  // keycloak user state
  const [keycloakUser, setKeycloakUser] = useState<KeycloakUser>();
  // States for the global settings application context
  const [isViewerState, toggleIsViewerState] = useState<boolean>(true);
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState<boolean>(true);
  // Company Context states
  const [companyUser, setCompanyUser] = useState<User[]>([]);
  const [loadedUserCompany, setLoadedUserCompany] =
    useState<Company>(createEmptyCompany);
  const [loadedAdminCompanies, setLoadedAdminCompanies] = useState<Company[]>(
    []
  );
  // tree data states
  const [generatedLeanAdminTreeData, setGeneratedLeanAdminTreeData] = useState<
    TreeConfig | undefined
  >();
  const [generatedPdcaTreeData, setGeneratedPdcaTreeData] = useState<
    TreeConfig | undefined
  >();
  const [generatedProjektAkteTreeData, setGeneratedProjektAkteTreeData] =
    useState<TreeConfig | undefined>();
  const [generatedLeanProdTreeData, setGeneratedLeanProdTreeData] = useState<
    TreeConfig | undefined
  >();
  const [loadedProzessbereiche, setLoadedProzessbereiche] = useState<
    ProzessBereich[]
  >([]);
  const [loadedProjektAkten, setLoadedProjektAkten] = useState<ProjektAkte[]>(
    []
  );

  const axios = useAxios();

  /**
   * Fetch all the needed keycloak user data
   */
  const updateKeycloakUser = () => {
    if (!!axios && keycloak && keycloak.authenticated) {
      createUserInstance(keycloak).then((localUser) => {
        setKeycloakUser(localUser);

        // load the company for the specific user
        updateCompanyContext(localUser.userRole, localUser.companyId);
        updateProzessBereiche(localUser.userRole, localUser.companyId);
        updateProjektAkten(localUser.userRole, localUser.companyId);
      });
    }
  };

  /**
   * This useEffect fetches the treedata if all prerequisites are met
   */
  useEffect(() => {
    if (
      (loadedAdminCompanies.length > 0 ||
        (loadedUserCompany && loadedUserCompany.id)) &&
      keycloakUser &&
      history
    ) {
      updateTreeData(
        keycloakUser.userRole,
        keycloakUser.companyId,
        loadedAdminCompanies,
        setGeneratedLeanAdminTreeData,
        setGeneratedPdcaTreeData,
        setGeneratedProjektAkteTreeData,
        loadedProzessbereiche,
        loadedUserCompany,
        history,
        loadedProjektAkten
      );
    }
    // eslint-disable-next-line
  }, [
    i18n.language,
    keycloakUser,
    loadedAdminCompanies,
    loadedProzessbereiche,
    loadedProjektAkten,
    loadedUserCompany,
    history,
  ]);

  /**
   * Updates the company for the current logged in user or all companies for admin
   *
   * @param role
   * @param companyId
   */
  const updateCompanyContext = (role: UserRole, companyId: string): void => {
    if (role === UserRole.PLATFORMADMIN) {
      fetchAllCompanies(axios).then((allFetchedCompanies: Company[]) => {
        if (allFetchedCompanies) {
          setLoadedAdminCompanies(allFetchedCompanies);
        }
      });
    } else {
      fetchCompany(axios, companyId).then((fetchedCompany: Company) => {
        if (fetchedCompany) {
          setLoadedUserCompany(fetchedCompany);
        } else {
          // show error message
          generateNotification(
            t("notifications.intro.company.errorTitle"),
            t("notifications.intro.company.errorContent"),
            "danger",
            -1
          );
        }
      });
    }
    fetchAllSimpleUser(
      axios,
      role === UserRole.PLATFORMADMIN ? undefined : companyId
    ).then((loadedUser) => setCompanyUser(loadedUser));
  };

  /**
   * Updates the ProjektAkten for the current logged in user or all companies for admin
   *
   * @param role
   * @param companyId
   */
  const updateProjektAkten = (role: UserRole, companyId: string): void => {
    if (role === UserRole.PLATFORMADMIN) {
      fetchAllProjektAktenForAdmin(axios).then((fetchedProjektAkten) =>
        setLoadedProjektAkten(fetchedProjektAkten)
      );
    } else {
      fetchAllProjektAktenForCompany(companyId, axios).then(
        (fetchedProjektAkten) => setLoadedProjektAkten(fetchedProjektAkten)
      );
    }
  };

  /**
   * Updates the Prozessbereiche from the server to the context
   *
   * @param role
   * @param companyId
   */
  const updateProzessBereiche = (role: UserRole, companyId: string): void => {
    if (role === UserRole.PLATFORMADMIN) {
      getAllSimpleProzessBereichForAdmin(axios).then(
        (fetchedProzessBereiche: ProzessBereich[]) => {
          if (fetchedProzessBereiche) {
            setLoadedProzessbereiche(
              sortListProzessBereiche(fetchedProzessBereiche)
            );
          } else {
            // show error message
            generateNotification(
              t("notifications.intro.company.errorTitle"),
              t("notifications.intro.company.errorContent"),
              "danger",
              -1
            );
          }
        }
      );
      // load data only if the user is valid by license
    } else if (
      isModuleValidInLicense(
        LicenseModuleType.ADMINISTRATION,
        loadedUserCompany.license
      )
    ) {
      getAllSimpleProzessBereichForCompany(companyId, axios).then(
        (fetchedProzessBereiche: ProzessBereich[]) => {
          if (fetchedProzessBereiche) {
            setLoadedProzessbereiche(
              sortListProzessBereiche(fetchedProzessBereiche)
            );
          } else {
            // show error message
            generateNotification(
              t("notifications.intro.company.errorTitle"),
              t("notifications.intro.company.errorContent"),
              "danger",
              -1
            );
          }
        }
      );
    }
  };

  useEffect(() => {
    updateKeycloakUser();
    //eslint-disable-next-line
  }, [keycloak, axios, history]);

  /**
   * adds a Login on the server
   */
  useEffect(() => {
    if (!!axios && keycloakUser)
      addLoginToServer(keycloakUser!.serviceId, axios);
    //eslint-disable-next-line
  }, [axios, keycloakUser]);

  return (
    <>
      <ReactNotification isMobile />

      <Router history={history}>
        <Switch>
          <GlobaleApplicationSettings.Provider
            value={{
              isViewerState: isViewerState,
              toggleViewerState: () => {
                toggleIsViewerState(!isViewerState);
              },
              isDesktopMenuOpen: isDesktopMenuOpen,
              toggleIsDesktopMenuOpen: () => {
                setIsDesktopMenuOpen(!isDesktopMenuOpen);
              },
            }}
          >
            <KeycloakUserContext.Provider
              value={{
                keycloakUser: keycloakUser,
                updateKeycloakUser: () => updateKeycloakUser(),
              }}
            >
              <CompanyContext.Provider
                value={{
                  setCompanyUser: setCompanyUser,
                  userCompany: loadedUserCompany,
                  setUserCompany: setLoadedUserCompany,
                  allCompanies: loadedAdminCompanies,
                  setAllCompanies: setLoadedAdminCompanies,
                  companyUser: companyUser,
                }}
              >
                <TreeDataContext.Provider
                  value={{
                    leanAdminTreeData: generatedLeanAdminTreeData,
                    leanAdminProzessbereiche: loadedProzessbereiche,
                    pdcaTreeData: generatedPdcaTreeData,
                    projektAkteTreeData: generatedProjektAkteTreeData,
                    leanProdTreeData: generatedLeanProdTreeData,
                    updateLeanAdminTreeData: setGeneratedLeanAdminTreeData,
                    updatePdcaTreeData: setGeneratedPdcaTreeData,
                    updateLeanProdTreeData: setGeneratedLeanProdTreeData,
                    updateProjektAkteTreeData: setGeneratedProjektAkteTreeData,
                    setLeanAdminProzessbereiche: setLoadedProzessbereiche,
                    projektAkten: loadedProjektAkten,
                    setProjektAkten: setLoadedProjektAkten,
                  }}
                >
                  <Route path="/" exact>
                    {initialized && keycloak && keycloak.authenticated ? (
                      <Redirect to="/dashboard" />
                    ) : (
                      () => keycloak.login()
                    )}
                  </Route>
                  <PrivateRoute Page={DashboardPage} path="/dashboard" />
                  <PrivateRoute Page={ProfilePage} path="/profile" />
                  <PrivateRoute Page={AccountListPage} path="/account" />
                  <PrivateRoute
                    Page={LeanAdminProzessBereichePage}
                    path="/administration/company"
                    licenseModule={LicenseModuleType.PROZESSBEREICHE}
                  />
                  <PrivateRoute
                    Page={LeanAdminProzessMappingPage}
                    path="/administration/company/prozessmap"
                    licenseModule={LicenseModuleType.PROZESSBEREICHE}
                  />
                  <PrivateRoute
                    Page={PdcaPage}
                    path="/administration/company/pdca"
                    licenseModule={LicenseModuleType.PDCA}
                  />
                  <PrivateRoute
                    Page={ProjektAktePage}
                    licenseModule={LicenseModuleType.PROJEKTAKTE}
                    path="/akte"
                  />
                  <PrivateRoute
                    Page={ProjektAkteDetailPage}
                    licenseModule={LicenseModuleType.PROJEKTAKTE}
                    path="/akte/project"
                  />
                </TreeDataContext.Provider>
              </CompanyContext.Provider>
            </KeycloakUserContext.Provider>
          </GlobaleApplicationSettings.Provider>
        </Switch>
      </Router>
    </>
  );
};

const ClearCacheComponent = withClearCache(App);

export default ClearCacheComponent;
