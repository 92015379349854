import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
  Option,
} from "beelean-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../../styles/index.scss";
import { useAxios } from "../../../utils/AxiosUtil";
import {
  HistoryEntry,
  ProzessBereich,
  ProzessMappingEntry,
  createEmptySipocChecklistEval,
} from "../../../utils/LeanAdmin/LeanAdmin.types";
import {
  createSelectOptionsForProzessBereiche,
  getAllLatestVersionsOfProzessBereichForCompany,
  isProzessBereichComplete,
} from "../../../utils/LeanAdmin/LeanAdminModulUtils";
import {
  ProjektAkte,
  UserRoleMappingEntry,
  createEmptyProjektAkte,
} from "../../../utils/LeanAdmin/ProjektAkte.types";
import {
  createProjektAkteEntry,
  deleteProjektAkte,
  getUserRoleMappingWithFirstSelectOption,
  saveProzessMapImageNameOnServer,
  validateDate,
  validateMilestones,
} from "../../../utils/LeanAdmin/ProjektAkteUtils";
import { createNewSipocChecklist } from "../../../utils/LeanAdmin/SipocChecklistUtils";
import { uploadProjektAkteOutputFile } from "../../../utils/LeanAdmin/SipocOutputConfigurationUtils";
import { generateNotification } from "../../../utils/NotificationUtil";
import { generateProzessMappingExportAsPng } from "../../../utils/PdfUtil";
import { getSelectOptionsForUser } from "../../../utils/UserUtil";
import Prozessmapping from "../prozessmapping/Prozessmapping";
import "./ProjektAkteCreateStyle.scss";
import { ProjektAkteCreateWorkflowProps } from "./ProjektAkteCreateWorkflow.types";
import ProjektAkteProzessSchrittEndDateMapping from "./ProjektAkteProzessSchrittEndDateMapping";
import ProjektAkteUserRoleMapping from "./ProjektAkteUserRoleMapping";
import { getProzessMappingEntry } from "../../../utils/LeanAdmin/ProzessMappingUtil";

const ProjektAkteCreateWorkflow: React.FC<ProjektAkteCreateWorkflowProps> = ({
  companyId,
  keycloakUser,
  companyUser,
  isViewerState,
  projektAkten,
  setProjektAkten,
  userCompany,
  setUserCompany,
  leanAdminProzessbereiche,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  // select options
  const [prozessBereichSelectOptions, setProzessBereichSelectOptions] =
    useState<Option[]>([]);
  const [releasedOnly, toggleReleasedOnly] = useState<boolean>(true);
  const [latestReleases, setLatestReleases] = useState<HistoryEntry[]>([]);
  const [userSelectOptions, setUserSelectOptions] = useState<Option[]>([]);
  // display states
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showExportProzessMapping, setShowExportProzessMapping] =
    useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  // working data
  const [newProjektAkte, setNewProjektAkte] = useState<ProjektAkte>(
    createEmptyProjektAkte(
      `${keycloakUser.firstname} ${keycloakUser.lastname}`,
      companyId
    )
  );

  //loads all released prozessbereiche available for creation
  useEffect(() => {
    if (axios && companyId)
      getAllLatestVersionsOfProzessBereichForCompany(axios, companyId).then(
        setLatestReleases
      );
  }, [axios, companyId]);

  //initializes projektakte with user and companyid
  useEffect(() => {
    setNewProjektAkte(
      createEmptyProjektAkte(
        `${keycloakUser.firstname} ${keycloakUser.lastname}`,
        companyId
      )
    );
    // eslint-disable-next-line
  }, [companyId]);

  //initially set first User of dropdown for Role
  useEffect(() => {
    if (
      newProjektAkte.userRoleMapping.length === 0 &&
      newProjektAkte.prozessBereich
    ) {
      let mapping: UserRoleMappingEntry[] =
        getUserRoleMappingWithFirstSelectOption(newProjektAkte.prozessBereich!);
      setNewProjektAkte({ ...newProjektAkte, userRoleMapping: mapping });
    }
    // eslint-disable-next-line
  }, [newProjektAkte.prozessBereich]);

  //prepares select options and initializes ProzessBereich and ProjectManager in ProjektAkte
  useEffect(() => {
    if (leanAdminProzessbereiche && latestReleases && companyUser) {
      const bereicheToUse: ProzessBereich[] = releasedOnly
        ? latestReleases.map((entry) => entry.bereichSnapshot)
        : leanAdminProzessbereiche;
      let currentProzessBereichOptions: Option[] =
        createSelectOptionsForProzessBereiche(bereicheToUse);
      setProzessBereichSelectOptions(currentProzessBereichOptions);
      let currentUserSelectOptions: Option[] =
        getSelectOptionsForUser(companyUser);
      setUserSelectOptions(currentUserSelectOptions);
    }
    // eslint-disable-next-line
  }, [latestReleases, leanAdminProzessbereiche, releasedOnly, companyUser]);

  // validates input of first step of workflow
  const validateFirstStep = (prozessMapping: ProzessMappingEntry): boolean => {
    let projektAkteValid: boolean = true;
    if (
      !validateDate(
        newProjektAkte.endDate,
        newProjektAkte.startDate,
        newProjektAkte.endDate
      )
    ) {
      generateNotification(
        t("notifications.projektAkteCreate.errorTitle"),
        t("notifications.projektAkteCreate.endDatePassed"),
        "warning",
        3000
      );
      projektAkteValid = false;
    }
    if (!newProjektAkte.title) projektAkteValid = false;

    if (
      !newProjektAkte.prozessBereich ||
      !isProzessBereichComplete(newProjektAkte.prozessBereich, prozessMapping)
    ) {
      projektAkteValid = false;
      generateNotification(
        t("notifications.projektAkteCreate.errorTitle"),
        t("notifications.projektAkteCreate.prozessBereichIncomplete"),
        "warning",
        3000
      );
    }
    return projektAkteValid;
  };

  /*+
   *validates first step and prepares export mapping, when not using released, prozessmapping is laoded
   **/
  const switchActiveStepWhenProzessMappingLoaded = async (): Promise<void> => {
    // load the ProzessMapping
    setIsLoading(true);
    let prozessMappingEntry: ProzessMappingEntry | undefined;
    if (!newProjektAkte.prozessBereich?.id || !newProjektAkte.projectManager) {
      setIsLoading(false);
      return;
    }
    if (releasedOnly) {
      prozessMappingEntry = latestReleases.find(
        (entry) =>
          entry.bereichSnapshot.id === newProjektAkte.prozessBereich!.id!
      )?.prozessMappingSnapshot;
    } else {
      prozessMappingEntry = await getProzessMappingEntry(
        companyId,
        newProjektAkte.prozessBereich.id!,
        axios
      );
    }

    if (prozessMappingEntry) {
      setNewProjektAkte({
        ...newProjektAkte,
        prozessMapping: prozessMappingEntry,
      });
      if (validateFirstStep(prozessMappingEntry)) setActiveStep(1);
      setShowExportProzessMapping(true);
    } else {
      generateNotification(
        t("notifications.projektAkteCreate.errorTitle"),
        t("notifications.projektAkteCreate.errorContent"),
        "danger",
        -1
      );
    }
    setIsLoading(false);
  };

  //creates ProjektAkte in backend and adds it to projektAkten, resets create Process afterwards
  const createProjektAkteAndFinishCreateProcess = () => {
    setIsLoading(true);
    if (!validateMilestones(newProjektAkte)) {
      generateNotification(
        t("notifications.projektAkteCreate.errorTitle"),
        t("notifications.projektAkteCreate.milestoneDateInvalid"),
        "warning",
        3000
      );
      setIsLoading(false);
      return;
    }
    createProjektAkteEntry(newProjektAkte, axios).then((updatedProjektAkte) => {
      if (updatedProjektAkte) {
        Promise.all([
          createNewSipocChecklist(
            {
              ...createEmptySipocChecklistEval(
                updatedProjektAkte.companyId,
                updatedProjektAkte.creator,
                updatedProjektAkte.prozessBereich?.id!
              ),
              projektAkteId: updatedProjektAkte.id,
              name: updatedProjektAkte.title,
            },
            axios
          ),
          generateProzessMappingExportAsPng("", true),
        ]).then(([newChecklist, generatedPngFile]) => {
          if (!newChecklist && !generatedPngFile) {
            generateNotification(
              t("notifications.projektAkteCreate.errorTitle"),
              t("notifications.projektAkteCreate.errorProzessImage"),
              "danger",
              -1
            );
            //removes ProjektAkte, because checklist failed
            deleteProjektAkte(axios, updatedProjektAkte.id!);
            setIsLoading(false);
            return;
          }
          uploadProjektAkteOutputFile(
            generatedPngFile as File,
            updatedProjektAkte.companyId,
            updatedProjektAkte.id!,
            axios
          ).then((newProzessMapImageFileName) => {
            saveProzessMapImageNameOnServer(
              updatedProjektAkte.id!,
              newProzessMapImageFileName,
              axios
            ).then(() => {
              //adds projektAkte to licenses
              setUserCompany({
                ...userCompany,
                createdProjektakte: userCompany.createdProjektakte + 1,
              });

              setShowExportProzessMapping(false);
              //starts with first step again
              setActiveStep(0);
              setIsLoading(false);

              //update list for Overview
              let localProjektAkten: ProjektAkte[] = [...projektAkten];
              localProjektAkten.push(updatedProjektAkte);
              setProjektAkten(localProjektAkten);
              //create new ProjektAkte
              setNewProjektAkte({
                ...createEmptyProjektAkte(
                  `${keycloakUser.firstname} ${keycloakUser.lastname}`,
                  companyId
                ),
              });
            });
          });
        });
      }
      setIsLoading(false);
    });
  };

  /**
   * checks if user role mapping is complete and if so, sets active step to 2
   */
  const checkUserRoleMappingAndSetNextStep = (): void => {
    const missingUserFound = newProjektAkte.userRoleMapping.some(
      (entry) => !entry.userId
    );
    if (missingUserFound) return;
    setActiveStep(2);
  };

  return (
    <>
      <form onSubmit={(event) => event.preventDefault()}>
        {
          // first step for title, prozessBereich and endDate
          activeStep === 0 && (
            <>
              <InputComponent
                required
                onChange={(value: string) =>
                  setNewProjektAkte({ ...newProjektAkte, title: value })
                }
                value={newProjektAkte.title}
                placeholder={t("projektAkte.create.nameTitle")}
                type="text"
              />
              <CheckboxComponent
                checked={releasedOnly}
                label={t("projektAkte.create.releasedOnly")}
                onClick={toggleReleasedOnly}
              />
              <DropdownComponent
                required
                options={prozessBereichSelectOptions}
                onChange={(prozessBereich) => {
                  const bereich: ProzessBereich | undefined = releasedOnly
                    ? latestReleases.find(
                        (historyEntry) =>
                          historyEntry.bereichSnapshot.id === prozessBereich
                      )?.bereichSnapshot
                    : leanAdminProzessbereiche.find(
                        (bereich) => bereich.id === prozessBereich
                      );
                  if (bereich)
                    setNewProjektAkte({
                      ...newProjektAkte,
                      prozessBereich: bereich,
                      userRoleMapping: [],
                      prozessSchrittEndDateMapping: [],
                    });
                }}
                placeholder={t("projektAkte.create.prozessBereich")}
                selectedOption={newProjektAkte.prozessBereich?.id}
              />
              <DropdownComponent
                required
                options={userSelectOptions}
                onChange={(selectedOption) =>
                  setNewProjektAkte({
                    ...newProjektAkte,
                    projectManager: selectedOption,
                  })
                }
                placeholder={t("projektAkte.create.projectManager")}
                selectedOption={
                  userSelectOptions.find(
                    (user) => user.value === newProjektAkte.projectManager
                  )!
                }
              />
              <InputComponent
                onChange={(newDate: Date) =>
                  setNewProjektAkte({
                    ...newProjektAkte,
                    endDate: newDate,
                  })
                }
                value={
                  newProjektAkte.endDate ? new Date(newProjektAkte.endDate) : ""
                }
                placeholder={t("projektAkte.create.endDate")}
                type="date"
                required
              />
              <ButtonComponent
                title={t("general.buttons.next")}
                isLoading={isLoading}
                onClick={switchActiveStepWhenProzessMappingLoaded}
              />
            </>
          )
        }
        {
          // second step for roles
          activeStep === 1 && (
            <>
              <ProjektAkteUserRoleMapping
                userSelectOptions={userSelectOptions}
                isViewerState={isViewerState}
                projektAkte={newProjektAkte}
                setProjektAkte={setNewProjektAkte}
              />
              <div className="flex-inline-box-component-wrapper">
                <ButtonComponent
                  title={t("general.buttons.back")}
                  type="button"
                  onClick={() => {
                    setShowExportProzessMapping(false);
                    setActiveStep(0);
                  }}
                />
                <ButtonComponent
                  title={t("general.buttons.next")}
                  isLoading={isLoading}
                  onClick={checkUserRoleMappingAndSetNextStep}
                />
              </div>
            </>
          )
        }
        {
          // third step for milestones
          activeStep === 2 && (
            <>
              <ProjektAkteProzessSchrittEndDateMapping
                isViewerState={isViewerState}
                projektAkte={newProjektAkte}
                setProjektAkte={setNewProjektAkte}
              />
              <div className="flex-inline-box-component-wrapper">
                <ButtonComponent
                  title={t("general.buttons.back")}
                  type="button"
                  onClick={() => setActiveStep(1)}
                />
                <ButtonComponent
                  title={t("general.buttons.save")}
                  isLoading={isLoading}
                  type="button"
                  onClick={() => {
                    createProjektAkteAndFinishCreateProcess();
                  }}
                />
              </div>
            </>
          )
        }
      </form>

      {showExportProzessMapping && (
        <div className="hidden-prozessmapping-image">
          <Prozessmapping
            key={`new-projektakte-${
              newProjektAkte.prozessBereich?.id || "empty"
            }`}
            companyId={newProjektAkte.companyId}
            prozessBereichId={newProjektAkte.prozessBereich?.id || ""}
            setExportFunction={() => {}}
            isReleased={releasedOnly}
          />
        </div>
      )}
    </>
  );
};

export default ProjektAkteCreateWorkflow;
