import { AxiosInstance } from "axios";
import { ProzessOverview } from "./LeanAdmin.types";

/**
 * API to get {@link ProzessOverview} by ID
 * @param axios  the network instance
 * @param id  the ID of the {@link ProzessOverview} to fetch
 * @returns the {@link ProzessOverview} with the given ID
 */
export const getProzessOverviewByID = async (
  axios: AxiosInstance,
  id: string
): Promise<ProzessOverview> =>
  axios
    .get(`/data/prozessoverview/`, {
      params: {
        id,
      },
    })
    .then((res) => res.data)
    .catch((err) => console.log("Couldn't fetch ProzessOverview by ID:", err));

/**
 * API to create a new {@link ProzessOverview}
 * @param axios  the network instance
 * @param overview  the {@link ProzessOverview} to create
 * @returns the created {@link ProzessOverview}
 */
export const createNewProzessOverview = async (
  axios: AxiosInstance,
  overview: ProzessOverview
): Promise<ProzessOverview> =>
  axios
    .post("/data/prozessoverview/", overview)
    .then((res) => res.data)
    .catch((err) => console.log("Couldn't create new ProzessOverview:", err));

/**
 * API to update a {@link ProzessOverview}
 * @param axios  the network instance
 * @param updatedOverview  the updated overview
 * @returns a response indicating the success of the update operation
 */
export const updateProzessOverview = async (
  axios: AxiosInstance,
  updatedOverview: ProzessOverview
): Promise<boolean> =>
  axios
    .post("/data/prozessoverview/update", updatedOverview)
    .then((res) => res.status === 200)
    .catch((err) => {
      console.log("Couldn't update ProzessOverview:", err);
      return false;
    });
