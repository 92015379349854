import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
  MilestoneBarComponent,
  Option,
  TableComponent,
  TableHeader,
  TableRow,
} from "beelean-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./ProjektAkteMetatagStyle.scss";
import { Prozessschritt } from "../../../utils/LeanAdmin/LeanAdmin.types";
import { ProzessSchrittEndDateMappingEntry } from "../../../utils/LeanAdmin/ProjektAkte.types";
import {
  buildFinishedMilestoneForMilestoneBar,
  buildMilestonesForMilestoneBar,
  createSelectOptionsForProzessSchritte,
  deleteEntryInProzessSchrittEndDateMapping,
  filterAlreadyUsedProzessSchritte,
  getLabelForProzessSchritt,
  validateDate,
} from "../../../utils/LeanAdmin/ProjektAkteUtils";
import { filterJustForNeededProzessschritte } from "../../../utils/LeanAdmin/SipocEvalUtils";
import { ReactComponent as DumpsterIcon } from "../../../assets/icons/dumpster.svg";
import { ProjektAkteProzessSchrittEndDateMappingProps } from "./ProjektAkteProzessSchrittEndDateMapping.types";
import { generateNotification } from "../../../utils/NotificationUtil";
import {
  getAmountOfCheckedElementsForProzessschritte,
  getTotalAmountOfElementsToCheck,
} from "../../../utils/LeanAdmin/SipocChecklistUtils";

const ProjektAkteProzessSchrittEndDateMapping: React.FC<
  ProjektAkteProzessSchrittEndDateMappingProps
> = ({
  projektAkte,
  setProjektAkte,
  isViewerState,
  withMilestoneBar,
  sipocChecklist,
}) => {
  const { t } = useTranslation();
  const [prozessSchrittSelectOptions, setProzessSchrittSelectOptions] =
    useState<Option[]>([]);
  // working data
  const [milestoneHeader, setMilestoneHeader] = useState<TableHeader[]>([]);
  const [milestoneRows, setMilestoneRows] = useState<TableRow[]>([]);
  const [
    newProzesschrittEndDateMappingEntry,
    setNewProzesschrittEndDateMappingEntry,
  ] = useState<ProzessSchrittEndDateMappingEntry>({
    prozessSchrittId: "",
    endDate: new Date(),
  });
  const [milestonesWithWorkdays, setMilestonesWithWorkdays] =
    useState<boolean>(true);
  const [checkedElements, setCheckedElements] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);

  //filter ProzessSchritte and prepare as select options
  useEffect(() => {
    if (
      projektAkte.prozessMapping &&
      projektAkte.prozessMapping.prozessSchritte.length > 0
    ) {
      let neededProzessSchritte: Prozessschritt[] =
        filterJustForNeededProzessschritte(
          filterAlreadyUsedProzessSchritte(
            projektAkte.prozessMapping.prozessSchritte,
            projektAkte.prozessSchrittEndDateMapping
          )
        );
      setProzessSchrittSelectOptions([
        ...createSelectOptionsForProzessSchritte(
          t("projektAkte.create.milestone.noNameSet"),
          neededProzessSchritte
        ),
      ]);
      setTotalElements(
        getTotalAmountOfElementsToCheck(projektAkte.prozessMapping!)
      );
    }
    setMilestoneRows(createMileStoneTable());
    setMilestoneHeader(getTableHeader());
    // eslint-disable-next-line
  }, [
    projektAkte.prozessMapping,
    projektAkte.prozessSchrittEndDateMapping.length,
    isViewerState,
  ]);

  // sets checked elements from checklist
  useEffect(() => {
    if (!sipocChecklist) return;
    let countCheckedElements: number = 0;
    if (sipocChecklist.checklistItems.length > 0) {
      countCheckedElements = getAmountOfCheckedElementsForProzessschritte(
        sipocChecklist,
        projektAkte.prozessMapping!.prozessSchritte
      );
      setCheckedElements(countCheckedElements);
    }
    // eslint-disable-next-line
  }, [sipocChecklist]);

  const getTableHeader = (): TableHeader[] => {
    if (isViewerState) {
      return t("projektAkte.create.milestone.tableHeaderNotEditable", {
        returnObjects: true,
      });
    } else {
      return t("projektAkte.create.milestone.tableHeaderEditable", {
        returnObjects: true,
      });
    }
  };

  //creates table according to prozessSchrittEndDateMapping of ProjektAkte
  const createMileStoneTable = (): TableRow[] => {
    // Update table
    let localMilestoneTable: TableRow[] = [];
    projektAkte.prozessSchrittEndDateMapping.forEach(
      (currentProzessSchrittEndDateMappingEntry) => {
        let newTableRow: TableRow = {
          id: currentProzessSchrittEndDateMappingEntry.prozessSchrittId,
          content: [
            getLabelForProzessSchritt(
              t("projektAkte.create.milestone.noNameSet"),
              projektAkte.prozessMapping!.prozessSchritte.find(
                (ProzessSchritt) =>
                  ProzessSchritt.id ===
                  currentProzessSchrittEndDateMappingEntry.prozessSchrittId
              )!
            ),
            new Date(
              currentProzessSchrittEndDateMappingEntry.endDate
            ).toLocaleDateString(),
            !isViewerState && (
              <div
                title={t("general.buttons.delete")}
                onClick={() => {
                  let currentProzessSchrittEndDateMapping: ProzessSchrittEndDateMappingEntry[] =
                    deleteEntryInProzessSchrittEndDateMapping(
                      currentProzessSchrittEndDateMappingEntry.prozessSchrittId,
                      projektAkte.prozessSchrittEndDateMapping
                    );
                  setProjektAkte({
                    ...projektAkte,
                    prozessSchrittEndDateMapping:
                      currentProzessSchrittEndDateMapping,
                  });
                }}
                className="delete-button-wrapper"
              >
                <DumpsterIcon />
              </div>
            ),
          ],
        };
        localMilestoneTable.push(newTableRow);
      }
    );
    return localMilestoneTable;
  };

  return (
    <div>
      {!isViewerState && (
        <>
          <DropdownComponent
            disabled={prozessSchrittSelectOptions.length === 0 || isViewerState}
            options={prozessSchrittSelectOptions}
            onChange={(selectedOption) =>
              setNewProzesschrittEndDateMappingEntry({
                ...newProzesschrittEndDateMappingEntry,
                prozessSchrittId: selectedOption,
              })
            }
            placeholder={t("projektAkte.create.milestone.title")}
            selectedOption={
              prozessSchrittSelectOptions.find(
                (entry) =>
                  entry.value ===
                  newProzesschrittEndDateMappingEntry.prozessSchrittId
              )!
            }
          />
          <InputComponent
            disabled={prozessSchrittSelectOptions.length === 0 || isViewerState}
            onChange={(newDate: Date) => {
              setNewProzesschrittEndDateMappingEntry({
                ...newProzesschrittEndDateMappingEntry,
                endDate: newDate,
              });
            }}
            value={
              newProzesschrittEndDateMappingEntry.endDate
                ? new Date(newProzesschrittEndDateMappingEntry.endDate)
                : ""
            }
            placeholder={t("projektAkte.create.milestone.endDate")}
            type="date"
          />
          <ButtonComponent
            title={t("projektAkte.create.milestone.add")}
            type="button"
            disabled={prozessSchrittSelectOptions.length === 0 || isViewerState}
            onClick={() => {
              if (
                !validateDate(
                  newProzesschrittEndDateMappingEntry.endDate,
                  projektAkte.startDate,
                  projektAkte.endDate
                )
              ) {
                generateNotification(
                  t("notifications.projektAkteCreate.errorTitle"),
                  t(
                    "notifications.projektAkteCreate.currentMilestoneDateInvalid"
                  ),
                  "warning",
                  3000
                );
                return;
              }
              let newProzessSchrittEndDateMapping: ProzessSchrittEndDateMappingEntry[] =
                projektAkte.prozessSchrittEndDateMapping || [];
              newProzessSchrittEndDateMapping.push(
                newProzesschrittEndDateMappingEntry
              );
              setProjektAkte({
                ...projektAkte,
                prozessSchrittEndDateMapping: newProzessSchrittEndDateMapping,
              });
            }}
          />
        </>
      )}

      {withMilestoneBar && (
        <>
          <MilestoneBarComponent
            endDateTitle={t("projektAkte.update.plannedEnd")}
            startDate={new Date(projektAkte.startDate)}
            finishedMilestone={buildFinishedMilestoneForMilestoneBar(
              projektAkte
            )}
            endDate={new Date(projektAkte.endDate)}
            milestones={buildMilestonesForMilestoneBar(
              projektAkte.prozessSchrittEndDateMapping,
              projektAkte.prozessMapping!.prozessSchritte,
              projektAkte.prozessMapping!,
              sipocChecklist!
            )}
            valueColor={
              totalElements !== checkedElements &&
              validateDate(new Date(), projektAkte.endDate, new Date())
                ? "#ee4e4b"
                : undefined
            }
            workdays={milestonesWithWorkdays}
          />
          <div className="projekt-akte-milestone-workdays-toggle">
            <CheckboxComponent
              checked={milestonesWithWorkdays}
              onClick={() => setMilestonesWithWorkdays(!milestonesWithWorkdays)}
              label={t("projektAkte.update.milestonesOnlyWorkdays")}
            />
          </div>
        </>
      )}

      <TableComponent
        key={`milestone-table-entry-${milestoneRows.length}-${milestoneHeader.length}`}
        headers={milestoneHeader}
        rows={milestoneRows}
      />
    </div>
  );
};

export default ProjektAkteProzessSchrittEndDateMapping;
