import { store } from "react-notifications-component";

/**
 * Sets default options for notifications and generates one
 *
 * @param title
 * @param content
 * @param onClick
 * @param duration
 * @param type
 */
export const generateNotification = (
  title: string,
  content: string,
  type?: "danger" | "success" | "default" | "warning",
  duration?: number,
  onClick?: Function
) => {
  return store.addNotification({
    title: title,
    message: content,
    type: type || "info",
    insert: "top",
    container: "top-right",
    dismiss:
      duration !== -1
        ? {
            duration: duration || 1000,
            pauseOnHover: true,
          }
        : undefined,
    onRemoval: (id, removedBy) => {
      if (removedBy === "click" && onClick) onClick(id);
    },
  });
};
