import {
  BoxComponent,
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
} from "beelean-component-library";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import "./AccountListStyle.scss";
import { ReactComponent as CompanyAddIcon } from "../../assets/icons/company-add.svg";
import { Company, createEmptyCompany, User } from "../../utils/Interfaces";
import { useAxios } from "../../utils/AxiosUtil";
import { createNewCompany, isCompanyValid } from "../../utils/CompanyUtil";
import { LicenseType, UserRole } from "../../utils/Enums";
import { createNewUser } from "../../utils/UserUtil";
import UserMetatag from "./UserMetatag";
import { generateNotification } from "../../utils/NotificationUtil";

interface CompanyCreateWorkflowProps {
  onCreateCompany: Function;
  onCreateUser: Function;
}

export const CompanyCreateWorkflow: React.FC<CompanyCreateWorkflowProps> = ({
  onCreateCompany,
  onCreateUser,
}) => {
  const { t } = useTranslation();
  const [localCompany, setLocalCompany] = useState<Company>(createEmptyCompany);
  const [localUsername, setLocalUsername] = useState<string>("");
  const [activeStep, setActiveStep] = useState<number>(0);
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <BoxComponent
      subtext={
        <p>
          <Trans i18nKey="company.createBox.subText">Firma anlegen</Trans>
        </p>
      }
      icon={<CompanyAddIcon />}
      title={
        activeStep === 1
          ? t("company.createBox.titleUser")
          : t("company.createBox.title")
      }
    >
      {activeStep === 0 && (
        <>
          <p>
            <Trans i18nKey="company.createBox.description">
              Hier kann eine neue Kundenfirma eingetragen werden.
            </Trans>
          </p>
          <CompanyMetatag
            isLoading={isLoading}
            onSubmit={(newCompany: Company) => {
              if (!isCompanyValid(newCompany)) {
                generateNotification(
                  t("notifications.companyCreate.warningTitle"),
                  t("notifications.companyCreate.wrongInput"),
                  "warning"
                );
                return;
              }

              setIsLoading(true);
              createNewCompany(newCompany, axios).then(
                (newCompany: Company) => {
                  setIsLoading(false);
                  if (newCompany) {
                    onCreateCompany(newCompany);
                    setLocalCompany(newCompany);
                    setActiveStep(1);
                  } else {
                    // TODO ERROR MESSAGE
                  }
                }
              );
            }}
          />
        </>
      )}

      {activeStep === 1 && (
        <>
          <p
            dangerouslySetInnerHTML={{
              __html: t("company.createBox.userDescription", {
                companyName: localCompany.name,
              }),
            }}
          />
          <UserMetatag
            companyCreation
            isLoading={isLoading}
            predefinedUserRole={UserRole.COMPANYADMIN}
            onAdd={(user: User) => {
              setIsLoading(true);
              createNewUser(
                {
                  ...user,
                  companyId: localCompany.id!,
                  userRole: UserRole.COMPANYADMIN,
                },
                axios
              ).then((newUser: User) => {
                setIsLoading(false);
                if (newUser) {
                  setLocalUsername(`${newUser.firstname} ${newUser.lastname}`);
                  onCreateUser(newUser);
                  setActiveStep(2);
                } else {
                  // TODO ERROR HANDLING
                }
              });
            }}
          />
        </>
      )}

      {activeStep === 2 && (
        <>
          <p
            dangerouslySetInnerHTML={{
              __html: t("company.createBox.finalDescription", {
                userName: localUsername,
                companyName: localCompany.name,
              }),
            }}
          />
          <ButtonComponent
            title={t("general.buttons.back")}
            onClick={() => {
              setLocalCompany(createEmptyCompany());
              setActiveStep(0);
            }}
          />
        </>
      )}
    </BoxComponent>
  );
};

interface CompanyMetatagProps {
  isLoading: boolean;
  updateCompany?: Company;
  onSubmit: Function;
}

export const CompanyMetatag: React.FC<CompanyMetatagProps> = ({
  isLoading,
  updateCompany,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [localCompany, setLocalCompany] = useState<Company>(createEmptyCompany);

  useEffect(() => {
    if (updateCompany) {
      setLocalCompany(updateCompany);
    }
    // eslint-disable-next-line
  }, [updateCompany]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(localCompany);
      }}
    >
      <InputComponent
        required
        onChange={(value: string) =>
          setLocalCompany({ ...localCompany, name: value })
        }
        value={localCompany.name}
        placeholder={t("company.metaTag.name")}
      />
      <InputComponent
        required
        onChange={(value: string) =>
          setLocalCompany({ ...localCompany, address: value })
        }
        value={localCompany.address}
        placeholder={t("company.metaTag.address")}
      />
      <InputComponent
        required
        onChange={(value: number) =>
          setLocalCompany({ ...localCompany, zipCode: value })
        }
        value={localCompany.zipCode === 0 ? "" : localCompany.zipCode}
        placeholder={t("company.metaTag.zipCode")}
      />
      <InputComponent
        required
        onChange={(value: string) =>
          setLocalCompany({ ...localCompany, city: value })
        }
        value={localCompany.city}
        placeholder={t("company.metaTag.city")}
      />
      <DropdownComponent
        onChange={(option) =>
          setLocalCompany({
            ...localCompany,
            license: { ...localCompany.license, type: option as LicenseType },
          })
        }
        // different filter if is beelean admin called
        options={Object.keys(LicenseType).map((licenseTyp: string) => {
          return { label: t(`licenseType.${licenseTyp}`), value: licenseTyp };
        })}
        selectedOption={localCompany.license.type as any}
      />
      {(localCompany.license.type === LicenseType.PRODUCTION ||
        localCompany.license.type === LicenseType.ALL) && (
        <>
          <InputComponent
            onChange={(value: number) =>
              setLocalCompany({
                ...localCompany,
                license: {
                  ...localCompany.license,
                  licensedStationAmount: value,
                },
              })
            }
            value={
              localCompany.license.licensedStationAmount === 0
                ? ""
                : localCompany.license.licensedStationAmount
            }
            type="number"
            placeholder={t("company.metaTag.licensedAmount")}
          />
          <InputComponent
            onChange={(value: Date) =>
              setLocalCompany({
                ...localCompany,
                license: {
                  ...localCompany.license,
                  productionValidUntil: value,
                },
              })
            }
            value={new Date(localCompany.license.productionValidUntil)}
            type="date"
          />
        </>
      )}
      {(localCompany.license.type === LicenseType.ADMINISTRATION ||
        localCompany.license.type === LicenseType.ALL) && (
        <>
          <InputComponent
            onChange={(value: number) =>
              setLocalCompany({
                ...localCompany,
                license: {
                  ...localCompany.license,
                  licensedProcessAmount: value,
                },
              })
            }
            value={
              localCompany.license.licensedProcessAmount === 0
                ? ""
                : localCompany.license.licensedProcessAmount
            }
            type="number"
            placeholder={t("company.metaTag.licensedProcessAmount")}
          />
          <CheckboxComponent
            label={t("company.metaTag.pdca")}
            checked={localCompany.license.activatePdca}
            onClick={() =>
              setLocalCompany({
                ...localCompany,
                license: {
                  ...localCompany.license,
                  activatePdca: !localCompany.license.activatePdca,
                },
              })
            }
          />
        </>
      )}
      {(localCompany.license.type === LicenseType.ADMINISTRATION_PROJEKTAKTE ||
        localCompany.license.type === LicenseType.ALL) && (
        <InputComponent
          onChange={(value: number) =>
            setLocalCompany({
              ...localCompany,
              license: {
                ...localCompany.license,
                licensedProjektakteAndProzessbereiche: value,
              },
            })
          }
          value={
            localCompany.license.licensedProjektakteAndProzessbereiche === 0
              ? ""
              : localCompany.license.licensedProjektakteAndProzessbereiche
          }
          type="number"
          placeholder={t(
            "company.metaTag.licensedlicensedProjektakteAndProzessbereicheAmount"
          )}
        />
      )}
      {(localCompany.license.type === LicenseType.ADMINISTRATION_USER ||
        localCompany.license.type === LicenseType.ALL) && (
        <InputComponent
          onChange={(value: number) =>
            setLocalCompany({
              ...localCompany,
              license: {
                ...localCompany.license,
                licensedUsers: value,
              },
            })
          }
          value={
            localCompany.license.licensedUsers === 0
              ? ""
              : localCompany.license.licensedUsers
          }
          type="number"
          placeholder={t("company.metaTag.licensedUsersAmount")}
        />
      )}
      {(localCompany.license.type === LicenseType.ADMINISTRATION_USER ||
        localCompany.license.type === LicenseType.ADMINISTRATION_PROJEKTAKTE ||
        localCompany.license.type === LicenseType.ADMINISTRATION ||
        localCompany.license.type === LicenseType.ALL) && (
        <InputComponent
          onChange={(value: Date) => {
            let dateToSave = new Date(value);
            //validating date from input
            if (isNaN(dateToSave.getTime())) return;
            //license always should expire at 23:59:59 so we just set it here
            dateToSave.setHours(23, 59, 59, 0);
            setLocalCompany({
              ...localCompany,
              license: {
                ...localCompany.license,
                administrationValidUntil: dateToSave,
              },
            });
          }}
          value={new Date(localCompany.license.administrationValidUntil)}
          type="date"
        />
      )}
      <ButtonComponent
        isLoading={isLoading}
        title={
          updateCompany ? t("general.buttons.save") : t("general.buttons.add")
        }
      />
    </form>
  );
};
