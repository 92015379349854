import { DropdownComponent } from "beelean-component-library";
import { UserRoleMappingEntry } from "../../../utils/LeanAdmin/ProjektAkte.types";
import { updateUserRoleMapping } from "../../../utils/LeanAdmin/ProjektAkteUtils";
import "./ProjektAkteMetatagStyle.scss";
import { ProjektAkteUserRoleMappingProps } from "./ProjektAkteUserRoleMapping.types";

const ProjektAkteUserRoleMapping: React.FC<ProjektAkteUserRoleMappingProps> = ({
  userSelectOptions,
  isViewerState,
  projektAkte,
  setProjektAkte,
}) => {
  /**
   * Helper to dispay the correct dropdown menus for
   * a given prozessbereich version
   * @returns jsx elements for selection
   */
  const getCorrectDropDownItems = (): JSX.Element[] => {
    if (projektAkte.prozessBereich!.version! >= 2) {
      return projektAkte.prozessBereich!.bereichRoles.map((role) => (
        <DropdownComponent
          key={`dd-role-user-mapping-${role.id}`}
          disabled={isViewerState}
          required
          options={userSelectOptions}
          onChange={(selectedOption) => {
            let userMapping: UserRoleMappingEntry[] = updateUserRoleMapping(
              role.name,
              role.id,
              selectedOption,
              projektAkte.userRoleMapping
            );
            setProjektAkte({
              ...projektAkte,
              userRoleMapping: userMapping,
            });
          }}
          placeholder={role.name}
          selectedOption={userSelectOptions.find(
            (user) =>
              user.value ===
              projektAkte.userRoleMapping.find(
                (entry) => entry.roleId === role.id
              )?.userId
          )}
        />
      ));
    } else
      return projektAkte.prozessBereich!.roles.map((roleName) => (
        <DropdownComponent
          key={`dd-role-user-mapping-${roleName}`}
          disabled={isViewerState}
          required
          options={userSelectOptions}
          onChange={(selectedOption) => {
            let userMapping: UserRoleMappingEntry[] = updateUserRoleMapping(
              roleName,
              "",
              selectedOption,
              projektAkte.userRoleMapping
            );
            setProjektAkte({
              ...projektAkte,
              userRoleMapping: userMapping,
            });
          }}
          placeholder={roleName}
          selectedOption={userSelectOptions.find(
            (user) =>
              user.value ===
              projektAkte.userRoleMapping.find(
                (entry) => entry.roleName === roleName
              )?.userId
          )}
        />
      ));
  };
  return <>{projektAkte.prozessBereich && getCorrectDropDownItems()}</>;
};

export default ProjektAkteUserRoleMapping;
