import {
  CheckboxComponent,
  ConfirmPopupComponent,
  GaugeComponent,
  LoaderComponent,
  MilestoneBarComponent,
} from "beelean-component-library";
import { useTranslation } from "react-i18next";
import { useAxios } from "../../../utils/AxiosUtil";
import {
  createEmptySipocChecklistEval,
  SipocChecklistEval,
} from "../../../utils/LeanAdmin/LeanAdmin.types";
import { ProjektAkte } from "../../../utils/LeanAdmin/ProjektAkte.types";
import {
  buildFinishedMilestoneForMilestoneBar,
  buildMilestonesForMilestoneBar,
  deleteProjektAkte,
  validateDate,
} from "../../../utils/LeanAdmin/ProjektAkteUtils";
import { ProjektAkteOverviewEntryProps } from "./ProjektAkteOverview.types";
import { ReactComponent as DumpsterIcon } from "../../../assets/icons/dumpster.svg";
import "./ProjektAkteOverviewEntryStyle.scss";
import {
  fetchSipocChecklistByProjektAkteId,
  getAmountOfCheckedElementsForProzessschritte,
  getTotalAmountOfElementsToCheck,
} from "../../../utils/LeanAdmin/SipocChecklistUtils";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const ProjektAkteOverviewEntry: React.FC<ProjektAkteOverviewEntryProps> = ({
  isViewerState,
  projektAkte,
  userCompany,
  keycloakUser,
  projektAkten,
  setProjektAkten,
  setUserCompany,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const history = useHistory();
  const [checkedElements, setCheckedElements] = useState<number>(0);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [milestonesWithWorkdays, setMilestonesWithWorkdays] =
    useState<boolean>(true);
  const [loadedChecklist, setLoadedChecklist] = useState<SipocChecklistEval>(
    createEmptySipocChecklistEval(
      keycloakUser.companyId,
      `${keycloakUser.firstname} ${keycloakUser.lastname}`
    )
  );
  const [showDeleteProjektAktePopup, setShowDeleteProjektAktePopup] =
    useState<boolean>(false);

  // sets checked elements from checklist as soon as axios is arrived
  useEffect(() => {
    // fetch the checklist
    if (axios) {
      let countCheckedElements: number = 0;
      fetchSipocChecklistByProjektAkteId(
        projektAkte.companyId,
        projektAkte.id!,
        axios
      ).then((fetchedChecklist) => {
        if (fetchedChecklist) setLoadedChecklist(fetchedChecklist);
        if (fetchedChecklist.checklistItems.length > 0) {
          countCheckedElements = getAmountOfCheckedElementsForProzessschritte(
            fetchedChecklist,
            projektAkte.prozessMapping!.prozessSchritte
          );
          setCheckedElements(countCheckedElements);
        }
      });
    }
    // eslint-disable-next-line
  }, [axios]);

  //sets total elements that can be checked initially
  useEffect(() => {
    setTotalElements(
      getTotalAmountOfElementsToCheck(projektAkte.prozessMapping!)
    );
    // eslint-disable-next-line
  }, []);

  //removes ProjektAkte
  const removeProjektAkte = (): void => {
    setIsDeleting(true);
    //backend
    deleteProjektAkte(axios, projektAkte.id!).then(() => {
      let localProjektAkten: ProjektAkte[] = projektAkten;
      localProjektAkten = localProjektAkten.filter(
        (localProjektAkte) => localProjektAkte.id !== projektAkte.id
      );
      //overview & tree
      setProjektAkten(localProjektAkten);
      //removes projektAkte from licenses
      setUserCompany({
        ...userCompany,
        createdProjektakte: userCompany.createdProjektakte - 1,
      });
      setIsDeleting(false);
    });
  };

  //redirects to ProjektAkteDetailPage for given ProjektAkte
  const goToProjektAkteDetail = (projektAkte: ProjektAkte): void => {
    history.push(`/akte/project`, {
      lastKey: userCompany.id
        ? `projektakte-root/${projektAkte.id}`
        : `projektakte-root/${projektAkte.companyId}/${projektAkte.id}`,
      openNodes: [
        "projektakte-root",
        userCompany.id
          ? undefined
          : `projektakte-root/${projektAkte.companyId}`,
      ],
      companyId: projektAkte.companyId,
      procjectId: projektAkte.id,
    });
  };

  return (
    <div
      className="projekt-akte-entry-wrapper"
      key={`projekt-akte-entry-${projektAkte.id}`}
    >
      <div className="projekt-akte-entry-data">
        <div className="projekt-akte-flex-grow-one-wrapper">
          <div
            className="projekt-akte-entry-title"
            onClick={() => goToProjektAkteDetail(projektAkte)}
          >
            <p>{projektAkte.title}</p>
          </div>

          <div className="milestone-bar-wrapper">
            <MilestoneBarComponent
              endDateTitle={t("projektAkte.update.plannedEnd")}
              finishedMilestone={buildFinishedMilestoneForMilestoneBar(
                projektAkte
              )}
              startDate={new Date(projektAkte.startDate)}
              endDate={new Date(projektAkte.endDate)}
              milestones={buildMilestonesForMilestoneBar(
                projektAkte.prozessSchrittEndDateMapping,
                projektAkte.prozessMapping?.prozessSchritte || [],
                projektAkte.prozessMapping!,
                loadedChecklist
              )}
              workdays={milestonesWithWorkdays}
              valueColor={
                totalElements !== checkedElements &&
                validateDate(new Date(), projektAkte.endDate, new Date())
                  ? "#ee4e4b"
                  : undefined
              }
            />
            <div className="projekt-akte-milestone-workdays-toggle">
              <CheckboxComponent
                checked={milestonesWithWorkdays}
                onClick={() =>
                  setMilestonesWithWorkdays(!milestonesWithWorkdays)
                }
                label={t("projektAkte.update.milestonesOnlyWorkdays")}
              />
            </div>
          </div>
        </div>
        <div className="gauge-component-wrapper">
          <GaugeComponent
            title={t("projektAkte.overview.progress")}
            subtitle={`${checkedElements}/${totalElements}`}
            percent={checkedElements / totalElements || 0}
          />
        </div>
      </div>
      <div className="projekt-akte-entry-info-func">
        <div className="projekt-akte-flex-grow-one-wrapper">
          {projektAkte.creator || t("general.unknown")} -{" "}
          {projektAkte.createDate
            ? `${projektAkte.createDate?.toLocaleDateString()}`
            : t("general.unknown")}
        </div>
        {!isViewerState && (
          <div className="projekt-akte-entry-delete-wrapper">
            <DumpsterIcon onClick={() => setShowDeleteProjektAktePopup(true)} />
          </div>
        )}
      </div>
      {showDeleteProjektAktePopup && (
        <ConfirmPopupComponent
          content={t("popup.projektAkte.delete", {
            name: projektAkte.title,
          })}
          title={t("general.buttons.delete")}
          closeFunction={() => setShowDeleteProjektAktePopup(false)}
          noText={t("general.buttons.no")}
          yesText={t("general.buttons.yes")}
          onYesFunction={() => {
            removeProjektAkte();
            setShowDeleteProjektAktePopup(false);
          }}
        />
      )}
      {isDeleting && <LoaderComponent inFront />}
    </div>
  );
};

export default ProjektAkteOverviewEntry;
