import { Vector2d } from "konva/types/types";
import React, { RefObject, useEffect, useState } from "react";
import { Arrow, Circle, Group, Shape, Text } from "react-konva";
import {
  Prozesslinie,
  ProzesslinieType,
  Prozessschritt,
} from "../../../../utils/LeanAdmin/LeanAdmin.types";
import {
  getAbsolutePositionOfProzessschritt,
  getCorrectConfigurationForLinie,
  getCorrectProzessLinieTooltipText,
  getVisibleProzessLinieText,
  isRightClick,
} from "../../../../utils/LeanAdmin/ProzessMappingUtil";
import ProzessschrittTooltip from "./ProzessschrittTooltip";

interface ProzessschrittLinieProps {
  prozessLinie: Prozesslinie;
  onDblClick: Function;
  stageRefObject: RefObject<any>;
  selected: boolean;
  listOfAllKundenIds: string[];
  startProzessSchritt: Prozessschritt;
  onChange: (prozesslinie: Prozesslinie) => void;
  localXChange?: number;
  isViewerState: boolean;
}
const ProzessschrittLinie: React.FC<ProzessschrittLinieProps> = ({
  prozessLinie,
  stageRefObject,
  onDblClick,
  selected,
  listOfAllKundenIds,
  startProzessSchritt,
  onChange,
  localXChange = 0,
  isViewerState,
}) => {
  const [localStartXY, setLocalStartXY] = useState<number[]>([]);
  const [localMiddleXY, setLocalMiddleXY] = useState<number[]>([]);
  const [localStopXY, setLocalStopXY] = useState<number[]>([]);
  const [showTooltip, toggleTooltip] = useState<boolean>(false);
  const [isDragging, toggleDragging] = useState<boolean>(false);
  const [linePositionPercent, setCurrentLinePositionPercent] = useState<number>(
    prozessLinie.linePositionPercent || 50
  );

  //update line after change of linePositionPercent
  useEffect(() => {
    calulateVectorPoints();
    // eslint-disable-next-line
  }, [linePositionPercent]);

  /**
   * Helper to filter the needed text for tooltip
   * @returns found text ot empty string
   */
  const filterCorrectOutputText = (): string => {
    if (!startProzessSchritt) return "";
    let foundIndex = startProzessSchritt.output.definition.findIndex(
      (item) => item.id === prozessLinie.stopProcessId
    );
    if (foundIndex === -1) return "";
    return startProzessSchritt.output.definition[foundIndex].content ?? "";
  };

  // calculates the correct start and endpoint of arrow
  const calulateVectorPoints = (): void => {
    let internStartXY: number[] = getAbsolutePositionOfProzessschritt(
      prozessLinie.startProcessId,
      stageRefObject
    );
    let internStopXY: number[] = getAbsolutePositionOfProzessschritt(
      prozessLinie.stopProcessId,
      stageRefObject
    );
    let internMiddleXY: number[] = [];

    const isInputOutputArrow: boolean =
      prozessLinie.type === ProzesslinieType.INFORMATIONSFLUSS_EDV ||
      prozessLinie.type === ProzesslinieType.INFORMATIONSFLUSS_PAPIER ||
      prozessLinie.type === ProzesslinieType.INFORMATIONSFLUSS_DEFAULT ||
      prozessLinie.type === ProzesslinieType.DIENSTLEISTUNGSLIEFERUNG_MATERIAL;

    const isStartKunde: boolean = listOfAllKundenIds.includes(
      prozessLinie.startProcessId
    );
    const isStopKunde: boolean = listOfAllKundenIds.includes(
      prozessLinie.stopProcessId
    );

    // Calculate the start and endpoints from the middle
    // of the selected prozessschritt
    // if the linie is in same row
    const arrowOffset: number = 40;
    if (internStartXY[1] === internStopXY[1]) {
      if (isInputOutputArrow || internStartXY[0] - internStopXY[0] < 0) {
        internStartXY[0] = internStartXY[0] + arrowOffset;
        internStopXY[0] = internStopXY[0] - arrowOffset;
      } else {
        internStartXY[0] = internStartXY[0] - arrowOffset;
        internStopXY[0] = internStopXY[0] + arrowOffset;
      }
    } else {
      if (isInputOutputArrow || internStartXY[0] - internStopXY[0] < 0) {
        // if Kunde is involved the Prozesslinie has to
        // start/stop at the nearest distance position (left/right corner)
        if (isStartKunde && internStartXY[0] - internStopXY[0] > 0)
          internStartXY[0] = internStartXY[0] - arrowOffset;
        else internStartXY[0] = internStartXY[0] + arrowOffset;
        // if Kunde is involved the Prozesslinie has to
        // start/stop at the nearest distance position (left/right corner)
        if (isStopKunde && internStartXY[0] - internStopXY[0] > 0)
          internStopXY[0] = internStopXY[0] + arrowOffset;
        else internStopXY[0] = internStopXY[0] - arrowOffset;
      } else {
        internStartXY[0] = internStartXY[0] - arrowOffset;
        internStopXY[0] = internStopXY[0] + arrowOffset;
      }
      if (prozessLinie.type !== ProzesslinieType.INFORMATIONSFLUSS_DEFAULT) {
        if (internStartXY[1] - internStopXY[1] < 0) {
          internStartXY[1] = internStartXY[1] + arrowOffset;
          internStopXY[1] = internStopXY[1] - arrowOffset;
        } else {
          internStartXY[1] = internStartXY[1] - arrowOffset;
          internStopXY[1] = internStopXY[1] + arrowOffset;
        }
      }
    }

    setLocalStartXY(internStartXY);
    setLocalStopXY(internStopXY);
    const differenceX = internStopXY[0] - internStartXY[0];
    const differenceY = internStopXY[1] - internStartXY[1];
    internMiddleXY = [
      differenceX / 2 + internStartXY[0],
      differenceY / 2 + internStartXY[1],
    ];

    switch (prozessLinie.type) {
      case ProzesslinieType.INFORMATIONSFLUSS_DEFAULT:
        const linePositionMultiplier: number = linePositionPercent / 100;
        internMiddleXY = [
          internStartXY[0] + differenceX * linePositionMultiplier,
          internStartXY[1],
          internStopXY[0] - differenceX * (1 - linePositionMultiplier),
          internStopXY[1],
        ];
        break;
      case ProzesslinieType.INFORMATIONSFLUSS_EDV:
        const multiplierLongPart = 5 / 8;
        const multiplierMiddlePart = 3 / 8;

        internMiddleXY = [
          differenceX * multiplierLongPart + internStartXY[0],
          differenceY * multiplierLongPart + internStartXY[1],
          differenceX * multiplierMiddlePart + internStartXY[0],
          differenceY * multiplierMiddlePart + internStartXY[1],
        ];
        // data for the lightning
        if (internStartXY[1] === internStopXY[1]) {
          internMiddleXY = [
            internMiddleXY[0] + 10,
            internMiddleXY[1] - 10,
            internMiddleXY[2] - 10,
            internMiddleXY[3] + 10,
          ];
        } else {
          const distance: number = 10;
          const slope: number = differenceY / differenceX;
          const perpSlope: number = -1 / slope;
          const perpSlopeSqrt: number = Math.sqrt(
            1 / (1 + perpSlope * perpSlope)
          );
          const x: number = internMiddleXY[0] + distance * perpSlopeSqrt;
          const y: number =
            internMiddleXY[1] + perpSlope * distance * perpSlopeSqrt;
          const x2: number = internMiddleXY[2] - distance * perpSlopeSqrt;
          const y2: number =
            internMiddleXY[3] - perpSlope * distance * perpSlopeSqrt;

          internMiddleXY = [x, y, x2, y2];
        }
        break;
      case ProzesslinieType.RUECKFRAGE:
        // just if the roles are different
        if (!(internStartXY[1] === internStopXY[1])) {
          // data for the curve
          const distance: number = 100;
          const slope: number = differenceY / differenceX;
          const perpSlope: number = -1 / slope;
          const perpSlopeSqrt: number = Math.sqrt(
            1 / (1 + perpSlope * perpSlope)
          );
          const x: number = internMiddleXY[0] + distance * perpSlopeSqrt;
          const y: number =
            internMiddleXY[1] + perpSlope * distance * perpSlopeSqrt;

          internMiddleXY = [x, y];
        }
        break;
      case ProzesslinieType.QUALITAETSMANGEL:
        internMiddleXY = [];
        const counterPoints: number = 4;
        const dx: number = differenceX / counterPoints;
        const dy: number = differenceY / counterPoints;
        for (let i: number = 1; i < counterPoints - 1; i++) {
          internMiddleXY.push(Math.round(dx * i + internStartXY[0]));
          internMiddleXY.push(
            Math.round(dy * i + internStartXY[1] + (i % 2 === 0 ? 80 : -80))
          );
        }
        break;
      default:
        break;
    }
    setLocalMiddleXY(internMiddleXY);
  };

  useEffect(() => {
    calulateVectorPoints();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    calulateVectorPoints();
    // eslint-disable-next-line
  }, [prozessLinie.type]);

  /**
   * Helper to get vertical middle of prozesslinie for positioning movable marker
   * @returns
   */
  const getVerticalCenterOfMiddle = (): number => {
    if (localMiddleXY.length !== 4) return -1;
    const valueToAdd: number =
      Math.abs(localMiddleXY[1] - localMiddleXY[3]) / 2;
    if (localMiddleXY[1] > localMiddleXY[3])
      return localMiddleXY[3] + valueToAdd;
    else return localMiddleXY[1] + valueToAdd;
  };

  /**
   * Handles dragging of point in prozesslinien of type INFORMATIONSFLUSS_DEFAULT
   * @param pos of current dragging
   * @returns position to set point
   */
  const handleProzesslinieDrag = (pos: Vector2d): Vector2d => {
    let x = pos.x + Math.abs(localXChange);
    const start =
      localStartXY[0] > localStopXY[0] ? localStopXY[0] : localStartXY[0];
    const end =
      localStartXY[0] < localStopXY[0] ? localStopXY[0] : localStartXY[0];
    const difference: number = Math.abs(localStartXY[0] - localStopXY[0]);
    if (x > end) x = end;
    if (x < start) x = start;

    if (localStartXY[0] < localStopXY[0])
      setCurrentLinePositionPercent(((x - start) / difference) * 100);
    else setCurrentLinePositionPercent(100 - ((x - start) / difference) * 100);
    return { x: x + localXChange, y: getVerticalCenterOfMiddle() };
  };

  return (
    <>
      {getCorrectConfigurationForLinie(prozessLinie.type).curvy && (
        <Shape
          bezier={getCorrectConfigurationForLinie(prozessLinie.type).bezier}
          onMouseOver={() => toggleTooltip(true)}
          onTap={() => toggleTooltip(!showTooltip)}
          onMouseOut={() => toggleTooltip(false)}
          dash={getCorrectConfigurationForLinie(prozessLinie.type).dash}
          stroke={
            selected
              ? "#0DAB94"
              : getCorrectConfigurationForLinie(prozessLinie.type).color
          }
          sceneFunc={(ctx, shape) => {
            ctx.beginPath();
            // Start circle
            ctx.moveTo(localStartXY[0], localStartXY[1]);
            ctx.quadraticCurveTo(
              // middle circle
              localMiddleXY[0],
              localMiddleXY[1],
              // end circle
              localStopXY[0],
              localStopXY[1]
            );

            ctx.fillStrokeShape(shape);
          }}
        />
      )}

      {getCorrectConfigurationForLinie(prozessLinie.type).border && (
        <Arrow
          bezier={getCorrectConfigurationForLinie(prozessLinie.type).bezier}
          dash={getCorrectConfigurationForLinie(prozessLinie.type).dash}
          onTap={() => onDblClick(prozessLinie)}
          onDblClick={(event) => {
            if (isRightClick(event)) return;
            onDblClick(prozessLinie);
          }}
          fill={"transparent"}
          stroke={
            selected
              ? "#0DAB94"
              : getCorrectConfigurationForLinie(prozessLinie.type).color
          }
          strokeWidth={
            getCorrectConfigurationForLinie(prozessLinie.type).strokeWidth
          }
          lineCap={"round"}
          points={[...localStartXY, ...localMiddleXY, ...localStopXY]}
        />
      )}

      <Arrow
        bezier={getCorrectConfigurationForLinie(prozessLinie.type).bezier}
        onMouseOver={() => toggleTooltip(true)}
        onMouseOut={() => toggleTooltip(false)}
        dash={
          getCorrectConfigurationForLinie(prozessLinie.type).curvy
            ? [0, 100000]
            : getCorrectConfigurationForLinie(prozessLinie.type).dash
        }
        onTap={() => toggleTooltip(!showTooltip)}
        onDblTap={() => onDblClick(prozessLinie)}
        onDblClick={(event) => {
          if (isRightClick(event)) return;
          onDblClick(prozessLinie);
        }}
        fill={
          getCorrectConfigurationForLinie(prozessLinie.type).border
            ? "white"
            : getCorrectConfigurationForLinie(prozessLinie.type).color
        }
        stroke={
          getCorrectConfigurationForLinie(prozessLinie.type).border
            ? "white"
            : selected
            ? "#0DAB94"
            : getCorrectConfigurationForLinie(prozessLinie.type).color
        }
        strokeWidth={
          getCorrectConfigurationForLinie(prozessLinie.type).border
            ? getCorrectConfigurationForLinie(prozessLinie.type).strokeWidth - 4
            : getCorrectConfigurationForLinie(prozessLinie.type).strokeWidth
        }
        lineCap={"round"}
        points={[...localStartXY, ...localMiddleXY, ...localStopXY]}
      />
      {prozessLinie.type === ProzesslinieType.INFORMATIONSFLUSS_DEFAULT &&
        !isViewerState && (
          <Circle
            radius={4}
            draggable
            dragBoundFunc={handleProzesslinieDrag}
            onDragEnd={() =>
              onChange({
                ...prozessLinie,
                linePositionPercent: Math.round(linePositionPercent) || 1,
              })
            }
            fill={
              getCorrectConfigurationForLinie(prozessLinie.type).border
                ? "white"
                : getCorrectConfigurationForLinie(prozessLinie.type).color
            }
            stroke={
              getCorrectConfigurationForLinie(prozessLinie.type).border
                ? "white"
                : isDragging
                ? "#0DAB94"
                : getCorrectConfigurationForLinie(prozessLinie.type).color
            }
            strokeWidth={
              getCorrectConfigurationForLinie(prozessLinie.type).border
                ? getCorrectConfigurationForLinie(prozessLinie.type)
                    .strokeWidth - 4
                : getCorrectConfigurationForLinie(prozessLinie.type).strokeWidth
            }
            onMouseEnter={() => toggleDragging(true)}
            onMouseLeave={() => toggleDragging(false)}
            x={localMiddleXY[0]}
            y={getVerticalCenterOfMiddle()}
          />
        )}

      {!!getVisibleProzessLinieText(prozessLinie) &&
        localStartXY.length > 0 && (
          <Group x={localStartXY[0]} y={localStartXY[1] - 15}>
            <Text text={getVisibleProzessLinieText(prozessLinie)} />
          </Group>
        )}

      {showTooltip &&
        (!!getCorrectProzessLinieTooltipText(prozessLinie) ||
          !!filterCorrectOutputText()) && (
          <ProzessschrittTooltip
            align="left"
            pointerDirection={"down"}
            text={`${getCorrectProzessLinieTooltipText(
              prozessLinie
            )} ${filterCorrectOutputText()}`}
            coords={localMiddleXY}
          />
        )}
    </>
  );
};

export default ProzessschrittLinie;
