import { LicenseType, UserRole } from "./Enums";

/**
 * Company interface from user service
 * including the license
 */
export interface Company {
  id?: string;
  createDate?: Date;
  name: string;
  address: string;
  zipCode: number;
  city: string;
  license: License;
  createdStations: number;
  createdProcesses: number;
  usedStorage: number;
  logoName: string;
  createdProjektakte: number;
  createdProzessBereiche: number;
}

/**
 * creates an empty company for example for creation
 */
export const createEmptyCompany = (): Company => ({
  id: undefined,
  logoName: "",
  address: "",
  city: "",
  license: createEmptyLicense(),
  name: "",
  zipCode: 0,
  createdStations: 0,
  createdProcesses: 0,
  usedStorage: 0,
  createdProjektakte: 0,
  createdProzessBereiche: 0,
});

/**
 * An license model
 */
export interface License {
  licensedStationAmount: number;
  productionValidUntil: Date;
  licensedProcessAmount: number;
  administrationValidUntil: Date;
  type: LicenseType;
  licensedStorage: number;
  licensedUsers: number;
  licensedProjektakteAndProzessbereiche: number;
  activatePdca: boolean;
}

/**
 * creates an empty license with default values
 */
export const createEmptyLicense = (): License => ({
  licensedStationAmount: 0,
  productionValidUntil: new Date(),
  licensedProcessAmount: 0,
  administrationValidUntil: new Date(),
  type: LicenseType.ADMINISTRATION,
  licensedStorage: 0,
  licensedProjektakteAndProzessbereiche: 0,
  activatePdca: false,
  licensedUsers: 0,
});

/**
 * User interface of a user from the database
 */
export interface User {
  id?: string;
  createDate?: Date;
  username: string;
  firstname: string;
  lastname: string;
  mail: string;
  phone: string;
  companyId: string;
  userRole: UserRole;
  enabled: boolean;
  password?: string;
  lastLogin?: Date;
  loginCounter: number;
  wantsMailNotification: boolean;
  wantsTwoDayreminder: boolean;
}

/**
 * creates an empty user with initial values
 */
export const createEmptyUser = (): User => ({
  companyId: "",
  firstname: "",
  id: undefined,
  lastname: "",
  mail: "",
  enabled: true,
  userRole: UserRole.NONE,
  username: "",
  phone: "",
  loginCounter: 0,
  wantsMailNotification: false,
  wantsTwoDayreminder: false,
});

/**
 * User interface of a user from the keycloak,
 * the companyId comes from the database
 */
export interface KeycloakUser {
  username: string;
  firstname: string;
  lastname: string;
  mail: string;
  enabled: boolean;
  userRole: UserRole;
  serviceId: string;
  companyId: string;
}
