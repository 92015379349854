import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
} from "beelean-component-library";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { KeycloakUserContext } from "../../pages/App";
import { UserRole } from "../../utils/Enums";
import { Company, User, createEmptyUser } from "../../utils/Interfaces";
import { generateNotification } from "../../utils/NotificationUtil";
import { isPasswordCorrect, isUserValid } from "../../utils/UserUtil";
import "./AccountListStyle.scss";

interface UserMetatagProps {
  onAdd: Function;
  predefinedUserRole?: UserRole;
  isLoading: boolean;
  updateUser?: User;
  companyList?: Company[];
  isAdmin?: boolean;
  showRoleSelect?: boolean;
  companyCreation?: boolean;
}

const UserMetatag: React.FC<UserMetatagProps> = ({
  onAdd,
  predefinedUserRole,
  isLoading,
  updateUser,
  companyList,
  isAdmin,
  showRoleSelect,
  companyCreation = false,
}) => {
  const { t } = useTranslation();
  const [localUser, setLocalUser] = useState<User>({
    ...createEmptyUser(),
    userRole: UserRole.WORKER,
  });
  const [localPassword, setLocalPassword] = useState<string>("");
  const [localPassword2, setLocalPassword2] = useState<string>("");
  const { keycloakUser } = useContext(KeycloakUserContext);

  /**
   * Updates loacl user with predefined role
   */
  useEffect(() => {
    if (predefinedUserRole)
      setLocalUser({ ...localUser, userRole: predefinedUserRole });
    // eslint-disable-next-line
  }, [predefinedUserRole]);

  useEffect(() => {
    if (updateUser) {
      setLocalUser(updateUser);
    }
    // eslint-disable-next-line
  }, [updateUser]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        let localTempUser: User = localUser;

        // check password
        if (localPassword !== "") {
          if (isPasswordCorrect(localPassword, localPassword2)) {
            localTempUser.password = localPassword;
            setLocalPassword("");
            setLocalPassword2("");
          } else {
            generateNotification(
              t("notifications.userCreate.errorTitle"),
              t("notifications.userCreate.password"),
              "danger",
              -1
            );
            return;
          }
        }

        // Workaround because initial setting data from dropdown
        // i not working correctly
        if (
          localTempUser.userRole !== UserRole.PLATFORMADMIN &&
          localTempUser.companyId === "" &&
          companyList &&
          companyList?.length > 0
        ) {
          localTempUser.companyId = companyList[0].id!;
        }

        // set companyId if the admin is companyadmin
        if (keycloakUser?.userRole === UserRole.COMPANYADMIN) {
          localTempUser.companyId = keycloakUser.companyId;
        }
        if (!isUserValid(localTempUser, companyCreation)) {
          generateNotification(
            t("notifications.userCreate.errorTitle"),
            t("notifications.userCreate.notValid"),
            "danger",
            -1
          );
          return;
        }
        onAdd(localTempUser);

        if (updateUser) {
          setLocalUser(updateUser);
        } else {
          setLocalUser({
            ...createEmptyUser(),
            userRole: predefinedUserRole ? predefinedUserRole : UserRole.WORKER,
          });
        }
      }}
    >
      <InputComponent
        onChange={(value: string) =>
          setLocalUser({ ...localUser, username: value })
        }
        required
        value={localUser.username}
        placeholder={t("user.metaTag.userName")}
      />
      <InputComponent
        onChange={(value: string) =>
          setLocalUser({ ...localUser, firstname: value })
        }
        value={localUser.firstname}
        required
        placeholder={t("user.metaTag.firstName")}
      />
      <InputComponent
        onChange={(value: string) =>
          setLocalUser({ ...localUser, lastname: value })
        }
        value={localUser.lastname}
        required
        placeholder={t("user.metaTag.lastName")}
      />
      <InputComponent
        onChange={(value: string) =>
          setLocalUser({ ...localUser, mail: value })
        }
        value={localUser.mail}
        required
        placeholder={t("user.metaTag.mail")}
        type="email"
      />
      <InputComponent
        onChange={(value: string) =>
          setLocalUser({ ...localUser, phone: value })
        }
        value={localUser.phone}
        placeholder={t("user.metaTag.phone")}
        type="tel"
      />
      {/* TODO MOMENTAN MUSS VORHER EINMAL WAS AUSGEWHÄLT WERDEN */}
      {localUser.userRole !== UserRole.PLATFORMADMIN &&
        companyList &&
        companyList?.length > 0 && (
          <DropdownComponent
            onChange={(option) =>
              setLocalUser({
                ...localUser,
                companyId:
                  localUser.userRole !== UserRole.PLATFORMADMIN ? option : "",
              })
            }
            options={companyList.map((company: Company) => {
              return { label: company.name, value: company.id! };
            })}
            selectedOption={companyList[0].id as any}
          />
        )}
      {showRoleSelect && (
        <DropdownComponent
          onChange={(option) =>
            setLocalUser({ ...localUser, userRole: option as UserRole })
          }
          // different filter if is beelean admin called
          options={Object.keys(UserRole)
            .filter(
              isAdmin
                ? (value: string) => value !== UserRole.NONE
                : (value: string) =>
                    value !== UserRole.NONE && value !== UserRole.PLATFORMADMIN
            )
            .map((userRole: string) => {
              return { label: t(`groups.${userRole}`), value: userRole };
            })}
          selectedOption={localUser.userRole as any}
        />
      )}
      {updateUser && (
        <>
          <InputComponent
            onChange={(value: string) => setLocalPassword(value)}
            value={localPassword}
            placeholder={t("user.metaTag.password")}
            type="password"
          />
          <InputComponent
            onChange={(value: string) => setLocalPassword2(value)}
            value={localPassword2}
            placeholder={t("user.metaTag.passwordConfirm")}
            type="password"
          />
          <CheckboxComponent
            checked={localUser.wantsMailNotification}
            label={t("user.metaTag.mailNotification")}
            onClick={() =>
              setLocalUser({
                ...localUser,
                wantsMailNotification: !localUser.wantsMailNotification,
                wantsTwoDayreminder: false,
              })
            }
          />
          <CheckboxComponent
            checked={localUser.wantsTwoDayreminder}
            disabled={!localUser.wantsMailNotification}
            label={t("user.metaTag.mailReminderNotification")}
            onClick={() =>
              setLocalUser({
                ...localUser,
                wantsTwoDayreminder: !localUser.wantsTwoDayreminder,
              })
            }
          />
        </>
      )}
      <ButtonComponent
        isLoading={isLoading}
        title={
          updateUser ? t("general.buttons.save") : t("general.buttons.add")
        }
      />
    </form>
  );
};

export default UserMetatag;
