import {
  LoaderComponent,
  PopupComponent,
  SelectPopupComponent,
} from "beelean-component-library";
import { Vector2d } from "konva/types/types";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Circle, Group, Layer, Rect, Stage, Text } from "react-konva";
import {
  CompanyContext,
  GlobaleApplicationSettings,
  KeycloakUserContext,
  TreeDataContext,
} from "../../../pages/App";
import { useAxios } from "../../../utils/AxiosUtil";
import {
  decreaseProcessLicenseCounter,
  fetchCompany,
} from "../../../utils/CompanyUtil";
import { ModuleShowType } from "../../../utils/Enums";
import { getFormattedDateTimeString } from "../../../utils/GeneralUtil";
import { Company, createEmptyCompany } from "../../../utils/Interfaces";
import {
  HistoryEntry,
  ProzessBereich,
  ProzessBereichRole,
  ProzessMappingEntry,
  Prozesslinie,
  Prozessschritt,
  Prozessschritttype,
  createEmptyProzessBereich,
  createEmptyProzessMappingEntry,
  createEmptyProzesslinie,
} from "../../../utils/LeanAdmin/LeanAdmin.types";
import { getProzessBereichById } from "../../../utils/LeanAdmin/LeanAdminModulUtils";
import {
  calculateDragBoundariesForStage,
  determineLinieType,
  fetchNeededProzessMappingEntry,
  fillLastUpdatedBy,
  generateNewEmptyProzessschritt,
  generatelistOfAllKundenIds,
  getAbsolutePositionOfProzessschritt,
  getCorrectWidthForContent,
  getProzessschrittByIdFromProzessMap,
  isNewProzesslinientypeConflicting,
  isProzessschrittValid,
  removeProzessschrittInProzessMappingEntry,
  saveProzessMappingToServer,
  updateProzessLinie,
  updateProzessschrittInProzessMappingEntry,
  useResize,
} from "../../../utils/LeanAdmin/ProzessMappingUtil";
import { handlePdcaCreationForKaizen } from "../../../utils/Pdca/PdcaUtil";
import {
  generateProzessMappingExportAsPdf,
  generateProzessMappingExportAsPng,
} from "../../../utils/PdfUtil";
import {
  getNameForUserId,
  isUserAllowedToDoThis,
} from "../../../utils/UserUtil";
import "./ProzessmappingStyle.scss";
import ProzessmappingToolbox from "./ProzessmappingToolbox";
import ProzesslinieEditMetaTag from "./subcomponents/ProzesslinieEditMetaTag";
import ProzessschrittEditMetaTag from "./subcomponents/ProzessschrittEditMetaTag";
import ProzessschrittImage from "./subcomponents/ProzessschrittImage";
import ProzessschrittLinie from "./subcomponents/ProzessschrittLinie";

interface ProzessmappingProps {
  companyId: string;
  prozessBereichId: string;
  setExportFunction(exportFunction: Function): void;
  isReleased?: boolean;
}

const Prozessmapping: React.FC<ProzessmappingProps> = ({
  companyId,
  prozessBereichId,
  setExportFunction,
  isReleased,
}) => {
  const { setUserCompany, userCompany } = useContext(CompanyContext);
  const resizeDivRef = useRef<HTMLDivElement>(null);
  const stageRef: React.RefObject<any> = useRef(null);
  const { width, height, reloadStageSize } = useResize(resizeDivRef);
  const [localXChange, setLocalXChange] = useState<number>(0);
  const [localYChange, setLocalYChange] = useState<number>(0);
  const { t } = useTranslation();
  const axios = useAxios();
  const [prozessLinieVersion, setProzessLinieVersion] = useState<number>(0);
  const [prozessSchrittVersion, setProzessSchrittVersion] = useState<number>(0);
  const [fetchedCompany, setFetchedCompany] = useState<Company>(
    createEmptyCompany()
  );
  const [loadedProzessBereichRoles, setLoadedProzessBereichRoles] = useState<
    string[]
  >([]);
  const [loadedProzessBereich, setLoadedProzessBereich] =
    useState<ProzessBereich>(createEmptyProzessBereich(companyId));
  const [loadedProzessMappingEntry, setLoadedProzessMappingEntry] =
    useState<ProzessMappingEntry>(
      createEmptyProzessMappingEntry(companyId, prozessBereichId)
    );
  const [isGeneratingExport, setIsGeneratingExport] = useState<boolean>(false);
  const [showSelectPopup, setShowSelectPopup] = useState<boolean>(false);
  const [isLoadingDone, toggleLoadingDone] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<Prozessschritttype>(
    Prozessschritttype.PROCESS
  );
  const [showProzessschrittPopup, setShowProzessschrittPopup] =
    useState<boolean>(false);
  const [showProzessliniePopup, setShowProzessliniePopup] =
    useState<boolean>(false);
  const [selectedProzessschritt, setSelectedProzessschritt] = useState<
    Prozessschritt | undefined
  >();
  const [selectedProzesslinie, setSelectedProzesslinie] = useState<
    Prozesslinie | undefined
  >();
  const [lastSelectedIdForLinie, setLastSelectedIdForLinie] =
    useState<string>("");
  const { isViewerState, isDesktopMenuOpen } = useContext(
    GlobaleApplicationSettings
  );
  const { keycloakUser } = useContext(KeycloakUserContext);
  const { leanAdminProzessbereiche, setLeanAdminProzessbereiche } =
    useContext(TreeDataContext);
  const [actualWidth, setActualWidth] = useState<number>(0);
  const [localIsUserAllowedToDoThis, setLocalIsUserAllowedToDoThis] =
    useState<boolean>(false);
  const [localListOfFilteredKundenIds, setLocalListOfFilteredKundenIds] =
    useState<string[]>([]);
  const [lastUpdaterName, setlastUpdaterName] = useState<string>(
    t("modules.SIPOC.unknown")
  );

  //helper to get name of last updater
  useEffect(() => {
    if (!loadedProzessMappingEntry.lastUpdatedBy || !axios || !t) return;
    getNameForUserId(loadedProzessMappingEntry.lastUpdatedBy, axios).then(
      (name) => setlastUpdaterName(name ? name : t("modules.SIPOC.unknown"))
    );
  }, [loadedProzessMappingEntry?.lastUpdatedBy, axios, t]);

  /**
   * set one time global
   */
  useEffect(() => {
    if (keycloakUser)
      setLocalIsUserAllowedToDoThis(
        isUserAllowedToDoThis(
          keycloakUser.userRole,
          ModuleShowType.SIPOC_CREATE
        )
      );
    // eslint-disable-next-line
  }, [keycloakUser]);

  /**
   * sets the current export function
   */
  useEffect(() => {
    setExportFunction(() => () => setShowSelectPopup(true));
    // eslint-disable-next-line
  }, []);

  /**
   * Helper to keep the list of Prozesschritt of type Kunden up to date with the ids
   */
  useEffect(() => {
    if (localListOfFilteredKundenIds.length !== 0) return;
    setLocalListOfFilteredKundenIds(
      generatelistOfAllKundenIds(loadedProzessMappingEntry.prozessSchritte)
    );
    // eslint-disable-next-line
  }, [loadedProzessMappingEntry]);

  /**
   * Functions and UseEffects for calculate the right width
   */
  useEffect(() => {
    setActualWidth(
      getCorrectWidthForContent(
        loadedProzessMappingEntry.prozessSchritte,
        width
      )
    );
    // eslint-disable-next-line
  }, [
    width,
    loadedProzessMappingEntry,
    isViewerState,
    isReleased,
    isDesktopMenuOpen,
  ]);
  useEffect(() => {
    if (resizeDivRef.current) {
      reloadStageSize();
    }
    // eslint-disable-next-line
  }, [resizeDivRef, isViewerState, isReleased, isDesktopMenuOpen]);
  /**
   * Fetches a ProcessMappignEntry from the server or creates a new one.
   * Fetches the role names from the Prozessbereich too.
   */
  useEffect(() => {
    if (!!axios && keycloakUser && companyId && prozessBereichId) {
      Promise.all([
        fetchCompany(axios, companyId),
        getProzessBereichById(prozessBereichId, axios),
        isReleased
          ? undefined
          : fetchNeededProzessMappingEntry(
              companyId,
              prozessBereichId,
              axios,
              leanAdminProzessbereiche,
              setLeanAdminProzessbereiche,
              keycloakUser.serviceId
            ),
      ]).then(([fetchedCompany, fetchedProzessBereich, entry]) => {
        setFetchedCompany(fetchedCompany);
        let prozessBereich: ProzessBereich = fetchedProzessBereich;
        let prozessMapping: ProzessMappingEntry | undefined = entry;
        if (isReleased) {
          const historyEntries: HistoryEntry[] = [
            ...fetchedProzessBereich.history,
          ].sort((entryA, entryB) => entryB.version - entryA.version);
          const latestRelease: HistoryEntry | undefined = historyEntries.find(
            (entry) => !!entry.released
          );
          if (latestRelease) {
            prozessBereich = latestRelease.bereichSnapshot;
            prozessMapping = latestRelease.prozessMappingSnapshot;
          }
        }
        if (prozessBereich.version! >= 2)
          setLoadedProzessBereich({
            ...prozessBereich,
            bereichRoles: [
              {
                id: process.env.REACT_APP_FIRST_ROW_ID!,
                name: t("modules.prozessMapping.roles.customer"),
                position: -1,
              },
              ...prozessBereich.bereichRoles,
            ],
          });
        else {
          setLoadedProzessBereichRoles([
            t("modules.prozessMapping.roles.customer"),
            ...prozessBereich.roles,
          ]);
          setLoadedProzessBereich(prozessBereich);
        }
        setLoadedProzessMappingEntry(
          prozessMapping ||
            createEmptyProzessMappingEntry(companyId, prozessBereichId)
        );
        toggleLoadingDone(true);
      });
    }
    // eslint-disable-next-line
  }, [axios, keycloakUser, companyId, isReleased, prozessBereichId]);

  /**
   * generates a new Prozessschritt in the swimmlanes by type
   *
   * @param event
   * @param role the name of the role in the swimmlane
   * @param swimmlaneIndex the index of the swimmlane
   * @param xIndex the index of the element in x raster
   */
  const addProzesschrittToProzessMapping = (
    event: any,
    role: string,
    swimmlaneIndex: number,
    xIndex: number
  ): void => {
    // abort if a line would be drawing
    if (!!lastSelectedIdForLinie) {
      setLastSelectedIdForLinie("");
      return;
    }
    generateNewEmptyProzessschritt(
      event,
      role,
      swimmlaneIndex,
      xIndex,
      selectedType,
      loadedProzessMappingEntry,
      stageRef,
      axios,
      companyId,
      userCompany,
      setUserCompany
    ).then((localLoadedProzessMappingEntry: ProzessMappingEntry) => {
      setLoadedProzessMappingEntry(
        fillLastUpdatedBy(
          { ...localLoadedProzessMappingEntry },
          keycloakUser!.serviceId
        )
      );
      saveProzessMappingToServer(
        leanAdminProzessbereiche,
        setLeanAdminProzessbereiche,
        localLoadedProzessMappingEntry,
        keycloakUser!.serviceId,
        axios
      );
    });
  };

  // what shall happen when an object is dropped to the stage
  const objectDropMethod = (event: any): void => {
    let position: {
      x: number;
      y: number;
    } = stageRef.current.getPointerPosition();
    // calculate posible xIndex
    // always have the x offset in mind
    const newxIndex: number = Math.trunc((position.x - localXChange) / 100);
    // by position the swimmlane is automatic filled in
    let usedRole: string = "";
    if (loadedProzessBereich.version! >= 2) {
      const localProzessBereichRoleList: ProzessBereichRole[] = [
        {
          id: process.env.REACT_APP_FIRST_ROW_ID!,
          name: t("modules.prozessMapping.roles.customer"),
          position: -1,
        },
        ...loadedProzessBereich.bereichRoles,
      ];
      const foundIndex: number = localProzessBereichRoleList.findIndex(
        (item) =>
          item.position === Math.trunc((position.y - localYChange) / 100) - 1
      );
      if (foundIndex !== -1)
        usedRole = localProzessBereichRoleList[foundIndex].id;
    } else
      usedRole =
        loadedProzessBereichRoles[
          Math.trunc((position.y - localYChange) / 100)
        ];
    addProzesschrittToProzessMapping(
      event,
      usedRole,
      Math.trunc((position.y - localYChange) / 100),
      newxIndex
    );
    // just in case something was selected
    setLastSelectedIdForLinie("");
  };

  /**
   * Helper to generate correct swimmlane stage height
   * @returns calculated height for current amount
   */
  const getCorrectStageHeight = (): number => {
    if (loadedProzessBereich.version! >= 2)
      return loadedProzessBereich.bereichRoles.length * 100;
    else return loadedProzessBereichRoles.length * 100;
  };

  /**
   * Helper to get actual height of visible stage
   * @returns calculate number
   */
  const getCorrectActualHeight = (): number => {
    if (loadedProzessBereich.version! >= 2)
      return height > loadedProzessBereich.bereichRoles.length * 100
        ? loadedProzessBereich.bereichRoles.length * 100
        : height;
    else
      return height > loadedProzessBereichRoles.length * 100
        ? loadedProzessBereichRoles.length * 100
        : height;
  };

  /**
   * Helper which generates the swimmlanes by the role names in the Prozessbereich
   * @returns generated element array
   */
  const generateRoleSwimmlanes = (): JSX.Element[] => {
    if (loadedProzessBereich.version! >= 2) {
      return loadedProzessBereich.bereichRoles
        .sort((a, b) => a.position - b.position)
        .map((value: ProzessBereichRole, index: number) => (
          <Group
            width={actualWidth}
            height={100}
            y={index * 100}
            key={`swimmlane-${value.name}-${index}`}
          >
            <Rect
              onClick={() => setLastSelectedIdForLinie("")}
              onTap={() => setLastSelectedIdForLinie("")}
              height={100}
              width={actualWidth}
              fill={index % 2 === 0 ? "white" : "lightgray"}
            />
            <Text
              rotation={270}
              text={value.name}
              x={10}
              y={90}
              fontSize={16}
              align="center"
              width={80}
              fill={"black"}
            />
          </Group>
        ));
    } else
      return loadedProzessBereichRoles.map((value: string, index: number) => (
        <Group
          width={actualWidth}
          height={100}
          y={index * 100}
          key={`swimmlane-${value}-${index}`}
        >
          <Rect
            onClick={() => setLastSelectedIdForLinie("")}
            onTap={() => setLastSelectedIdForLinie("")}
            height={100}
            width={actualWidth}
            fill={index % 2 === 0 ? "white" : "lightgray"}
          />
          <Text
            rotation={270}
            text={value}
            x={10}
            y={90}
            fontSize={16}
            align="center"
            width={80}
            fill={"black"}
          />
        </Group>
      ));
  };

  /**
   * deletes a Prozesslinie from the ProzessMapping Entry
   * @param prozessLinieId
   */
  const deleteProzessLinie = (prozessLinieId: string): void => {
    let localProzessMappingEntry: ProzessMappingEntry = {
      ...loadedProzessMappingEntry,
      prozessLinie: loadedProzessMappingEntry.prozessLinie.filter(
        (value: Prozesslinie) => value.id !== prozessLinieId
      ),
    };
    setLoadedProzessMappingEntry(
      fillLastUpdatedBy(localProzessMappingEntry, keycloakUser!.serviceId)
    );
    saveProzessMappingToServer(
      leanAdminProzessbereiche,
      setLeanAdminProzessbereiche,
      localProzessMappingEntry,
      keycloakUser!.serviceId,
      axios
    );
    setLastSelectedIdForLinie("");
    setSelectedProzesslinie(undefined);
    setShowProzessliniePopup(false);
    setProzessLinieVersion(prozessLinieVersion + 1);
  };

  /**
   * Deletes a Prozesschritt from the ProzessMapping Entry
   *
   * @param prozessSchrittId
   */
  const deleteProzessschrittInProzessMappingEntry = async (
    prozessSchrittId: string,
    prozessSchrittType: Prozessschritttype
  ) => {
    let localProzessMappingEntry: ProzessMappingEntry =
      removeProzessschrittInProzessMappingEntry(
        prozessSchrittId,
        loadedProzessMappingEntry
      );
    setLoadedProzessMappingEntry(
      fillLastUpdatedBy(localProzessMappingEntry, keycloakUser!.serviceId)
    );
    setShowProzessschrittPopup(false);
    saveProzessMappingToServer(
      leanAdminProzessbereiche,
      setLeanAdminProzessbereiche,
      localProzessMappingEntry,
      keycloakUser!.serviceId,
      axios
    );
    // remove from license
    if (
      prozessSchrittType === Prozessschritttype.PROCESS ||
      prozessSchrittType === Prozessschritttype.PROCESSSIMPLE
    ) {
      if (await decreaseProcessLicenseCounter(companyId, axios)) {
        setUserCompany &&
          userCompany &&
          setUserCompany({
            ...userCompany,
            createdProcesses:
              userCompany.createdProcesses - 1 < 0
                ? 0
                : userCompany.createdProcesses - 1,
          });
      }
    }
    setProzessLinieVersion(prozessLinieVersion + 1);
    setProzessSchrittVersion(prozessSchrittVersion + 1);
  };

  /**
   * Happens onClick only on Prozessschritt without Kaizen
   *
   * @param prozessSchritt
   */
  const clickedProzessschritt = (prozessSchritt: Prozessschritt): void => {
    setLastSelectedIdForLinie(prozessSchritt.id);
    // Do nothing in viewerstate
    if (isViewerState || isReleased || !localIsUserAllowedToDoThis) return;
    if (
      !!lastSelectedIdForLinie &&
      lastSelectedIdForLinie !== prozessSchritt.id
    ) {
      // draw the damn line
      let localProzesslinie: Prozesslinie[] =
        loadedProzessMappingEntry.prozessLinie;

      let localNewProzesslinie: Prozesslinie = createEmptyProzesslinie(
        lastSelectedIdForLinie,
        prozessSchritt.id,
        determineLinieType(
          lastSelectedIdForLinie,
          prozessSchritt.id,
          loadedProzessMappingEntry
        )
      );
      localProzesslinie.push(localNewProzesslinie);
      let updatedProzessMappingEntry: ProzessMappingEntry = fillLastUpdatedBy(
        { ...loadedProzessMappingEntry },
        keycloakUser!.serviceId
      );
      updatedProzessMappingEntry.prozessLinie = localProzesslinie;
      setLoadedProzessMappingEntry(updatedProzessMappingEntry);
      saveProzessMappingToServer(
        leanAdminProzessbereiche,
        setLeanAdminProzessbereiche,
        updatedProzessMappingEntry,
        keycloakUser!.serviceId,
        axios
      );
      setLastSelectedIdForLinie("");
    }
  };

  /**
   * Helper to fetch the correct role name for title
   * @returns found name
   */
  const getCorrectMetaTagTitle = (): string => {
    if (loadedProzessBereich.version! >= 2) {
      const foundIndex: number = loadedProzessBereich.bereichRoles.findIndex(
        (item) => item.id === selectedProzessschritt!.role
      );
      if (foundIndex === -1)
        return `${selectedProzessschritt!.role} (${t(
          `modules.prozessMapping.prozessSchritte.${
            selectedProzessschritt!.type
          }`
        )})`;
      else
        return `${loadedProzessBereich.bereichRoles[foundIndex].name} (${t(
          `modules.prozessMapping.prozessSchritte.${
            selectedProzessschritt!.type
          }`
        )})`;
    } else
      return `- (${t(
        `modules.prozessMapping.prozessSchritte.${selectedProzessschritt!.type}`
      )})`;
  };

  if (!loadedProzessMappingEntry.id && isReleased) return <></>;
  return (
    <>
      {showProzessliniePopup && (
        <PopupComponent
          closeText={t("general.buttons.close")}
          title={
            selectedProzesslinie
              ? t(`modules.prozessMapping.lines.${selectedProzesslinie.type}`)
              : ""
          }
          closeFunction={() => {
            setShowProzessliniePopup(false);
            saveProzessMappingToServer(
              leanAdminProzessbereiche,
              setLeanAdminProzessbereiche,
              loadedProzessMappingEntry,
              keycloakUser!.serviceId,
              axios
            );
          }}
        >
          <ProzesslinieEditMetaTag
            prozessLinie={selectedProzesslinie!}
            onChange={(updatedProzessLinie: Prozesslinie) =>
              setLoadedProzessMappingEntry(
                updateProzessLinie(
                  updatedProzessLinie,
                  fillLastUpdatedBy(
                    { ...loadedProzessMappingEntry },
                    keycloakUser!.serviceId
                  )
                )
              )
            }
            onDelete={(prozessLinieId: string) =>
              deleteProzessLinie(prozessLinieId)
            }
            hasConflicts={(updatedProzesslinie) =>
              isNewProzesslinientypeConflicting(
                updatedProzesslinie,
                loadedProzessMappingEntry
              )
            }
          />
        </PopupComponent>
      )}

      {showProzessschrittPopup && (
        <PopupComponent
          title={getCorrectMetaTagTitle()}
          closeText={t("general.buttons.close")}
          closeFunction={() => {
            setShowProzessschrittPopup(false);
            if (isViewerState || isReleased) return;
            saveProzessMappingToServer(
              leanAdminProzessbereiche,
              setLeanAdminProzessbereiche,
              handlePdcaCreationForKaizen(
                selectedProzessschritt!,
                loadedProzessMappingEntry,
                axios,
                `${keycloakUser!.firstname} ${keycloakUser!.lastname}`
              ),
              keycloakUser!.serviceId,
              axios
            );
            setProzessLinieVersion(prozessLinieVersion + 1);
          }}
        >
          <ProzessschrittEditMetaTag
            prozessBereich={loadedProzessBereich}
            onDelete={(prozessSchrittId: string) =>
              deleteProzessschrittInProzessMappingEntry(
                prozessSchrittId,
                selectedProzessschritt!.type
              )
            }
            prozessSchritt={selectedProzessschritt!}
            onChange={(updatedProzessSchritt: Prozessschritt) =>
              setLoadedProzessMappingEntry(
                updateProzessschrittInProzessMappingEntry(
                  updatedProzessSchritt,
                  loadedProzessMappingEntry
                )
              )
            }
          />
        </PopupComponent>
      )}

      <div className="process-mapping-drawing-wrapper">
        {!isViewerState && !isReleased && localIsUserAllowedToDoThis && (
          <ProzessmappingToolbox
            onDropFunction={(event) => objectDropMethod(event)}
            stageRefObject={stageRef}
            onSelect={(selectedProzessschritt) =>
              setSelectedType(selectedProzessschritt)
            }
          />
        )}
        <div
          className="process-mapping-drawing-stage-wrapper"
          onDrop={(event) => {
            stageRef.current.setPointersPositions(event);
            event.preventDefault();
            objectDropMethod(event);
          }}
          ref={resizeDivRef}
          onDragOver={(e) => e.preventDefault()}
        >
          {isLoadingDone && (
            <Stage
              draggable
              dragBoundFunc={(pos: Vector2d) => {
                let newPosition: number[] = calculateDragBoundariesForStage(
                  pos,
                  width,
                  actualWidth,
                  height,
                  loadedProzessBereichRoles.length
                );
                setLocalXChange(newPosition[0]);
                setLocalYChange(newPosition[1]);
                return {
                  x: newPosition[0],
                  y: newPosition[1],
                };
              }}
              ref={stageRef}
              width={actualWidth}
              height={getCorrectStageHeight()}
            >
              {
                <Layer
                  key={`prozessschritt-version-${prozessSchrittVersion}-prozessLinieVersion-${prozessLinieVersion}`}
                >
                  {generateRoleSwimmlanes()}

                  {loadedProzessMappingEntry.prozessLinie.map(
                    (prozessLinie: Prozesslinie, prozessLinieIndex: number) => (
                      <ProzessschrittLinie
                        startProzessSchritt={getProzessschrittByIdFromProzessMap(
                          prozessLinie.startProcessId,
                          loadedProzessMappingEntry
                        )}
                        listOfAllKundenIds={localListOfFilteredKundenIds}
                        selected={
                          lastSelectedIdForLinie ===
                            prozessLinie.startProcessId ||
                          lastSelectedIdForLinie === prozessLinie.stopProcessId
                        }
                        stageRefObject={stageRef}
                        onDblClick={(internProzessLinie: Prozesslinie) => {
                          // Do nothing in viewerstate
                          if (
                            isViewerState ||
                            isReleased ||
                            !localIsUserAllowedToDoThis
                          )
                            return;
                          setLastSelectedIdForLinie("");
                          setSelectedProzesslinie(internProzessLinie);
                          setShowProzessliniePopup(true);
                        }}
                        key={`prozesslinien-layer-index-${prozessLinieIndex}-${localListOfFilteredKundenIds.length}`}
                        prozessLinie={prozessLinie}
                        onChange={(updatedProzessLinie: Prozesslinie) => {
                          const updatedMapping: ProzessMappingEntry =
                            updateProzessLinie(
                              { ...updatedProzessLinie },
                              loadedProzessMappingEntry
                            );
                          setLoadedProzessMappingEntry(updatedMapping);
                          saveProzessMappingToServer(
                            leanAdminProzessbereiche,
                            setLeanAdminProzessbereiche,
                            updatedMapping,
                            keycloakUser!.serviceId,
                            axios
                          );
                          // setProzessLinieVersion(prozessLinieVersion + 1);
                        }}
                        localXChange={localXChange}
                        isViewerState={isReleased || isViewerState}
                      />
                    )
                  )}

                  {loadedProzessMappingEntry.prozessSchritte.map(
                    (
                      prozessSchritt: Prozessschritt,
                      prozessSchrittIndex: number
                    ) => (
                      <ProzessschrittImage
                        isValid={isProzessschrittValid(
                          prozessSchritt,
                          loadedProzessMappingEntry.prozessLinie
                        )}
                        yChange={localYChange}
                        selected={
                          lastSelectedIdForLinie === prozessSchritt.id &&
                          prozessSchritt.type !== Prozessschritttype.KAIZEN
                        }
                        actualWidth={actualWidth}
                        actualHeight={getCorrectActualHeight()}
                        draggable={
                          !isViewerState &&
                          !isReleased &&
                          localIsUserAllowedToDoThis
                        }
                        onDragEnd={() => {
                          saveProzessMappingToServer(
                            leanAdminProzessbereiche,
                            setLeanAdminProzessbereiche,
                            loadedProzessMappingEntry,
                            keycloakUser!.serviceId,
                            axios
                          );
                          setLoadedProzessMappingEntry((mapping) =>
                            fillLastUpdatedBy(
                              { ...mapping },
                              keycloakUser!.serviceId
                            )
                          );
                          setProzessLinieVersion(prozessLinieVersion + 1);
                        }}
                        onClick={(prozessSchritt) =>
                          prozessSchritt.type !== Prozessschritttype.KAIZEN &&
                          clickedProzessschritt(prozessSchritt)
                        }
                        onDblClick={(prozessSchritt) => {
                          // Do nothing in viewerstate
                          if (!localIsUserAllowedToDoThis) return;
                          setLastSelectedIdForLinie("");
                          setSelectedProzessschritt(prozessSchritt);
                          setShowProzessschrittPopup(true);
                        }}
                        prozessBereich={loadedProzessBereich}
                        key={`prozessschritt-image-index-${prozessSchrittIndex}`}
                        prozessSchritt={prozessSchritt}
                      />
                    )
                  )}
                </Layer>
              }

              <Layer>
                {!!lastSelectedIdForLinie &&
                  !isViewerState &&
                  !isReleased &&
                  localIsUserAllowedToDoThis && (
                    <Circle
                      radius={10}
                      x={
                        getAbsolutePositionOfProzessschritt(
                          lastSelectedIdForLinie,
                          stageRef
                        )[0]
                      }
                      y={
                        getAbsolutePositionOfProzessschritt(
                          lastSelectedIdForLinie,
                          stageRef
                        )[1]
                      }
                      fill={"#0000007f"}
                      stroke={"black"}
                    />
                  )}
              </Layer>
            </Stage>
          )}
          <div className="process-mapping-last-updated">
            <p>
              {t("modules.SIPOC.lastUpdate", {
                replace: {
                  date: getFormattedDateTimeString(
                    loadedProzessMappingEntry?.lastUpdated ||
                      loadedProzessMappingEntry?.createDate
                  ),
                  by: lastUpdaterName,
                },
              })}
            </p>
          </div>
        </div>
      </div>

      {showSelectPopup && (
        <SelectPopupComponent
          closeFunction={() => setShowSelectPopup(false)}
          closeText={t("general.buttons.close")}
          content={t("modules.prozessMapping.export")}
          title={t("general.buttons.export")}
          functionList={[
            {
              function: () => {
                setShowSelectPopup(false);
                setIsGeneratingExport(true);
                generateProzessMappingExportAsPdf(
                  loadedProzessBereich.bereich,
                  fetchedCompany.name,
                  fetchedCompany.logoName,
                  axios
                ).then(() => {
                  setIsGeneratingExport(false);
                });
              },
              text: t("general.buttons.pdf"),
            },
            {
              function: () => {
                setShowSelectPopup(false);
                setIsGeneratingExport(true);
                generateProzessMappingExportAsPng(
                  loadedProzessBereich.bereich
                ).then(() => {
                  setIsGeneratingExport(false);
                });
              },
              text: t("general.buttons.png"),
            },
          ]}
        />
      )}
      {isGeneratingExport && (
        <LoaderComponent inFront showText text={t("general.buttons.export")} />
      )}
    </>
  );
};

export default Prozessmapping;
