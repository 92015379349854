import {
  BoxComponent,
  ButtonComponent,
  ConfirmPopupComponent,
  DragNDropComponent,
  InputComponent,
  PopupComponent,
} from "beelean-component-library";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import { ReactComponent as DumpsterIcon } from "../../../assets/icons/dumpster.svg";
import { ReactComponent as LeanAdminModulIcon } from "../../../assets/icons/leanadmin.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/tools-edit.svg";
import {
  GlobaleApplicationSettings,
  KeycloakUserContext,
} from "../../../pages/App";
import { useAxios } from "../../../utils/AxiosUtil";
import { ModuleShowType, UserRole } from "../../../utils/Enums";
import {
  createEmptyProzessBereichRole,
  ProzessBereich,
  ProzessBereichRole,
} from "../../../utils/LeanAdmin/LeanAdmin.types";
import { updateProzessBereich } from "../../../utils/LeanAdmin/LeanAdminModulUtils";
import { generateNotification } from "../../../utils/NotificationUtil";
import { isUserAllowedToDoThis } from "../../../utils/UserUtil";
import "./LeanAdminModulStyle.scss";
import { useHistory } from "react-router-dom";

interface LeanAdminModulEntryProps {
  prozessBereich: ProzessBereich;
  onChange: (bereich: ProzessBereich) => void;
  onDelete: () => void;
  companyName?: string;
  userRole: UserRole;
  onCopy: (bereich: ProzessBereich) => void;
}

const LeanAdminModulEntry: React.FC<LeanAdminModulEntryProps> = ({
  prozessBereich,
  onChange,
  onDelete,
  companyName,
  userRole,
  onCopy,
}) => {
  const { isViewerState } = useContext(GlobaleApplicationSettings);
  const [currentRoleName, setCurrentRoleName] = useState<string>("");
  const axios = useAxios();
  const history = useHistory();
  const { t } = useTranslation();
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
  const [showEditRolePopup, setShowEditRolePopup] = useState<boolean>(false);
  const [selectedRoleIndex, setSelectedRoleIndex] = useState<number>(-1);
  const [selectedRoleId, setSelectedRoleId] = useState<string>();
  const [selectedEditProzessBereichRole, setSelectedEditProzessBereichRole] =
    useState<ProzessBereichRole>(createEmptyProzessBereichRole());
  const [newRolePosition, setNewRolePosition] = useState<number>(-1);
  const [dragRolePosition, setDragRolePosition] = useState<number>(-1);
  const [dropzoneRolePosition, setDropzoneRolePosition] = useState<number>(-1);
  const { keycloakUser } = useContext(KeycloakUserContext);

  /**
   * Helper to store updated role data with optional rearrange index
   */
  const updateRoleInList = (): void => {
    let foundIndex: number = prozessBereich.bereichRoles.findIndex(
      (item) => item.id === selectedEditProzessBereichRole.id
    );
    if (foundIndex === -1) {
      generateNotification(
        t("notifications.stammdatumUpdate.errorTitle"),
        t("notifications.stammdatumUpdate.errorContentRole"),
        "danger"
      );
      return;
    }
    prozessBereich.bereichRoles[foundIndex].name =
      selectedEditProzessBereichRole.name;
    // rearrange order if it has changed
    if (newRolePosition !== selectedEditProzessBereichRole.position) {
      const isPlus: boolean =
        selectedEditProzessBereichRole.position > newRolePosition;
      prozessBereich.bereichRoles[foundIndex].position = isPlus
        ? newRolePosition - 1
        : newRolePosition + 1;
      prozessBereich.bereichRoles = prozessBereich.bereichRoles.sort(
        (a, b) => a.position - b.position
      );
      for (let index = 0; index < prozessBereich.bereichRoles.length; index++) {
        prozessBereich.bereichRoles[index].position = index;
      }
    }
    onChange({ ...prozessBereich });
    updateProzessBereichonServer({ ...prozessBereich });
  };

  /**
   * Helper to add a new role to the prozessbereichs roles
   */
  const addRoleToList = (): void => {
    if (!currentRoleName) return;
    if (prozessBereich.version! >= 2) {
      let localNewRole: ProzessBereichRole = createEmptyProzessBereichRole();
      localNewRole.name = currentRoleName;
      localNewRole.position = prozessBereich.bereichRoles.length;
      prozessBereich.bereichRoles.push(localNewRole);
    } else {
      let localRoles: string[] = prozessBereich.roles;
      localRoles.push(currentRoleName);
      prozessBereich.roles = localRoles;
    }
    onChange({ ...prozessBereich });
    updateProzessBereichonServer({ ...prozessBereich });
    setCurrentRoleName("");
  };

  /**
   * Helper to remove role item from list old and new version
   * @param roleIndex index in old roles string array
   * @param roleId generated id of the role
   */
  const removeRoleFromList = (roleIndex: number, roleId: string): void => {
    if (prozessBereich.version! >= 2) {
      let localProzessBereichRoles: ProzessBereichRole[] = [
        ...prozessBereich.bereichRoles,
      ];
      localProzessBereichRoles = localProzessBereichRoles
        .sort((a, b) => a.position - b.position)
        .filter((item) => item.id !== roleId);
      for (let index = 0; index < localProzessBereichRoles.length; index++) {
        localProzessBereichRoles[index].position = index;
      }
      prozessBereich.bereichRoles = localProzessBereichRoles;
    } else {
      // in old version is the id the index in array
      let localRoles: string[] = prozessBereich.roles;
      localRoles.splice(roleIndex, 1);
      prozessBereich.roles = localRoles;
    }
    onChange({ ...prozessBereich });
    updateProzessBereichonServer({ ...prozessBereich });
    setShowDeletePopup(false);
  };

  const updateProzessBereichonServer = (
    prozessBereich: ProzessBereich
  ): void => {
    updateProzessBereich(prozessBereich, keycloakUser!.serviceId, axios).then(
      (updatedVersion: ProzessBereich) => {
        if (!updatedVersion) {
          generateNotification(
            t("notifications.stammdatumUpdate.errorTitle"),
            t("notifications.stammdatumUpdate.errorContent", {
              status: updatedVersion,
            }),
            "danger",
            -1
          );
        }
      }
    );
  };

  /**
   * Helper to generate the correct content for the current prozessbereich
   * @returns generated content list
   */
  const generateContent = (): JSX.Element => {
    if (prozessBereich.version! >= 2) {
      return (
        <>
          {prozessBereich.bereichRoles
            .sort((a, b) => a.position - b.position)
            .map((role: ProzessBereichRole, roleIndex: number) => (
              <DragNDropComponent
                key={roleIndex}
                groupId={`bereich-roles_${prozessBereich.id}`}
                disabled={
                  !isUserAllowedToDoThis(
                    userRole,
                    ModuleShowType.PROZESSBEREICH_CREATE
                  ) || isViewerState
                }
                position={role.position}
                setActiveItem={setDragRolePosition}
                moveItemTo={updateRoleAfterDrag}
                activeItem={dragRolePosition}
                setShowDropzone={setDropzoneRolePosition}
                showDropzone={dropzoneRolePosition}
              >
                <li>
                  <div>{role.name}</div>
                  {isUserAllowedToDoThis(
                    userRole,
                    ModuleShowType.PROZESSBEREICH_CREATE
                  ) &&
                    !isViewerState && (
                      <>
                        <div
                          onClick={() => {
                            setSelectedEditProzessBereichRole(role);
                            setNewRolePosition(role.position);
                            setShowEditRolePopup(true);
                          }}
                          title={t("general.buttons.edit")}
                          className="lean-admin-modul-entry-role-edit"
                        >
                          <EditIcon />
                        </div>

                        <div
                          onClick={() => {
                            setSelectedRoleId(role.id);
                            setShowDeletePopup(true);
                          }}
                          title={t("general.buttons.delete")}
                          className="lean-admin-modul-entry-role-remove"
                        >
                          <DumpsterIcon />
                        </div>
                      </>
                    )}
                </li>
              </DragNDropComponent>
            ))}
        </>
      );
    } else
      return (
        <>
          {prozessBereich.roles.map((role: string, roleIndex: number) => (
            <li key={roleIndex}>
              <div>{role}</div>
              {isUserAllowedToDoThis(
                userRole,
                ModuleShowType.PROZESSBEREICH_CREATE
              ) &&
                !isViewerState && (
                  <div
                    onClick={() => {
                      setSelectedRoleIndex(roleIndex);
                      setShowDeletePopup(true);
                    }}
                    title={t("general.buttons.delete")}
                    className="lean-admin-modul-entry-role-remove"
                  >
                    <DumpsterIcon />
                  </div>
                )}
            </li>
          ))}
        </>
      );
  };

  /**
   * Helper method to update position of role at dragRolePosition
   * @param newPos
   */
  const updateRoleAfterDrag = (newPos: number): void => {
    const updatedRoles: ProzessBereichRole[] = [...prozessBereich.bereichRoles];
    let indexToMove: number = dragRolePosition || 0;
    const role = updatedRoles[indexToMove];
    role.position = newPos;
    updatedRoles.splice(indexToMove, 1);
    updatedRoles.splice(newPos, 0, role);
    updatedRoles.forEach((role, index) => (role.position = index));
    const updatedProzessbereich: ProzessBereich = {
      ...prozessBereich,
      bereichRoles: updatedRoles,
    };
    updateProzessBereichonServer(updatedProzessbereich);
  };

  return (
    <>
      {showDeletePopup && (
        <ConfirmPopupComponent
          content={t("popup.leanAdminModul.delete.role", {
            name: prozessBereich.roles[selectedRoleIndex],
          })}
          title={t("general.buttons.delete")}
          closeFunction={() => setShowDeletePopup(false)}
          noText={t("general.buttons.no")}
          yesText={t("general.buttons.yes")}
          onYesFunction={() =>
            removeRoleFromList(selectedRoleIndex, selectedRoleId || "")
          }
        />
      )}

      {showEditPopup && (
        <PopupComponent
          closeFunction={() => {
            updateProzessBereichonServer(prozessBereich);
            setShowEditPopup(false);
          }}
          closeText={t("general.buttons.close")}
          title={prozessBereich.bereich}
        >
          <InputComponent
            value={prozessBereich.bereich}
            placeholder={t("modules.leanAdminModul.bereichsName")}
            onChange={(value: string) =>
              onChange({ ...prozessBereich, bereich: value })
            }
          />
        </PopupComponent>
      )}

      {showEditRolePopup && (
        <PopupComponent
          closeFunction={() => {
            updateRoleInList();
            setShowEditRolePopup(false);
          }}
          closeText={t("general.buttons.close")}
          title={prozessBereich.bereich}
        >
          <InputComponent
            value={selectedEditProzessBereichRole.name}
            placeholder={t("modules.leanAdminModul.nameRole")}
            onChange={(value: string) =>
              setSelectedEditProzessBereichRole({
                ...selectedEditProzessBereichRole,
                name: value,
              })
            }
          />
          <div className="flex-inline-box-component-wrapper add-subtract-button-wrapper">
            <ButtonComponent
              title="-"
              disabled={newRolePosition === 0}
              onClick={() => setNewRolePosition(newRolePosition - 1)}
            />
            <div>{newRolePosition + 1}</div>
            <ButtonComponent
              title="+"
              disabled={
                newRolePosition === prozessBereich.bereichRoles.length - 1
              }
              onClick={() => setNewRolePosition(newRolePosition + 1)}
            />
          </div>
          <p className="add-subtract-button-wrapper-label">
            {t("modules.leanAdminModul.positionDescription")}
          </p>
        </PopupComponent>
      )}

      <BoxComponent
        icon={<LeanAdminModulIcon />}
        onClick={() =>
          history.push(`/administration/company/prozessmap`, {
            companyId: prozessBereich.companyId,
            prozessBereichId: prozessBereich.id,
            openNodes: [
              prozessBereich.companyId,
              "lean-admin-root",
              `lean-admin-root/${prozessBereich.companyId}`,
            ],
            lastKey:
              keycloakUser?.userRole === UserRole.PLATFORMADMIN
                ? `lean-admin-root/${prozessBereich.companyId}/${prozessBereich.id}`
                : `lean-admin-root/${prozessBereich.id}`,
          })
        }
        title={
          <div title={t("modules.leanAdminModul.goToBereich")}>
            {prozessBereich.bereich}
          </div>
        }
        subtext={
          <div className="lean-admin-modul-entry-subtext-wrapper">
            <p>
              {companyName && <>{companyName} - </>}
              {new Date(prozessBereich.createDate!).toLocaleDateString("de-DE")}
            </p>

            {isUserAllowedToDoThis(
              userRole,
              ModuleShowType.PROZESSBEREICH_CREATE
            ) &&
              !isViewerState && (
                <>
                  <div
                    onClick={() => setShowEditPopup(true)}
                    title={t("general.buttons.edit")}
                    className="lean-admin-modul-entry-edit"
                  >
                    <EditIcon />
                  </div>
                  <div
                    onClick={() => onCopy(prozessBereich)}
                    title={t("general.buttons.copy")}
                    className="lean-admin-modul-entry-copy"
                  >
                    <CopyIcon />
                  </div>
                  <div
                    onClick={() => onDelete()}
                    title={t("general.buttons.delete")}
                    className="lean-admin-modul-entry-remove"
                  >
                    <DumpsterIcon />
                  </div>
                </>
              )}
          </div>
        }
      >
        {isUserAllowedToDoThis(
          userRole,
          ModuleShowType.PROZESSBEREICH_CREATE
        ) &&
          !isViewerState && (
            <div className="lean-admin-modul-entry-role-wrapper">
              <InputComponent
                placeholder={t("modules.leanAdminModul.role")}
                value={currentRoleName}
                onChange={(value: string) => setCurrentRoleName(value)}
              />
              <ButtonComponent
                title={"+"}
                type="button"
                onClick={() => addRoleToList()}
              />
            </div>
          )}

        <ul className="lean-admin-modul-entry-role-list">
          {generateContent()}
        </ul>
      </BoxComponent>
    </>
  );
};

export default LeanAdminModulEntry;
