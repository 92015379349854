import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import i18n from "../i18n";
import { Language, UserRole } from "./Enums";
import { KeycloakUser } from "./Interfaces";
import { ReactComponent as EnIcon } from "../assets/icons/en.svg";
import { ReactComponent as DeIcon } from "../assets/icons/de.svg";

/**
 * Helper to get correct image to current language
 * @param lng current language
 * @returns icon element
 */
export const getCorrectLanguageImage = (lng: Language): JSX.Element => {
  switch (lng) {
    case Language.en:
      return <EnIcon />;
    case Language.de:
    default:
      return <DeIcon />;
  }
};

/**
 * Helper to toggle the languages by click
 */
export const toggleLanguage = (): void => {
  if (i18n.language.includes(Language.de)) i18n.changeLanguage(Language.en);
  else i18n.changeLanguage(Language.de);
};

/**
 * Helper method to create the methods
 * which are needed by the layout component.
 *
 * @param userObject
 */
export const useLayoutConfiguration = (
  userObject: KeycloakUser
): {
  isAdmin: boolean;
  isPlatformAdmin: boolean;
  showToggleViewEdit: boolean;
  homeFunction: Function;
  logoutFunction: Function;
  profileFunction: Function;
  accountListFunction: Function;
  username: string;
  text: { home: string; impressum: string; privacy: string };
  isTest: boolean;
  lng: Language;
} => {
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const history = useHistory();
  const [currentLanguage, setCurrentLanguage] = useState<Language>(Language.de);

  /**
   * this useeffect checks for language
   */
  useEffect(() => {
    if (i18n.language.includes(Language.de)) setCurrentLanguage(Language.de);
    else setCurrentLanguage(Language.en);
    // eslint-disable-next-line
  }, [i18n.language]);

  return {
    lng: currentLanguage,
    showToggleViewEdit: userObject.userRole !== UserRole.WORKER,
    username: userObject.firstname + " " + userObject.lastname,
    text: {
      home: t("layout.home"),
      impressum: t("layout.impressum"),
      privacy: t("layout.privacy"),
    },
    homeFunction: () => history.push("/"),
    profileFunction: () => history.push("/profile"),
    accountListFunction: () => history.push("/account"),
    logoutFunction: () => keycloak.logout(),
    isAdmin: isRoleAdmin(userObject.userRole),
    isPlatformAdmin: userObject.userRole === UserRole.PLATFORMADMIN,
    isTest: JSON.parse(process.env.REACT_APP_SHOW_TEST!),
  };
};

/**
 * Helper to get just the right roles for admin
 *
 * @param role
 */
const isRoleAdmin = (role: UserRole): boolean => {
  switch (role) {
    case UserRole.PLATFORMADMIN:
    case UserRole.COMPANYADMIN:
      return true;

    default:
      return false;
  }
};
