/**
 * constant to define the current language in the client
 */
export enum Language {
  de = "de",
  en = "en",
}

/**
 * Different types of a license,
 * can be used for modules
 */
export enum LicenseModuleType {
  ADMINISTRATION = "ADMINISTRATION",
  PRODUCTION = "PRODUCTION",
  PDCA = "PDCA",
  PROJEKTAKTE = "PROJEKTAKTE",
  PROZESSBEREICHE = "PROZESSBEREICHE",
}

/**
 * Enum for identification of the user roles
 */
export enum UserRole {
  PLATFORMADMIN = "PLATFORMADMIN",
  COMPANYADMIN = "COMPANYADMIN",
  KEYUSER = "KEYUSER",
  EVALUATION = "EVALUATION",
  WORKER = "WORKER",
  NONE = "NONE",
}

/**
 * Defines the type of a license if it without stations
 */
export enum LicenseType {
  ADMINISTRATION_USER = "ADMINISTRATION_USER",
  ADMINISTRATION = "ADMINISTRATION",
  ADMINISTRATION_PROJEKTAKTE = "ADMINISTRATION_PROJEKTAKTE",
  PRODUCTION = "PRODUCTION",
  ALL = "ALL",
}

/**
 * Enum of all to show Elements
 * used for isUserAllowedToSeeThis
 */
export enum ModuleShowType {
  PROZESSBEREICH_CREATE = "PROZESSBEREICH_CREATE",
  SIPOC_CHECKLIST = "SIPOC_CHECKLIST",
  SIPOC_CREATE = "SIPOC_CREATE",
  PROZESSMAPPING = "PROZESSMAPPING",
  LOGO_ADMINISTRATION = "LOGO_ADMINISTRATION",
  PDCA_CREATE = "PDCA_CREATE",
  PROJEKTAKTE_CREATE = "PROJEKTAKTE_CREATE",
  PROJEKTAKTE_CHANGE = "PROJEKTAKTE_CHANGE",
}
