import { groupBy } from "./GeneralUtil";
import {
  ProzessBereichRole,
  ProzessMappingEntry,
  ProzessSchritteWithParent,
  ProzessTreeNode,
  Prozesslinie,
  ProzesslinieType,
  Prozessschritt,
  Prozessschritttype,
} from "./LeanAdmin/LeanAdmin.types";
import { getRoleNameByRoleId } from "./ProzessEntryMappingUtil";

import { TimeUnit } from "beelean-component-library";
import {
  GanttDiagrammComponentProps,
  GanttLane,
} from "beelean-component-library/build/GanttDiagrammComponent/GanttDiagrammComponent.types";

/**
 * Out of a list of prozessschritte get the max prozesszeit
 * @param schritte  The prozessschritte
 * @returns       The longest prozesszeit
 */
export const getMaxProzessZeit = (schritte: Prozessschritt[]): number =>
  Math.max(...schritte.flatMap((schritt) => schritt.prozessZeit.value));

/**
 * Gets the nested prozessschritte via the prozesslinien
 * @param prozessSchritteIds  The root ids of the prozessschritte
 * @param prozessLinien     The prozesslinien
 * @param allProzessSchritte All prozessschritte
 * @returns               The nested prozessschritte - next in line
 */
export const getNestedProzessSchritte = (
  prozessSchritteIds: string[],
  prozessLinien: Prozesslinie[],
  allProzessSchritte: Prozessschritt[]
): ProzessSchritteWithParent[] => {
  let nestedProzessSchritte: ProzessSchritteWithParent[] = [];
  for (let schrittId of prozessSchritteIds) {
    const startProzessLinien: Prozesslinie[] = prozessLinien.filter(
      (linie) => linie.startProcessId === schrittId
    );
    const stopProzessIds: string[] = startProzessLinien.flatMap(
      (linie) => linie.stopProcessId
    );
    nestedProzessSchritte.push({
      parentId: schrittId,
      schritte: allProzessSchritte.filter((schritt) => {
        return stopProzessIds.includes(schritt.id);
      }),
    });
  }
  return nestedProzessSchritte;
};

/**
 * Prepare the data for the gant diagramm
 * @param prozessMappingEntry  The prozess mapping entry
 * @param bereichRoles       The bereich roles
 * @returns                 The gant diagramm props
 */
export const prepareGantDiagramData = (
  prozessMappingEntry: ProzessMappingEntry,
  bereichRoles: ProzessBereichRole[]
): GanttDiagrammComponentProps => {
  const kundeRole: string = process.env.REACT_APP_FIRST_ROW_ID!;
  const externRole: string = process.env.REACT_APP_EXTERNAL_ROW!;
  const schritteForGantt: Prozessschritt[] =
    prozessMappingEntry.prozessSchritte.filter((schritt) =>
      [
        Prozessschritttype.EXTERN,
        Prozessschritttype.KUNDEN,
        Prozessschritttype.PROCESS,
        Prozessschritttype.PROCESSSIMPLE,
      ].includes(schritt.type)
    );
  const linienForGantt: Prozesslinie[] =
    prozessMappingEntry.prozessLinie.filter(
      (linie) =>
        ![
          ProzesslinieType.QUALITAETSMANGEL,
          ProzesslinieType.RUECKFRAGE,
        ].includes(linie.type)
    );
  let ganttItems: ProzessTreeNode[] = [];
  //schritte without input f.E. for additional customer input or extern
  let schritteToStartWith = schritteForGantt.filter(
    (schritt) =>
      !linienForGantt.some((linie) => linie.stopProcessId === schritt.id)
  );
  //first Kunde prozessschritt, can have Input, when process ends with them
  const kundenSchritte = schritteForGantt.filter(
    (schritt) => schritt.type === Prozessschritttype.KUNDEN
  );
  if (kundenSchritte.length > 0) {
    const firstProzessSchritt = kundenSchritte.reduce((smallest, current) => {
      if (smallest.position > current.position) return current;
      return smallest;
    });
    if (firstProzessSchritt.id) {
      //make sure the first is included in schritte to start with
      const firstIsIncluded: boolean = schritteToStartWith.some(
        (schritt) => schritt.id === firstProzessSchritt.id
      );
      if (!firstIsIncluded) schritteToStartWith.push(firstProzessSchritt);
    }
  }

  let addedFollowingOf: string[] = [];
  for (const schritt of schritteToStartWith) {
    let schritteWithParent: ProzessSchritteWithParent[] =
      getNestedProzessSchritte([schritt.id], linienForGantt, schritteForGantt);
    ganttItems.push({
      schritt: schritt,
      children: schritteWithParent.flatMap((swp) =>
        swp.schritte.flatMap((schritt) => schritt.id)
      ),
      parentId: "",
      role:
        schritt.type === Prozessschritttype.EXTERN
          ? externRole
          : schritt.type === Prozessschritttype.KUNDEN
          ? kundeRole
          : schritt.role,
    });
    while (schritteWithParent.length > 0) {
      for (let swp of schritteWithParent) {
        for (let schritt of swp.schritte) {
          const nestedProzessSchritteWithParent = getNestedProzessSchritte(
            [schritt.id],
            linienForGantt,
            schritteForGantt
          );
          if (
            schritteToStartWith.some(
              (startSchritt) => startSchritt.id === schritt.id
            )
          )
            continue;
          ganttItems.push({
            children: nestedProzessSchritteWithParent.flatMap((swp) =>
              swp.schritte.flatMap((schritt) => schritt.id)
            ),
            schritt: schritt,
            parentId: swp.parentId,
            role:
              schritt.type === Prozessschritttype.EXTERN
                ? externRole
                : schritt.type === Prozessschritttype.KUNDEN
                ? kundeRole
                : schritt.role,
          });
        }
        addedFollowingOf.push(swp.parentId!);
      }
      schritteWithParent = getNestedProzessSchritte(
        schritteWithParent.flatMap((schrittWithParent) =>
          schrittWithParent.schritte.flatMap((schritt) => schritt.id)
        ),
        linienForGantt,
        schritteForGantt
      ).filter((entry) => !addedFollowingOf.includes(entry.parentId!));
    }
  }

  const groupedGanttItemsByRole = groupBy(ganttItems, "role");
  const ganttDiagramLanes: GanttLane[] = Array.from(
    groupedGanttItemsByRole.entries()
  )
    //put KUNDE and EXTERN to the top
    .sort(([roleA], [roleB]) => {
      let valueA =
        bereichRoles.length -
        1 -
        (bereichRoles.find((role) => role.id === roleA)?.position || 0);
      let valueB =
        bereichRoles.length -
        1 -
        (bereichRoles.find((role) => role.id === roleB)?.position || 0);
      if (roleA === kundeRole) valueA = bereichRoles.length + 1;
      if (roleA === externRole) valueA = bereichRoles.length;
      if (roleB === kundeRole) valueB = bereichRoles.length + 1;
      if (roleB === externRole) valueB = bereichRoles.length;
      return valueB - valueA;
    })
    .map(([role, items]): GanttLane => {
      return {
        title: getRoleNameByRoleId(role, bereichRoles || []),
        items: items.map((item) => ({
          title:
            item.schritt.type === Prozessschritttype.EXTERN
              ? item.schritt.bezeichnung || ""
              : item.schritt.displayName || "",
          following: [...item.children],
          former: item.parentId || "",
          stepId: item.schritt.id,
          seconds:
            item.schritt.type === Prozessschritttype.KUNDEN
              ? -1
              : item.schritt.type === Prozessschritttype.EXTERN
              ? item.schritt.dauer.value
              : item.schritt.prozessZeit.value,
        })),
      };
    });
  return {
    lanes: ganttDiagramLanes,
    unit: TimeUnit.HOURS,
    //hide kunde lane since kunde has no time
    lanesToHide: [0],
  };
};
