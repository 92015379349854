import {
  CheckboxComponent,
  PopupComponent,
  TextAreaComponent,
} from "beelean-component-library";
import React, { useContext, useEffect, useState } from "react";
import { ModuleShowType } from "../../../../utils/Enums";
import { ReactComponent as Trash } from "../../../../assets/icons/dumpster.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/company-edit.svg";
import { ReactComponent as HasConfigIcon } from "../../../../assets/icons/clipboard-check.svg";
import { ReactComponent as ToolsEditIcon } from "../../../../assets/icons/tools-edit.svg";
import "../SipocEvalStyle.scss";
import {
  addUpdatedProzessTaskItem,
  findAndInsertText,
  findAndReturnText,
  getCorrectCSSClassForDot,
  getCorrectTaetigkeit,
  setNextTaetigkeit,
} from "../../../../utils/LeanAdmin/SipocEvalUtils";
import { useTranslation } from "react-i18next";
import {
  GlobaleApplicationSettings,
  KeycloakUserContext,
} from "../../../../pages/App";
import ProzessschrittEditMetaTag from "../../prozessmapping/subcomponents/ProzessschrittEditMetaTag";
import { isUserAllowedToDoThis } from "../../../../utils/UserUtil";
import {
  createEmptyProzess,
  createEmptyProzessTask,
  createEmptyProzessTaskItem,
  DotData,
  ProzessBereich,
  ProzessBereichRole,
  ProzesslinieType,
  Prozessschritt,
  Prozessschritttype,
  ProzessTaskItem,
  ProzessTaskType,
  SipocChecklistEval,
  SipocChecklistEvalOutputData,
} from "../../../../utils/LeanAdmin/LeanAdmin.types";
import {
  checkIfSpecificIdIsTrue,
  generateIdForCheckbox,
} from "../../../../utils/LeanAdmin/SipocChecklistUtils";
import SipocOutputConfigurationCheck from "./SipocOutputConfigurationCheck";
import {
  getLabelForProzessSchritt,
  sendEMailReminderForProjektAkte,
} from "../../../../utils/LeanAdmin/ProjektAkteUtils";
import SipocOutputConfiguration from "../SipocOutputConfiguration";
import { InputMailRequest } from "../../../../utils/LeanAdmin/ProjektAkte.types";
import { useAxios } from "../../../../utils/AxiosUtil";
import { getCorrectWidthForRoles } from "../../../../utils/LeanAdmin/ProzessMappingUtil";
interface SipocDetailviewInputLineProps {
  prozessBereich: ProzessBereich;
  onChange?(changedProzessTaskItem: ProzessTaskItem): void;
  onDelete?(): void;
  index: number;
  type: ProzessTaskType;
  item?: ProzessTaskItem;
  prozessSchritt?: Prozessschritt;
  detailViewProzessSchritt: Prozessschritt;
  isDeleteable?: boolean;
  isExport?: boolean;
  updateProzessschrittInProzessMapping(
    prozessSchritt: Prozessschritt,
    uploadOnServer: boolean
  ): void;
  selectedSipocChecklist?: SipocChecklistEval;
  onClickToUpdate?(
    id: string,
    nextSipocChecklistEvalEntryId?: string,
    checked?: boolean,
    fileList?: string[],
    outputConfig?: SipocChecklistEvalOutputData[]
  ): void;
  companyId: string;
  projektManagerId: string;
  responsibleUserId: string;
  isInputComplete?(prozessschritt: Prozessschritt): boolean;
  projektAkteTitle?: string;
  hasProzesslinieDesiredType?: (
    startProcessId: string,
    stopProcessId: string,
    desiredTypes: ProzesslinieType[]
  ) => boolean;
  isReleased?: boolean;
}

const SipocDetailviewInputLine: React.FC<SipocDetailviewInputLineProps> = ({
  projektManagerId,
  prozessBereich,
  onChange,
  index,
  item,
  type,
  prozessSchritt,
  isDeleteable,
  isExport,
  onDelete,
  updateProzessschrittInProzessMapping,
  detailViewProzessSchritt,
  selectedSipocChecklist,
  onClickToUpdate,
  companyId,
  responsibleUserId,
  isInputComplete,
  projektAkteTitle,
  hasProzesslinieDesiredType,
  isReleased,
}) => {
  const { t } = useTranslation();
  const { isViewerState } = useContext(GlobaleApplicationSettings);
  const { keycloakUser } = useContext(KeycloakUserContext);
  const axios = useAxios();
  const [showProzessschrittPopup, setShowProzessschrittPopup] =
    useState<boolean>(false);
  const [showOutputConfigChecklist, setShowOutputConfigChecklist] =
    useState<boolean>(false);
  const [completeInput, setCompleteInput] = useState<boolean>(false);

  const [showOutputConfig, setShowOutputConfig] = useState<boolean>(false);
  const [showOutputConfigInViewMode, setShowOutputConfigInViewMode] =
    useState<boolean>(false);
  const [localProzessschritt, setLocalProzessschritt] =
    useState<Prozessschritt>(
      createEmptyProzess("", Prozessschritttype.PROCESS, 0)
    );
  const [generatedSipocChecklist, setGeneratedSipocChecklist] =
    useState<string>("");

  const [
    generatedSipocChecklistNextInput,
    setGeneratedSipocChecklistNextInput,
  ] = useState<string>("");

  const [hasOutput, toggleHasOutput] = useState<boolean>(false);

  const outputOfDetailViewProzesschrittUsed: boolean =
    type === ProzessTaskType.INPUT &&
    prozessSchritt?.type !== Prozessschritttype.KUNDEN &&
    prozessSchritt?.type !== Prozessschritttype.EXTERN;
  const [displayConfig, toggleDisplayConfig] = useState<boolean>(false);

  /**
   * Helper method to determine if prozesschritt should display config icon
   */
  const shouldProzessschrittHaveConfig = (): boolean => {
    if (!localProzessschritt) return false;
    if (selectedSipocChecklist) return false;
    if (type === ProzessTaskType.PROCESS) return false;
    if (localProzessschritt.type === Prozessschritttype.KUNDEN) {
      if (!hasProzesslinieDesiredType) return false;

      const desiredTypes: ProzesslinieType[] = [
        ProzesslinieType.INFORMATIONSFLUSS_EDV,
        ProzesslinieType.INFORMATIONSFLUSS_PAPIER,
        ProzesslinieType.INFORMATIONSFLUSS_DEFAULT,
      ];
      if (type === ProzessTaskType.OUTPUT)
        return hasProzesslinieDesiredType(
          detailViewProzessSchritt.id,
          localProzessschritt.id,
          desiredTypes
        );
      else
        return hasProzesslinieDesiredType(
          localProzessschritt.id,
          detailViewProzessSchritt.id,
          desiredTypes
        );
    }
    if (
      localProzessschritt.type === Prozessschritttype.EXTERN &&
      type === ProzessTaskType.INPUT
    ) {
      if (!hasProzesslinieDesiredType) return false;
      const desiredTypes: ProzesslinieType[] = [
        ProzesslinieType.DIENSTLEISTUNGSLIEFERUNG_MATERIAL,
      ];
      return hasProzesslinieDesiredType(
        localProzessschritt.id,
        detailViewProzessSchritt.id,
        desiredTypes
      );
    }
    return true;
  };

  //setter for flag to display config icon
  useEffect(() => {
    const localDisplayConfig: boolean = shouldProzessschrittHaveConfig();
    toggleDisplayConfig(localDisplayConfig);
    // eslint-disable-next-line
  }, [localProzessschritt]);

  //update output indicator for view mode
  useEffect(() => {
    const localDisplayConfig: boolean = shouldProzessschrittHaveConfig();
    toggleDisplayConfig(localDisplayConfig);
    if (type === ProzessTaskType.PROCESS) return;
    const hasOutputConfig: boolean = (
      outputOfDetailViewProzesschrittUsed
        ? detailViewProzessSchritt.outputConfig
        : localProzessschritt.outputConfig
    ).some(
      (outputConfig) =>
        outputConfig.id.split("_")[0] ===
        (!outputOfDetailViewProzesschrittUsed
          ? detailViewProzessSchritt.id
          : localProzessschritt.id)
    );
    const hasOutputFileList: boolean = (
      outputOfDetailViewProzesschrittUsed
        ? detailViewProzessSchritt.outputFileList
        : localProzessschritt.outputFileList
    ).some(
      (fileName) =>
        fileName.split("_")[0] ===
        (!outputOfDetailViewProzesschrittUsed
          ? detailViewProzessSchritt.id
          : localProzessschritt.id)
    );
    toggleHasOutput(hasOutputConfig || hasOutputFileList);
    // eslint-disable-next-line
  }, [localProzessschritt, detailViewProzessSchritt]);

  /**
   * creates a local copy of a given Prozessschritt
   */
  useEffect(() => {
    if (!prozessSchritt) return;
    setLocalProzessschritt(prozessSchritt); //initially set configs according to type

    // eslint-disable-next-line
  }, [prozessSchritt]);

  /**
   * generates the line id for setting and getting
   * the checked status in the sipoc checklist eval
   */
  useEffect(() => {
    setGeneratedSipocChecklist(
      generateIdForCheckbox(
        type,
        index,
        detailViewProzessSchritt,
        prozessSchritt
      )
    );
    if (type === ProzessTaskType.OUTPUT)
      setGeneratedSipocChecklistNextInput(
        generateIdForCheckbox(
          ProzessTaskType.INPUT,
          index,
          prozessSchritt!,
          detailViewProzessSchritt
        )
      );
  }, [type, index, detailViewProzessSchritt, prozessSchritt]);

  /**
   * defines which string is the right to display
   *
   * @returns string for the value
   */
  const getCorrectValue = (): string => {
    switch (type) {
      // get text on current Prozessschritt with detail view id
      case ProzessTaskType.INPUT:
        return findAndReturnText(
          localProzessschritt,
          detailViewProzessSchritt!.id
        );
      // normal display the info in process
      case ProzessTaskType.PROCESS:
        return item?.description || "";
      // look in the detail view Prozesschritt for
      // the content for current Prozessschritt
      case ProzessTaskType.OUTPUT:
        return findAndReturnText(
          detailViewProzessSchritt!,
          localProzessschritt.id
        );
    }
  };

  /**
   *
   * @param value
   */
  const setCorrectValue = (value: string): void => {
    let internCopyOfProzessschritt: Prozessschritt = localProzessschritt;

    switch (type) {
      case ProzessTaskType.PROCESS:
        // just change the text if it is an item
        if (item) onChange && onChange({ ...item, description: value });
        return;
      case ProzessTaskType.INPUT:
        setLocalProzessschritt({
          ...findAndInsertText(
            localProzessschritt,
            detailViewProzessSchritt!.id,
            value
          ),
        });
        break;
      case ProzessTaskType.OUTPUT:
        // set for default edit
        internCopyOfProzessschritt = findAndInsertText(
          detailViewProzessSchritt!,
          localProzessschritt.id,
          value
        );
        break;
    }
    updateProzessschrittInProzessMapping(
      { ...internCopyOfProzessschritt },
      false
    );
  };

  /**
   * adds the new Tätigkeit to the correct position
   */
  const setCorrectNextTaetigkeit = (role: string): void => {
    let localInputCopyOfProzessschritt: Prozessschritt;
    let newInputTaetigkeit: ProzessTaskItem;

    switch (type) {
      // adds the info to the current Prozessschritt
      case ProzessTaskType.PROCESS:
        if (item) onChange!(setNextTaetigkeit(role, type, item));
        return;
      case ProzessTaskType.INPUT:
        // create input data is needed
        if (
          !localProzessschritt?.input ||
          localProzessschritt?.input.items.length === 0
        ) {
          localProzessschritt.input = createEmptyProzessTask(type);
          localProzessschritt.input.items.push(createEmptyProzessTaskItem());
        }
        // fetch the next Tätigkeit
        newInputTaetigkeit = setNextTaetigkeit(
          role,
          type,
          localProzessschritt.input.items[0]
        );
        break;
      case ProzessTaskType.OUTPUT:
        // create input data is needed
        if (
          !localProzessschritt?.output ||
          localProzessschritt?.output.items.length === 0
        ) {
          localProzessschritt.output = createEmptyProzessTask(type);
          localProzessschritt.output.items.push(createEmptyProzessTaskItem());
        }
        // fetch the next Tätigkeit
        newInputTaetigkeit = setNextTaetigkeit(
          role,
          type,
          localProzessschritt.output.items[0]
        );
        break;
    }
    // add the new Prozesschritt
    localInputCopyOfProzessschritt = addUpdatedProzessTaskItem(
      newInputTaetigkeit,
      0,
      localProzessschritt,
      type
    );
    setLocalProzessschritt({ ...localInputCopyOfProzessschritt });
    updateProzessschrittInProzessMapping(
      {
        ...localInputCopyOfProzessschritt,
      },
      false
    );
  };

  /**
   * loads and return correct DotData Array
   *
   * @returns correct DotData Array for line
   */
  const getCorrectDotData = (): DotData[] | undefined => {
    switch (type) {
      case ProzessTaskType.INPUT:
        return localProzessschritt.input?.items[0]
          ? localProzessschritt.input?.items[0].dots
          : undefined;
      case ProzessTaskType.PROCESS:
        return item?.dots;
      case ProzessTaskType.OUTPUT:
        return localProzessschritt.output?.items[0]
          ? localProzessschritt.output?.items[0].dots
          : undefined;
    }
  };

  //returns title as JSX-Element with proper style for Config Popups
  const getTitleForOutputConfig = (
    prozessSchritt: Prozessschritt
  ): JSX.Element => {
    return (
      <div className="sipoc-config-pop-up-title">
        <p>{getCorrectValue()}</p>
        <p>
          {getLabelForProzessSchritt(
            t("projektAkte.create.milestone.noNameSet"),
            prozessSchritt!
          )}
        </p>
      </div>
    );
  };

  //checks if Input of prozessSchritt is complete and sends mail if so
  const checkCompleteInputAndSendMail = (
    prozessSchritt: Prozessschritt
  ): void => {
    if (
      prozessSchritt.type === Prozessschritttype.KUNDEN &&
      type === ProzessTaskType.OUTPUT
    )
      return;
    let currentCompleteInput: boolean = false;
    if (isInputComplete) currentCompleteInput = isInputComplete(prozessSchritt);
    if (!completeInput && currentCompleteInput) {
      let inputMailRequest: InputMailRequest = {
        userId: responsibleUserId,
        prozessSchrittName: getLabelForProzessSchritt(
          t("projektAkte.create.milestone.noNameSet"),
          prozessSchritt
        ),
        projektAkteName:
          projektAkteTitle || t("projektAkte.create.milestone.noNameSet"),
      };
      sendEMailReminderForProjektAkte(inputMailRequest, axios);
    }
  };

  /**
   * Helper to generate the correct inputs for export and/or types
   * @returns generated Element to bis displayed
   */
  const getCorrectInput = (): JSX.Element => {
    if (isExport)
      return (
        <p className="sipoc-detail-line-export-text-wrapper">
          {getCorrectValue()}
        </p>
      );
    return (
      <TextAreaComponent
        rows={1}
        maxRows={4}
        autoResize
        placeholder={
          prozessSchritt && localProzessschritt?.name
            ? localProzessschritt?.name
            : localProzessschritt.type === Prozessschritttype.EXTERN
            ? t("modules.SIPOC.detail.extern")
            : ""
        }
        disabled={
          !!selectedSipocChecklist ||
          isReleased ||
          isViewerState ||
          !isUserAllowedToDoThis(
            keycloakUser!.userRole,
            ModuleShowType.SIPOC_CREATE
          )
        }
        onChange={(value: string) => setCorrectValue(value)}
        value={getCorrectValue()}
      />
    );
  };

  /**
   * Helper to create the correct role lines
   * @returns generate role lines
   */
  const getCorrectRoleLines = (): JSX.Element[] => {
    if (prozessBereich.version! >= 2) {
      return prozessBereich.bereichRoles.map(
        (role: ProzessBereichRole, roleIndex: number) => (
          <div
            className={getCorrectCSSClassForDot(
              role.id,
              type,
              isViewerState || isReleased || !!selectedSipocChecklist,
              getCorrectDotData(),
              localProzessschritt?.role
            )}
            title={t(
              `modules.SIPOC.detail.dotType.${getCorrectTaetigkeit(
                role.id,
                type,
                getCorrectDotData()
              )}`
            )}
            onClick={() => {
              // just do something for type PORCESS
              if (
                !isViewerState &&
                !isReleased &&
                isUserAllowedToDoThis(
                  keycloakUser!.userRole,
                  ModuleShowType.SIPOC_CREATE
                )
              ) {
                setCorrectNextTaetigkeit(role.id);
              }
            }}
            key={`sipoc-detailview-checkbox-${role.name}-${roleIndex}`}
            style={{
              left: `${roleIndex * 20 + 8}px`,
            }}
          />
        )
      );
    } else
      return prozessBereich.roles.map((role: string, roleIndex: number) => (
        <div
          className={getCorrectCSSClassForDot(
            role,
            type,
            isViewerState || isReleased || !!selectedSipocChecklist,
            getCorrectDotData(),
            localProzessschritt?.role
          )}
          title={t(
            `modules.SIPOC.detail.dotType.${getCorrectTaetigkeit(
              role,
              type,
              getCorrectDotData()
            )}`
          )}
          onClick={() => {
            // just do something for type PORCESS
            if (
              !isViewerState &&
              !isReleased &&
              isUserAllowedToDoThis(
                keycloakUser!.userRole,
                ModuleShowType.SIPOC_CREATE
              )
            ) {
              setCorrectNextTaetigkeit(role);
            }
          }}
          key={`sipoc-detailview-checkbox-${role}-${roleIndex}`}
          style={{
            left: `${roleIndex * 20 + 8}px`,
          }}
        />
      ));
  };

  return (
    <>
      {/* Generated through Data */}
      <div
        className="sipoc-detailview-content-input-line"
        key={`sipoc-detailview-version-${isReleased ? "released" : "current"}`}
      >
        {/* Checkboxen */}
        <div
          className="sipoc-detailview-checkbox-wrapper"
          style={{
            width: `${getCorrectWidthForRoles(prozessBereich) * 30 + 10}px`,
          }}
        >
          {getCorrectRoleLines()}
        </div>

        {/* Text */}
        <div className="sipoc-detailview-input-text-wrapper">
          <div className="sipoc-detailview-input-text-number">{index}</div>
          <div className="sipoc-detailview-input-text-content">
            {getCorrectInput()}
          </div>
          {isDeleteable &&
            !isViewerState &&
            !isReleased &&
            !isExport &&
            !selectedSipocChecklist && (
              <div
                title={t("general.buttons.delete")}
                className="sipoc-detailview-input-text-trash"
                onClick={() => onDelete && onDelete()}
              >
                <Trash />
              </div>
            )}
          {(isViewerState ||
            isReleased ||
            isExport ||
            selectedSipocChecklist) &&
            hasOutput &&
            type !== ProzessTaskType.PROCESS && (
              <div
                className="sipoc-detailview-input-config-indicator"
                onClick={() => setShowOutputConfigInViewMode(true)}
              >
                <HasConfigIcon />
              </div>
            )}
          {displayConfig && !isViewerState && !isReleased && (
            <div
              title={t("general.buttons.edit")}
              className="sipoc-detailview-input-text-edit-todos"
              onClick={() => setShowOutputConfig(true)}
            >
              <ToolsEditIcon />
            </div>
          )}
          {prozessSchritt &&
            !isViewerState &&
            !isReleased &&
            !isExport &&
            !selectedSipocChecklist && (
              <div
                title={t("general.buttons.edit")}
                className="sipoc-detailview-input-text-edit"
                onClick={() => {
                  setShowProzessschrittPopup(true);
                }}
              >
                <EditIcon />
              </div>
            )}
          {isExport && !selectedSipocChecklist && (
            <CheckboxComponent
              label=""
              checked={false}
              onClick={() => console.log("I´m just for the export")}
            />
          )}
          {selectedSipocChecklist && keycloakUser && (
            <CheckboxComponent
              label=""
              checked={checkIfSpecificIdIsTrue(
                selectedSipocChecklist,
                generatedSipocChecklist
              )}
              onClick={() => {
                if (
                  !isUserAllowedToDoThis(
                    keycloakUser!.userRole,
                    ModuleShowType.SIPOC_CHECKLIST,
                    projektManagerId,
                    keycloakUser.serviceId,
                    responsibleUserId
                  )
                )
                  return;
                if (
                  type === ProzessTaskType.OUTPUT ||
                  type === ProzessTaskType.INPUT
                ) {
                  setShowOutputConfigChecklist(true);
                  isInputComplete &&
                    setCompleteInput(isInputComplete(localProzessschritt));
                } else {
                  onClickToUpdate?.(generatedSipocChecklist);
                }
              }}
            />
          )}
        </div>
      </div>

      {showProzessschrittPopup && (
        <PopupComponent
          title={`${prozessSchritt!.role} (${t(
            `modules.prozessMapping.prozessSchritte.${prozessSchritt!.type}`
          )})`}
          closeText={t("general.buttons.close")}
          closeFunction={() => {
            updateProzessschrittInProzessMapping(localProzessschritt, true);
            setShowProzessschrittPopup(false);
          }}
        >
          <ProzessschrittEditMetaTag
            prozessBereich={prozessBereich}
            onDelete={(prozessSchrittId: string) => {
              // TODO auch einbauen
            }}
            prozessSchritt={localProzessschritt}
            onChange={(updatedProzessSchritt: Prozessschritt) => {
              setLocalProzessschritt(updatedProzessSchritt);
            }}
          />
        </PopupComponent>
      )}
      {showOutputConfigChecklist && (
        <SipocOutputConfigurationCheck
          onClickToUpdate={onClickToUpdate!}
          sipocChecklistEvalEntryId={generatedSipocChecklist}
          nextSipocChecklistEvalEntryId={generatedSipocChecklistNextInput}
          prozessSchritt={localProzessschritt}
          detailViewProzessSchritt={detailViewProzessSchritt}
          sipocChecklistItems={selectedSipocChecklist?.checklistItems || []}
          companyId={companyId}
          type={type}
          index={index}
          initialChecked={
            selectedSipocChecklist &&
            checkIfSpecificIdIsTrue?.(
              selectedSipocChecklist,
              generatedSipocChecklist
            )
          }
          popupProps={{
            title: getTitleForOutputConfig(localProzessschritt),
            closeText: t("general.buttons.close"),
            closeFunction: () => {
              updateProzessschrittInProzessMapping(localProzessschritt, true);
              setShowOutputConfigChecklist(false);
              checkCompleteInputAndSendMail(localProzessschritt);
            },
          }}
        />
      )}
      {(showOutputConfig || showOutputConfigInViewMode) && (
        <PopupComponent
          title={getTitleForOutputConfig(localProzessschritt)}
          closeText={t("general.buttons.close")}
          closeFunction={() => {
            if (!showOutputConfigInViewMode)
              updateProzessschrittInProzessMapping(localProzessschritt, true);
            setShowOutputConfigInViewMode(false);
            setShowOutputConfig(false);
          }}
        >
          <SipocOutputConfiguration
            prozessBereichId={prozessBereich.id!}
            localProzessSchritt={localProzessschritt}
            updateLocalProzessschritt={(updatedProzessschritt) => {
              setLocalProzessschritt(updatedProzessschritt);
              updateProzessschrittInProzessMapping(updatedProzessschritt, true);
            }}
            companyId={companyId}
            detailViewProzessSchritt={detailViewProzessSchritt}
            type={type}
            isViewerState={
              isReleased || isViewerState || showOutputConfigInViewMode
            }
          />
        </PopupComponent>
      )}
    </>
  );
};

export default SipocDetailviewInputLine;
