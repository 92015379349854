import React, { useContext } from "react";
import { LicenseType, UserRole } from "../../utils/Enums";
import { Trans, useTranslation } from "react-i18next";
import {
  BarComponent,
  BoxComponent,
  CheckboxComponent,
} from "beelean-component-library";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as BlockedIcon } from "../../assets/icons/blocked.svg";
import { ReactComponent as LicenseIcon } from "../../assets/icons/license.svg";
import { ReactComponent as LeanAdminModulIcon } from "../../assets/icons/leanadmin.svg";
import { ReactComponent as LeanProdIcon } from "../../assets/icons/LeanProd.svg";
import "./IntroStyle.scss";
import { IntroLicenseOverviewProps } from "./Intro.types";
import { CompanyContext } from "../../pages/App";

const IntroLicenseOverview: React.FC<IntroLicenseOverviewProps> = ({
  keycloakUser,
  company,
}) => {
  const { t } = useTranslation();
  const { companyUser } = useContext(CompanyContext);
  /**
   * Helper to get status icon of license date
   *
   * @param licenseDate
   * @returns JSX.Element
   */
  const statusOfLicenseDate = (licenseDate: Date): JSX.Element => {
    return new Date(licenseDate) > new Date() ? (
      <CheckIcon className="license-icon valid" />
    ) : (
      <BlockedIcon className="license-icon invalid" />
    );
  };

  /**
   * gets correct icon for the type of license
   *
   * @returns correct SVG Icon
   */
  const getCorrectLicenseImage = (type: LicenseType): JSX.Element => {
    switch (type) {
      case LicenseType.ADMINISTRATION_USER:
      case LicenseType.ADMINISTRATION_PROJEKTAKTE:
      case LicenseType.ADMINISTRATION:
        return (
          <div className="license-icon-wrapper">
            <LeanAdminModulIcon className="license-image" />
          </div>
        );
      case LicenseType.ALL:
      case LicenseType.PRODUCTION:
        return (
          <div className="license-icon-wrapper">
            <LeanProdIcon className="license-image" />
          </div>
        );
    }
  };

  return (
    <>
      {keycloakUser.userRole === UserRole.COMPANYADMIN && (
        <>
          <BoxComponent
            icon={<LicenseIcon />}
            subtext={
              <p>
                <Trans i18nKey="intro.license.subText">
                  Hier können Sie Ihre Lizenzdaten einsehen
                </Trans>
              </p>
            }
            title={t("license.title")}
          >
            <div className="flex-inline-box-component-wrapper">
              {(company.license.type === LicenseType.ADMINISTRATION_USER ||
                company.license.type === LicenseType.ALL) && (
                <div
                  className={[
                    "intro-element-wrapper",
                    company.license.type === LicenseType.ALL
                      ? "two-active-license"
                      : "one-active-license",
                  ].join(" ")}
                >
                  <BarComponent
                    value={companyUser.length / company.license.licensedUsers}
                    barText={t("license.user", {
                      replace: {
                        amount: `${companyUser.length}/${company.license.licensedUsers}`,
                      },
                    })}
                  />
                  <div className="intro-element-spacer" />
                  <div className="column-align">
                    <p>
                      {new Date(
                        company.license.administrationValidUntil
                      ).toLocaleDateString("de-DE")}
                    </p>
                  </div>
                  <div className="column-align">
                    {statusOfLicenseDate(
                      company.license.administrationValidUntil
                    )}
                  </div>
                </div>
              )}
              {(company.license.type ===
                LicenseType.ADMINISTRATION_PROJEKTAKTE ||
                company.license.type === LicenseType.ALL) && (
                <div
                  className={[
                    "intro-element-wrapper",
                    company.license.type === LicenseType.ALL
                      ? "two-active-license"
                      : "one-active-license",
                  ].join(" ")}
                >
                  <BarComponent
                    value={
                      (company.createdProjektakte +
                        company.createdProzessBereiche) /
                      company.license.licensedProjektakteAndProzessbereiche
                    }
                    barText={t("license.prozessbereicheProjektakte", {
                      replace: {
                        amount: `${
                          company.createdProjektakte +
                          company.createdProzessBereiche
                        }/${
                          company.license.licensedProjektakteAndProzessbereiche
                        }`,
                      },
                    })}
                  />
                  <div className="intro-element-spacer" />
                  <div className="column-align">
                    <p>
                      {new Date(
                        company.license.administrationValidUntil
                      ).toLocaleDateString("de-DE")}
                    </p>
                  </div>
                  <div className="column-align">
                    {statusOfLicenseDate(
                      company.license.administrationValidUntil
                    )}
                  </div>
                </div>
              )}
              {(company.license.type === LicenseType.ADMINISTRATION ||
                company.license.type === LicenseType.ALL) && (
                <div
                  className={[
                    "intro-element-wrapper",
                    company.license.type === LicenseType.ALL
                      ? "two-active-license"
                      : "one-active-license",
                  ].join(" ")}
                >
                  <div className="LeanAdmin-License-Prozess">
                    <CheckboxComponent
                      checked={company.license.activatePdca}
                      label={t("modules.pdca.headline")}
                      onClick={() => {}}
                      disabled
                    />
                    <BarComponent
                      value={
                        company.createdProcesses /
                        company.license.licensedProcessAmount
                      }
                      barText={t("license.prozesse", {
                        replace: {
                          prozessAmount: `${company.createdProcesses}/${company.license.licensedProcessAmount}`,
                        },
                      })}
                    />
                  </div>
                  <div className="intro-element-spacer" />
                  <div className="column-align">
                    <p>
                      {new Date(
                        company.license.administrationValidUntil
                      ).toLocaleDateString("de-DE")}
                    </p>
                  </div>
                  <div className="column-align">
                    {statusOfLicenseDate(
                      company.license.administrationValidUntil
                    )}
                  </div>
                </div>
              )}
              {(company.license.type === LicenseType.PRODUCTION ||
                company.license.type === LicenseType.ALL) && (
                <div
                  className={[
                    "intro-element-wrapper",
                    company.license.type === LicenseType.ALL
                      ? "two-active-license"
                      : "one-active-license",
                  ].join(" ")}
                >
                  <BarComponent
                    value={
                      company.createdStations /
                      company.license.licensedStationAmount
                    }
                    barText={t("license.stations", {
                      replace: {
                        stationAmount: `${company.createdStations}/${company.license.licensedStationAmount}`,
                      },
                    })}
                  />
                  <div className="intro-element-spacer" />
                  <div className="column-align">
                    <p>
                      {new Date(
                        company.license.productionValidUntil
                      ).toLocaleDateString("de-DE")}
                    </p>
                  </div>
                  <div className="column-align">
                    {statusOfLicenseDate(company.license.productionValidUntil)}
                  </div>
                </div>
              )}
              <div className={"intro-element-wrapper column-align"}>
                {getCorrectLicenseImage(company.license.type)}
                <div>{t(`licenseType.${company.license.type}`)}</div>
              </div>
            </div>
          </BoxComponent>
        </>
      )}
    </>
  );
};

export default IntroLicenseOverview;
