import {
  DropdownComponent,
  InputComponent,
  Option,
} from "beelean-component-library";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyContext, TreeDataContext } from "../../../pages/App";
import { ProzessBereich } from "../../../utils/LeanAdmin/LeanAdmin.types";
import {
  createSelectOptionsForProzessBereicheAndProjektAkten,
  createSelectOptionsForRoles,
} from "../../../utils/LeanAdmin/LeanAdminModulUtils";
import { Pdca } from "../../../utils/Pdca/Pdca.types";
import { getSelectOptionsForUser } from "../../../utils/UserUtil";
import { PdcaEditFormProperties } from "./PdcaModule.types";

const PdcaEditForm: React.FC<PdcaEditFormProperties> = ({
  pdcaToEdit,
  updatePdcaToEdit,
  editDisabled = false,
}) => {
  const { leanAdminProzessbereiche, projektAkten } =
    useContext(TreeDataContext);
  const { companyUser } = useContext(CompanyContext);
  const [prozessBereichSelectOptions, setProzessBereichSelectOptions] =
    useState<Option[]>([]);
  const [userSelectOptions, setUserSelectOptions] = useState<Option[]>([]);
  const [roleSelectOptions, setRoleSelectOptions] = useState<Option[]>([]);
  const { t } = useTranslation();
  const [localTempProzessBereichId, setLocalTempProzessBereichId] =
    useState<string>(pdcaToEdit.prozessBereich || "");

  // if it is a new pdca entry set initially things
  useEffect(() => {
    if (leanAdminProzessbereiche.length === 0 || userSelectOptions.length === 0)
      return;
    if (!pdcaToEdit.id) {
      updatePdcaToEdit({
        ...pdcaToEdit,
      });
    } else if (pdcaToEdit.id) {
      let localPdcaEntry: Pdca = pdcaToEdit;
      updatePdcaToEdit({ ...localPdcaEntry });
    }
    // eslint-disable-next-line
  }, [leanAdminProzessbereiche, userSelectOptions]);

  // if the prozessbereich/akteid changes load new roles
  useEffect(() => {
    if (!!pdcaToEdit.prozessBereich) {
      // always set roles when Prozessbereich changed
      const createdRoleOptions: Option[] = createSelectOptionsForRoles(
        findCorrectProzessbereich(pdcaToEdit.prozessBereich)
      );
      setRoleSelectOptions(createdRoleOptions);
      updatePdcaToEdit({
        ...pdcaToEdit,
        responsibleRole:
          localTempProzessBereichId === pdcaToEdit.prozessBereich
            ? pdcaToEdit.responsibleRole
            : "",
      });
      setLocalTempProzessBereichId(pdcaToEdit.prozessBereich);
    }
    // eslint-disable-next-line
  }, [pdcaToEdit.prozessBereich]);

  /**
   * Helper to return the correct Prozessbereich.
   * An original one or direct from a Projektakte.
   *
   * @returns found Prozessbereich or undefined
   */
  const findCorrectProzessbereich = (
    id: string
  ): ProzessBereich | undefined => {
    let foundProzessBereich: ProzessBereich | undefined =
      leanAdminProzessbereiche.find((bereich) => bereich.id === id);
    if (!foundProzessBereich) {
      foundProzessBereich = projektAkten.find(
        (akte) => akte.id === id
      )?.prozessBereich;
    }
    return foundProzessBereich;
  };

  /**
   * This useEffect updated the dropdowns according their selection dependencies
   */
  useEffect(() => {
    if (leanAdminProzessbereiche && companyUser) {
      setProzessBereichSelectOptions(
        createSelectOptionsForProzessBereicheAndProjektAkten(
          leanAdminProzessbereiche,
          projektAkten
        )
      );

      setUserSelectOptions(getSelectOptionsForUser(companyUser));
    }
    //eslint-disable-next-line
  }, [leanAdminProzessbereiche, companyUser]);

  return (
    <div>
      <form>
        <DropdownComponent
          required
          options={prozessBereichSelectOptions}
          onChange={(selectedOption) => {
            updatePdcaToEdit({
              ...pdcaToEdit,
              prozessBereich: selectedOption,
            });
          }}
          placeholder={t("pdca.editForm.prozessBereich")}
          disabled={editDisabled}
          selectedOption={
            prozessBereichSelectOptions.find(
              (bereich) => bereich.value === pdcaToEdit.prozessBereich
            )!
          }
        />
        <InputComponent
          required
          placeholder={t("pdca.editForm.description")}
          onChange={(input: string) =>
            updatePdcaToEdit({ ...pdcaToEdit, description: input })
          }
          value={pdcaToEdit.description!}
          disabled={editDisabled}
        />
        <InputComponent
          required
          placeholder={t("pdca.editForm.actions")}
          onChange={(input: string) =>
            updatePdcaToEdit({ ...pdcaToEdit, actions: input })
          }
          value={pdcaToEdit.actions!}
          disabled={editDisabled}
        />
        <InputComponent
          placeholder={t("pdca.editForm.followUpDate")}
          onChange={(input: Date) =>
            updatePdcaToEdit({ ...pdcaToEdit, followUpDate: input })
          }
          type="date"
          value={
            pdcaToEdit.followUpDate ? new Date(pdcaToEdit.followUpDate) : ""
          }
          disabled={editDisabled}
        />
        <InputComponent
          placeholder={t("pdca.editForm.targetDate")}
          onChange={(input: Date) =>
            updatePdcaToEdit({ ...pdcaToEdit, targetDate: input })
          }
          type="date"
          required
          value={pdcaToEdit.targetDate ? new Date(pdcaToEdit.targetDate) : ""}
          disabled={editDisabled}
        />
        <InputComponent
          required
          placeholder={t("pdca.editForm.status")}
          onChange={(input: number) =>
            updatePdcaToEdit({ ...pdcaToEdit, status: input })
          }
          type="number"
          max={4}
          min={0}
          value={pdcaToEdit.status || 0}
          disabled={editDisabled}
        />
        <DropdownComponent
          required
          options={roleSelectOptions}
          disabled={editDisabled || roleSelectOptions.length === 0}
          onChange={(selectedOption) =>
            updatePdcaToEdit({
              ...pdcaToEdit,
              responsibleRole: selectedOption,
            })
          }
          placeholder={t("pdca.editForm.responsibleRole")}
          selectedOption={
            roleSelectOptions.find(
              (role) => role.value === pdcaToEdit.responsibleRole
            )!
          }
        />
        <DropdownComponent
          required
          options={userSelectOptions}
          onChange={(selectedOption) =>
            updatePdcaToEdit({
              ...pdcaToEdit,
              responsibleUser: selectedOption,
            })
          }
          placeholder={t("pdca.editForm.responsibleUser")}
          selectedOption={
            userSelectOptions.find(
              (user) => user.value === pdcaToEdit.responsibleUser
            )!
          }
          disabled={editDisabled}
        />
        <InputComponent
          placeholder={t("pdca.editForm.prio")}
          onChange={(input: number) =>
            updatePdcaToEdit({ ...pdcaToEdit, prio: input })
          }
          type="number"
          min={0}
          value={pdcaToEdit.prio || 0}
          disabled={editDisabled}
        />
      </form>
    </div>
  );
};

export default PdcaEditForm;
