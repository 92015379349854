import i18n from "../i18n";
import { groupBy } from "./GeneralUtil";
import {
  ProcessTimeUnit,
  ProzessBereichRole,
  Prozessschritt,
  Prozessschritttype,
} from "./LeanAdmin/LeanAdmin.types";

/**
 * Format time to a readable format
 * @param seconds The time in seconds
 * @returns      The formatted time
 */
export const convertSecondsToFormattedTime = (seconds: number): string => {
  if (seconds === 0) {
    return "0";
  }

  const days = Math.floor(seconds / (60 * 60 * 8));
  const hours = Math.floor((seconds % (60 * 60 * 8)) / (60 * 60));
  const minutes = Math.floor((seconds % (60 * 60)) / 60);
  const remainingSeconds = seconds % 60;

  let formattedTime = "";
  if (days > 0) {
    formattedTime += ` ${days}AT`;
  }
  if (hours > 0) {
    formattedTime += ` ${hours}h`;
  }
  if (minutes > 0) {
    formattedTime += ` ${minutes}m`;
  }
  if (remainingSeconds > 0) {
    formattedTime += ` ${remainingSeconds}s`;
  }

  return formattedTime.trim();
};
/**
 * Util to get the total time of all prozessschritte
 * @param prozessSchritte  Array of prozessschritte
 * @returns               Total time of all prozessschritte
 */
export const getTotalTimeOfProzessSchritte = <T extends boolean>(
  prozessSchritte: Prozessschritt[],
  returnSeconds: T = false as T
): T extends true ? number : JSX.Element => {
  const relevantSchritte: Prozessschritt[] = prozessSchritte.filter(
    (schritt) =>
      schritt.type === Prozessschritttype.PROCESS ||
      schritt.type === Prozessschritttype.PROCESSSIMPLE
  );
  const noZeroSchritte: Prozessschritt[] = relevantSchritte.filter(
    (schritt) => schritt.prozessZeit.value !== 0
  );
  const groupedProzessSchritte: Map<number, ProcessTimeUnit[]> = groupBy(
    noZeroSchritte.flatMap((schritt) => schritt.prozessZeit),
    "unit"
  );
  const totalTime: number = noZeroSchritte.reduce((totalTime, schritt) => {
    return totalTime + schritt.prozessZeit.value;
  }, 0);

  if (returnSeconds) {
    return Array.from(groupedProzessSchritte.keys())
      .reverse()
      .reduce((totalTime, key) => {
        const time = groupedProzessSchritte
          .get(key)
          ?.reduce((total, schritt) => {
            return total + schritt.value;
          }, 0);
        return time ? totalTime + time : totalTime;
      }, 0) as T extends true ? number : never;
  } else {
    return (
      <p
        style={{
          color:
            noZeroSchritte.length !== relevantSchritte.length ? "red" : "black",
        }}
      >
        {convertSecondsToFormattedTime(totalTime)}
      </p>
    ) as T extends true ? never : JSX.Element;
  }
};

/**
 * Extracts the role name by its id
 * @param id - role id
 * @param roles  - Array of roles
 * @returns  if id not present return id
 * Also important for version 1 roles since its not an id but their name
 */
export const getRoleNameByRoleId = (
  id: string,
  roles: ProzessBereichRole[]
): string => {
  switch (id) {
    case process.env.REACT_APP_FIRST_ROW_ID:
      return i18n.t("modules.prozessMapping.roles.customer");
    case process.env.REACT_APP_EXTERNAL_ROW:
      return i18n.t("modules.SIPOC.detail.extern");
    default:
      return roles.find((role) => role.id === id)?.name ?? id;
  }
};
