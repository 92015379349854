import {
  BoxComponent,
  ButtonComponent,
  CheckboxComponent,
  LayoutComponent,
  LoaderComponent,
  PopupComponent,
  TableComponent,
  TableRow,
  TreeConfig,
} from "beelean-component-library";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { LicenseModuleType, ModuleShowType, UserRole } from "../utils/Enums";
import { Company, KeycloakUser, User } from "../utils/Interfaces";
import { ProzessBereich } from "../utils/LeanAdmin/LeanAdmin.types";
import { isModuleValidInLicense } from "../utils/LicenseUtil";
import { ReactComponent as PdcaIcon } from "../assets/icons/pdca.svg";
import {
  getCorrectLanguageImage,
  toggleLanguage,
  useLayoutConfiguration,
} from "../utils/NavigationUtil";
import "../styles/PdcaPageStyle.scss";
import {
  CompanyContext,
  GlobaleApplicationSettings,
  KeycloakUserContext,
  TreeDataContext,
} from "./App";
import { createNewMinimalPdcaEntry, Pdca } from "../utils/Pdca/Pdca.types";
import { useAxios } from "../utils/AxiosUtil";
import {
  createPdcaEntry,
  createPdcaTableEntriesFromPdcas,
  loadPdcasForCompany,
  updatePdcaEntry,
  deletePdcaEntry,
  createQuarterCircle,
} from "../utils/Pdca/PdcaUtil";
import PdcaEditForm from "../components/modules/pdca/PdcaEditForm";
import { generatePdcaExportTable } from "../utils/PdfUtil";
import { isUserAllowedToDoThis } from "../utils/UserUtil";
import { fetchCompany } from "../utils/CompanyUtil";
import { ProjektAkte } from "../utils/LeanAdmin/ProjektAkte.types";

interface PdcaPageProps {
  keycloakUser: KeycloakUser;
  userCompany: Company;
  companyUser: User[];
  leanAdminProzessbereiche: ProzessBereich[];
  projektAkten: ProjektAkte[];
  leanAdminTreeData?: TreeConfig;
  pdcaTreeData?: TreeConfig;
  leanProdTreeData?: TreeConfig;
  projektAkteTreeData?: TreeConfig;
  isViewerState: boolean;
  toggleViewerState(viewState: boolean): void;
  isDesktopMenuOpen: boolean;
  toggleIsDesktopMenuOpen(desktopOpenState: boolean): void;
}

const Page: React.FC<PdcaPageProps> = ({
  keycloakUser,
  userCompany,
  companyUser,
  leanAdminTreeData,
  pdcaTreeData,
  leanProdTreeData,
  leanAdminProzessbereiche,
  projektAkten,
  isViewerState,
  toggleViewerState,
  isDesktopMenuOpen,
  toggleIsDesktopMenuOpen,
  projektAkteTreeData,
}) => {
  const history = useHistory();
  const {
    text,
    isAdmin,
    username,
    homeFunction,
    logoutFunction,
    isPlatformAdmin,
    profileFunction,
    accountListFunction,
    showToggleViewEdit,
    isTest,
    lng,
  } = useLayoutConfiguration(keycloakUser);
  const { t } = useTranslation();

  // Location for expanding or collapse tree
  const [openNodes, setOpenNodes] = useState<string[]>([]);
  const axios = useAxios();
  const [lastKey, setLastKey] = useState<string>("");
  const [loadedPdcas, setLoadedPdcas] = useState<Pdca[]>([]);
  const [pdcaToEdit, setPdcaToEdit] = useState<Pdca>();
  const [newPdcaToCreate, setNewPdcaToCreate] = useState<Pdca>();
  const [pdcaTableEntries, setPdcaTableEntries] = useState<TableRow[]>([]);
  const [localCompanyId, setLocalCompanyId] = useState<string>("");
  const [version, setVersion] = useState<number>(0);
  const location = useLocation<{
    openNodes: string[];
    lastKey: string;
    companyId: string;
  }>();
  const [isExportGenerating, setIsExportGenerating] = useState<boolean>(false);
  const [filterTargetDate, setFilterTargetDate] = useState<boolean>(false);
  const [filterFollowUpDate, setFilterFollowUpDate] = useState<boolean>(false);
  const [filterFinished, setFilterFinished] = useState<boolean>(false);

  /**
   * This useEffect checks the license and redirects to not valid page
   */
  useEffect(() => {
    if (keycloakUser.userRole === UserRole.PLATFORMADMIN) return;
    if (keycloakUser && !userCompany.id && axios) {
      fetchCompany(axios, keycloakUser.companyId).then((loadedCompany) => {
        if (
          isModuleValidInLicense(LicenseModuleType.PDCA, loadedCompany.license)
        ) {
          setLocalCompanyId(keycloakUser.companyId);
        }
      });
    } else if (keycloakUser && userCompany.id && !!axios) {
      if (isModuleValidInLicense(LicenseModuleType.PDCA, userCompany.license)) {
        return;
      }
      history.push("/notvalid", {
        licenseMissingFor: t("modules.pdca.headline"),
      });
    }
    // eslint-disable-next-line
  }, [keycloakUser, userCompany, axios]);

  /**
   * This useEffect handles the nav tree behaviour
   */
  useEffect(() => {
    if (location?.state !== undefined) {
      setOpenNodes(location.state.openNodes);
      setLastKey(location.state.lastKey);
      setLocalCompanyId(location.state.companyId);
      setVersion(version + 1);
    } else if (keycloakUser.userRole !== UserRole.PLATFORMADMIN) {
      setLocalCompanyId(keycloakUser.companyId);
    } else {
      history.push(`/`);
    }
    // eslint-disable-next-line
  }, [location]);

  /**
   * This useEffect updates the table
   * when the loaded pdcas changes
   */
  useEffect(() => {
    if (loadedPdcas) {
      let localPdcas: Pdca[] = loadedPdcas;

      // filter status 4
      if (filterFinished)
        localPdcas = localPdcas.filter(
          (currentPdca) => currentPdca.status !== 4
        );
      if (filterFollowUpDate)
        localPdcas = localPdcas.filter(
          (currentPdca) => new Date(currentPdca.followUpDate!) < new Date()
        );
      if (filterTargetDate)
        localPdcas = localPdcas.filter(
          (currentPdca) => new Date(currentPdca.targetDate!) < new Date()
        );

      setPdcaTableEntries(
        createPdcaTableEntriesFromPdcas(
          localPdcas,
          projektAkten,
          leanAdminProzessbereiche,
          companyUser,
          (row: TableRow) => onTableRowClick(row, localPdcas)
        )
      );
      setVersion(version + 1);
    }
    // eslint-disable-next-line
  }, [
    loadedPdcas,
    filterFinished,
    filterFollowUpDate,
    filterTargetDate,
    projektAkten,
  ]);

  /**
   * This useEffect loads the company's pdca entries and
   * transforms them into table entries
   */
  useEffect(() => {
    if (!!axios && localCompanyId) {
      loadPdcasForCompany(axios, localCompanyId).then((pdcas) => {
        setLoadedPdcas(pdcas);
      });
    }
    // eslint-disable-next-line
  }, [axios, localCompanyId]);

  /**
   * Helper method to update the local PDCA state with the
   * updated or created PDCA data
   *
   * @param createdPdca Optional the new created PDCA
   * @returns void
   */
  const updatePdcaLocally = (createdPdca?: Pdca): void => {
    let stateCopy: Pdca[] = loadedPdcas;
    if (createdPdca) {
      stateCopy.push(createdPdca);
    } else {
      const updateIndex: number = stateCopy.findIndex(
        (pdca) => pdca.id === pdcaToEdit?.id
      )!;
      if (updateIndex < 0) return;
      stateCopy[updateIndex] = pdcaToEdit!;
    }
    setLoadedPdcas([...stateCopy]);
  };

  /**
   * Helper method to update the local PDCA data after deletion of
   * of a PDCA
   *
   * @returns void
   */
  const deletePdcaLocally = (): void => {
    let stateCopy: Pdca[] = loadedPdcas;
    const deleteIndex: number = stateCopy.findIndex(
      (pdca) => pdca.id === pdcaToEdit!.id
    )!;
    if (deleteIndex < 0) return;
    stateCopy.splice(deleteIndex, 1);
    setLoadedPdcas([...stateCopy]);
  };

  /**
   * Helper method to create the onclick method for PDCA table entries
   *
   * @param clickedRow The clicked row instance
   * @param pdcas All PDCAs because they are given into the table
   * @returns void
   */
  const onTableRowClick = (clickedRow: TableRow, pdcas: Pdca[]): void =>
    setPdcaToEdit(pdcas.find((pdca) => pdca.id === clickedRow.id));

  /**
   * Helper method to create a legend for the PDCA table
   *
   * @returns legend for PDCA table as JSX Element
   */
  const generateLegendForPDCA = (): JSX.Element => {
    const legend: string[] = t("pdca.legend", { returnObjects: true });
    return (
      <div className="legend">
        {[0, 1, 2, 3, 4].map((index) => (
          <div className="legend-entry-wrapper" key={`legend-icon-${index}`}>
            <div className="legend-icon">{createQuarterCircle(index)}</div>
            <div>{legend[index]}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <LayoutComponent
      noDataInTree={t("tree.noData")}
      adminProjektAkteTreeData={projektAkteTreeData}
      isTest={isTest}
      onExportFunction={() => {
        setIsExportGenerating(true);
        generatePdcaExportTable(
          pdcaTableEntries,
          userCompany.name,
          userCompany.logoName,
          axios
        ).then(() => setIsExportGenerating(false));
      }}
      showToggleViewEdit={showToggleViewEdit}
      isDesktopMenuOpen={isDesktopMenuOpen}
      onToogleDesktopMenuOpen={() =>
        toggleIsDesktopMenuOpen(!isDesktopMenuOpen)
      }
      viewEditStatus={isViewerState}
      onToggleViewEditFunction={() => toggleViewerState(!isViewerState)}
      showLeanAdminTree={
        isPlatformAdmin ||
        isModuleValidInLicense(
          LicenseModuleType.ADMINISTRATION,
          userCompany.license
        )
      }
      showLeanProdTree={
        isPlatformAdmin ||
        isModuleValidInLicense(
          LicenseModuleType.PRODUCTION,
          userCompany.license
        )
      }
      text={text}
      title={t("modules.pdca.headline")}
      leanProdTreeData={leanProdTreeData}
      leanAdminTreeData={{
        ...leanAdminTreeData!,
        openNodes: openNodes,
        lastSelectedKey: lastKey,
      }}
      adminPdcaTreeData={{
        ...pdcaTreeData!,
        openNodes: openNodes,
        lastSelectedKey: lastKey,
      }}
      username={username}
      homeFunction={() => homeFunction()}
      logoutFunction={() => logoutFunction()}
      onUserAdminFunction={() => accountListFunction()}
      onCreateLineFunction={() => console.log("Cretae Line!")}
      isAdmin={isAdmin}
      profileFunction={() => profileFunction()}
      key={localCompanyId}
      version={t("general.version")}
      languageImage={getCorrectLanguageImage(lng)}
      onChangeLanguage={toggleLanguage}
    >
      {pdcaToEdit && (
        <PopupComponent
          closeFunction={() => {
            if (
              isViewerState ||
              !isUserAllowedToDoThis(
                keycloakUser.userRole,
                ModuleShowType.PDCA_CREATE
              )
            ) {
              setPdcaToEdit(undefined);
            } else {
              updatePdcaEntry(pdcaToEdit, axios).then(() => {
                updatePdcaLocally();
                setPdcaToEdit(undefined);
              });
            }
          }}
          closeText={t("general.buttons.close")}
          title={t(`pdca.${isViewerState ? "view" : "edit"}`)}
        >
          <PdcaEditForm
            pdcaToEdit={pdcaToEdit}
            updatePdcaToEdit={setPdcaToEdit}
            editDisabled={isViewerState}
          />
          {isViewerState ||
            (isUserAllowedToDoThis(
              keycloakUser.userRole,
              ModuleShowType.PDCA_CREATE
            ) && (
              <ButtonComponent
                title={t("general.buttons.delete")}
                background="#ee4f4b"
                onClick={() =>
                  deletePdcaEntry(pdcaToEdit, axios).then(() => {
                    deletePdcaLocally();
                    setPdcaToEdit(undefined);
                  })
                }
              />
            ))}
        </PopupComponent>
      )}
      {newPdcaToCreate && (
        <PopupComponent
          closeFunction={() => {
            if (newPdcaToCreate.description) {
              createPdcaEntry(newPdcaToCreate, axios).then((createdPdca) => {
                updatePdcaLocally(createdPdca);
                setNewPdcaToCreate(undefined);
              });
            } else {
              setNewPdcaToCreate(undefined);
            }
          }}
          closeText={t("general.buttons.close")}
          title={t(`pdca.newTitle`)}
        >
          <PdcaEditForm
            pdcaToEdit={newPdcaToCreate}
            updatePdcaToEdit={setNewPdcaToCreate}
          />
        </PopupComponent>
      )}

      <BoxComponent icon={<PdcaIcon className="box-special-icon" />}>
        {isViewerState || (
          <div id="new-pdca-button-wrapper">
            <ButtonComponent
              title={t("pdca.newButton")}
              type="button"
              onClick={() =>
                setNewPdcaToCreate(
                  createNewMinimalPdcaEntry(
                    `${keycloakUser.firstname} ${keycloakUser.lastname}`,
                    keycloakUser.companyId
                  )
                )
              }
            />
          </div>
        )}
        <p className="quick-filter-settings-headline">
          {t("modules.pdca.filter")}
        </p>
        <div className="quick-filter-settings">
          <CheckboxComponent
            checked={filterTargetDate}
            onClick={() => setFilterTargetDate(!filterTargetDate)}
            label={t("modules.pdca.targetDateExceeded")}
          />
          <CheckboxComponent
            checked={filterFollowUpDate}
            onClick={() => setFilterFollowUpDate(!filterFollowUpDate)}
            label={t("modules.pdca.FollowUpDateExceeded")}
          />
          <CheckboxComponent
            checked={filterFinished}
            onClick={() => setFilterFinished(!filterFinished)}
            label={t("modules.pdca.hideFinishedTask")}
          />
        </div>
        <TableComponent
          filter
          headers={t("pdca.tableHeader", { returnObjects: true })}
          rows={pdcaTableEntries}
          key={`pdca-table-entries-${version}`}
        />
        {generateLegendForPDCA()}
      </BoxComponent>

      {isExportGenerating && (
        <LoaderComponent inFront showText text={t("general.buttons.export")} />
      )}
    </LayoutComponent>
  );
};

const PdcaPage = () => {
  // needed context
  const { keycloakUser } = useContext(KeycloakUserContext);
  const { userCompany, companyUser } = useContext(CompanyContext);
  const {
    leanAdminTreeData,
    leanProdTreeData,
    pdcaTreeData,
    leanAdminProzessbereiche,
    projektAkten,
    projektAkteTreeData,
  } = useContext(TreeDataContext);
  const {
    isViewerState,
    toggleViewerState,
    isDesktopMenuOpen,
    toggleIsDesktopMenuOpen,
  } = useContext(GlobaleApplicationSettings);
  const { t } = useTranslation();

  return keycloakUser ? (
    <Suspense
      fallback={
        <LoaderComponent inFront showText text={t("general.loading")} />
      }
    >
      <Page
        isDesktopMenuOpen={isDesktopMenuOpen}
        toggleIsDesktopMenuOpen={toggleIsDesktopMenuOpen}
        isViewerState={isViewerState}
        toggleViewerState={toggleViewerState}
        keycloakUser={keycloakUser}
        userCompany={userCompany}
        companyUser={companyUser}
        leanAdminProzessbereiche={leanAdminProzessbereiche}
        projektAkten={projektAkten}
        leanAdminTreeData={leanAdminTreeData}
        pdcaTreeData={pdcaTreeData}
        leanProdTreeData={leanProdTreeData}
        projektAkteTreeData={projektAkteTreeData}
      />
    </Suspense>
  ) : (
    <LoaderComponent inFront showText text={t("general.loading")} />
  );
};

export default PdcaPage;
