import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  GanttDiagrammComponent,
  LoaderComponent,
  Option,
  TableComponent,
  TableRow,
  TextAreaComponent,
} from "beelean-component-library";
import { GanttDiagrammComponentProps } from "beelean-component-library/build/GanttDiagrammComponent/GanttDiagrammComponent.types";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/dumpster.svg";
import {
  GlobaleApplicationSettings,
  KeycloakUserContext,
  TreeDataContext,
} from "../../../pages/App";
import { useAxios } from "../../../utils/AxiosUtil";
import { fetchCompany } from "../../../utils/CompanyUtil";
import { UserRole } from "../../../utils/Enums";
import { prepareGantDiagramData } from "../../../utils/GantDiagramUtil";
import { groupBy } from "../../../utils/GeneralUtil";
import { Company, User, createEmptyCompany } from "../../../utils/Interfaces";
import {
  HistoryEntry,
  ProzessBereich,
  ProzessMappingEntry,
  ProzessOverview,
  Prozessschritt,
  Prozessschritttype,
  createEmptyProzess,
  createEmptyProzessMappingEntry,
  createEmptyProzessOverview,
} from "../../../utils/LeanAdmin/LeanAdmin.types";
import {
  getProzessBereichById,
  getProzessOverviewFulfillment,
  updateProzessBereich,
} from "../../../utils/LeanAdmin/LeanAdminModulUtils";
import { fetchNeededProzessMappingEntry } from "../../../utils/LeanAdmin/ProzessMappingUtil";
import {
  createNewProzessOverview,
  getProzessOverviewByID,
  updateProzessOverview,
} from "../../../utils/LeanAdmin/ProzessOverview.axios";
import {
  deleteOutputFile,
  download,
  getFileNameWithoutId,
} from "../../../utils/LeanAdmin/SipocOutputConfigurationUtils";
import { generateNotification } from "../../../utils/NotificationUtil";
import { generateOverviewExport } from "../../../utils/PdfUtil";
import { getTotalTimeOfProzessSchritte } from "../../../utils/ProzessEntryMappingUtil";
import { fetchAllSimpleUser, getFullName } from "../../../utils/UserUtil";
import Prozessmapping from "../prozessmapping/Prozessmapping";
import SipocEval from "../sipoc/SipocEval";
import "./ProzessOverview.styles.scss";

const ProzessOverviewComponent: React.FC<{
  companyId: string;
  prozessBereichId: string;
  setExportFunction(exportFunction: Function): void;
}> = ({ companyId, prozessBereichId, setExportFunction }) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [companyUsers, setCompanyUsers] = useState<User[]>([]);
  const [prozessBereich, setProzessBereich] = useState<ProzessBereich>();
  const [overview, setOverview] = useState<ProzessOverview>(
    createEmptyProzessOverview()
  );
  const { isViewerState } = useContext(GlobaleApplicationSettings);
  const [groupedProzessSchritte, setGroupedProzessSchritte] =
    useState<Map<string, Prozessschritt[]>>();
  const [summedTotalTime, setSummedTotalTime] = useState<number>(0);
  const [totalTimeByRole, setTotalTimeByRole] = useState<Map<string, number>>();
  const [loadedProzessMappingEntry, setLoadedProzessMappingEntry] =
    useState<ProzessMappingEntry>(
      createEmptyProzessMappingEntry(companyId, prozessBereichId)
    );

  const [outputFileList, setOutputFileList] = useState<string[]>([]);
  const [gantDiagram, setGantDiagram] = useState<GanttDiagrammComponentProps>();
  const [fetchedCompany, setFetchedCompany] = useState<Company>(
    createEmptyCompany()
  );

  const [firstInputPair, setFirstInputPair] = useState<string>("");
  const [lastOutputPair, setLastOutputPair] = useState<string>("");
  const [isExportGenerating, setIsExportGenerating] = useState<boolean>(false);
  const { keycloakUser } = useContext(KeycloakUserContext);
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [fulfilledState, setFulfilledState] = useState<number>(0);
  const { leanAdminProzessbereiche, setLeanAdminProzessbereiche } =
    useContext(TreeDataContext);
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout>();
  const [save, toggleSave] = useState<boolean>(false);
  const [prozessBereichSelectOptions, setProzessBereichSelectOptions] =
    useState<Option[]>([]);

  //saves prozessbereich after change, save state is manages by timeout
  useEffect(() => {
    if (!save) return;
    if (prozessBereich && keycloakUser?.serviceId)
      updateProzessBereich(prozessBereich, keycloakUser?.serviceId, axios).then(
        (bereich) => {
          if (bereich.history.length > prozessBereich.history.length)
            setProzessBereich(bereich);
          toggleSave(false);
        }
      );

    // eslint-disable-next-line
  }, [save]);

  /**
   * This useEffect loads all needed data from the server
   */
  useEffect(() => {
    if (
      !!axios &&
      !!companyId &&
      !!prozessBereichId &&
      keycloakUser?.serviceId
    ) {
      toggleLoading(true);
      Promise.all([
        fetchAllSimpleUser(axios, companyId),
        getProzessBereichById(prozessBereichId, axios),
        fetchNeededProzessMappingEntry(
          companyId,
          prozessBereichId,
          axios,
          [],
          () => {},
          keycloakUser.serviceId
        ),
        fetchCompany(axios, companyId),
      ]).then(([fetchedUsers, fetchedProzessBereich, entry, company]) => {
        setCompanyUsers(fetchedUsers);
        if (fetchedProzessBereich.version! >= 2)
          setProzessBereich({
            ...fetchedProzessBereich,
            bereichRoles: [...fetchedProzessBereich.bereichRoles],
          });
        else setProzessBereich(fetchedProzessBereich);
        if (!!fetchedProzessBereich.prozessOverviewId) {
          getProzessOverviewByID(
            axios,
            fetchedProzessBereich.prozessOverviewId
          ).then((loadedOverview) => {
            setOverview(loadedOverview);
            toggleLoading(false);
          });
        } else {
          createNewProzessOverview(axios, overview).then((newOverview) => {
            updateProzessBereich(
              {
                ...fetchedProzessBereich,
                prozessOverviewId: newOverview.id,
              },
              keycloakUser.serviceId,
              axios
            ).then((bereich) => {
              setProzessBereich(bereich);
              setOverview(newOverview);
              toggleLoading(false);
            });
          });
        }

        setLoadedProzessMappingEntry(entry);
        setFetchedCompany(company);
        if (entry.prozessLinie.length > 0 && entry.prozessSchritte.length > 0) {
          const firstProzessSchritt = entry.prozessSchritte
            .filter(
              (schritt) => schritt.role === process.env.REACT_APP_FIRST_ROW_ID
            )
            .reduce((smallest, current) => {
              if (smallest.position > current.position) return current;
              return smallest;
            }, createEmptyProzess("", Prozessschritttype.PROCESS, Infinity));
          const firstInputLine = entry.prozessLinie.find(
            (line) => line.startProcessId === firstProzessSchritt.id
          );
          const firstInputStopProcess = entry.prozessSchritte.find(
            (schritt) => schritt.id === firstInputLine?.stopProcessId
          );
          if (firstInputStopProcess)
            setFirstInputPair(
              firstProzessSchritt.output.definition.find((entry) =>
                entry.id.includes(firstInputStopProcess.id)
              )?.content || ""
            );
          const lastProzessSchritt = entry.prozessSchritte
            .filter(
              (schritt) => schritt.role === process.env.REACT_APP_FIRST_ROW_ID
            )
            .reduce((biggest, current) => {
              if (biggest.position < current.position) return current;
              return biggest;
            }, createEmptyProzess("", Prozessschritttype.PROCESS, -1));
          const lastOutputLine = entry.prozessLinie.find(
            (line) => line.stopProcessId === lastProzessSchritt.id
          );
          const lastOutputStartProcess = entry.prozessSchritte.find(
            (schritt) => schritt.id === lastOutputLine?.startProcessId
          );
          if (lastOutputStartProcess)
            setLastOutputPair(
              lastOutputStartProcess.output.definition.find((entry) =>
                entry.id.includes(lastProzessSchritt.id)
              )?.content || ""
            );
        }
      });
    }
    // eslint-disable-next-line
  }, [axios, keycloakUser?.serviceId, companyId, prozessBereichId]);

  /**
   * sets the current export function
   */
  useEffect(() => {
    if (!fetchedCompany.id) return;
    setExportFunction(() => () => {
      setIsExportGenerating(true);
      // setIsExportGenerating(true);
      generateOverviewExport(
        0,
        "0",
        fetchedCompany.name,
        fetchedCompany.logoName,
        axios,
        ""
      ).then(() => setIsExportGenerating(false));
    });
    // eslint-disable-next-line
  }, [fetchedCompany]);

  useEffect(() => {
    if (!loadedProzessMappingEntry.id) return;
    const outputFileList = loadedProzessMappingEntry.prozessSchritte.flatMap(
      (prozessschritt) => prozessschritt.outputFileList
    );
    setOutputFileList(outputFileList);
  }, [loadedProzessMappingEntry]);

  /**
   * As soon a prozess mapping entry is loaded, prepare diagram props
   */
  useEffect(() => {
    if (!loadedProzessMappingEntry.id) return;
    setGantDiagram(
      prepareGantDiagramData(
        loadedProzessMappingEntry,
        prozessBereich?.bereichRoles || []
      )
    );
  }, [loadedProzessMappingEntry, prozessBereich?.bereichRoles]);

  useEffect(() => {
    if (
      !overview.id ||
      !keycloakUser?.serviceId ||
      !axios ||
      isLoading ||
      isViewerState ||
      !prozessBereichId
    )
      return;
    updateProzessOverview(axios, overview);

    // eslint-disable-next-line
  }, [
    overview,
    keycloakUser?.serviceId,
    axios,
    isViewerState,
    prozessBereichId,
  ]);

  /**
   * Helper to edit the prozess overview
   * @param partialProzessOverview  Partial prozess overview
   */
  const editPartialProzessOverview = (
    partialProzessOverview: Partial<ProzessOverview>
  ): void => {
    setOverview({
      ...overview,
      ...partialProzessOverview,
    });
  };

  /**
   * Prepares the dropdown options for the scope user
   */
  const scopeUserOptions: Option[] = useMemo(
    () =>
      companyUsers.map((user) => ({
        label: `${user.firstname} ${user.lastname}`,
        value: user.id || "",
      })),
    [companyUsers]
  );
  /**
   * Prepares the dropdown options for the process roles
   */
  const processRoles: string[] = useMemo(
    () => [
      ...(prozessBereich?.bereichRoles.map((role) => role.name) || []),
      ...(prozessBereich?.roles.map((role) => role) || []),
    ],
    [prozessBereich]
  );

  /**
   * This useeffect groups the prozessschritte by role and calculates time of prozesscritte for each role
   */
  useEffect(() => {
    if (!loadedProzessMappingEntry) return;
    const groupedBereiche = groupBy(
      loadedProzessMappingEntry.prozessSchritte.filter(
        (schritt) =>
          schritt.type === Prozessschritttype.PROCESS ||
          schritt.type === Prozessschritttype.PROCESSSIMPLE
      ),
      "role"
    );
    setGroupedProzessSchritte(groupedBereiche);
    const totalTimeByRole = new Map<string, number>();
    groupedBereiche.forEach((prozessSchritte, role) => {
      totalTimeByRole.set(
        role,
        getTotalTimeOfProzessSchritte(prozessSchritte, true)
      );
    });
    setTotalTimeByRole(totalTimeByRole);
    setSummedTotalTime(
      getTotalTimeOfProzessSchritte(
        loadedProzessMappingEntry.prozessSchritte.filter(
          (schritt) =>
            schritt.type === Prozessschritttype.PROCESS ||
            schritt.type === Prozessschritttype.PROCESSSIMPLE
        ),
        true
      )
    );
  }, [loadedProzessMappingEntry, prozessBereich]);

  /**
   * Prepares the table rows for the analytics table
   */
  const analyticsRows: TableRow[] = useMemo((): TableRow[] => {
    const relevantSchritte: Prozessschritt[] =
      loadedProzessMappingEntry.prozessSchritte.filter(
        (schritt) =>
          schritt.type === Prozessschritttype.PROCESS ||
          schritt.type === Prozessschritttype.PROCESSSIMPLE
      );
    const hasMissingTimes: boolean = relevantSchritte.some(
      (schritt) => schritt.prozessZeit.value === 0
    );
    return [
      {
        id: "Total",
        content: [
          "Total",
          loadedProzessMappingEntry.prozessSchritte.filter((schritt) =>
            [
              Prozessschritttype.PROCESS,
              Prozessschritttype.PROCESSSIMPLE,
            ].includes(schritt.type)
          ).length,
          getTotalTimeOfProzessSchritte(
            loadedProzessMappingEntry.prozessSchritte
          ),
          <p style={hasMissingTimes ? { color: "red" } : undefined}>100%</p>,
        ],
      },
      ...(prozessBereich?.roles.map((role) => ({
        id: role,
        content: [
          role,

          groupedProzessSchritte?.get(role)?.length || 0,
          getTotalTimeOfProzessSchritte(
            groupedProzessSchritte?.get(role) || [],
            false
          ),
          <p
            style={hasMissingTimes ? { color: "red" } : undefined}
          >{`${Math.round(
            ((totalTimeByRole?.get(role) || 0) / summedTotalTime) * 100
          )}%`}</p>,
        ],
      })) || []),
      ...(prozessBereich?.bereichRoles.map((role) => ({
        id: role.id,
        content: [
          role.name,
          groupedProzessSchritte?.get(role.id)?.length || 0,
          getTotalTimeOfProzessSchritte(
            groupedProzessSchritte?.get(role.id) || [],
            false
          ),
          <p
            style={hasMissingTimes ? { color: "red" } : undefined}
          >{`${Math.round(
            ((totalTimeByRole?.get(role.id) || 0) / summedTotalTime) * 100
          )}%`}</p>,
        ],
      })) || []),
    ];
  }, [
    prozessBereich,
    loadedProzessMappingEntry,
    groupedProzessSchritte,
    summedTotalTime,
    totalTimeByRole,
  ]);

  const changeHistoryRows: TableRow[] = useMemo(() => {
    /**
     * Helper to start save timeout
     */
    const startSaveTimeout = () => {
      if (saveTimeout) clearTimeout(saveTimeout);
      setSaveTimeout(
        setTimeout(() => {
          toggleSave(true);
        }, 300)
      );
    };
    /**
     * Helper to handle release of Prozessbereich
     * @param checked current checked state
     * @param prozessBereich current bereich
     * @param historyEntry current entry to release
     */
    const handleRelease = (
      checked: boolean,
      prozessBereich: ProzessBereich,
      historyEntry: HistoryEntry
    ): void => {
      if (checked && fulfilledState !== 5) {
        generateNotification(
          t("notifications.overview.errorOverviewIncomplete"),
          t("notifications.overview.errorOverviewIncompleteText"),
          "warning",
          3000
        );
        return;
      }

      const updatedBereich = {
        ...prozessBereich,
        history: prozessBereich.history.map((history) =>
          history.version === historyEntry.version
            ? { ...history, released: !history.released }
            : { ...history, released: false }
        ),
      };

      setProzessBereich(updatedBereich);
      if (saveTimeout) clearTimeout(saveTimeout);
      updateProzessBereich(updatedBereich, keycloakUser!.serviceId, axios).then(
        (bereich) => {
          if (bereich.history.length > updatedBereich.history.length)
            setProzessBereich(bereich);
          let deleteAllIrrelevantFiles: Promise<boolean>[] = [];
          //when more than two versions exist we need to remove obsolete files
          if (bereich.history.length > 2) {
            //getting latest release before releasing
            const pastMapping: ProzessMappingEntry =
              updatedBereich?.history.sort((entryA, entryB) => {
                if (!entryA.released) return -updatedBereich?.history.length;
                if (!entryB.released) return updatedBereich?.history.length;
                return entryA.version - entryB.version;
              })[0].prozessMappingSnapshot;
            if (!pastMapping) return;
            pastMapping.prozessSchritte.forEach((schritt) =>
              schritt.outputFileList.forEach((file) =>
                deleteAllIrrelevantFiles.push(
                  deleteOutputFile(file, companyId, axios, bereich.id!)
                )
              )
            );
          }
        }
      );
    };

    let latestReleasedHistoryDate: Date | undefined;
    let historyRows: TableRow[] = [];
    prozessBereich?.history.forEach((historyEntry, index) => {
      if (
        prozessBereich.history.length >= 2 &&
        index === prozessBereich.history.length - 2
      ) {
        latestReleasedHistoryDate = historyEntry.lastUpdated;
      } else if (
        latestReleasedHistoryDate?.getTime() ===
        historyEntry.lastUpdated.getTime()
      )
        return;
      historyRows.push({
        id: historyEntry.version.toString(),
        backgroundColor:
          index === prozessBereich.history.length - 1 && !historyEntry.comment
            ? "#fbb"
            : "",
        content: [
          historyEntry.version.toFixed(1).toString(),
          new Date(historyEntry.lastUpdated).toLocaleDateString("de-DE"),
          <TextAreaComponent
            rows={1}
            autoResize
            disabled={
              isViewerState || index !== prozessBereich.history.length - 1
            }
            value={historyEntry.comment || ""}
            placeholder=""
            onChange={(comment: string) => {
              setProzessBereich({
                ...prozessBereich,
                latestComment:
                  index === prozessBereich.history.length - 1
                    ? comment
                    : prozessBereich.latestComment,
                history: prozessBereich.history.map((history) =>
                  history.version === historyEntry.version
                    ? { ...history, comment }
                    : history
                ),
              });
              startSaveTimeout();
            }}
          />,
          getFullName(
            companyUsers.find((user) => user.id === historyEntry.lastUpdatedBy),
            "Admin"
          ),
          <CheckboxComponent
            label={t("modules.overview.changeHistory.check")}
            checked={
              index !== prozessBereich.history.length - 1 ||
              historyEntry.released
            }
            disabled={
              isViewerState ||
              index !== prozessBereich.history.length - 1 ||
              overview.scope.userInCharge !== keycloakUser?.serviceId
            }
            onClick={(checked) => {
              handleRelease(checked, prozessBereich, historyEntry);
            }}
          />,
        ],
      });
    });
    return historyRows;
    // eslint-disable-next-line
  }, [
    prozessBereich,
    isViewerState,
    keycloakUser,
    overview,
    t,
    fulfilledState,
    companyUsers,
  ]);
  /**
   * This useeffect updates the prozessbereich in the backend when the history changes
   */
  useEffect(() => {
    if (!prozessBereich?.history || !axios || !keycloakUser?.serviceId) return;
    setFulfilledState(
      getProzessOverviewFulfillment(prozessBereich, [overview])
    );
  }, [prozessBereich, axios, keycloakUser?.serviceId, overview]);

  //keeps context up to date for lean admin overview
  useEffect(() => {
    const updatedBereiche: ProzessBereich[] = leanAdminProzessbereiche.map(
      (bereich) =>
        prozessBereich && prozessBereich.id === bereich.id
          ? prozessBereich
          : bereich
    );
    setLeanAdminProzessbereiche(updatedBereiche);
    // eslint-disable-next-line
  }, [fulfilledState]);

  /**
   * builds prozessbereich dropdowns
   */
  useEffect(() => {
    setProzessBereichSelectOptions(
      leanAdminProzessbereiche.map((bereich) => ({
        label: bereich.bereich!,
        value: bereich.id!,
      }))
    );
  }, [leanAdminProzessbereiche]);

  return (
    <div id="prozess-overview" className="prozess-overview__wrapper">
      {isExportGenerating && (
        <LoaderComponent inFront showText text={t("general.buttons.export")} />
      )}
      <div className="prozess-overview__goal">
        <h3 className={overview.goal ? "" : "invalid"}>
          {t("modules.overview.goal.title")}
        </h3>
        <p className="prozess-overview__description">
          {t("modules.overview.goal.description")}
        </p>
        <TextAreaComponent
          rows={1}
          autoResize
          value={overview.goal || ""}
          onChange={(goal: string) => editPartialProzessOverview({ goal })}
          required
          placeholder=""
          disabled={isViewerState}
        />
      </div>
      <div className="prozess-overview__scope">
        <h3 className={overview.scope?.userInCharge ? "" : "invalid"}>
          {t("modules.overview.scope.title")}
        </h3>
        <p className="prozess-overview__description">
          {t("modules.overview.scope.description")}
        </p>
        <h4>{t("modules.overview.scope.userInCharge")}</h4>
        <DropdownComponent
          options={scopeUserOptions}
          selectedOption={
            scopeUserOptions.find(
              (option) => option.value === overview.scope?.userInCharge
            ) || {
              label: t("modules.overview.scope.selectUser"),
              value: "",
            }
          }
          disabled={
            isViewerState ||
            (keycloakUser?.userRole !== UserRole.KEYUSER &&
              keycloakUser?.userRole !== UserRole.PLATFORMADMIN &&
              keycloakUser?.userRole !== UserRole.COMPANYADMIN)
          }
          onChange={(user) =>
            editPartialProzessOverview({
              scope: {
                userInCharge: user,
              },
            })
          }
        />
        <h4>{t("modules.overview.scope.processParticipants")}</h4>
        <TextAreaComponent
          rows={1}
          autoResize
          value={processRoles.join(", ")}
          onChange={() => {}}
          disabled
        />
      </div>
      <div className="prozess-overview__input-output">
        <h3>{t("modules.overview.assignmentsAndInterfaces.title")}</h3>
        <div className="prozess-overview__input-output-wrapper">
          <div>
            <div className="input-wrapper">
              <p>{t("modules.overview.assignmentsAndInterfaces.previous")}</p>
              <DropdownComponent
                disabled={isViewerState}
                selectedOption={overview.assignmentsAndInterfaces?.previousId}
                placeholder={"—"}
                hideLabel
                options={prozessBereichSelectOptions}
                onChange={(value) => {
                  editPartialProzessOverview({
                    assignmentsAndInterfaces: {
                      ...overview.assignmentsAndInterfaces,
                      previousId: value,
                    },
                  });
                }}
              />
            </div>
            <div className="output-wrapper">
              <p>{t("modules.overview.assignmentsAndInterfaces.following")}</p>
              <DropdownComponent
                disabled={isViewerState}
                selectedOption={overview.assignmentsAndInterfaces?.followingId}
                placeholder={"—"}
                options={prozessBereichSelectOptions}
                onChange={(value) => {
                  editPartialProzessOverview({
                    assignmentsAndInterfaces: {
                      ...overview.assignmentsAndInterfaces,
                      followingId: value,
                    },
                  });
                }}
              />
            </div>
            <div className="input-wrapper">
              <p>{t("modules.overview.assignmentsAndInterfaces.interface")}</p>
              <TextAreaComponent
                rows={1}
                autoResize
                disabled={isViewerState}
                value={overview.assignmentsAndInterfaces?.interfaceInfo || ""}
                placeholder=""
                onChange={(value: string) =>
                  editPartialProzessOverview({
                    assignmentsAndInterfaces: {
                      ...overview.assignmentsAndInterfaces,
                      interfaceInfo: value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="prozess-overview__input-output">
        <h3>{t("modules.overview.inputOutput.title")}</h3>

        <div className="prozess-overview__input-output-wrapper">
          {[
            <div>
              <div className="input-wrapper">
                <p>{t("modules.overview.inputOutput.inputTitle")}</p>
                <TextAreaComponent
                  rows={1}
                  autoResize
                  value={firstInputPair}
                  placeholder={""}
                  onChange={() => {}}
                  disabled
                />
              </div>
              <div className="output-wrapper">
                <p>{t("modules.overview.inputOutput.outputTitle")}</p>
                <TextAreaComponent
                  rows={1}
                  autoResize
                  value={lastOutputPair}
                  placeholder={""}
                  onChange={() => {}}
                  disabled
                />
              </div>
            </div>,
            ...overview.inAndOutputs.map((inputOutput, index) => (
              <div className="delete-wrapper">
                <div className="flex-wrapper">
                  <div className="input-wrapper">
                    <p>{t("modules.overview.inputOutput.inputTitle")}</p>
                    <TextAreaComponent
                      rows={1}
                      autoResize
                      disabled={isViewerState}
                      value={inputOutput.input}
                      placeholder={""}
                      onChange={(input: string) =>
                        editPartialProzessOverview({
                          inAndOutputs: overview.inAndOutputs.map(
                            (inputOutput, i) => {
                              if (i === index) {
                                return {
                                  ...inputOutput,
                                  input,
                                };
                              }
                              return inputOutput;
                            }
                          ),
                        })
                      }
                    />
                  </div>
                  <div className="output-wrapper">
                    <p>{t("modules.overview.inputOutput.outputTitle")}</p>
                    <TextAreaComponent
                      rows={1}
                      autoResize
                      disabled={isViewerState}
                      value={inputOutput.output}
                      placeholder={""}
                      onChange={(output: string) =>
                        editPartialProzessOverview({
                          inAndOutputs: overview.inAndOutputs.map(
                            (inputOutput, i) => {
                              if (i === index) {
                                return {
                                  ...inputOutput,
                                  output,
                                };
                              }
                              return inputOutput;
                            }
                          ),
                        })
                      }
                    />
                  </div>
                </div>
                {isViewerState || (
                  <DeleteIcon
                    onClick={() =>
                      editPartialProzessOverview({
                        inAndOutputs: overview.inAndOutputs.filter(
                          (_, i) => i !== index
                        ),
                      })
                    }
                  />
                )}
              </div>
            )),
          ]}
          <ButtonComponent
            title={t("modules.overview.indicator.add")}
            disabled={isViewerState}
            onClick={() =>
              editPartialProzessOverview({
                inAndOutputs: [
                  ...overview.inAndOutputs,
                  {
                    input: "",
                    output: "",
                  },
                ],
              })
            }
          />
        </div>
      </div>
      <div className="prozess-overview__indicator">
        <h3>{t("modules.overview.indicator.title")}</h3>

        <div className="prozess-overview__indicator-wrapper">
          {overview.leistungsindikatoren.map((indicator, index) => (
            <div className="delete-wrapper">
              <div className="flex-wrapper">
                <div className="kpi-wrapper">
                  <p>{t("modules.overview.indicator.kpiTitle")}</p>
                  <TextAreaComponent
                    rows={1}
                    autoResize
                    disabled={isViewerState}
                    value={indicator.kpi}
                    placeholder={""}
                    onChange={(kpi: string) =>
                      editPartialProzessOverview({
                        leistungsindikatoren: overview.leistungsindikatoren.map(
                          (indicator, i) => {
                            if (i === index) {
                              return {
                                ...indicator,
                                kpi,
                              };
                            }
                            return indicator;
                          }
                        ),
                      })
                    }
                  />
                </div>
                <div className="description-wrapper">
                  <p>{t("modules.overview.indicator.descriptionTitle")}</p>
                  <TextAreaComponent
                    rows={1}
                    autoResize
                    disabled={isViewerState}
                    value={indicator.description}
                    placeholder={""}
                    onChange={(description: string) =>
                      editPartialProzessOverview({
                        leistungsindikatoren: overview.leistungsindikatoren.map(
                          (indicator, i) => {
                            if (i === index) {
                              return {
                                ...indicator,
                                description,
                              };
                            }
                            return indicator;
                          }
                        ),
                      })
                    }
                  />
                </div>
              </div>
              {isViewerState || (
                <DeleteIcon
                  onClick={() =>
                    editPartialProzessOverview({
                      leistungsindikatoren:
                        overview.leistungsindikatoren.filter(
                          (_, i) => i !== index
                        ),
                    })
                  }
                />
              )}
            </div>
          ))}
          <ButtonComponent
            disabled={isViewerState}
            title={t("modules.overview.indicator.add")}
            onClick={() =>
              editPartialProzessOverview({
                leistungsindikatoren: [
                  ...overview.leistungsindikatoren,
                  {
                    kpi: "",
                    description: "",
                  },
                ],
              })
            }
          />
        </div>
      </div>
      <div className="prozess-overview__chance-risks">
        <h3>{t("modules.overview.chanceRisks.title")}</h3>
        <div className="prozess-overview__chance-risks-wrapper">
          {overview.chanceAndRisk.map((chanceRisks, index) => (
            <div className="delete-wrapper">
              <div className="flex-wrapper">
                <div className="chance-wrapper">
                  <p>{t("modules.overview.chanceRisks.chanceTitle")}</p>
                  <TextAreaComponent
                    rows={1}
                    autoResize
                    disabled={isViewerState}
                    value={chanceRisks.chance}
                    placeholder={""}
                    onChange={(chance: string) =>
                      editPartialProzessOverview({
                        chanceAndRisk: overview.chanceAndRisk.map(
                          (chanceAndRisk, i) => {
                            if (i === index) {
                              return {
                                ...chanceAndRisk,
                                chance: chance,
                              };
                            }
                            return chanceAndRisk;
                          }
                        ),
                      })
                    }
                  />
                </div>
                <div className="risk-wrapper">
                  <p>{t("modules.overview.chanceRisks.riskTitle")}</p>
                  <TextAreaComponent
                    rows={1}
                    autoResize
                    disabled={isViewerState}
                    value={chanceRisks.risk}
                    placeholder={""}
                    onChange={(risk: string) =>
                      editPartialProzessOverview({
                        chanceAndRisk: overview.chanceAndRisk.map(
                          (chanceRisk, i) => {
                            if (i === index) {
                              return {
                                ...chanceRisk,
                                risk,
                              };
                            }
                            return chanceRisk;
                          }
                        ),
                      })
                    }
                  />
                </div>
              </div>
              {isViewerState || (
                <DeleteIcon
                  onClick={() =>
                    editPartialProzessOverview({
                      chanceAndRisk: overview.chanceAndRisk.filter(
                        (_, i) => i !== index
                      ),
                    })
                  }
                />
              )}
            </div>
          ))}
          <ButtonComponent
            disabled={isViewerState}
            title={t("modules.overview.indicator.add")}
            onClick={() =>
              editPartialProzessOverview({
                chanceAndRisk: [
                  ...overview.chanceAndRisk,
                  {
                    chance: "",
                    risk: "",
                  },
                ],
              })
            }
          />
        </div>
      </div>

      <div
        id="export-content"
        style={{
          display: "none",
        }}
      >
        <div>
          <h3>{t("modules.overview.description.title")}</h3>
          <SipocEval
            setExportFunction={() => {}}
            companyId={companyId}
            prozessBereichId={prozessBereichId}
          />
        </div>
        <div>
          <h3>{t("modules.overview.diagram.title")}</h3>
          <Prozessmapping
            setExportFunction={() => {}}
            companyId={companyId}
            prozessBereichId={prozessBereichId}
          />
        </div>
      </div>

      <div className="prozess-overview__documents">
        <h3>{t("modules.overview.documents.title")}</h3>

        <div className="prozess-overview__documents-wrapper">
          {outputFileList.map((fileName: string) => (
            <div className="single-document-wrapper">
              <div className="filename">
                <p>{`${t("modules.overview.documents.type")}: ${
                  getFileNameWithoutId(fileName, false).split(".")[1]
                }`}</p>
              </div>
              <div className="document" key={`sipoc-output-file-${fileName}`}>
                <div>{getFileNameWithoutId(fileName, false)}</div>
                <div
                  className={"download-icon"}
                  onClick={() => {
                    download(fileName, companyId, axios);
                  }}
                >
                  <DownloadIcon />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="prozess-overview__analyse">
        <h3>{t("modules.overview.analyse.title")}</h3>

        {analyticsRows.length > 0 && (
          <TableComponent
            key={`analytics-table`}
            headers={t("modules.overview.analyse.table-header", {
              returnObjects: true,
            })}
            rows={analyticsRows}
          />
        )}
        {(gantDiagram?.lanes.length ?? 0) > 0 && (
          <div className="prozess-overview__gant">
            <h4>{t("modules.overview.gant.title")}</h4>
            <GanttDiagrammComponent
              {...gantDiagram!}
              translation={(unit) =>
                t(`modules.overview.gant.timeUnits.${unit}`)
              }
            />
          </div>
        )}
      </div>
      <div className="prozess-overview__analyse">
        <h3>{t("modules.overview.changeHistory.title")}</h3>
        {changeHistoryRows.length > 0 && (
          <TableComponent
            key={`history-table`}
            headers={t("modules.overview.changeHistory.table-header", {
              returnObjects: true,
            })}
            rows={changeHistoryRows}
          />
        )}
      </div>
    </div>
  );
};

export default ProzessOverviewComponent;
