import { AxiosInstance } from "axios";
import { KeycloakInstance } from "keycloak-js";
import i18n from "../i18n";
import { ModuleShowType, UserRole } from "./Enums";
import { KeycloakUser, User } from "./Interfaces";
import { generateNotification } from "./NotificationUtil";
import { Option } from "beelean-component-library";

/**
 * checks if the password is correct
 *
 * @param localPassword
 * @param localPassword2
 * @returns
 * @tested
 */
export const isPasswordCorrect = (
  localPassword: string,
  localPassword2: string
): boolean => {
  if (localPassword === localPassword2) return true;
  return false;
};

/**
 * Adds a server user to the list
 *
 * @param user
 * @param loadedUsers
 * @returns
 * @tested
 */
export const addUserToList = (user: User, loadedUsers: User[]): User[] => {
  let localTempUsers: User[] = loadedUsers;
  localTempUsers.push(user);
  return [...localTempUsers];
};

/**
 * Checks mail
 *
 * @param mail
 * @returns if mail is valid according to regex
 * @tested
 */
export const isMailValid = (mail: string): boolean => {
  const mailRegEx: RegExp = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
  return mailRegEx.test(String(mail));
};

/**
 * check if user is full with all information
 *
 * @param user
 * @returns if user is valid
 * @tested
 */
export const isUserValid = (
  user: User,
  companyCreation: boolean = false
): boolean => {
  let isValid: boolean =
    !!user.firstname &&
    !!user.lastname &&
    isMailValid(user.mail) &&
    !!user.username;
  if (user.userRole !== UserRole.PLATFORMADMIN && !companyCreation) {
    isValid = isValid && !!user.companyId;
  }
  return isValid;
};

/**
 * Fetches all users from the database. If a companyId is given,
 * only the users for a company is returned.
 *
 * @param axios
 */
export const fetchAllUser = async (
  axios: AxiosInstance,
  companyId?: string
): Promise<User[]> => {
  if (companyId === undefined) {
    return await axios
      .get("/user/all/")
      .then((userResponse) => userResponse.data)
      .catch((exc) => console.error("Error during users fetching!", exc));
  } else {
    return await axios
      .get("/user/all/company/", { params: { companyId: companyId } })
      .then((userResponse) => userResponse.data)
      .catch((exc) => console.error("Error during users fetching!", exc));
  }
};

/**
 * Fetches all users from the database. If a companyId is given,
 * only the users for a company is returned.
 *
 * @param axios The axios instance
 * @param companyId The id of the company to fetch the users for
 */
export const fetchAllSimpleUser = async (
  axios: AxiosInstance,
  companyId?: string
): Promise<User[]> => {
  if (companyId === undefined) {
    return await axios
      .get("/user/all/simple/")
      .then((userResponse) => userResponse.data)
      .catch((exc) =>
        console.error("Error during all simple users fetching!", exc)
      );
  } else {
    return await axios
      .get("/user/all/company/simple/", { params: { companyId: companyId } })
      .then((userResponse) => userResponse.data)
      .catch((exc) =>
        console.error("Error during simple users fetching!", exc)
      );
  }
};

/**
 * Creates a new user on the server
 *
 * @param newUser
 * @param axios
 * @return 201 is successful and -1 if not
 */
export const createNewUser = async (
  newUser: User,
  axios: AxiosInstance
): Promise<User> => {
  return axios
    .post("/user/", newUser)
    .then((userResponse) => userResponse.data)
    .catch((exc) => {
      if (exc.response && exc.response.status === 406)
        generateNotification(
          i18n.t("notifications.userCreate.successTitle"),
          i18n.t("notifications.userCreate.errorUserCreationLicense"),
          "warning",
          -1
        );
      else {
        generateNotification(
          i18n.t("notifications.userCreate.errorTitle"),
          i18n.t("notifications.userCreate.serverCreation"),
          "danger",
          -1
        );
        console.error("Error during user creation!", exc);
      }
      return null;
    });
};

/**
 * Adds a login to the server
 *
 * @param userId
 * @param axios
 * @returns status code of request or -1 if exception
 */
export const addLoginToServer = async (
  userId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .post("/user/login/", userId)
    .then((userResponse) => userResponse.status)
    .catch((exc) => {
      console.error("Error during user login adding!", exc);
      return -1;
    });
};

/**
 * fetches one user from the database by id
 *
 * @param userId
 * @param axios
 */
export const getOneUser = async (
  userId: string,
  axios: AxiosInstance
): Promise<User> => {
  return axios
    .get("/user/", { params: { userId: userId } })
    .then((userResponse) => userResponse.data)
    .catch((exc) => {
      console.error("Error during user fetch!", exc);
      return null;
    });
};

/**
 * fetches the name of a user from the database by id
 *
 * @param userId to get name for, can be undefined when lastUpdatedBy value isn't set yet
 * @param axios instance for network request
 * @returns users first and lastname as string
 */
export const getNameForUserId = async (
  userId: string | undefined,
  axios: AxiosInstance
): Promise<string> => {
  if (!userId) return "";
  return axios
    .get("/user/name/", { params: { userId: userId } })
    .then((userResponse) => userResponse.data)
    .catch((exc) => {
      console.error("Error during user name fetch!", exc);
      return "";
    });
};

/**
 * For toggling the enabled status of a user
 *
 * @param userId
 * @param axios
 */
export const toggleStatusOfUser = async (
  userId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .post("/user/status/toggle/", userId)
    .then((userResponse) => userResponse.status)
    .catch((exc) => {
      console.error("Error during user fetch!", exc);
      return -1;
    });
};

/**
 * Updates user on server
 *
 * @param updateUser
 * @param axios
 */
export const updateUserOnServer = async (
  updateUser: User,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .post("/user/update/", updateUser)
    .then((userResponse) => userResponse.status)
    .catch((exc) => {
      console.error("Error during user update!", exc);
      return -1;
    });
};

/**
 * deletes a user on the server and keycloak
 *
 * @param userId
 * @param axios
 */
export const deleteUserOnServer = async (
  userId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .post("/user/delete/", userId)
    .then((userResponse) => userResponse.status)
    .catch((exc) => {
      console.error("Error during user deleting!", exc);
      return -1;
    });
};

/**
 * Helper method to create a keycloakUser from the keycloak user profile.
 *
 * @param keycloak The current keycloak instance
 */
export const createUserInstance = async (
  keycloak: KeycloakInstance
): Promise<KeycloakUser> => {
  let newKeycloakUser: KeycloakUser;
  return keycloak
    .loadUserProfile()
    .then(
      (userInfo: any) =>
        (newKeycloakUser = {
          enabled: userInfo.enabled!,
          firstname: userInfo.firstName!,
          lastname: userInfo.lastName!,
          username: userInfo.username!,
          userRole: UserRole.NONE,
          mail: userInfo.email!,
          serviceId: userInfo.attributes.serviceId[0],
          companyId: userInfo.attributes.companyId[0],
        })
    )
    .then(() => {
      if (keycloak.hasRealmRole(UserRole.PLATFORMADMIN)) {
        newKeycloakUser = {
          ...newKeycloakUser,
          userRole: UserRole.PLATFORMADMIN,
        };
      }
      if (keycloak.hasRealmRole(UserRole.COMPANYADMIN)) {
        newKeycloakUser = {
          ...newKeycloakUser,
          userRole: UserRole.COMPANYADMIN,
        };
      }
      if (keycloak.hasRealmRole(UserRole.KEYUSER)) {
        newKeycloakUser = {
          ...newKeycloakUser,
          userRole: UserRole.KEYUSER,
        };
      }
      if (keycloak.hasRealmRole(UserRole.EVALUATION)) {
        newKeycloakUser = {
          ...newKeycloakUser,
          userRole: UserRole.EVALUATION,
        };
      }
      if (keycloak.hasRealmRole(UserRole.WORKER)) {
        newKeycloakUser = {
          ...newKeycloakUser,
          userRole: UserRole.WORKER,
        };
      }
    })
    .then(() => newKeycloakUser);
};

/**
 * Configuration if a specific role is allow to see a thing
 *
 * @param role
 * @param id
 * @returns if user is allowed
 */
export const isUserAllowedToDoThis = (
  role: UserRole,
  id?: ModuleShowType,
  projektManagerId?: string,
  serviceId?: string,
  responsibleUserId?: string
): boolean => {
  if (
    role === UserRole.PLATFORMADMIN &&
    id !== ModuleShowType.LOGO_ADMINISTRATION
  )
    return true;

  switch (id) {
    case ModuleShowType.PROZESSBEREICH_CREATE:
      return role === UserRole.COMPANYADMIN || role === UserRole.KEYUSER;

    case ModuleShowType.SIPOC_CREATE:
      return role === UserRole.COMPANYADMIN || role === UserRole.KEYUSER;

    case ModuleShowType.SIPOC_CHECKLIST:
      return (
        role === UserRole.COMPANYADMIN ||
        projektManagerId === serviceId ||
        responsibleUserId === serviceId
      );

    case ModuleShowType.PROZESSMAPPING:
      return (
        role === UserRole.COMPANYADMIN ||
        role === UserRole.KEYUSER ||
        role === UserRole.EVALUATION
      );

    case ModuleShowType.LOGO_ADMINISTRATION:
      return role === UserRole.COMPANYADMIN;

    case ModuleShowType.PDCA_CREATE:
      return (
        role === UserRole.COMPANYADMIN ||
        role === UserRole.KEYUSER ||
        role === UserRole.EVALUATION
      );

    case ModuleShowType.PROJEKTAKTE_CREATE:
      return (
        role === UserRole.COMPANYADMIN ||
        role === UserRole.KEYUSER ||
        role === UserRole.EVALUATION
      );
    case ModuleShowType.PROJEKTAKTE_CHANGE:
      return (
        role === UserRole.COMPANYADMIN ||
        role === UserRole.KEYUSER ||
        projektManagerId === serviceId
      );

    default:
      return false;
  }
};

/**
 * Util method to map user objects on dropdown options
 *
 * @param users Loaded user objects
 * @returns List of user options for a dropdown
 * @tested
 */
export const getSelectOptionsForUser = (users: User[]): Option[] => {
  return users.map((user) => ({
    label: `${user.firstname} ${user.lastname}`,
    value: user.id!,
  }));
};

/**
 * Util to get a full name from a user object
 * @param user  User object
 * @returns   Full name of the user
 */
export const getFullName = (user?: User, fallback?: string): string =>
  user ? `${user.firstname} ${user.lastname}` : fallback ? fallback : "";
