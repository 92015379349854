import {
  LayoutComponent,
  LoaderComponent,
  TreeConfig,
} from "beelean-component-library";
import React, { Suspense, useContext } from "react";
import { useTranslation } from "react-i18next";
import Intro from "../components/intro/Intro";
import { LicenseModuleType } from "../utils/Enums";
import { Company, KeycloakUser } from "../utils/Interfaces";
import { ProzessBereich } from "../utils/LeanAdmin/LeanAdmin.types";
import { isModuleValidInLicense } from "../utils/LicenseUtil";
import {
  getCorrectLanguageImage,
  toggleLanguage,
  useLayoutConfiguration,
} from "../utils/NavigationUtil";
import {
  CompanyContext,
  GlobaleApplicationSettings,
  KeycloakUserContext,
  TreeDataContext,
} from "./App";

interface DashboardPageProps {
  keycloakUser: KeycloakUser;
  userCompany: Company;
  leanAdminTreeData?: TreeConfig;
  pdcaTreeData?: TreeConfig;
  leanProdTreeData?: TreeConfig;
  projektAkteTreeData?: TreeConfig;
  isViewerState: boolean;
  toggleViewerState(toggleState: boolean): void;
  isDesktopMenuOpen: boolean;
  toggleIsDesktopMenuOpen(toggleState: boolean): void;
  leanAdminProzessbereiche: ProzessBereich[];
  allCompanies: Company[];
}

const Page: React.FC<DashboardPageProps> = ({
  keycloakUser,
  userCompany,
  leanAdminTreeData,
  pdcaTreeData,
  leanProdTreeData,
  isViewerState,
  toggleViewerState,
  isDesktopMenuOpen,
  leanAdminProzessbereiche,
  toggleIsDesktopMenuOpen,
  allCompanies,
  projektAkteTreeData,
}) => {
  const { t } = useTranslation();

  const {
    text,
    isAdmin,
    username,
    homeFunction,
    logoutFunction,
    isPlatformAdmin,
    profileFunction,
    accountListFunction,
    showToggleViewEdit,
    isTest,
    lng,
  } = useLayoutConfiguration(keycloakUser);

  return (
    <LayoutComponent
      noDataInTree={t("tree.noData")}
      isTest={isTest}
      showToggleViewEdit={showToggleViewEdit}
      viewEditStatus={isViewerState}
      onToggleViewEditFunction={() => toggleViewerState(!isViewerState)}
      isDesktopMenuOpen={isDesktopMenuOpen}
      onToogleDesktopMenuOpen={() =>
        toggleIsDesktopMenuOpen(!isDesktopMenuOpen)
      }
      showLeanAdminTree={
        isPlatformAdmin ||
        isModuleValidInLicense(
          LicenseModuleType.ADMINISTRATION,
          userCompany.license
        )
      }
      showLeanProdTree={
        isPlatformAdmin ||
        isModuleValidInLicense(
          LicenseModuleType.PRODUCTION,
          userCompany.license
        )
      }
      text={text}
      adminProjektAkteTreeData={projektAkteTreeData}
      title={t("layout.dashboardTitle")}
      leanProdTreeData={leanProdTreeData}
      leanAdminTreeData={leanAdminTreeData}
      adminPdcaTreeData={pdcaTreeData}
      username={username}
      homeFunction={() => homeFunction()}
      logoutFunction={() => logoutFunction()}
      onUserAdminFunction={() => accountListFunction()}
      onCreateLineFunction={() => console.log("Cretae Line!")}
      isAdmin={isAdmin}
      profileFunction={() => profileFunction()}
      key={leanAdminProzessbereiche.length}
      version={t("general.version")}
      languageImage={getCorrectLanguageImage(lng)}
      onChangeLanguage={toggleLanguage}
    >
      <Intro
        loadedServerCompanies={allCompanies}
        keycloakUser={keycloakUser}
        company={userCompany}
        prozessBereiche={leanAdminProzessbereiche}
      />
    </LayoutComponent>
  );
};

const DashboardPage = () => {
  // needed context
  const { keycloakUser } = useContext(KeycloakUserContext);
  const {
    isViewerState,
    toggleViewerState,
    isDesktopMenuOpen,
    toggleIsDesktopMenuOpen,
  } = useContext(GlobaleApplicationSettings);
  const { userCompany, allCompanies } = useContext(CompanyContext);
  const {
    leanAdminTreeData,
    leanProdTreeData,
    pdcaTreeData,
    leanAdminProzessbereiche,
    projektAkteTreeData,
  } = useContext(TreeDataContext);
  const { t } = useTranslation();

  return keycloakUser ? (
    <Suspense
      fallback={
        <LoaderComponent inFront showText text={t("general.loading")} />
      }
    >
      <Page
        allCompanies={allCompanies}
        isDesktopMenuOpen={isDesktopMenuOpen}
        toggleIsDesktopMenuOpen={toggleIsDesktopMenuOpen}
        isViewerState={isViewerState}
        toggleViewerState={toggleViewerState}
        keycloakUser={keycloakUser}
        userCompany={userCompany}
        leanAdminTreeData={leanAdminTreeData}
        pdcaTreeData={pdcaTreeData}
        leanProdTreeData={leanProdTreeData}
        leanAdminProzessbereiche={leanAdminProzessbereiche}
        projektAkteTreeData={projektAkteTreeData}
      />
    </Suspense>
  ) : (
    <LoaderComponent inFront showText text={t("general.loading")} />
  );
};

export default DashboardPage;
