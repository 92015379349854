import { LicenseModuleType, LicenseType, UserRole } from "./Enums";
import { Company, License } from "./Interfaces";

/**
 * Validates the license if its valid
 *
 * @param license
 * @param currentStationAmount
 * @param currentProzessbereicheAmount
 * @param currentProjektakteAmount
 * @param currentProcessAmount
 * @returns if the current license is valid
 */
export const isLicenseValid = (
  license: License,
  currentStationAmount: number,
  currentProcessAmount: number,
  currentProzessbereicheAmount: number,
  currentProjektakteAmount: number
): boolean => {
  switch (license.type) {
    case LicenseType.ADMINISTRATION_USER:
      return license.administrationValidUntil >= new Date();
    case LicenseType.ADMINISTRATION_PROJEKTAKTE:
      return (
        license.administrationValidUntil >= new Date() &&
        (license.licensedProjektakteAndProzessbereiche >=
          currentProzessbereicheAmount + currentProjektakteAmount ||
          license.licensedProjektakteAndProzessbereiche === 0)
      );
    case LicenseType.ADMINISTRATION:
      return (
        license.administrationValidUntil >= new Date() &&
        (license.licensedProcessAmount >= currentProcessAmount ||
          license.licensedProcessAmount === 0)
      );
    case LicenseType.PRODUCTION:
      return (
        license.productionValidUntil >= new Date() &&
        (license.licensedStationAmount >= currentStationAmount ||
          license.licensedStationAmount === 0)
      );
    case LicenseType.ALL:
      return (
        license.administrationValidUntil >= new Date() &&
        license.productionValidUntil >= new Date() &&
        (license.licensedStationAmount >= currentStationAmount ||
          license.licensedStationAmount === 0) &&
        (license.licensedProcessAmount >= currentProcessAmount ||
          license.licensedProcessAmount === 0) &&
        (license.licensedProjektakteAndProzessbereiche >=
          currentProzessbereicheAmount + currentProjektakteAmount ||
          license.licensedProjektakteAndProzessbereiche === 0)
      );
  }
};

/**
 * Helper to identify if the module is licensed for the company
 *
 * @param module
 * @param license
 */
export const isModuleValidInLicense = (
  module: LicenseModuleType,
  license: License
): boolean => {
  if (license.type === LicenseType.ALL) return true;

  switch (module) {
    case LicenseModuleType.ADMINISTRATION:
      return (
        license.type === LicenseType.ADMINISTRATION ||
        license.type === LicenseType.ADMINISTRATION_PROJEKTAKTE ||
        license.type === LicenseType.ADMINISTRATION_USER
      );
    case LicenseModuleType.PRODUCTION:
      return license.type === LicenseType.PRODUCTION;
    case LicenseModuleType.PDCA:
      return (
        ((license.type === LicenseType.ADMINISTRATION &&
          license.activatePdca) ||
          license.type === LicenseType.ADMINISTRATION_PROJEKTAKTE ||
          license.type === LicenseType.ADMINISTRATION_USER) &&
        new Date(license.administrationValidUntil) >= new Date()
      );
    case LicenseModuleType.PROZESSBEREICHE:
      return (
        (license.type === LicenseType.ADMINISTRATION ||
          license.type === LicenseType.ADMINISTRATION_PROJEKTAKTE ||
          license.type === LicenseType.ADMINISTRATION_USER) &&
        new Date(license.administrationValidUntil) >= new Date()
      );
    case LicenseModuleType.PROJEKTAKTE:
      return (
        (license.type === LicenseType.ADMINISTRATION_PROJEKTAKTE ||
          license.type === LicenseType.ADMINISTRATION_USER) &&
        new Date(license.administrationValidUntil) >= new Date()
      );
  }
};

/**
 * Helper to determine if a user role is allowed to see lean admin check
 *
 * @param company company of the used user for the license
 * @param role user role to check
 * @returns if user role is allowed
 * @tested
 */
export const isUserAllowedForLeanAdminCheck = (
  company: Company,
  role: UserRole
): boolean =>
  role === UserRole.PLATFORMADMIN ||
  company.license.type === LicenseType.ADMINISTRATION ||
  company.license.type === LicenseType.ADMINISTRATION_PROJEKTAKTE ||
  company.license.type === LicenseType.ADMINISTRATION_USER ||
  company.license.type === LicenseType.ALL;
