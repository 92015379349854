import {
  LayoutComponent,
  LoaderComponent,
  TreeConfig,
} from "beelean-component-library";
import React, { Suspense, useContext } from "react";
import { useTranslation } from "react-i18next";
import { LicenseModuleType } from "../utils/Enums";
import { Company, KeycloakUser } from "../utils/Interfaces";
import { isModuleValidInLicense } from "../utils/LicenseUtil";
import {
  getCorrectLanguageImage,
  toggleLanguage,
  useLayoutConfiguration,
} from "../utils/NavigationUtil";
import "../styles/NotValidPage.scss";
import {
  CompanyContext,
  GlobaleApplicationSettings,
  KeycloakUserContext,
  TreeDataContext,
} from "./App";

interface NotValidPageProps {
  keycloakUser: KeycloakUser;
  userCompany: Company;
  leanAdminTreeData?: TreeConfig;
  pdcaTreeData?: TreeConfig;
  leanProdTreeData?: TreeConfig;
  projektAkteTreeData?: TreeConfig;
  isViewerState: boolean;
  toggleViewerState(viewState: boolean): void;
  isDesktopMenuOpen: boolean;
  toggleIsDesktopMenuOpen(desktopOpenState: boolean): void;
}

const Page: React.FC<NotValidPageProps> = ({
  keycloakUser,
  userCompany,
  leanAdminTreeData,
  pdcaTreeData,
  leanProdTreeData,
  isViewerState,
  toggleViewerState,
  isDesktopMenuOpen,
  projektAkteTreeData,
  toggleIsDesktopMenuOpen,
}) => {
  const {
    text,
    isAdmin,
    username,
    homeFunction,
    logoutFunction,
    profileFunction,
    accountListFunction,
    showToggleViewEdit,
    isTest,
    lng,
  } = useLayoutConfiguration(keycloakUser);
  const { t } = useTranslation();

  return (
    <LayoutComponent
      noDataInTree={t("tree.noData")}
      adminProjektAkteTreeData={projektAkteTreeData}
      isTest={isTest}
      showToggleViewEdit={showToggleViewEdit}
      isDesktopMenuOpen={isDesktopMenuOpen}
      onToogleDesktopMenuOpen={() =>
        toggleIsDesktopMenuOpen(!isDesktopMenuOpen)
      }
      viewEditStatus={isViewerState}
      onToggleViewEditFunction={() => toggleViewerState(!isViewerState)}
      showLeanAdminTree={isModuleValidInLicense(
        LicenseModuleType.ADMINISTRATION,
        userCompany.license
      )}
      showLeanProdTree={isModuleValidInLicense(
        LicenseModuleType.PRODUCTION,
        userCompany.license
      )}
      text={text}
      title={t("notvalid.notLicensed")}
      leanProdTreeData={leanProdTreeData}
      leanAdminTreeData={leanAdminTreeData}
      adminPdcaTreeData={pdcaTreeData}
      username={username}
      homeFunction={() => homeFunction()}
      logoutFunction={() => logoutFunction()}
      onUserAdminFunction={() => accountListFunction()}
      onCreateLineFunction={() => {}}
      isAdmin={isAdmin}
      profileFunction={() => profileFunction()}
      version={t("general.version")}
      languageImage={getCorrectLanguageImage(lng)}
      onChangeLanguage={toggleLanguage}
    >
      <p className="not-valid-message">{t("notvalid.text")}</p>
    </LayoutComponent>
  );
};

const NotValidPage = () => {
  // needed context
  const { keycloakUser } = useContext(KeycloakUserContext);
  const { userCompany } = useContext(CompanyContext);
  const {
    leanAdminTreeData,
    leanProdTreeData,
    pdcaTreeData,
    projektAkteTreeData,
  } = useContext(TreeDataContext);
  const {
    isViewerState,
    toggleViewerState,
    isDesktopMenuOpen,
    toggleIsDesktopMenuOpen,
  } = useContext(GlobaleApplicationSettings);
  const { t } = useTranslation();

  return keycloakUser ? (
    <Suspense
      fallback={
        <LoaderComponent inFront showText text={t("general.loading")} />
      }
    >
      <Page
        isDesktopMenuOpen={isDesktopMenuOpen}
        toggleIsDesktopMenuOpen={toggleIsDesktopMenuOpen}
        isViewerState={isViewerState}
        toggleViewerState={toggleViewerState}
        keycloakUser={keycloakUser}
        userCompany={userCompany}
        leanAdminTreeData={leanAdminTreeData}
        pdcaTreeData={pdcaTreeData}
        leanProdTreeData={leanProdTreeData}
        projektAkteTreeData={projektAkteTreeData}
      />
    </Suspense>
  ) : (
    <LoaderComponent inFront showText text={t("general.loading")} />
  );
};

export default NotValidPage;
