import { InputTimeUnit } from "beelean-component-library";
import { v4 as uuidv4 } from "uuid";

/**
 * Describes Tätigkeit for SIPOC Analyse from Lean Admin Modul
 */
export enum Taetigkeit {
  VERANTWORTLICHDURCHFUEHREND = "VERANTWORTLICHDURCHFUEHREND",
  VERANTWORTLICH = "VERANTWORTLICH",
  DURCHFUEHREND = "DURCHFUEHREND",
  MITWIRKEND = "MITWIRKEND",
  INFORMIERT = "INFORMIERT",
  EMPFAENGER = "EMPFAENGER",
}

/**
 * For SIPOC eval in Lean Admin Modul for
 * Analysis
 */
export enum Verlustfaktoren {
  WERTSCHOEPFEND = "WERTSCHOEPFEND",
  NICHTWERTSCHOEPFEND = "NICHTWERTSCHOEPFEND",
  MEETINGSCHULZEIT = "MEETINGSCHULZEIT",
  UEBERUNTERLAST = "UEBERUNTERLAST",
  MGMTZEIT = "MGMTZEIT",
  VARIABILITAET = "VARIABILITAET",
  VERFUEGBAREZEIT = "VERFUEGBAREZEIT",
}

/**
 * same as Prozesschritt, but for the lines
 */
export enum ProzesslinieType {
  RUECKFRAGE = "RUECKFRAGE",
  QUALITAETSMANGEL = "QUALITAETSMANGEL",
  INFORMATIONSFLUSS_PAPIER = "INFORMATIONSFLUSS_PAPIER",
  INFORMATIONSFLUSS_EDV = "INFORMATIONSFLUSS_EDV",
  DIENSTLEISTUNGSLIEFERUNG_MATERIAL = "DIENSTLEISTUNGSLIEFERUNG_MATERIAL",
  INFORMATIONSFLUSS_DEFAULT = "INFORMATIONSFLUSS_DEFAULT",
}

/**
 * different types of Prozessschritte
 */
export enum Prozessschritttype {
  BESTAND = "BESTAND",
  BESTANDEDV = "BESTANDEDV",
  BESTANDPHYSISCH = "BESTANDPHYSISCH",
  BESTANDPHYSISCHEDV = "BESTANDPHYSISCHEDV",
  KUNDEN = "KUNDEN",
  PROCESS = "PROCESS",
  PROCESSSIMPLE = "PROCESSSIMPLE",
  KAIZEN = "KAIZEN",
  EXTERN = "EXTERN",
}

/**
 * Type of Parts in the SIPOC Eval
 */
export enum ProzessTaskType {
  INPUT = "INPUT",
  PROCESS = "PROCESS",
  OUTPUT = "OUTPUT",
}

/**
 * Types for Output Configuration in SIPOC
 */
export enum SipocOutputType {
  CHECKBOX = "CHECKBOX",
  TEXT = "TEXT",
  DATE = "DATE",
  LINK = "LINK",
}

/**
 * key for the total amount of checked elements in the generated map
 */
export const keyForTheTotalAmountOfCheckedElements: string =
  "total-amount-of-checked-elements";

/**
 * Is an history entry for the whole Prozessbereich, on change it snapshots the current ProzessMapping and saves it here
 */
export interface HistoryEntry {
  version: number;
  lastUpdated: Date;
  lastUpdatedBy: string;
  comment: string;
  released: boolean;
  bereichSnapshot: ProzessBereich;
  prozessMappingSnapshot: ProzessMappingEntry;
}

/**
 * Interface for a Lean Admin Modul Stammdatum
 */
export interface ProzessBereich {
  id?: string;
  createDate?: Date;
  lastUpdated?: Date;
  lastUpdatedBy: string;
  companyId: string;
  bereich: string;
  roles: string[];
  correcEgProzessMapping: number;
  totalEgProzessMapping: number;
  correcEgSipoc: number;
  totalEgSipoc: number;
  version?: number;
  bereichRoles: ProzessBereichRole[];
  prozessOverviewId: string;
  history: HistoryEntry[];
  latestComment?: string;
}

/**
 * Defines who is in charge and part of the process
 */
export interface ProzessScope {
  userInCharge: string;
}

/**
 * Defines the input and output of a process
 */
export interface InAndOutput {
  input: string;
  output: string;
}

/**
 * Defines the KPIs of a process
 */
export interface Leistungsindikator {
  kpi: string;
  description: string;
}

/**
 * Defines the chances and risks of a process
 */
export interface ChanceAndRisk {
  chance: string;
  risk: string;
}

/**
 * Is a summary for a process
 */
export interface ProzessOverview {
  id: string;
  goal: string;
  scope: ProzessScope;
  inAndOutputs: InAndOutput[];
  leistungsindikatoren: Leistungsindikator[];
  chanceAndRisk: ChanceAndRisk[];
  assignmentsAndInterfaces: AssignmentsAndInterfaces;
}

/**
 * object to include former and following process
 */
export interface AssignmentsAndInterfaces {
  previousId: string;
  followingId: string;
  interfaceInfo: string;
}

/**
 * Helper to generate an empty ProzessOverview object
 * @returns  empty ProzessOverview
 */
export const createEmptyProzessOverview = (): ProzessOverview => ({
  id: undefined!,
  goal: "",
  scope: {
    userInCharge: "",
  },
  inAndOutputs: [],
  leistungsindikatoren: [],
  chanceAndRisk: [],
  assignmentsAndInterfaces: {
    followingId: "",
    previousId: "",
    interfaceInfo: "",
  },
});

/**
 * model of a role for a Prozessbereich in version 2
 */
export interface ProzessBereichRole {
  id: string;
  name: string;
  position: number;
}

/**
 * Helper to generate an empty ProzessBereichRole object
 * @returns empty ProzessBereichRole
 */
export const createEmptyProzessBereichRole = (): ProzessBereichRole => ({
  id: uuidv4(),
  name: "",
  position: -1,
});

/**
 * Creates a new Prozessbereich
 *
 * @param companyId
 * @returns empty ProzessNereich
 * @tested
 */
export const createEmptyProzessBereich = (
  companyId: string
): ProzessBereich => ({
  companyId: companyId,
  id: undefined,
  lastUpdatedBy: "",
  bereich: "",
  roles: [],
  correcEgProzessMapping: 0,
  correcEgSipoc: 0,
  totalEgProzessMapping: 0,
  totalEgSipoc: 0,
  bereichRoles: [],
  history: [],
  prozessOverviewId: undefined!,
});

/**
 * ProzessMapping
 */
export interface ProzessMappingEntry {
  id?: string;
  createDate?: Date;
  lastUpdated?: Date;
  lastUpdatedBy?: string;
  companyId: string;
  prozessBereichId: string;
  prozessSchritte: Prozessschritt[];
  prozessLinie: Prozesslinie[];
}

/**
 * creates an empty ProzessMappingEntry
 *
 * @param companyId
 * @param prozessBereichId
 * @returns ProzessMappingEntry
 */
export const createEmptyProzessMappingEntry = (
  companyId: string,
  prozessBereichId: string
): ProzessMappingEntry => ({
  id: undefined,
  companyId: companyId,
  prozessBereichId: prozessBereichId,
  prozessLinie: [],
  prozessSchritte: [],
});

/**
 * Interface for a Prozessschritt used in ProzessMappingEntry
 */
export interface Prozessschritt {
  id: string;
  role: string;
  type: Prozessschritttype;
  position: number;
  note: string;
  // Kunde / Prozess
  name?: string;
  displayName?: string;
  // Kunde
  auftrag?: string;
  // Bestand
  vorgaenge: number;
  durchlaufZeit: ProcessTimeUnit;
  processLink?: string;
  // Kaizen
  beschreibung?: string;
  priorisierung?: number;
  coords?: number[];
  createdPdca?: boolean;
  // Extern / Dienstleister
  bezeichnung?: string;
  dauer: ProcessTimeUnit;
  // Prozess
  bestandLink?: string;
  workerAmount: number;
  prozessSchritte: number;
  prozessZeit: ProcessTimeUnit;
  minProzessZeit: ProcessTimeUnit;
  maxProzessZeit: ProcessTimeUnit;
  verweilZeit: ProcessTimeUnit;
  rueckfrageQuote: number;
  qualitaetsQuote: number;
  input: ProzessTask;
  process: ProzessTask;
  output: ProzessTask;
  outputConfig: SipocOutputConfigurationEntry[];
  outputFileList: string[];
}

/**
 * Helper interface to save the parent of a Prozessschritt
 */
export interface ProzessSchritteWithParent {
  schritte: Prozessschritt[];
  parentId?: string;
}

/**
 * Helper interface to save the parent of a Prozessschritt, its children and its nested level
 */
export interface ProzessTreeNode {
  schritt: Prozessschritt;
  children: string[];
  parentId?: string;
  role: string;
}

/**
 * Interface for a time input field
 */
export interface ProcessTimeUnit {
  value: number;
  unit: InputTimeUnit;
}

/**
 * creates an empty time input field
 *
 * @returns ProcessTimeUnit
 */
export const createEmptyProcessTimeUnit = (): ProcessTimeUnit => ({
  unit: 3600,
  value: 0,
});

/**
 * Creates a new Prozessschritt of given Prozesstype
 *
 * @param role
 * @param type
 * @param position
 * @returns
 * @tested
 */
export const createEmptyProzess = (
  role: string,
  type: Prozessschritttype,
  position: number
): Prozessschritt => ({
  position: position,
  note: "",
  type: type,
  role: role,
  id: uuidv4(),
  dauer: createEmptyProcessTimeUnit(),
  durchlaufZeit: createEmptyProcessTimeUnit(),
  maxProzessZeit: createEmptyProcessTimeUnit(),
  minProzessZeit: createEmptyProcessTimeUnit(),
  prozessSchritte: 0,
  prozessZeit: createEmptyProcessTimeUnit(),
  qualitaetsQuote: 0,
  rueckfrageQuote: 0,
  verweilZeit: createEmptyProcessTimeUnit(),
  vorgaenge: 0,
  workerAmount: 0,
  input: createEmptyProzessTask(ProzessTaskType.INPUT),
  process: createEmptyProzessTask(ProzessTaskType.PROCESS),
  output: createEmptyProzessTask(ProzessTaskType.OUTPUT),
  outputConfig: [],
  outputFileList: [],
});

/**
 * creates an empty ProzessTask, which is used in a Prozessschritt
 *
 * @param type
 * @returns ProzessTask
 */
export const createEmptyProzessTask = (type: ProzessTaskType): ProzessTask => ({
  items: [],
  type: type,
  definition: [],
});

/**
 * Interface for DotData,
 * which is used to map a role to a Tätigkeit
 */
export interface DotData {
  taetigkeit?: Taetigkeit;
  role: string;
}

/**
 * Interface of a item in a ProzessTask
 */
export interface ProzessTaskItem {
  dots: DotData[];
  description: string;
  position?: number;
}

/**
 * creates an empty ProzessTaskItem
 *
 * @returns ProzessTaskItem
 */
export const createEmptyProzessTaskItem = (): ProzessTaskItem => ({
  description: "",
  dots: [],
  position: -1,
});

/**
 * Definition for a ProzessTask
 */
export interface ProzessTaskDefinition {
  id: string;
  content: string;
}

/**
 * interface of ProzessTask in a Prozessschritt
 */
export interface ProzessTask {
  items: ProzessTaskItem[];
  type: ProzessTaskType;
  definition: ProzessTaskDefinition[];
}

/**
 * The line between two Prozessschritt
 */
export interface Prozesslinie {
  id: string;
  startProcessId: string;
  stopProcessId: string;
  type: ProzesslinieType;
  // Dienstleistungslinie
  lieferzeit: ProcessTimeUnit;
  zusaetzlicherAufwand: number;
  vorfaelle: number;
  // Papierinformationslinie
  uebergabeZeit: ProcessTimeUnit;
  // Rückfragelinie
  dauer: ProcessTimeUnit;
  // Informationslinie
  linePositionPercent?: number;
}

/**
 * creates an empty linie
 *
 * @param startProcessId
 * @param stopProcessId
 * @param type
 * @returns Prozesslinie
 */
export const createEmptyProzesslinie = (
  startProcessId: string,
  stopProcessId: string,
  type: ProzesslinieType
): Prozesslinie => ({
  id: uuidv4(),
  startProcessId: startProcessId,
  stopProcessId: stopProcessId,
  type: type,
  dauer: createEmptyProcessTimeUnit(),
  lieferzeit: createEmptyProcessTimeUnit(),
  uebergabeZeit: createEmptyProcessTimeUnit(),
  vorfaelle: 0,
  zusaetzlicherAufwand: 0,
});

/**
 * A wrapper for elements for a sipoc checklist evaluation
 */
export interface SipocChecklistEval {
  id?: string;
  name: string;
  creator: string;
  createDate?: Date;
  companyId: string;
  projektAkteId?: string;
  prozessBereichId: string;
  checklistItems: SipocChecklistEvalEntry[];
}

/**
 * an entry in the checked elements list
 */
export interface SipocChecklistEvalEntry {
  id: string;
  checked: boolean;
  fileList: string[];
  outputValues: SipocChecklistEvalOutputData[];
}

/**
 * output data to display results of SipocOutputConfiguration
 * in SipocChecklistEvalEntry
 */
export interface SipocChecklistEvalOutputData {
  id: string;
  value: string;
}

/**
 * creates an empty checked elements wrapper
 *
 * @param companyId
 * @param prozessBereichId
 * @returns SipocChecklistEval
 */
export const createEmptySipocChecklistEval = (
  companyId: string,
  creator: string,
  prozessBereichId?: string
): SipocChecklistEval => ({
  checklistItems: [],
  companyId: companyId,
  name: "",
  creator: creator,
  prozessBereichId: prozessBereichId || "",
});

export interface SipocOutputConfigurationEntry {
  id: string;
  type: string;
  title: string;
}

/**
 * creates an empty SipocOutputConfigurationEntry
 *
 * @returns SipocOutputConfiguration
 */
export const createEmptySipocOutputConfigurationEntry = (
  prozessSchrittId: string,
  relatedProzessSchrittId: string
): SipocOutputConfigurationEntry => ({
  id: relatedProzessSchrittId + "_" + prozessSchrittId + "_" + uuidv4(),
  type: "",
  title: "",
});
