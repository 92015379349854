import { AxiosInstance } from "axios";
import { Option } from "beelean-component-library";
import i18next from "i18next";
import { generateNotification } from "../NotificationUtil";
import {
  HistoryEntry,
  ProzessBereich,
  Prozesslinie,
  ProzesslinieType,
  ProzessMappingEntry,
  ProzessOverview,
  Prozessschritt,
  Prozessschritttype,
} from "./LeanAdmin.types";
import { ProjektAkte } from "./ProjektAkte.types";
import { isSipocProzessschrittValid } from "./SipocEvalUtils";

/**
 * removes a prozessBereich by id from the sidetree
 *
 * @param id
 * @param currentProzessBereiche
 * @tested
 */
export const removeProzessBereichFromList = (
  id: string,
  currentProzessBereiche: ProzessBereich[]
): ProzessBereich[] => {
  let localLoadedProzessBereiche: ProzessBereich[] = currentProzessBereiche;
  localLoadedProzessBereiche = localLoadedProzessBereiche.filter(
    (value: ProzessBereich) => value.id !== id
  );
  return [...localLoadedProzessBereiche];
};

/**
 * adds a new Prozesssbereich to the tree
 *
 * @param newProzessBereich
 * @param currentProzessBereiche
 * @tested
 */
export const addLoadedProzessBereich = (
  newProzessBereich: ProzessBereich,
  currentProzessBereiche: ProzessBereich[]
): ProzessBereich[] => {
  let localLoadedProzessBereiche: ProzessBereich[] = currentProzessBereiche;
  localLoadedProzessBereiche.push(newProzessBereich);
  return [...localLoadedProzessBereiche];
};

/**
 * Updates the list of prozessberieche in loadedlist
 *
 * @param prozessBereich
 * @param currentProzessBereiche
 * @tested
 */
export const updateProzessBereichInList = (
  prozessBereich: ProzessBereich,
  currentProzessBereiche: ProzessBereich[]
): ProzessBereich[] => {
  let localLoadedProzessBereiche: ProzessBereich[] = currentProzessBereiche;
  const foundIndex: number = localLoadedProzessBereiche.findIndex(
    (p: ProzessBereich) => prozessBereich.id === p.id
  );
  if (foundIndex !== -1) {
    localLoadedProzessBereiche[foundIndex] = prozessBereich;
    return [...localLoadedProzessBereiche];
  }
  return [...currentProzessBereiche];
};

/**
 * fetches all Prozessbereiche from server just for one company
 *
 * @param companyId
 * @param axios
 */
export const getAllSimpleProzessBereichForCompany = async (
  companyId: string,
  axios: AxiosInstance
): Promise<ProzessBereich[]> => {
  return axios
    .get("/data/prozessbereich/all/simple/", {
      params: { companyId: companyId },
    })
    .then((stammdatumResponse) => stammdatumResponse.data)
    .catch((exc) => {
      console.error(
        "Error during Lean Admin Stammdatum fetching for company!",
        exc
      );
      return null;
    });
};

/**
 * fetches all latest versions of prozessbereiche from server just for one company
 *
 * @param axios     instance for network call
 * @param companyId to fetch releases for
 * @returns latest released history entries for all prozessbereiche, prozessbereiche without release are left out
 */
export const getAllLatestVersionsOfProzessBereichForCompany = async (
  axios: AxiosInstance,
  companyId: string
): Promise<HistoryEntry[]> => {
  return axios
    .get("/data/prozessbereich/all/latest/", {
      params: { companyId: companyId },
    })
    .then((stammdatumResponse) => stammdatumResponse.data)
    .catch((exc) => {
      console.error(
        "Error during fetching of latest releases for company!",
        exc
      );
      return [];
    });
};

/**
 * fetches all Prozessbereiche from server
 *
 * @param axios
 */
export const getAllSimpleProzessBereichForAdmin = async (
  axios: AxiosInstance
): Promise<ProzessBereich[]> => {
  return axios
    .get("/data/prozessbereich/admin/all/simple/")
    .then((stammdatumResponse) => stammdatumResponse.data)
    .catch((exc) => {
      console.error(
        "Error during Lean Admin Stammdatum fetching for Admin!",
        exc
      );
      return null;
    });
};

/**
 * API METHOD - fetches prozessBereich by given Id
 * @param prozessBereichId
 * @param axios
 * @returns Prozessbereich for given id, if found, else null
 */
export const getProzessBereichById = async (
  prozessBereichId: string,
  axios: AxiosInstance
): Promise<ProzessBereich> => {
  return axios
    .get("/data/prozessbereich/", {
      params: { prozessBereichId: prozessBereichId },
    })
    .then((stammdatumResponse) => stammdatumResponse.data)
    .catch((exc) => {
      console.error("Error during Lean Admin Stammdatum fetching!", exc);
      return null;
    });
};

/**
 * Creates a new Stammdatum on Server
 *
 * @param newStammdatum
 * @param axios
 * @returns created ProzessBereich or generates
 *  notification and returns null
 */
export const createNewProzessBereich = async (
  newProzessBereich: ProzessBereich,
  updaterId: string,
  axios: AxiosInstance
): Promise<ProzessBereich> => {
  let prozessBereichWithLastUpdater: ProzessBereich = {
    ...newProzessBereich,
  };
  prozessBereichWithLastUpdater.lastUpdatedBy = updaterId;
  return axios
    .post("/data/prozessbereich/v2/", prozessBereichWithLastUpdater)
    .then((stammdatumResponse) => stammdatumResponse.data)
    .catch((error) => {
      if (error.response.status === 409)
        generateNotification(
          i18next.t("notifications.stammdatumCreate.errorContent"),
          i18next.t("notifications.stammdatumCreate.errorContentLicense"),
          "danger"
        );
      console.error(
        "error while creating Prozessbereich",
        error.response.status
      );
      return null;
    });
};

/**
 * API METHOD - to copy a given prozessbereich
 * @param prozessbereichToCopy the original prozessbereich to copy
 * @param axios netwrok instance
 * @returns copied Prozessbereich for adding or null
 */
export const copyProzessBereich = async (
  prozessbereichToCopy: ProzessBereich,
  updaterId: string,
  axios: AxiosInstance
): Promise<ProzessBereich> => {
  let prozessBereichWithLastUpdater: ProzessBereich = {
    ...prozessbereichToCopy,
  };
  prozessBereichWithLastUpdater.lastUpdatedBy = updaterId;
  prozessBereichWithLastUpdater.history = [];
  return axios
    .post("/data/prozessbereich/copy/", prozessBereichWithLastUpdater)
    .then((copyResp) => copyResp.data)
    .catch((error) => {
      if (error.response.status === 409)
        generateNotification(
          i18next.t("notifications.stammdatumCreate.errorContent"),
          i18next.t("notifications.stammdatumCreate.errorContentLicense"),
          "danger"
        );
      console.error(
        "error while creating Prozessbereich",
        error.response.status
      );
      return null;
    });
};

/**
 * fetches all Stammdaten from server just for one company
 *
 * @param companyId
 * @param axios
 */
export const getAllProzessBereichForCompany = async (
  companyId: string,
  axios: AxiosInstance
): Promise<ProzessBereich[]> => {
  return axios
    .get("/data/prozessbereich/all/", { params: { companyId: companyId } })
    .then((stammdatumResponse) => stammdatumResponse.data)
    .catch((exc) => {
      console.error(
        "Error during Lean Admin Stammdatum fetching for company!",
        exc
      );
      return null;
    });
};

/**
 * fetches all Stammdaten from server for all companies
 *
 * @param axios
 */
export const getAllProzessBereichForAdmin = async (
  axios: AxiosInstance
): Promise<ProzessBereich[]> => {
  return axios
    .get("/data/prozessbereich/admin/all/")
    .then((stammdatumResponse) => stammdatumResponse.data)
    .catch((exc) => {
      console.error("Error during Lean Admin Stammdatum fetching!", exc);
      return null;
    });
};
/**
 * API METHOD - to update a Prozessbereich on the server
 *
 * @param updateProzessbereich Prozessbereich to update in the server
 * @param axios network instance
 * @returns recieved status code or -1 on error
 */
export const updateProzessBereich = async (
  updateProzessbereich: ProzessBereich,
  updaterId: string,
  axios: AxiosInstance
): Promise<ProzessBereich> => {
  let correctUrl: string = "/data/prozessbereich/update/";
  if (updateProzessbereich.version! >= 2)
    correctUrl = "/data/prozessbereich/v2/update/";

  return axios
    .post(correctUrl, { ...updateProzessbereich, lastUpdatedBy: updaterId })
    .then((updateResp) => updateResp.data)
    .catch((exc) => {
      console.error("Error during Prozessbereich update!", exc);
      return null;
    });
};

/**
 * Removes an Prozessbereich from the server and decreased the license count
 *
 * @param prozessBereichId
 * @param companyId
 * @param axios
 * @returns status of the response
 */
export const deleteProzessBereich = async (
  prozessBereichId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .post("/data/prozessbereich/delete/", prozessBereichId)
    .then((prozessBereichResponse) => prozessBereichResponse.data)
    .catch((exc) => {
      console.error("Error during Lean Admin Stammdatum update!", exc);
      return -1;
    });
};

/**
 * updates local the Eg of ProzessMapping
 *
 * @param loadedProzessBereiche
 * @param updateProzessBereicheFunction
 * @param prozessBereichId
 * @param correcEgProzessMapping
 * @param totalEgProzessMapping
 */
export const updateLocalEg = (
  loadedProzessBereiche: ProzessBereich[],
  updateProzessBereicheFunction: Function,
  prozessBereichId: string,
  correcEgProzessMapping: number,
  totalEgProzessMapping: number,
  correcEgSipoc: number,
  totalEgSipoc: number
): void => {
  const foundIndex: number = loadedProzessBereiche.findIndex(
    (prozessBereich) => prozessBereich.id === prozessBereichId
  );
  if (foundIndex !== -1) {
    let localCopyOfLoadedProzessBereiche: ProzessBereich[] =
      loadedProzessBereiche;
    let localCopyOfProzessBereich: ProzessBereich =
      localCopyOfLoadedProzessBereiche[foundIndex];
    // update what has to be updated
    localCopyOfProzessBereich.correcEgSipoc = correcEgSipoc;
    localCopyOfProzessBereich.totalEgSipoc = totalEgSipoc;
    localCopyOfProzessBereich.correcEgProzessMapping = correcEgProzessMapping;
    localCopyOfProzessBereich.totalEgProzessMapping = totalEgProzessMapping;
    localCopyOfLoadedProzessBereiche[foundIndex] = localCopyOfProzessBereich;
    updateProzessBereicheFunction(localCopyOfLoadedProzessBereiche);
  }
};

/**
 * sorts a given list of ProzessBereiche by name
 *
 * @param listOfProzessBereiche
 * @returns sorted list of ProzessBereiche
 * @tested
 */
export const sortListProzessBereiche = (
  listOfProzessBereiche: ProzessBereich[]
): ProzessBereich[] => {
  return listOfProzessBereiche.sort((prozessBereichOne, prozessBereichTwo) =>
    prozessBereichOne.bereich.toUpperCase() <
    prozessBereichTwo.bereich.toUpperCase()
      ? -1
      : prozessBereichOne.bereich.toUpperCase() >
        prozessBereichTwo.bereich.toUpperCase()
      ? 1
      : 0
  );
};

/**
 * Util method that maps the given ProzessBereiche on dropdown options
 *
 * @param prozessBereiche The loaded ProzessBereiche
 * @returns Array of ProzessBereich dropdown options
 */
export const createSelectOptionsForProzessBereiche = (
  prozessBereiche: ProzessBereich[]
): Option[] => {
  return prozessBereiche.map((bereich) => ({
    label: bereich.bereich!,
    value: bereich.id!,
  }));
};

/**
 * Util method that maps the given ProzessBereiche and Projektakten on dropdown options
 *
 * @param prozessBereiche
 * @param projektAkten
 * @returns  Array of ProzessBereich and Projektakten dropdown options
 */
export const createSelectOptionsForProzessBereicheAndProjektAkten = (
  prozessBereiche: ProzessBereich[],
  projektAkten: ProjektAkte[]
): Option[] => {
  const prozessBereichOptions: Option[] = prozessBereiche.map((bereich) => ({
    label: bereich.bereich!,
    value: bereich.id!,
  }));
  const projektAktenOptions: Option[] = projektAkten.map((akte) => ({
    label: akte.title,
    value: akte.id!,
  }));
  return [...prozessBereichOptions, ...projektAktenOptions];
};

/**
 * Util method that maps the roles of the given ProzessBereich on dropdown options
 *
 * @param prozessBereiche The loaded ProzessBereiche
 * @returns Array of role dropdown options
 */
export const createSelectOptionsForRoles = (
  prozessBereich: ProzessBereich | undefined
): Option[] => {
  if (!prozessBereich) return [];
  if (prozessBereich.version! >= 2)
    return prozessBereich.bereichRoles.map((role) => ({
      label: role.name,
      value: role.id,
    }));
  else
    return prozessBereich.roles.map((role) => ({ label: role, value: role }));
};

/**
 * checks if the ProzessBereich is set properly
 *
 * @param prozessBereich
 * @returns boolean
 * @tested
 */
export const isProzessBereichComplete = (
  prozessBereich: ProzessBereich,
  prozessMapping: ProzessMappingEntry
): boolean => {
  const isProzessBereichEgComplete =
    prozessBereich.totalEgProzessMapping > 0 &&
    prozessBereich.correcEgProzessMapping ===
      prozessBereich.totalEgProzessMapping;
  if (!isProzessBereichEgComplete) return false;

  const areProzessSchritteValid: boolean = !prozessMapping.prozessSchritte.some(
    (prozessSchritt: Prozessschritt) => {
      if (prozessSchritt.type.includes(Prozessschritttype.BESTAND))
        return false;
      return !isSipocProzessschrittValid(
        prozessMapping.prozessSchritte,
        prozessSchritt,
        prozessMapping.prozessLinie.filter(
          (prozessLinie: Prozesslinie) =>
            prozessLinie.type !== ProzesslinieType.QUALITAETSMANGEL &&
            prozessLinie.type !== ProzesslinieType.RUECKFRAGE
        )
      );
    }
  );
  return areProzessSchritteValid;
};

/**
 * Helper to determine if a prozessbereich should
 * be displayed by a given search string
 * @param prozessBereich current prozessbereich to validate
 * @param search text to search in roles and name
 * @returns if text is found in roles or name
 * @tested
 */
export const shouldProzessBereichBeShown = (
  prozessBereich: ProzessBereich,
  search: string
): boolean => {
  if (!search) return true;
  let upperCaseSearch: string = search.toUpperCase();

  switch (true) {
    case prozessBereich.bereich.toUpperCase().indexOf(upperCaseSearch) !== -1:
    case prozessBereich.bereichRoles.filter((roles) =>
      roles.name.toUpperCase().includes(upperCaseSearch)
    ).length !== 0:
    case prozessBereich.roles.filter((role) =>
      role.toUpperCase().includes(upperCaseSearch)
    ).length !== 0:
      return true;

    default:
      return false;
  }
};

/**
 * Helper to get numeric fullfillment of prozessbereich overview
 * @param prozessBereich          prozessbereich ti get fulfillment for
 * @param prozessOverviews available overviews
 * @returns number between 0 and 5 to display fulfillment of overview
 */
export const getProzessOverviewFulfillment = (
  prozessBereich: ProzessBereich,
  prozessOverviews: ProzessOverview[]
): number => {
  let fulfillment: number = 0;
  //sipoc must be valid
  if (prozessBereich.correcEgSipoc === prozessBereich.totalEgSipoc)
    fulfillment++;
  //prozessmapping must be valid
  if (
    prozessBereich.correcEgProzessMapping ===
    prozessBereich.totalEgProzessMapping
  )
    fulfillment++;
  //most recent history must have comment for version
  if (prozessBereich.latestComment) fulfillment++;
  const prozessOverview: ProzessOverview | undefined = prozessOverviews.find(
    (overview) => overview.id === prozessBereich.prozessOverviewId
  );
  if (!prozessOverview) return fulfillment;
  //must have goal
  if (prozessOverview.goal) fulfillment++;
  //scope must have user in charge
  if (prozessOverview.scope.userInCharge) fulfillment++;
  return fulfillment;
};
