import {
  DotData,
  Prozesslinie,
  Prozessschritt,
  Prozessschritttype,
  ProzessTaskDefinition,
  ProzessTaskItem,
  ProzessTaskType,
  Taetigkeit,
} from "./LeanAdmin.types";
import { isProzessschrittValid } from "./ProzessMappingUtil";

/**
 * Filter for sorted and filtered Prozesschritte for Sipoc Eval
 *
 * @param prozessSchritte
 * @returns just needed Prozesschritte
 * @tested
 */
export const filterJustForNeededProzessschritte = (
  prozessSchritte: Prozessschritt[]
): Prozessschritt[] => {
  let localProzessschritteData: Prozessschritt[] = prozessSchritte;
  // just get the needed Prozesse
  localProzessschritteData = localProzessschritteData.filter(
    (prozessSchritt: Prozessschritt) =>
      prozessSchritt.type === Prozessschritttype.PROCESS ||
      prozessSchritt.type === Prozessschritttype.PROCESSSIMPLE
  );
  // sort by position
  localProzessschritteData.sort(
    (prozessSchrittOne: Prozessschritt, prozessSchrittTwo: Prozessschritt) =>
      prozessSchrittOne.position - prozessSchrittTwo.position
  );
  return localProzessschritteData;
};

/**
 * generates the needed index numbers for sipoc
 *
 * @param prozessSchritte
 * @returns string array with calculated index numbers
 * @tested
 */
export const generateProzesschrittNumbers = (
  prozessSchritte: Prozessschritt[]
): string[] => {
  let previousPosition: number = -1;
  let currentDuplicatePosition: number = 0;
  let localNumbersString: string[] = [];
  prozessSchritte.forEach((prozessSchritt: Prozessschritt) => {
    // if last Position was the same count internal
    // else reset the duplicate counter
    if (previousPosition === prozessSchritt.position) {
      currentDuplicatePosition++;
    } else {
      currentDuplicatePosition = 0;
    }
    // build the number of the Prozess
    localNumbersString.push(
      `${prozessSchritt.position}.${currentDuplicatePosition}`
    );
    // save for next Prozess
    previousPosition = prozessSchritt.position;
  });
  return localNumbersString;
};

/**
 * checks if a specific type exists in DotData
 *
 * @param type
 * @param dotData
 * @returns if the type is found or not
 */
export const checkIfTaetigkeitIsPresent = (
  type: Taetigkeit,
  dotData: DotData[]
): boolean => {
  let isFound: boolean = false;
  dotData.forEach((dotData: DotData) => {
    if (dotData.taetigkeit === type) isFound = true;
  });
  return isFound;
};

/**
 * Returns the next Taetigkeit
 *
 * @param currentType
 * @returns Taetigkeit | undefined
 * @tested
 */
export const getNextTaetigkeit = (
  dotData?: DotData[],
  currentType?: Taetigkeit
): Taetigkeit | undefined => {
  // if everything is empty
  if (!currentType && dotData?.length === 0)
    return Taetigkeit.VERANTWORTLICHDURCHFUEHREND;
  // if there is data to process
  if (dotData && dotData.length > 0) {
    switch (currentType) {
      case Taetigkeit.VERANTWORTLICHDURCHFUEHREND:
        return Taetigkeit.VERANTWORTLICH;
      case Taetigkeit.VERANTWORTLICH:
        if (checkIfTaetigkeitIsPresent(Taetigkeit.DURCHFUEHREND, dotData)) {
          return Taetigkeit.MITWIRKEND;
        }
        return Taetigkeit.DURCHFUEHREND;
      case Taetigkeit.DURCHFUEHREND:
        return Taetigkeit.MITWIRKEND;
      case Taetigkeit.MITWIRKEND:
        return Taetigkeit.INFORMIERT;
      case Taetigkeit.INFORMIERT:
        return Taetigkeit.EMPFAENGER;
      case Taetigkeit.EMPFAENGER:
        return undefined;
      // check initially what is possible
      case undefined:
        if (
          checkIfTaetigkeitIsPresent(
            Taetigkeit.VERANTWORTLICHDURCHFUEHREND,
            dotData
          )
        ) {
          return Taetigkeit.MITWIRKEND;
        } else if (
          checkIfTaetigkeitIsPresent(Taetigkeit.VERANTWORTLICH, dotData)
        ) {
          return Taetigkeit.DURCHFUEHREND;
        } else if (
          checkIfTaetigkeitIsPresent(Taetigkeit.DURCHFUEHREND, dotData)
        ) {
          return Taetigkeit.VERANTWORTLICH;
        }
        return Taetigkeit.VERANTWORTLICHDURCHFUEHREND;
    }
  }
};

/**
 * fetches the right index of a dotdata list
 *
 * @param lineRole
 * @returns index of found role
 * @tested
 */
export const getCorrectDotDataIndexForRole = (
  lineRole: string,
  dots?: DotData[]
): number => {
  if (!dots || dots?.length === 0) return -1;
  let foundDotDataIndex: number = dots.findIndex(
    (dotData: DotData) => dotData.role === lineRole
  );
  return foundDotDataIndex;
};

/**
 * creates a string with all css classes needed for specific Tätigkeit
 *
 * @param lineRole
 * @returns string with css classes
 * @tested
 */
export const getCorrectCSSClassForDot = (
  lineRole: string,
  type: ProzessTaskType,
  isViewerState: boolean,
  dots?: DotData[],
  prozessSchrittRole?: string
): string => {
  // always needed css class
  let cssClasses: string[] = ["sipoc-detailview-checkbox"];
  if (isViewerState) cssClasses.push("sipoc-detailview-checkbox-view");
  const currentTaetigkeit: Taetigkeit | undefined = getCorrectTaetigkeit(
    lineRole,
    type,
    dots,
    prozessSchrittRole
  );
  if (currentTaetigkeit) {
    cssClasses.push("definition-dot");
    cssClasses.push("dot-" + currentTaetigkeit);
  }
  return cssClasses.join(" ");
};

/**
 * fetches the correct Taetigkeit for a specific dot
 *
 * @param lineRole
 * @param type
 * @param dots
 * @returns correct Taetigkeit or undefined
 * @tested
 */
export const getCorrectTaetigkeit = (
  lineRole: string,
  type: ProzessTaskType,
  dots?: DotData[],
  prozessSchrittRole?: string
): Taetigkeit | undefined => {
  // for fixed Tätigkeit
  if (lineRole === prozessSchrittRole && type === ProzessTaskType.INPUT)
    return Taetigkeit.VERANTWORTLICHDURCHFUEHREND;
  else if (lineRole === prozessSchrittRole && type === ProzessTaskType.OUTPUT)
    return Taetigkeit.EMPFAENGER;
  // for dynamic Tätigkeit
  const foundDotDataIndex: number = getCorrectDotDataIndexForRole(
    lineRole,
    dots
  );
  if (foundDotDataIndex !== -1) {
    return dots![foundDotDataIndex].taetigkeit;
  } else {
    return undefined;
  }
};

/**
 * adds the next Tätigkeit for a DetailView Line
 *
 * @param lineRole
 * @param item
 * @returns adds new dot data to line
 * @tested
 */
export const setNextTaetigkeit = (
  lineRole: string,
  type: ProzessTaskType,
  item: ProzessTaskItem
): ProzessTaskItem => {
  let predefinedTaetigkeit: Taetigkeit | undefined;
  // Input set next Tätigkeit
  if (type === ProzessTaskType.INPUT) {
    predefinedTaetigkeit = Taetigkeit.VERANTWORTLICHDURCHFUEHREND;
  }
  // Output set next Tätigkeit
  if (type === ProzessTaskType.OUTPUT) {
    predefinedTaetigkeit = Taetigkeit.EMPFAENGER;
  }
  // Process set next Tätigkeit
  const foundDotDataIndex: number = getCorrectDotDataIndexForRole(
    lineRole,
    item?.dots
  );
  let localProzessTaskItem: ProzessTaskItem = item;
  // add or update Tätigkeit
  if (foundDotDataIndex === -1) {
    localProzessTaskItem.dots.push({
      role: lineRole,
      taetigkeit: predefinedTaetigkeit
        ? getNextTaetigkeit(
            item?.dots.concat({
              role: "",
              taetigkeit: predefinedTaetigkeit,
            })
          )
        : getNextTaetigkeit(item?.dots),
    });
  } else {
    localProzessTaskItem.dots[foundDotDataIndex].taetigkeit =
      predefinedTaetigkeit
        ? getNextTaetigkeit(
            item?.dots.concat({
              role: "",
              taetigkeit: predefinedTaetigkeit,
            }),
            item?.dots[foundDotDataIndex].taetigkeit
          )
        : getNextTaetigkeit(
            item?.dots,
            item?.dots[foundDotDataIndex].taetigkeit
          );
  }
  return localProzessTaskItem;
};

/**
 * Extracts all next and previous Prozesschritte in
 * the loaded Prozesschritte for the Detail view
 *
 * @param prozessSchritt
 * @param prozessLinien
 * @param prozessSchritte
 * @param withoutPrevious
 */
export const extractPreviousAndNextProzessSchritt = (
  prozessSchritt: Prozessschritt,
  prozessLinien: Prozesslinie[],
  prozessSchritte: Prozessschritt[]
): Prozessschritt[][] => {
  let localPreProzessschritte: Prozessschritt[] = [];
  let localNextProzessschritte: Prozessschritt[] = [];
  // filter needed Prozesslinien, incl. with the previous Bestand Prozessschritt
  let localListOfPossibleProzessLinien: Prozesslinie[] = prozessLinien.filter(
    (prozessLinie: Prozesslinie) =>
      prozessLinie.startProcessId === prozessSchritt.id ||
      prozessLinie.stopProcessId === prozessSchritt.id ||
      prozessLinie.stopProcessId === prozessSchritt.bestandLink
  );
  // fetch the needed Prozessschritte
  localListOfPossibleProzessLinien.forEach((prozessLinie: Prozesslinie) => {
    let foundProzessschritt: Prozessschritt | undefined;
    // add next Prozessschritt
    if (prozessLinie.startProcessId === prozessSchritt.id) {
      foundProzessschritt = fetchProzessschrittById(
        prozessLinie.stopProcessId,
        prozessSchritte
      );
      // if the next Prozessschritt is some kind of type Bestand,
      // then get the real connected Prozessschritt
      if (
        foundProzessschritt &&
        foundProzessschritt.type !== Prozessschritttype.BESTAND &&
        foundProzessschritt.type !== Prozessschritttype.BESTANDEDV &&
        foundProzessschritt.type !== Prozessschritttype.BESTANDPHYSISCH &&
        foundProzessschritt.type !== Prozessschritttype.BESTANDPHYSISCHEDV
      ) {
        localNextProzessschritte.push(foundProzessschritt);
      } else {
        // fetch the real next one
        foundProzessschritt = fetchProzessschrittById(
          foundProzessschritt?.processLink || "",
          prozessSchritte
        );
        if (foundProzessschritt) {
          localNextProzessschritte.push(foundProzessschritt);
        }
      }
      // add previous Prozessschritt
    } else if (
      prozessLinie.stopProcessId === prozessSchritt.id ||
      prozessLinie.stopProcessId === prozessSchritt.bestandLink
    ) {
      foundProzessschritt = fetchProzessschrittById(
        prozessLinie.startProcessId,
        prozessSchritte
      );
      if (foundProzessschritt)
        localPreProzessschritte.push(foundProzessschritt);
    }
  });
  return [localPreProzessschritte, localNextProzessschritte];
};

/**
 * fetches the Prozesschritt by id
 *
 * @param prozessId
 * @returns Prozesschritt or undefined
 * @tested
 */
export const fetchProzessschrittById = (
  prozessId: string,
  prozessSchritte: Prozessschritt[]
): Prozessschritt | undefined => {
  // filter just the Prozessschritt
  const filteredProzessSchritte: Prozessschritt[] = prozessSchritte.filter(
    (prozessSchritt: Prozessschritt) => prozessSchritt.id === prozessId
  );
  // return it
  if (filteredProzessSchritte.length > 0) return filteredProzessSchritte[0];
  return undefined;
};

/**
 * adds a new ProzessItem in a Prozessschritt at
 * the correct position
 *
 * @param item
 * @param index
 * @param prozessSchritt
 * @param type
 * @returns updated Prozesschritt
 * @tested
 */
export const addUpdatedProzessTaskItem = (
  item: ProzessTaskItem,
  index: number,
  prozessSchritt: Prozessschritt,
  type: ProzessTaskType
): Prozessschritt => {
  let copyLocalProzessschritt: Prozessschritt = prozessSchritt;
  let neededItems: ProzessTaskItem[] = [];
  // get the correct input data
  switch (type) {
    case ProzessTaskType.INPUT:
      neededItems = copyLocalProzessschritt.input?.items || [];
      break;
    case ProzessTaskType.PROCESS:
      neededItems = copyLocalProzessschritt.process?.items || [];
      break;
    case ProzessTaskType.OUTPUT:
      neededItems = copyLocalProzessschritt.output?.items || [];
      break;
  }

  // abort if array is empty
  if (neededItems.length === 0) return copyLocalProzessschritt;
  neededItems[index] = item;
  updatePositionValues(neededItems);

  // set the data back to prozesschritt
  switch (type) {
    case ProzessTaskType.INPUT:
      copyLocalProzessschritt = {
        ...copyLocalProzessschritt,
        input: { ...copyLocalProzessschritt.input!, items: neededItems },
      };
      break;
    case ProzessTaskType.PROCESS:
      copyLocalProzessschritt = {
        ...copyLocalProzessschritt,
        process: { ...copyLocalProzessschritt.process!, items: neededItems },
      };
      break;
    case ProzessTaskType.OUTPUT:
      copyLocalProzessschritt = {
        ...copyLocalProzessschritt,
        output: { ...copyLocalProzessschritt.output!, items: neededItems },
      };
      break;
  }

  // set local copy
  return copyLocalProzessschritt;
};

/**
 * updates the value in a ProzessTaskItem
 *
 * @param toModifyProzessschritt
 * @param compareProzessschrittId
 * @param value
 * @returns updated Prozessschritt
 * @tested
 */
export const findAndInsertText = (
  toModifyProzessschritt: Prozessschritt,
  compareProzessschrittId: string,
  value: string
): Prozessschritt => {
  let internCopyOfProzessschritt: Prozessschritt = toModifyProzessschritt;
  let foundIndex: number =
    internCopyOfProzessschritt.output!.definition!.findIndex(
      (definition: ProzessTaskDefinition) =>
        definition.id === compareProzessschrittId
    );
  if (foundIndex === -1) {
    internCopyOfProzessschritt.output!.definition!.push({
      content: value,
      id: compareProzessschrittId,
    });
  } else {
    internCopyOfProzessschritt.output!.definition![foundIndex].content = value;
  }
  return internCopyOfProzessschritt;
};

/**
 * fetch text from Prozesschritt
 *
 * @param toReadProzessschritt
 * @param compareProzessschrittId
 * @returns found text
 * @tested
 */
export const findAndReturnText = (
  toReadProzessschritt: Prozessschritt,
  compareProzessschrittId: string
): string => {
  let foundText: string = "";
  let foundIndex: number = -1;

  if (toReadProzessschritt?.output?.definition) {
    foundIndex = toReadProzessschritt!.output!.definition!.findIndex(
      (definition: ProzessTaskDefinition) =>
        definition.id === compareProzessschrittId
    );
    if (foundIndex !== -1) {
      foundText = toReadProzessschritt!.output!.definition![foundIndex].content;
    }
  }

  return foundText;
};

/**
 * Validates if a Prozessschritt is valid in the SIPOC Eval
 *
 * @param allProzessschritte
 * @param currentProzessSchritt
 * @param neededProzesslinien
 * @returns true if the prozessschritt is valid false if otherwise
 * @tested
 */
export const isSipocProzessschrittValid = (
  allProzessschritte: Prozessschritt[],
  currentProzessSchritt: Prozessschritt,
  neededProzesslinien: Prozesslinie[]
): boolean => {
  // this checks the elements from the ProzessMapping too
  let isValid: boolean = isProzessschrittValid(
    currentProzessSchritt,
    neededProzesslinien
  );
  // Sipoc specific validation check
  const filteredPreNextProzessschritte: Prozessschritt[][] =
    extractPreviousAndNextProzessSchritt(
      currentProzessSchritt,
      neededProzesslinien,
      allProzessschritte
    );
  const nextProzessschritte: Prozessschritt[] =
    filteredPreNextProzessschritte[1];
  const preProzessschritte: Prozessschritt[] =
    filteredPreNextProzessschritte[0];

  // check input output text
  nextProzessschritte.forEach((nextProzesschritt) => {
    if (!findAndReturnText(currentProzessSchritt, nextProzesschritt.id))
      isValid = false;
  });
  preProzessschritte.forEach((preProzesschritt) => {
    if (!findAndReturnText(preProzesschritt, currentProzessSchritt.id))
      isValid = false;
  });
  if (
    currentProzessSchritt.process.items.length <
    currentProzessSchritt.prozessSchritte
  )
    isValid = false;
  // check for Taetigkeit VERANTWORTLICH/DURCHFUEHREND and description for each line
  currentProzessSchritt.process.items.forEach((item) => {
    if (!item.description) isValid = false;
    if (
      !(
        checkIfTaetigkeitIsPresent(
          Taetigkeit.VERANTWORTLICHDURCHFUEHREND,
          item.dots
        ) ||
        (checkIfTaetigkeitIsPresent(Taetigkeit.VERANTWORTLICH, item.dots) &&
          checkIfTaetigkeitIsPresent(Taetigkeit.DURCHFUEHREND, item.dots))
      )
    )
      isValid = false;
  });
  return isValid;
};

/**
 * Calulates the correct numbers for eg calculation of Sipoc
 *
 * @param allProzessschritte
 * @param neededProzesslinien
 * @returns array of number [correctProzessschritte, checkedProzessschritteAmount]
 * @tested
 */
export const getAmountOfCorrectEgSipocAndLength = (
  allProzessschritte: Prozessschritt[],
  neededProzesslinien: Prozesslinie[]
): number[] => {
  let egSipoc: number = 0;
  const localNeededProzessschritte: Prozessschritt[] =
    filterJustForNeededProzessschritte(allProzessschritte);
  localNeededProzessschritte.forEach((prozessSchritt: Prozessschritt) => {
    if (
      isSipocProzessschrittValid(
        allProzessschritte,
        prozessSchritt,
        neededProzesslinien
      )
    )
      egSipoc = egSipoc + 1;
  });
  return [egSipoc, localNeededProzessschritte.length];
};

/**
 *  set the previous or next Prozesschritt in Detailview
 *
 * @param isNext
 * @param currentProzessschrittId
 * @param neededProzessschritte
 * @returns new index or -1
 */
export const getPreNextProzessschritt = (
  isNext: boolean,
  currentProzessschrittId: string,
  neededProzessschritte: Prozessschritt[]
): number => {
  let foundIndex: number = neededProzessschritte.findIndex(
    (prozessSchritt: Prozessschritt) =>
      prozessSchritt.id === currentProzessschrittId
  );
  // next
  if (isNext && foundIndex !== -1) {
    if (foundIndex + 1 < neededProzessschritte.length) {
      return foundIndex + 1;
    }
    // pre
  } else if (!isNext && foundIndex !== -1) {
    if (foundIndex - 1 >= 0) {
      return foundIndex - 1;
    }
  }
  // not found
  return -1;
};

/**
 * helper method to update position values of items
 * @param items
 * @tested
 */
export const updatePositionValues = (items: ProzessTaskItem[]): void => {
  for (let index = 0; index < items.length; index++) {
    items[index].position = index;
  }
};
