import { useKeycloak } from "@react-keycloak/web";
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { KeycloakUserContext, CompanyContext } from "../pages/App";
import { LicenseModuleType, UserRole } from "./Enums";
import { isModuleValidInLicense } from "./LicenseUtil";
import NotValidPage from "../pages/NotValidPage";

const PrivateRoute: React.FC<{
  Page: React.ComponentType<any>;
  path: string;
  licenseModule?: LicenseModuleType;
}> = ({ Page, path, licenseModule }) => {
  const { keycloakUser } = useContext(KeycloakUserContext);
  const { userCompany } = useContext(CompanyContext);
  const { initialized, keycloak } = useKeycloak();

  /**
   * @returns authenticated by keycloak
   */
  const checkIfUserIsAuthenticated = (): boolean => {
    return initialized && !!keycloak && !!keycloak.authenticated;
  };

  /**
   * Helper to determine if the user is allowed to open the page
   *
   * @returns if the called page is valid in license
   */
  const checkIfUserIsValidForModule = (): boolean => {
    if (!licenseModule) return true;
    return (
      keycloakUser?.userRole === UserRole.PLATFORMADMIN ||
      isModuleValidInLicense(licenseModule, userCompany.license)
    );
  };

  return (
    <>
      {checkIfUserIsAuthenticated() ? (
        <Route path={path} exact>
          {checkIfUserIsValidForModule() ? <Page /> : <NotValidPage />}
        </Route>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default PrivateRoute;
