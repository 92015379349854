import { TreeConfig, TreeItem } from "beelean-component-library";
import { LicenseModuleType, UserRole } from "./Enums";
import { Company } from "./Interfaces";
import { isModuleValidInLicense } from "./LicenseUtil";
import { History } from "history";
import { ProzessBereich } from "./LeanAdmin/LeanAdmin.types";
import { ProjektAkte } from "./LeanAdmin/ProjektAkte.types";
import i18n from "../i18n";

/**
 * Helper to configure the whole updateTree
 * It has to be in a subcomponent of the router because otherwise
 * you cant use useHitory
 *
 * @param role
 * @param companyId
 * @param allLoadedAdminCompanies
 * @param updateAdminTreeCallback
 * @param leanAdminProzessbereiche
 * @param userCompany
 * @param history
 */
export const updateTreeData = (
  role: UserRole,
  companyId: string,
  allLoadedAdminCompanies: Company[],
  updateAdminTreeCallback: Function,
  updatePdcaTreeCallback: Function,
  updateProjektAkteTreeCallback: Function,
  leanAdminProzessbereiche: ProzessBereich[],
  userCompany: Company,
  history: History,
  projektAkten: ProjektAkte[]
): void => {
  // update Lean Admin Tree Data if needed
  if (role === UserRole.PLATFORMADMIN) {
    // generate Lean Admin Tree Data for Admin/Platform
    updateAdminTreeCallback(
      generateLeanAdminTreeConfigForAdmin(
        leanAdminProzessbereiche,
        (treeItem: any) => {
          if (treeItem.level === 1) {
            // open with companyId
            history.push(`/administration/company`, {
              lastKey: treeItem.key,
              openNodes: treeItem.openNodes,
              companyId: (treeItem.key as string).replace(
                "lean-admin-root/",
                ""
              ),
            });
          } else if (treeItem.level === 2) {
            let localKeyItem: string = (treeItem.key as string).replace(
              "lean-admin-root/",
              ""
            );
            let localSplittedKeyItem: string[] = localKeyItem.split("/");
            // open with companyId and prozessBereichId
            history.push(`/administration/company/prozessmap`, {
              lastKey: treeItem.key,
              openNodes: treeItem.openNodes,
              companyId: localSplittedKeyItem[0],
              prozessBereichId: localSplittedKeyItem[1],
            });
          }
        },
        allLoadedAdminCompanies
      )
    );
    updatePdcaTreeCallback(
      generatePdcaTreeConfigForPlatformAdmins(allLoadedAdminCompanies, history)
    );
    updateProjektAkteTreeCallback(
      generateProjektAkteTreeConfigForPlatformAdmins(
        projektAkten,
        allLoadedAdminCompanies,
        history
      )
    );
    // load data only if the user is valid by license
  } else if (
    isModuleValidInLicense(
      LicenseModuleType.ADMINISTRATION,
      userCompany.license
    )
  ) {
    updateAdminTreeCallback(
      generateLeanAdminTreeConfigForUser(
        leanAdminProzessbereiche,
        (treeItem: any) => {
          if (treeItem.level === 0) {
            // open with companyId
            history.push(`/administration/company`, {
              lastKey: treeItem.key,
              openNodes: treeItem.openNodes,
              companyId: companyId,
            });
          } else if (treeItem.level === 1) {
            history.push(`/administration/company/prozessmap`, {
              lastKey: treeItem.key,
              openNodes: treeItem.openNodes,
              companyId: companyId,
              prozessBereichId: (treeItem.key as string).replace(
                "lean-admin-root/",
                ""
              ),
            });
          }
        }
      )
    );
    updatePdcaTreeCallback({
      title: i18n.t("tree.pdca"),
      nodes: [],
      onClick: () =>
        history.push("/administration/company/pdca", {
          lastKey: "pdca-root",
          openNodes: ["pdca-root"],
          companyId: companyId,
        }),
    } as TreeConfig);
    updateProjektAkteTreeCallback(
      generateProjektAkteTreeConfig(projektAkten, companyId, history)
    );
  }
};

/**
 * Helper to sort TreeItems by Name
 *
 * @param treeItems
 * @returns sorted Tree Items
 * @tested
 */
export const sortConvertedTreeItems = (treeItems: TreeItem[]): TreeItem[] => {
  return treeItems.sort((treeItemOne, treeItemTwo) =>
    treeItemOne.label.toUpperCase() < treeItemTwo.label.toUpperCase()
      ? -1
      : treeItemOne.label.toUpperCase() > treeItemTwo.label.toUpperCase()
      ? 1
      : 0
  );
};

/**
 * Helper to generate Lean Admin Tree
 *
 * @param prozessBereiche
 * @param changeFuntion
 * @returns
 */
export const generateLeanAdminTreeConfigForUser = (
  prozessBereiche: ProzessBereich[],
  changeFuntion: Function
): TreeConfig => {
  let convertedTreeItems: TreeItem[] = [];

  prozessBereiche.forEach((prozessBereich: ProzessBereich) => {
    convertedTreeItems.push({
      key: prozessBereich.id!,
      label: prozessBereich.bereich,
    });
  });

  convertedTreeItems = sortConvertedTreeItems(convertedTreeItems);

  return {
    nodes: convertedTreeItems,
    onClick: (treeItem: any) => changeFuntion(treeItem),
    title: i18n.t("tree.leanAdmin"),
  };
};

/**
 * Helper to generate Lean Admin Tree for an admin user
 *
 * @param prozessBereiche
 * @param changeFunction
 * @param loadedCompanies
 * @returns
 */
export const generateLeanAdminTreeConfigForAdmin = (
  prozessBereiche: ProzessBereich[],
  changeFunction: Function,
  loadedCompanies: Company[]
): TreeConfig => {
  let convertedTreeItems: TreeItem[] = [];

  loadedCompanies.forEach((company: Company) => {
    let companyTreeItems: TreeItem[] = [];

    prozessBereiche
      .filter((value: ProzessBereich) => value.companyId === company.id)
      .forEach((prozessBereich: ProzessBereich) => {
        companyTreeItems.push({
          key: prozessBereich.id!,
          label: prozessBereich.bereich,
        });
      });

    companyTreeItems = sortConvertedTreeItems(companyTreeItems);
    convertedTreeItems.push({
      key: company.id!,
      label: company.name,
      nodes: companyTreeItems,
    });
  });
  convertedTreeItems = sortConvertedTreeItems(convertedTreeItems);

  return {
    nodes: convertedTreeItems,
    onClick: (treeItem: any) => changeFunction(treeItem),
    title: i18n.t("tree.leanAdmin"),
  };
};

/**
 * Helper method to generate the pdca tree config for the plattform admin
 *
 * @param loadedCompanies All loaded company objects
 * @param history The current browser history
 * @returns The generated tree config
 * @tested
 */
export const generatePdcaTreeConfigForPlatformAdmins = (
  loadedCompanies: Company[],
  history: History
): TreeConfig => {
  let targetObject: TreeItem[] = [];
  loadedCompanies.forEach((company) => {
    targetObject.push({
      key: company.id!,
      label: company.name,
      nodes: [],
    });
  });
  return {
    nodes: targetObject,
    onClick: (props: any) => {
      if (props.level !== 0) {
        history.push(`/administration/company/pdca`, {
          lastKey: props.key,
          openNodes: props.openNodes,
          companyId: (props.key as string).replace("lean-admin-root/", ""),
        });
      }
    },
    hasSearch: false,
    title: i18n.t("tree.pdca"),
  };
};

/**
 * Generates the admin ProjektAkte Tree
 *
 * @param projektAkten
 * @param loadedCompanies
 * @param history
 * @returns TreeConfig
 */
const generateProjektAkteTreeConfigForPlatformAdmins = (
  projektAkten: ProjektAkte[],
  loadedCompanies: Company[],
  history: History
): TreeConfig => {
  let convertedTreeItems: TreeItem[] = [];

  loadedCompanies.forEach((company: Company) => {
    let companyTreeItems: TreeItem[] = [];

    projektAkten
      .filter((projektAkte) => projektAkte.companyId === company.id)
      .forEach((projektAkte) => {
        companyTreeItems.push({
          key: projektAkte.id!,
          label: projektAkte.title,
        });
      });

    companyTreeItems = sortConvertedTreeItems(companyTreeItems);
    convertedTreeItems.push({
      key: company.id!,
      label: company.name,
      nodes: companyTreeItems,
    });
  });
  convertedTreeItems = sortConvertedTreeItems(convertedTreeItems);

  return {
    title: i18n.t("tree.projectFile"),
    nodes: convertedTreeItems,
    onClick: (treeItem: any) => {
      if (treeItem.level === 1) {
        // open with companyId
        history.push("/akte", {
          lastKey: treeItem.key,
          openNodes: treeItem.openNodes,
          companyId: (treeItem.key as string).replace("projektakte-root/", ""),
        });
      } else if (treeItem.level === 2) {
        let localKeyItem: string = (treeItem.key as string).replace(
          "projektakte-root/",
          ""
        );
        let localSplittedKeyItem: string[] = localKeyItem.split("/");
        history.push("/akte/project", {
          lastKey: treeItem.key,
          openNodes: treeItem.openNodes,
          companyId: localSplittedKeyItem[0],
          procjectId: localSplittedKeyItem[1],
          projectLabel: treeItem.label,
        });
      }
    },
  };
};

/**
 * Generates the ProjektAkte Tree for a user
 *
 * @param projektAkten
 * @param history
 * @returns TreeConfig
 */
const generateProjektAkteTreeConfig = (
  projektAkten: ProjektAkte[],
  companyId: string,
  history: History
): TreeConfig => {
  let convertedTreeItems: TreeItem[] = [];

  projektAkten.forEach((projektAkte) => {
    convertedTreeItems.push({
      key: projektAkte.id!,
      label: projektAkte.title,
    });
  });

  convertedTreeItems = sortConvertedTreeItems(convertedTreeItems);

  return {
    nodes: convertedTreeItems,
    title: i18n.t("tree.projectFile"),
    onClick: (treeItem: any) => {
      if (treeItem.level === 0) {
        // open with companyId
        history.push("/akte", {
          lastKey: treeItem.key,
          openNodes: treeItem.openNodes,
          companyId: companyId,
        });
      } else if (treeItem.level === 1) {
        history.push("/akte/project", {
          lastKey: treeItem.key,
          openNodes: treeItem.openNodes,
          companyId: companyId,
          procjectId: (treeItem.key as string).replace(`projektakte-root/`, ""),
          projectLabel: treeItem.label,
        });
      }
    },
  };
};
