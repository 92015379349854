import { AxiosInstance } from "axios";
import i18next from "i18next";
import { generateNotification } from "../NotificationUtil";
import {
  keyForTheTotalAmountOfCheckedElements,
  ProzessMappingEntry,
  Prozesslinie,
  ProzesslinieType,
  Prozessschritt,
  ProzessTaskType,
  SipocChecklistEval,
  SipocChecklistEvalOutputData,
  Prozessschritttype,
} from "./LeanAdmin.types";
import {
  extractPreviousAndNextProzessSchritt,
  filterJustForNeededProzessschritte,
} from "./SipocEvalUtils";

/**
 * fetches all sipoc checklist by company and prozessbereich
 *
 * @param prozessbereichId
 * @param companyId
 * @param axios
 * @returns a list of SipocChecklistEval
 */
export const fetchAllSipocChecklistEval = async (
  prozessbereichId: string,
  companyId: string,
  axios: AxiosInstance
): Promise<SipocChecklistEval[]> => {
  return axios
    .get("/eval/sipocchecklist/company/", {
      params: { companyId: companyId, prozessBereichId: prozessbereichId },
    })
    .then((sipocChecklistList) => sipocChecklistList.data)
    .catch((error) => {
      console.error("Error during fetching sipocchecklist!", error);
      return [];
    });
};

/**
 * creates a new SipocChecklistEval on the server
 *
 * @param newSipocChecklist
 * @param axios
 * @returns the newly created SipocChecklistEval or the error statuscode
 */
export const createNewSipocChecklist = async (
  newSipocChecklist: SipocChecklistEval,
  axios: AxiosInstance
): Promise<SipocChecklistEval> => {
  return axios
    .post("/eval/sipocchecklist/", newSipocChecklist)
    .then((newCreatedSipocChecklist) => newCreatedSipocChecklist.data)
    .catch((error) => {
      console.error("Error during creating sipocchecklist!", error);
      generateNotification(
        i18next.t("notifications.projektakte.title"),
        i18next.t("notifications.SIPOC.checklist.errorAkteCreate", {
          status: error.response ? error.response.status : "-1",
        }),
        "danger",
        -1
      );
      return null;
    });
};

/**
 * deletes a SipocChecklistEval on the server by Id
 *
 * @param sipocChecklistId
 * @param axios
 * @returns status code
 */
export const deleteSipocChecklist = async (
  sipocChecklistId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .post("/eval/sipocchecklist/delete/", sipocChecklistId)
    .then((responseDeletion) => responseDeletion.status)
    .catch((error) => {
      console.error("Error during creating sipocchecklist!", error);
      return error.response.status;
    });
};

/**
 * updates a SipocChecklistEval on the server
 *
 * @param updatedSipocChecklist
 * @param axios
 * @returns the created status code 200 if successfully
 */
export const updateSipocChecklist = async (
  updatedSipocChecklist: SipocChecklistEval,
  axios: AxiosInstance
): Promise<SipocChecklistEval> => {
  return axios
    .post("/eval/sipocchecklist/update", updatedSipocChecklist)
    .then((newCreatedSipocChecklist) => newCreatedSipocChecklist.status)
    .catch((error) => {
      console.error("Error during update sipocchecklist!", error);
      return error.response.status;
    });
};

/**
 * Helper to fetch Sipoc Checklist for a ProjektAkte
 *
 * @param companyId
 * @param projektAkteId
 * @param axios
 * @returns found Checklist or null
 */
export const fetchSipocChecklistByProjektAkteId = async (
  companyId: string,
  projektAkteId: string,
  axios: AxiosInstance
): Promise<SipocChecklistEval> => {
  return axios
    .get("/eval/sipocchecklist/akte/", {
      params: { companyId: companyId, projektAkteId: projektAkteId },
    })
    .then((loadedSipocCheckList) => loadedSipocCheckList.data)
    .catch((error) => {
      console.error("Error during fetching sipocchecklist!", error);
      generateNotification(
        i18next.t("notifications.projektakte.title"),
        i18next.t("notifications.SIPOC.checklist.errorAkteFetch", {
          status: error.response ? error.response.status : "-1",
        }),
        "danger",
        -1
      );
      return null;
    });
};

/**
 * calculates the amount of checked elements for a Prozessschritt and a total
 *
 * @param prozessMappingEntryResponse
 * @param noInputs doesn't count input, except if it is tpe KUNDEN
 * @returns a map of id => true counts and a total
 * @tested
 */
export const generateProzessschrittIdAndNeededCheckedElementsAmount = (
  prozessMappingEntryResponse: ProzessMappingEntry,
  noInputs?: boolean
): Map<string, number> => {
  let neededProzessschritte: Prozessschritt[] =
    filterJustForNeededProzessschritte(
      prozessMappingEntryResponse.prozessSchritte
    );
  let neededProzesslinien: Prozesslinie[] =
    prozessMappingEntryResponse.prozessLinie.filter(
      (prozessLinie: Prozesslinie) =>
        prozessLinie.type !== ProzesslinieType.QUALITAETSMANGEL &&
        prozessLinie.type !== ProzesslinieType.RUECKFRAGE
    );

  // count how much checked elements are needed for a role
  let targetMap: Map<string, number> = new Map<string, number>();
  let totalAmountOfNeededCheckedElements: number = 0;
  neededProzessschritte.forEach((currentProzessschritt: Prozessschritt) => {
    let currentNeededCheckedElements: number =
      currentProzessschritt.process.items.length;

    let filteredProzessschrittePreAndNext: Prozessschritt[][] =
      extractPreviousAndNextProzessSchritt(
        currentProzessschritt,
        neededProzesslinien,
        prozessMappingEntryResponse.prozessSchritte
      );
    if (noInputs)
      filteredProzessschrittePreAndNext[0].forEach(
        (prozessSchritt: Prozessschritt) => {
          if (prozessSchritt.type === Prozessschritttype.KUNDEN)
            currentNeededCheckedElements++;
        }
      );
    else
      currentNeededCheckedElements =
        currentNeededCheckedElements +
        filteredProzessschrittePreAndNext[0].length;

    currentNeededCheckedElements =
      currentNeededCheckedElements +
      filteredProzessschrittePreAndNext[1].length;

    targetMap.set(currentProzessschritt.id, currentNeededCheckedElements);
    totalAmountOfNeededCheckedElements =
      totalAmountOfNeededCheckedElements + currentNeededCheckedElements;
  });
  targetMap.set(
    keyForTheTotalAmountOfCheckedElements,
    totalAmountOfNeededCheckedElements
  );
  return targetMap;
};

/**
 * updates or creates a SipocChecklistEvalEntry in the given SipocChecklistEval
 *
 * @param checklist
 * @param id id of entry to change
 * @param checked if empty, value will be toggled
 * @fileList optional, won't be changed if empty
 * @outputValues optional, won't be changed if empty
 * @returns the updated SipocChecklistEval
 * @tested
 */
export const updateCheckedElementByIdInChecklist = (
  checklist: SipocChecklistEval,
  id: string,
  checked?: boolean,
  fileList?: string[],
  outputValues?: SipocChecklistEvalOutputData[]
): SipocChecklistEval => {
  let localCopyOfChecklist: SipocChecklistEval = checklist;
  let foundIndex: number = localCopyOfChecklist.checklistItems.findIndex(
    (currentChecklist) => currentChecklist.id === id
  );
  if (foundIndex === -1)
    localCopyOfChecklist.checklistItems.push({
      id: id,
      checked: checked === undefined ? true : checked!,
      fileList: fileList || [],
      outputValues: outputValues || [],
    });
  else {
    localCopyOfChecklist.checklistItems[foundIndex].checked =
      checked === undefined
        ? !localCopyOfChecklist.checklistItems[foundIndex].checked
        : checked!;

    if (fileList)
      localCopyOfChecklist.checklistItems[foundIndex].fileList = fileList;

    if (outputValues)
      localCopyOfChecklist.checklistItems[foundIndex].outputValues =
        outputValues;
  }

  return localCopyOfChecklist;
};

/**
 * creates a id by given information for a checkbox in a Sipoc detail view line
 *
 * @param type
 * @param index
 * @param detailViewProzessSchritt
 * @param prozessSchritt
 * @returns created Id as string
 * @tested
 */
export const generateIdForCheckbox = (
  type: ProzessTaskType,
  index: number,
  detailViewProzessSchritt: Prozessschritt,
  prozessSchritt?: Prozessschritt
): string => {
  if (prozessSchritt)
    return `${detailViewProzessSchritt.id}_${type}_${prozessSchritt.id}`;
  else return `${detailViewProzessSchritt.id}_${type}_${index}`;
};

/**
 * search for a specific id in SipocChecklistEval items and returns the value
 *
 * @param checklist
 * @param id
 * @returns found state in SipocChecklistEval
 * @tested
 */
export const checkIfSpecificIdIsTrue = (
  checklist: SipocChecklistEval,
  id: string
): boolean => {
  let foundIndex: number = checklist.checklistItems.findIndex(
    (currentChecklist) => currentChecklist.id === id
  );
  if (foundIndex === -1) return false;
  else return checklist.checklistItems[foundIndex].checked;
};

/**
 * calculates the amount of found checked elements for a prozesschrittId
 *
 * @param checklist
 * @param prozessSchrittId
 * @returns number of found checked elements for prozessschrittId
 * @tested
 */
export const getAmountOfCheckedElementsForProzessschritt = (
  checklist: SipocChecklistEval,
  prozessSchrittId: string,
  prozessSchritte: Prozessschritt[],
  countInput?: boolean
): number => {
  let foundCheckedElements: number = 0;
  checklist.checklistItems.forEach((currentChecklist) => {
    let checklistIdParts: string[] = currentChecklist.id?.split("_") || [];
    let inputCondition: boolean;
    if (countInput) {
      inputCondition = true;
    } else {
      inputCondition =
        checklistIdParts[1] !== ProzessTaskType.INPUT ||
        isProzessSchrittKunde(checklistIdParts[2], prozessSchritte);
    }
    if (
      checklistIdParts[0] === prozessSchrittId &&
      inputCondition &&
      currentChecklist.checked
    )
      foundCheckedElements = foundCheckedElements + 1;
  });
  return foundCheckedElements;
};

/**
 * returns total amount of checklist elements for given prozessMapping
 *
 * @param prozessMapping
 * @returns number of found elements
 * @tested
 */
export const getTotalAmountOfElementsToCheck = (
  prozessMapping: ProzessMappingEntry
): number => {
  // fetch for ProzessMapping Prozessschritte
  let totalAmountOfElements: number = 0;
  let checkedElementsMap: Map<string, number> =
    generateProzessschrittIdAndNeededCheckedElementsAmount(
      prozessMapping!,
      true
    );
  totalAmountOfElements = checkedElementsMap.has(
    keyForTheTotalAmountOfCheckedElements
  )
    ? checkedElementsMap.get(keyForTheTotalAmountOfCheckedElements)!
    : 0;
  return totalAmountOfElements;
};

/**
 * checks if prozessSchritt has Prozessschritttype KUNDEN
 *
 * @param prozessSchrittId
 * @param prozessSchritte
 * @returns number of found elements
 * @tested
 */
export const isProzessSchrittKunde = (
  prozessSchrittId: string,
  prozessSchritte: Prozessschritt[]
): boolean => {
  let neededProzessSchrittIndex: number = prozessSchritte.findIndex(
    (prozessSchritt: Prozessschritt) => prozessSchritt.id === prozessSchrittId
  );
  if (neededProzessSchrittIndex === -1) return false;
  return (
    prozessSchritte[neededProzessSchrittIndex].type ===
    Prozessschritttype.KUNDEN
  );
};

/**
 * calculates the amount of found checked elements for the prozessSchritte
 *
 * @param checklist
 * @param prozessSchritte
 * @returns number of found checked elements for prozessschrittId
 * @tested
 */
export const getAmountOfCheckedElementsForProzessschritte = (
  checklist: SipocChecklistEval,
  prozessSchritte: Prozessschritt[]
): number => {
  let foundCheckedElements: number = 0;
  checklist.checklistItems.forEach((currentChecklist) => {
    let checklistIdParts: string[] = currentChecklist.id?.split("_") || [];
    if (
      (checklistIdParts[1] !== ProzessTaskType.INPUT ||
        isProzessSchrittKunde(checklistIdParts[2], prozessSchritte)) &&
      currentChecklist.checked
    )
      foundCheckedElements = foundCheckedElements + 1;
  });
  return foundCheckedElements;
};

/**
 * checks if input is completed for prozessSchritte that arent extern
 *
 * @param checklist
 * @param prozessSchritte
 * @returns
 * @tested
 */
export const isInputComplete = (
  prozessSchritt: Prozessschritt,
  prozesslinien: Prozesslinie[],
  sipocChecklist: SipocChecklistEval
): boolean => {
  if (prozessSchritt.type === Prozessschritttype.EXTERN) return false;
  let relevantProzesslinien: Prozesslinie[] = prozesslinien.filter(
    (prozesslinie) =>
      prozesslinie.stopProcessId === prozessSchritt.id ||
      prozesslinie.stopProcessId === prozessSchritt.bestandLink
  );

  let foundCheckedElements: number = 0;
  sipocChecklist.checklistItems.forEach((currentChecklist) => {
    let checklistIdParts: string[] = currentChecklist.id?.split("_") || [];
    if (
      checklistIdParts[0] === prozessSchritt.id &&
      checklistIdParts[1] === ProzessTaskType.INPUT &&
      currentChecklist.checked
    )
      foundCheckedElements = foundCheckedElements + 1;
  });
  return relevantProzesslinien.length === foundCheckedElements;
};
