import {
  ButtonComponent,
  DropdownComponent,
  InputComponent,
  LoaderComponent,
  Option,
} from "beelean-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { ReactComponent as DumpsterIcon } from "../../../assets/icons/dumpster.svg";
import { useAxios } from "../../../utils/AxiosUtil";
import {
  ProzessTaskType,
  Prozessschritt,
  Prozessschritttype,
  SipocOutputConfigurationEntry,
  createEmptyProzess,
  createEmptySipocOutputConfigurationEntry,
} from "../../../utils/LeanAdmin/LeanAdmin.types";
import {
  buildTypeSelectOptions,
  checkIsFileTooBig,
  deleteOutputFile,
  download,
  getFileNameWithoutId,
  uploadOutputFile,
} from "../../../utils/LeanAdmin/SipocOutputConfigurationUtils";
import "./SipocOutputConfiguration.scss";
import { SipocOutputConfigurationProps } from "./SipocOutputConfiguration.types";

const SipocOutputConfiguration: React.FC<SipocOutputConfigurationProps> = ({
  localProzessSchritt,
  updateLocalProzessschritt,
  companyId,
  detailViewProzessSchritt,
  type,
  isViewerState,
  prozessBereichId,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loaderText, setLoaderText] = useState<string>("");
  const [outputConfigurationEntry, setOutputConfigurationEntry] =
    useState<SipocOutputConfigurationEntry>(
      createEmptySipocOutputConfigurationEntry(
        localProzessSchritt.id,
        detailViewProzessSchritt.id
      )
    );
  const [typeSelectOptions, setTypeSelectOptions] = useState<Option[]>([]);
  const [editable, setEditable] = useState<boolean>(false);
  const [outputProzessSchritt, setOutputProzessSchritt] =
    useState<Prozessschritt>(
      createEmptyProzess("", Prozessschritttype.PROCESS, 0)
    );

  const [relativeProzessSchrittId, setRelativeProzessSchrittId] =
    useState<string>("");

  /**
   * output config is allowed for all outputs, but also for inputs of type "KUNDEN" or "EXTERN"
   */
  const allowOutputConfig: boolean =
    type === ProzessTaskType.OUTPUT ||
    localProzessSchritt.type === Prozessschritttype.KUNDEN ||
    localProzessSchritt.type === Prozessschritttype.EXTERN;

  //adds new configuration to the local ProzessSchritt and
  //initializes new SipocOutputConfigurationEntry
  const submitNewConfigurationEntry = (): void => {
    if (!outputConfigurationEntry.title) return;
    let currentOutputConfiguratoin: SipocOutputConfigurationEntry[] =
      localProzessSchritt.outputConfig;
    currentOutputConfiguratoin.push(outputConfigurationEntry);
    updateLocalProzessschritt({
      ...localProzessSchritt,
      outputConfig: currentOutputConfiguratoin,
    });
    setOutputConfigurationEntry({
      ...createEmptySipocOutputConfigurationEntry(
        localProzessSchritt.id,
        detailViewProzessSchritt.id
      ),
      type: typeSelectOptions[0].value,
    });
  };

  //deletes output file locally and in backend
  const locallyDeleteOutputFile = (fileName: string): void => {
    setLoaderText(t("modules.SIPOC.outputConfiguration.loadingTexts.deleting"));
    setIsLoading(true);
    deleteOutputFile(fileName, companyId, axios, prozessBereichId).then(
      (success: boolean) => {
        if (!success) {
          setIsLoading(false);
          return;
        }
        let currentFileStrings: string[] = localProzessSchritt.outputFileList;
        currentFileStrings = localProzessSchritt.outputFileList.filter(
          (localFile) => localFile !== fileName
        );
        updateLocalProzessschritt({
          ...localProzessSchritt,
          outputFileList: currentFileStrings,
        });
        setIsLoading(false);
      }
    );
  };

  //uploads output file locally and in backend
  const locallyUploadOutputFile = (incomingFile: File): void => {
    if (checkIsFileTooBig(incomingFile.size)) return;
    setLoaderText(
      t("modules.SIPOC.outputConfiguration.loadingTexts.uploading")
    );
    setIsLoading(true);
    uploadOutputFile(incomingFile, companyId, axios).then(
      (fileName: string) => {
        if (!fileName) {
          setIsLoading(false);
          return;
        }
        let currentFileStrings: string[] = localProzessSchritt.outputFileList;
        currentFileStrings.push(detailViewProzessSchritt.id + "_" + fileName);
        updateLocalProzessschritt({
          ...localProzessSchritt,
          outputFileList: currentFileStrings,
        });
        setIsLoading(false);
      }
    );
  };

  //deletes SipocOutputConfigurationEntry
  const deleteEntryInOutputConfiguration = (entryId: string): void => {
    let currentOutputConfiguratoin: SipocOutputConfigurationEntry[] =
      localProzessSchritt.outputConfig;
    currentOutputConfiguratoin = currentOutputConfiguratoin.filter(
      (localEntry) => localEntry.id !== entryId
    );
    updateLocalProzessschritt({
      ...localProzessSchritt,
      outputConfig: currentOutputConfiguratoin,
    });
  };

  //initially builds and presets select options for config type
  useEffect(() => {
    let newTypeSelectOptions: Option[] = buildTypeSelectOptions();
    setTypeSelectOptions(newTypeSelectOptions);
    setOutputConfigurationEntry({
      ...outputConfigurationEntry,
      type: newTypeSelectOptions[0].value,
    });
    setEditable(!isViewerState && allowOutputConfig);
    if (allowOutputConfig) {
      setOutputProzessSchritt(localProzessSchritt);
      setRelativeProzessSchrittId(detailViewProzessSchritt.id);
    } else if (type === ProzessTaskType.INPUT) {
      setOutputProzessSchritt(detailViewProzessSchritt);
      setRelativeProzessSchrittId(localProzessSchritt.id);
    }
    // eslint-disable-next-line
  }, []);

  //updates outputProzessschritt outputConfig and outputFileList according to changes
  useEffect(() => {
    if (!allowOutputConfig) return;
    setOutputProzessSchritt(localProzessSchritt);
    // eslint-disable-next-line
  }, [
    localProzessSchritt.outputConfig.length,
    localProzessSchritt.outputFileList.length,
  ]);

  return (
    <div>
      {editable && (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitNewConfigurationEntry();
          }}
        >
          <DropdownComponent
            required
            options={typeSelectOptions}
            onChange={(selectedOption) => {
              setOutputConfigurationEntry({
                ...outputConfigurationEntry,
                type: selectedOption,
              });
            }}
            placeholder={t("modules.SIPOC.outputConfiguration.type")}
            selectedOption={
              typeSelectOptions.find(
                (localentry) =>
                  localentry.value === outputConfigurationEntry.type
              )!
            }
          />
          <InputComponent
            required
            onChange={(text: string) => {
              setOutputConfigurationEntry({
                ...outputConfigurationEntry,
                title: text,
              });
            }}
            value={outputConfigurationEntry.title}
            placeholder={t("modules.SIPOC.outputConfiguration.title")}
            type="text"
          />
          <ButtonComponent title={t("general.buttons.add")} type="submit" />
        </form>
      )}

      <div className="sipoc-configuration-list-title">
        <p>{t("modules.SIPOC.outputConfiguration.files")}</p>
      </div>
      <ul className="sipoc-configuration-list">
        {outputProzessSchritt.outputFileList
          .filter(
            (fileName: string) =>
              fileName.split("_")[0] === relativeProzessSchrittId
          )
          .map((fileName: string) => (
            <li key={`sipoc-output-file-${fileName}`}>
              <div>{getFileNameWithoutId(fileName, false)}</div>
              <div
                className={"sipoc-configuration-download"}
                onClick={() => {
                  setLoaderText(
                    t(
                      "modules.SIPOC.outputConfiguration.loadingTexts.downloading"
                    )
                  );
                  setIsLoading(true);
                  download(fileName, companyId, axios).then(() =>
                    setIsLoading(false)
                  );
                }}
              >
                <DownloadIcon />
              </div>
              {editable && (
                <div
                  onClick={() => {
                    locallyDeleteOutputFile(fileName);
                  }}
                  title={t("general.buttons.delete")}
                  className="sipoc-configuration-remove"
                >
                  <DumpsterIcon />
                </div>
              )}
            </li>
          ))}
      </ul>
      {editable && (
        <ButtonComponent
          acceptedFiles="*"
          isFileUpload
          setFiles={(incomingFiles: File[]) => {
            locallyUploadOutputFile(incomingFiles[0]);
          }}
          title={t("modules.SIPOC.outputConfiguration.addFile")}
        />
      )}
      <div className="sipoc-configuration-list-title">
        <p>{t("modules.SIPOC.outputConfiguration.entries")}</p>
      </div>
      <ul className="sipoc-configuration-list">
        {outputProzessSchritt.outputConfig
          .filter(
            (outputConfig: SipocOutputConfigurationEntry) =>
              outputConfig.id.split("_")[0] === relativeProzessSchrittId
          )
          .map((entry: SipocOutputConfigurationEntry) => (
            <li key={`sipoc-configuration-entry-${entry.id}`}>
              <div>{`${entry.title} (${t(
                "modules.SIPOC.outputConfiguration.sipocOutputType." +
                  entry.type
              )})`}</div>
              {editable && (
                <div
                  onClick={() => deleteEntryInOutputConfiguration(entry.id)}
                  title={t("general.buttons.delete")}
                  className="sipoc-configuration-remove"
                >
                  <DumpsterIcon />
                </div>
              )}
            </li>
          ))}
      </ul>
      {isLoading && <LoaderComponent inFront showText text={loaderText} />}
    </div>
  );
};

export default SipocOutputConfiguration;
