import { BoxComponent, ButtonComponent } from "beelean-component-library";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAxios } from "../../utils/AxiosUtil";
import "./AccountListStyle.scss";
import { ReactComponent as UploadIcon } from "../../assets/icons/upload.svg";
import { CompanyContext } from "../../pages/App";
import {
  getCurrentCompanyLogo,
  uploadNewCompanyLogo,
  updateNewCompanyLogo,
} from "../../utils/CompanyUtil";
import { generateNotification } from "../../utils/NotificationUtil";

const LogoAdministration: React.FC<{}> = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const reader = new FileReader();
  const [files, setFiles] = useState<File[]>([]);
  const [base64ImageString, setBase64ImageString] = useState<string>("");
  const [initalImage, setInitalImage] = useState<string>("");
  const { userCompany, setUserCompany } = useContext(CompanyContext);
  /**
   * Updates the image in the wrapper locally
   */
  useEffect(() => {
    if (files.length === 1) {
      reader.readAsDataURL(files[0]);
      reader.onload = () => setBase64ImageString(reader.result as string);
    }
    // eslint-disable-next-line
  }, [files]);

  /**
   * updates the image on the server
   */
  useEffect(() => {
    if (!base64ImageString) return;
    if (!!initalImage) {
      updateNewCompanyLogo(
        axios,
        userCompany.id!,
        files[0],
        userCompany.logoName
      ).then((newFileName) => {
        // update the new file name locally server is saving it in the comapny object
        if (!!newFileName)
          setUserCompany({ ...userCompany, logoName: newFileName });
        else
          generateNotification(
            t("notifications.logoAdministration.title"),
            t("notifications.logoAdministration.errorUpdate"),
            "danger",
            -1
          );
        setBase64ImageString("");
      });
    } else {
      uploadNewCompanyLogo(axios, userCompany.id!, files[0]).then(
        (newFileName) => {
          // update the new file name locally server is saving it in the comapny object
          if (!!newFileName)
            setUserCompany({ ...userCompany, logoName: newFileName });
          else
            generateNotification(
              t("notifications.logoAdministration.title"),
              t("notifications.logoAdministration.errorUpdate"),
              "danger",
              -1
            );
          setBase64ImageString("");
        }
      );
    }

    // eslint-disable-next-line
  }, [base64ImageString]);

  /**
   * initally updates the image if something is present
   */
  useEffect(() => {
    if (!!axios && userCompany.logoName && userCompany.id) {
      getCurrentCompanyLogo(userCompany.logoName, axios).then(
        (logoResponse) => {
          if (logoResponse) {
            reader.readAsDataURL(logoResponse);
            reader.onload = () => setInitalImage(reader.result as string);
          } else
            generateNotification(
              t("notifications.logoAdministration.title"),
              t("notifications.logoAdministration.errorFetch"),
              "danger",
              -1
            );
        }
      );
    }
    // eslint-disable-next-line
  }, [axios, userCompany]);

  return (
    <BoxComponent
      subtext={
        <p>
          <Trans i18nKey="modules.logo.subTitle">
            Hier können Sie Ihr Firmenlogo verändern
          </Trans>
        </p>
      }
      title={t("modules.logo.title")}
      icon={<UploadIcon />}
    >
      <div className="logo-administration-image-wrapper">
        {!!base64ImageString ? (
          <img src={base64ImageString} alt={`${userCompany.name} Logo`} />
        ) : !!initalImage ? (
          <img src={initalImage} alt={`${userCompany.name} Logo`} />
        ) : (
          <UploadIcon />
        )}
      </div>
      <ButtonComponent
        acceptedFiles="image/png,image/jpeg"
        isFileUpload
        setFiles={setFiles}
        title={t("general.buttons.upload")}
      />
    </BoxComponent>
  );
};

export default LogoAdministration;
