import React from "react";
import { Text, Tag, Label } from "react-konva";

interface ProzessschrittTooltipProps {
  text: string;
  align?: "center" | "left" | "right";
  coords: number[];
  pointerDirection: "up" | "down";
}

const ProzessschrittTooltip: React.FC<ProzessschrittTooltipProps> = ({
  text,
  align,
  coords,
  pointerDirection,
}) => {
  return (
    <>
      {text !== "" && (
        <Label x={coords[0]} y={coords[1]}>
          <Tag
            pointerDirection={pointerDirection}
            cornerRadius={3}
            pointerWidth={10}
            pointerHeight={10}
            fill={"#00000084"}
          />
          <Text
            fill={"white"}
            align={align ? align : "center"}
            width={80}
            padding={5}
            text={text}
          />
        </Label>
      )}
    </>
  );
};

export default ProzessschrittTooltip;
