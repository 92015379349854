import { AxiosInstance } from "axios";
import { Option, TableRow } from "beelean-component-library";
import i18next from "i18next";
import {
  quarterCirle0,
  quarterCirle1,
  quarterCirle2,
  quarterCirle3,
  quarterCirle4,
} from "../../assets/base64PdcaCircles";
import { User } from "../Interfaces";
import {
  ProzessMappingEntry,
  ProzessBereich,
  Prozessschritt,
  Prozessschritttype,
} from "../LeanAdmin/LeanAdmin.types";
import { ProjektAkte } from "../LeanAdmin/ProjektAkte.types";
import { generateNotification } from "../NotificationUtil";
import { createNewPdcaFromProzessMappingEntry, Pdca } from "./Pdca.types";

/**
 * util method to update the given ProzessMappingEntry in case a PDCA entry
 * has to be created
 *
 * @param kaizenProzessSchritt The actual kaizen
 * @param prozessMapping The prozessmapping the kaizen belongs to
 * @param axios The axios instance
 * @param currentUser The current user as "Firstname Lastname"
 * @returns The updated ProzessMappingEntry
 */
export const handlePdcaCreationForKaizen = (
  kaizenProzessSchritt: Prozessschritt,
  prozessMapping: ProzessMappingEntry,
  axios: AxiosInstance,
  currentUser: string
): ProzessMappingEntry => {
  let localCopy: ProzessMappingEntry = prozessMapping;
  if (
    kaizenProzessSchritt.type === Prozessschritttype.KAIZEN &&
    !kaizenProzessSchritt.createdPdca
  ) {
    let currentProzessIndex: number = localCopy.prozessSchritte.findIndex(
      (prozess) => prozess.id === kaizenProzessSchritt.id
    );
    // a PDCA entry has only to be created if the KAIZEN has a description
    if (
      currentProzessIndex > -1 &&
      !!localCopy.prozessSchritte[currentProzessIndex].beschreibung
    ) {
      localCopy.prozessSchritte[currentProzessIndex].createdPdca = true;
      createPdcaEntry(
        createNewPdcaFromProzessMappingEntry(
          prozessMapping,
          localCopy.prozessSchritte[currentProzessIndex].beschreibung!,
          currentUser
        ),
        axios
      );
    }
  }
  return localCopy;
};

/**
 * API method to create a PDCA entry on the backend
 *
 * @param pdcaEntry The PDCA entry to create
 * @param axios The axios instance
 * @returns Promise of the created PDCA
 */
export const createPdcaEntry = async (
  pdcaEntry: Pdca,
  axios: AxiosInstance
): Promise<Pdca> => {
  return axios
    .post("/data/pdca/admin/", pdcaEntry)
    .then((resp) => {
      generateNotification(
        i18next.t("notifications.pdca.title"),
        i18next.t("notifications.pdca.successCreate"),
        "success"
      );
      return resp.data;
    })
    .catch((exc) => {
      console.error("Error during admin PDCA entry creation", exc);
      generateNotification(
        i18next.t("notifications.pdca.title"),
        i18next.t("notifications.pdca.errorCreate", {
          status: exc.response.status,
        }),
        "danger",
        -1
      );
    });
};

/**
 * API method to update a PDCA entry on the backend
 *
 * @param updatedPdca The PDCA entry to update
 * @param axios The axios instance
 * @returns void Promise
 */
export const updatePdcaEntry = async (
  updatedPdca: Pdca,
  axios: AxiosInstance
): Promise<void> => {
  axios.post("/data/pdca/admin/update/", updatedPdca).catch((exc) => {
    console.error("Error during admin PDCA update", exc);
    generateNotification(
      i18next.t("notifications.pdca.title"),
      i18next.t("notifications.pdca.errorUpdate", {
        status: exc.response.status,
      }),
      "danger",
      -1
    );
  });
};

/**
 * API method to delete a PDCA entry on the backend
 *
 * @param pdcaToDelete The PDCA entry to delete
 * @param axios The axios instance
 * @returns void Promise
 */
export const deletePdcaEntry = async (
  pdcaToDelete: Pdca,
  axios: AxiosInstance
): Promise<void> => {
  axios.post("/data/pdca/admin/delete/", pdcaToDelete).catch((exc) => {
    console.error("Error during admin PDCA deletion", exc);
    generateNotification(
      i18next.t("notifications.pdca.title"),
      i18next.t("notifications.pdca.errorDeletion", {
        name: pdcaToDelete.prozessBereich,
        status: exc.response.status,
      }),
      "danger",
      -1
    );
  });
};

/**
 * API method to load all PDCA entries for a company
 *
 * @param axios The axios instance
 * @param companyId The id of the company
 * @returns Promise of all loaded PDCAs
 */
export const loadPdcasForCompany = async (
  axios: AxiosInstance,
  companyId: String
): Promise<Pdca[]> => {
  return axios
    .get("/data/pdca/admin/company/", {
      params: { companyId: companyId.replace("pdca-root/", "") },
    })
    .then((serverResp) => serverResp.data)
    .catch((exc) => {
      console.error("Error during admin PDCA fetch", exc);
      generateNotification(
        i18next.t("notifications.pdca.title"),
        i18next.t("notifications.pdca.errorLoad", {
          status: exc.response.status,
        }),
        "danger",
        -1
      );
      return [];
    });
};

/**
 * Helper to determine if a required information in pdca is missing
 *
 * @param pdca
 * @returns if an information is missing
 * @tested
 */
export const allRequiredPdcaFieldsAreFilled = (pdca: Pdca): boolean => {
  return (
    !!pdca.prozessBereich &&
    !!pdca.description &&
    !!pdca.actions &&
    !!pdca.targetDate &&
    !!pdca.responsibleUser &&
    !!pdca.responsibleRole
  );
};

/**
 * Helper to find the correct name
 *
 * @param projektAkten
 * @param prozessBereiche
 * @param id
 * @returns found name of Projektakte or Prozessbereich
 */
const findCorrectNameForPdcaAdminEntryFromProjektakteOrProzessbereich = (
  projektAkten: ProjektAkte[],
  prozessBereiche: ProzessBereich[],
  id: string
): string => {
  return (
    prozessBereiche.find((bereich) => bereich.id === id)?.bereich ||
    projektAkten.find((akte) => akte.id === id)?.title ||
    ""
  );
};

/**
 * Helper to fetch the correct name for a role in a prozessbereich
 * @param prozessBereiche all loaded prozessbereiche as simple
 * @param pdca current working pdca
 * @returns found name or empty string
 */
export const getCorrectNameForPdcaResponsibleId = (
  prozessBereiche: ProzessBereich[],
  pdca: Pdca
): string => {
  let responseName: string = "";
  let foundIndex: number = prozessBereiche.findIndex(
    (item) => item.id === pdca.prozessBereich
  );
  if (foundIndex !== -1) {
    if (prozessBereiche[foundIndex].version! >= 2) {
      let foundIndexRole: number = prozessBereiche[
        foundIndex
      ].bereichRoles.findIndex((item) => item.id === pdca.responsibleRole);
      if (foundIndexRole !== -1) {
        responseName =
          prozessBereiche[foundIndex].bereichRoles[foundIndexRole].name;
      }
    } else responseName = pdca.responsibleRole || "";
  }
  return responseName;
};

/**
 * Util method to create the tableRows needed to display all loaded
 * PDCAs for a company
 *
 * @param loadedPdcas All loaded PDCA entries
 * @param prozessBereiche The prozessBereiche
 * @param companyUser All user of the company
 * @param onClick The callback function for the row onlick
 * @returns Array of all TableRows
 */
export const createPdcaTableEntriesFromPdcas = (
  loadedPdcas: Pdca[],
  projektAkten: ProjektAkte[],
  prozessBereiche: ProzessBereich[],
  companyUser: User[],
  onClick: Function
): TableRow[] => {
  let targetObject: TableRow[] = [];
  loadedPdcas.forEach((pdca) => {
    const allRequiredInfosAreThere: boolean =
      allRequiredPdcaFieldsAreFilled(pdca);
    const isFollowUpDateOk: boolean = !(
      new Date(pdca.followUpDate!) < new Date() && pdca.status !== 4
    );
    const isTargetDateOk: boolean = !(
      new Date(pdca.targetDate!) < new Date() && pdca.status !== 4
    );

    const respUser: User | undefined = companyUser.find(
      (user) => user.id === pdca.responsibleUser
    );

    targetObject.push({
      content: [
        <p>
          {findCorrectNameForPdcaAdminEntryFromProjektakteOrProzessbereich(
            projektAkten,
            prozessBereiche,
            pdca.prozessBereich || ""
          )}
        </p>,
        <p>{pdca.description}</p>,
        <p>{pdca.actions}</p>,
        <p>{new Date(pdca.createDate).toLocaleDateString("de-DE")}</p>,
        <p>{pdca.createdBy}</p>,
        <p className={isFollowUpDateOk ? undefined : "pdca-table-missing-info"}>
          {pdca.followUpDate
            ? new Date(pdca.followUpDate).toLocaleDateString("de-DE")
            : undefined}
        </p>,
        <p className={isTargetDateOk ? undefined : "pdca-table-missing-info"}>
          {pdca.targetDate
            ? new Date(pdca.targetDate).toLocaleDateString("de-DE")
            : undefined}
        </p>,
        createQuarterCircle(pdca.status!),
        <p>{respUser ? `${respUser.firstname} ${respUser.lastname}` : ""}</p>,
        <p>{getCorrectNameForPdcaResponsibleId(prozessBereiche, pdca)}</p>,
        <p>{pdca.prio}</p>,
      ],
      id: pdca.id,
      onClick: (tableRow: TableRow) => onClick(tableRow),
      rowClassName: allRequiredInfosAreThere
        ? undefined
        : "pdca-table-missing-info",
    });
  });
  return targetObject;
};

/**
 * Helper method to generate a SVG of the filled quarter circle
 *
 * @param filledQuarters Amount of quarters to be filled
 * @returns A SVG tag with the filled quarter circle
 */
export const createQuarterCircle = (
  filledQuarters: number
): React.ReactNode => {
  let fillPath: string = "";
  // the cast is needed because after an update the number is given as string
  switch (Number(filledQuarters)) {
    case 1:
      fillPath = "M15,15 L15,5 A10,10 1 0,1 25,15 z";
      break;
    case 2:
      fillPath = "M15,15 L15,5 A10,10 1 0,1 15,25 z";
      break;
    case 3:
      fillPath = "M15,15 L15,5 A10,10 1 0,1 15,25 A10,10 1 0,1 5,15 z";
      break;
    case 4:
      fillPath = "M15,15 L15,5 A10,10 1 0,1 15,25 A10,10 1 0,1 15,5 z";
  }
  return (
    <svg
      className={["pdca-quartered-circle", `status-${filledQuarters}`].join(
        " "
      )}
    >
      <circle cx="15" cy="15" r="10" />
      <path d={fillPath} />
    </svg>
  );
};

/**
 * Helper to convert the pdca table text into an strign array array
 *
 * The structure is as follows:
 *   rowWrapper: [[row],[row]]
 *
 * @param tableEntries
 * @returns the rowWrapper for the PDF creation
 */
export const convertPdcaTableToPdfArray = (
  tableEntries: TableRow[]
): string[][] => {
  const adjustedPdcaList: string[][] = [];
  tableEntries.forEach((tableRow) => {
    let currentPdacAutotableEntry: string[] = [];
    tableRow.content.forEach((entry: React.ReactElement) => {
      if (
        entry.props.className &&
        (entry.props.className as string).includes("pdca-quartered-circle")
      ) {
        const classNamesOfCircle: string = entry.props.className;
        if (classNamesOfCircle.includes("status-0")) {
          currentPdacAutotableEntry.push(quarterCirle0);
        } else if (classNamesOfCircle.includes("status-1")) {
          currentPdacAutotableEntry.push(quarterCirle1);
        } else if (classNamesOfCircle.includes("status-2")) {
          currentPdacAutotableEntry.push(quarterCirle2);
        } else if (classNamesOfCircle.includes("status-3")) {
          currentPdacAutotableEntry.push(quarterCirle3);
        } else if (classNamesOfCircle.includes("status-4")) {
          currentPdacAutotableEntry.push(quarterCirle4);
        }
      } else
        currentPdacAutotableEntry.push(
          entry.props.children ? entry.props.children.toString() : ""
        );
    });
    adjustedPdcaList.push(currentPdacAutotableEntry);
  });
  return adjustedPdcaList;
};

/**
 * Helper to set the first entry of options for the new pdca
 *
 * @param pdcaToEdit
 * @param userSelectOptions
 * @param prozessBereichSelectOptions
 * @returns updated Pdca
 * @tested
 */
export const updateInitialNewPdcaEntry = (
  pdcaToEdit: Pdca,
  userSelectOptions: Option[],
  prozessBereichSelectOptions: Option[]
) => {
  // save if something is predefined
  let prozessBereichSelect: string = pdcaToEdit.prozessBereich || "";
  let userSelect: string = pdcaToEdit.responsibleUser || "";
  // set if not set before
  if (prozessBereichSelectOptions.length > 0 && !prozessBereichSelect)
    prozessBereichSelect = prozessBereichSelectOptions[0].value;
  if (userSelectOptions.length > 0 && !userSelect)
    userSelect = userSelectOptions[0].value;
  // save it to the pdca
  return {
    ...pdcaToEdit,

    prozessBereich: prozessBereichSelect,
    responsibleUser: userSelect,
  };
};
