import {
  BoxComponent,
  HorizontalSubMenuComponent,
  LayoutComponent,
  LoaderComponent,
  TreeConfig,
} from "beelean-component-library";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LicenseModuleType } from "../utils/Enums";
import { Company, KeycloakUser, User } from "../utils/Interfaces";
import { isModuleValidInLicense } from "../utils/LicenseUtil";
import {
  getCorrectLanguageImage,
  toggleLanguage,
  useLayoutConfiguration,
} from "../utils/NavigationUtil";
import {
  CompanyContext,
  GlobaleApplicationSettings,
  KeycloakUserContext,
  TreeDataContext,
} from "./App";
import { useHistory, useLocation } from "react-router";
import {
  checkIfChecklistIsFullfilled,
  fetchProjektAkteById,
  reopenProjektAkte,
  sendMailForFinishedProjektAkte,
} from "../utils/LeanAdmin/ProjektAkteUtils";
import { useAxios } from "../utils/AxiosUtil";
import {
  createEmptyProjektAkte,
  ProjektAkte,
} from "../utils/LeanAdmin/ProjektAkte.types";
import SipocEval from "../components/modules/sipoc/SipocEval";
import {
  fetchSipocChecklistByProjektAkteId,
  updateCheckedElementByIdInChecklist,
  updateSipocChecklist,
} from "../utils/LeanAdmin/SipocChecklistUtils";
import {
  createEmptySipocChecklistEval,
  SipocChecklistEval,
  SipocChecklistEvalOutputData,
} from "../utils/LeanAdmin/LeanAdmin.types";
import ProjektAkteMetatag from "../components/modules/projektakte/ProjektAkteMetatag";
import { ReactComponent as TasksIcon } from "../assets/icons/tasks.svg";
import { getProjektAkteOutputFile } from "../utils/LeanAdmin/SipocOutputConfigurationUtils";
import { getFormattedDateTimeString } from "../utils/GeneralUtil";

interface ProjektAkteDetailPageProps {
  keycloakUser: KeycloakUser;
  userCompany: Company;
  setUserCompany(company: Company): void;
  leanAdminTreeData?: TreeConfig;
  pdcaTreeData?: TreeConfig;
  leanProdTreeData?: TreeConfig;
  projektAkteTreeData?: TreeConfig;
  isViewerState: boolean;
  toggleViewerState(viewState: boolean): void;
  isDesktopMenuOpen: boolean;
  toggleIsDesktopMenuOpen(desktopOpenState: boolean): void;
  companyUser: User[];
}

const Page: React.FC<ProjektAkteDetailPageProps> = ({
  keycloakUser,
  userCompany,
  setUserCompany,
  leanAdminTreeData,
  pdcaTreeData,
  leanProdTreeData,
  isViewerState,
  toggleViewerState,
  isDesktopMenuOpen,
  projektAkteTreeData,
  toggleIsDesktopMenuOpen,
  companyUser,
}) => {
  const {
    text,
    isAdmin,
    username,
    homeFunction,
    logoutFunction,
    profileFunction,
    accountListFunction,
    showToggleViewEdit,
    isTest,
    lng,
  } = useLayoutConfiguration(keycloakUser);
  const { t } = useTranslation();
  const [loadedImageBase64String, setLoadedImageBase64String] =
    useState<string>("");
  const [localCompanyId, setLocalCompanyId] = useState<string>("");
  const [localProjektAkteId, setLocalProjektAkteId] = useState<string>("");
  const [localProjektAkte, setLocalProjektAkte] = useState<ProjektAkte>(
    createEmptyProjektAkte(
      `${keycloakUser.firstname} ${keycloakUser.lastname}`,
      keycloakUser.companyId
    )
  );
  const [loadedChecklist, setLoadedChecklist] = useState<SipocChecklistEval>(
    createEmptySipocChecklistEval(
      keycloakUser.companyId,
      `${keycloakUser.firstname} ${keycloakUser.lastname}`
    )
  );
  const [openNodes, setOpenNodes] = useState<string[]>([]);
  const [currentExportFunction, setCurrentExportFunction] =
    useState<Function>();
  const [showDetailSipoc, setShowDetailSipoc] = useState<boolean>(true);
  const [showProzessMapping, setShowProzessMapping] = useState<boolean>(false);
  const [showInfoProjektAkte, setShowInfoProjektAkte] =
    useState<boolean>(false);
  const [lastKey, setLastKey] = useState<string>("");
  const history = useHistory();
  const axios = useAxios();
  const location = useLocation<{
    openNodes: string[];
    lastKey: string;

    companyId: string;

    procjectId: string;
  }>();
  const { projektAkten, setProjektAkten } = useContext(TreeDataContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingText, setLoadingText] = useState<string>("");

  /**
   * This useEffect handles the name of the not valid license module
   */
  useEffect(() => {
    if (location?.state !== undefined) {
      setOpenNodes(location.state.openNodes);
      setLastKey(location.state.lastKey);
      setLocalCompanyId(location.state.companyId);
      setLocalProjektAkteId(location.state.procjectId);
    } else {
      history.push(`/`);
    }
    // eslint-disable-next-line
  }, [location]);

  // Loads current ProjektAkte
  useEffect(() => {
    if (!!localProjektAkteId && axios)
      Promise.all([
        fetchProjektAkteById(axios, localProjektAkteId),
        fetchSipocChecklistByProjektAkteId(
          localCompanyId,
          localProjektAkteId,
          axios
        ),
      ]).then(([loadedProjektAkte, loadedChecklist]) => {
        setLocalProjektAkte(loadedProjektAkte);
        if (loadedChecklist) setLoadedChecklist(loadedChecklist);
      });
    // eslint-disable-next-line
  }, [localProjektAkteId, axios]);

  // fetches the ProzessMap Image
  useEffect(() => {
    if (!!localProjektAkte.prozessMapImageName) {
      getProjektAkteOutputFile(
        localProjektAkte.prozessMapImageName,
        localProjektAkteId,
        axios,
        true
      ).then((blob) => setLoadedImageBase64String(URL.createObjectURL(blob)));
    }
    // eslint-disable-next-line
  }, [localProjektAkte]);

  /**
   * updates an entry in the Sipoc checklist and updates it on the
   * server
   * when the projektAkte is finished or reopened, it's also updated
   * together with the license counter
   *
   * @param id id of entry to change
   * @param checked if empty, value will be toggled
   * @fileList optional, won't be changed if empty
   * @outputValues optional, won't be changed if empty
   */
  const updateCheckListInLoadedChecklists = async (
    id: string,
    nextSipocChecklistEvalEntryId?: string,
    checked?: boolean,
    fileList?: string[],
    outputConfig?: SipocChecklistEvalOutputData[]
  ): Promise<void> => {
    setIsLoading(true);
    let updatedChecklist: SipocChecklistEval = { ...loadedChecklist };
    updatedChecklist = updateCheckedElementByIdInChecklist(
      updatedChecklist,
      id,
      checked,
      fileList,
      outputConfig
    );
    if (nextSipocChecklistEvalEntryId)
      updatedChecklist = updateCheckedElementByIdInChecklist(
        updatedChecklist,
        nextSipocChecklistEvalEntryId,
        checked
      );
    const isFulfilled = checkIfChecklistIsFullfilled(
      localProjektAkte,
      updatedChecklist
    );
    setLoadingText(
      isFulfilled
        ? t("projektAkte.detail.loadingFinishingProjektAkte")
        : t("projektAkte.detail.loadingReopeningProjektAkte")
    );
    let finishedDate: Date | undefined = localProjektAkte.finishedDate;
    //check if there is a finishDate to tell if ProjektAkte is finished
    //finished ProjektAkte must be reopened, which causes a license check, if
    //it's not successful, the ProjektAkte can't be reopened
    if (!!localProjektAkte.finishedDate && !isFulfilled) {
      await reopenProjektAkte(localProjektAkte.id!, axios);
    }
    //checking if the Checklist is fulfilled, and if yes, we close it
    //and send Mail about finishing to all project members
    if (!localProjektAkte.finishedDate && isFulfilled) {
      finishedDate = await sendMailForFinishedProjektAkte(
        localProjektAkte.id!,
        axios
      );
    }
    finishOrReopenProjektAkte(updatedChecklist, finishedDate);
    setIsLoading(false);
  };

  /**
   * this method locally updates the projektAkte, checklist and licenses
   * @param updatedChecklist updated Checklist to set in frontend
   * @param finishedDate ig this date is set, the ProjektAkte will be
   * closed and license goes up down by one
   */
  const finishOrReopenProjektAkte = (
    updatedChecklist: SipocChecklistEval,
    finishedDate?: Date
  ): void => {
    const updatedProjektAkte: ProjektAkte = {
      ...localProjektAkte,
      finishedDate: finishedDate ? finishedDate : undefined,
    };
    const licenseUpdate: number = finishedDate ? -1 : 1;
    setLocalProjektAkte(updatedProjektAkte);
    updateProjektAkteInContext(updatedProjektAkte);
    setUserCompany({
      ...userCompany,
      createdProjektakte: userCompany.createdProjektakte + licenseUpdate,
    });
    setLoadedChecklist({ ...updatedChecklist });
    updateSipocChecklist(updatedChecklist, axios);
  };

  /**
   * updates given ProjektAkte in Context
   * @param projektAkte
   */
  const updateProjektAkteInContext = (projektAkte: ProjektAkte) => {
    let foundIndex: number = projektAkten.findIndex(
      (searchProjektAkte) => searchProjektAkte.id === projektAkte.id
    );
    if (foundIndex !== -1) {
      let localProjektAkten: ProjektAkte[] = projektAkten;
      localProjektAkten[foundIndex] = projektAkte;
      setProjektAkten([...localProjektAkten]);
    }
  };

  return (
    <LayoutComponent
      noDataInTree={t("tree.noData")}
      onExportFunction={() => currentExportFunction && currentExportFunction()}
      adminProjektAkteTreeData={{
        ...projektAkteTreeData!,
        openNodes: openNodes,
        lastSelectedKey: lastKey,
      }}
      isTest={isTest}
      showToggleViewEdit={showToggleViewEdit}
      isDesktopMenuOpen={isDesktopMenuOpen}
      onToogleDesktopMenuOpen={() =>
        toggleIsDesktopMenuOpen(!isDesktopMenuOpen)
      }
      viewEditStatus={isViewerState}
      onToggleViewEditFunction={() => toggleViewerState(!isViewerState)}
      showLeanAdminTree={isModuleValidInLicense(
        LicenseModuleType.ADMINISTRATION,
        userCompany.license
      )}
      showLeanProdTree={isModuleValidInLicense(
        LicenseModuleType.PRODUCTION,
        userCompany.license
      )}
      text={text}
      title={localProjektAkte.title}
      leanProdTreeData={leanProdTreeData}
      leanAdminTreeData={leanAdminTreeData}
      adminPdcaTreeData={pdcaTreeData}
      username={username}
      homeFunction={() => homeFunction()}
      logoutFunction={() => logoutFunction()}
      onUserAdminFunction={() => accountListFunction()}
      onCreateLineFunction={() => {}}
      isAdmin={isAdmin}
      profileFunction={() => profileFunction()}
      key={openNodes.length}
      version={t("general.version")}
      languageImage={getCorrectLanguageImage(lng)}
      onChangeLanguage={toggleLanguage}
    >
      {isLoading && <LoaderComponent inFront showText text={loadingText} />}
      <HorizontalSubMenuComponent
        preselectedIndex={0}
        items={[
          {
            label: t("projektAkte.detail.checklistTitle"),
            onClick: () => {
              setShowDetailSipoc(true);
              setShowInfoProjektAkte(false);
              setShowProzessMapping(false);
            },
          },
          {
            label: t("projektAkte.detail.projectInfoTitle"),
            onClick: () => {
              setShowDetailSipoc(false);
              setShowInfoProjektAkte(true);
              setShowProzessMapping(false);
            },
          },
          {
            label: "ProzessMapping",
            onClick: () => {
              setShowDetailSipoc(false);
              setShowInfoProjektAkte(false);
              setShowProzessMapping(true);
            },
          },
        ]}
      />
      {!!localProjektAkte.id && showDetailSipoc && (
        <SipocEval
          key={`sipoc-eval-for-projektakte-${localProjektAkte.id}`}
          companyId={localProjektAkte.companyId}
          prozessBereichId={localProjektAkte.prozessBereich?.id || ""}
          setExportFunction={setCurrentExportFunction}
          selectedSipocChecklist={loadedChecklist}
          onClickToUpdate={updateCheckListInLoadedChecklists}
          projektAkte={localProjektAkte}
        />
      )}
      {!!localProjektAkte.id && showInfoProjektAkte && (
        <BoxComponent
          title={t("projektAkte.update.title")}
          subtext={<p>{t("projektAkte.update.infoSubText")}</p>}
          icon={<TasksIcon />}
        >
          <ProjektAkteMetatag
            companyUser={companyUser}
            projektAkte={localProjektAkte}
            isViewerState={isViewerState}
            setProjektAkte={setLocalProjektAkte}
            sipocChecklist={loadedChecklist}
            updateProjektAkteInContext={updateProjektAkteInContext}
          />
        </BoxComponent>
      )}
      {showProzessMapping && (
        <BoxComponent
          title={t("projektAkte.detail.prozessMapping")}
          subtext={
            <p>
              {t("modules.SIPOC.lastUpdate", {
                replace: {
                  date: getFormattedDateTimeString(
                    localProjektAkte.prozessMapping?.lastUpdated ||
                      localProjektAkte.prozessMapping?.createDate
                  ),
                  by: localProjektAkte.creator,
                },
              })}
            </p>
          }
          icon={<TasksIcon />}
        >
          <img
            src={loadedImageBase64String}
            className="projektakte-info-prozessmap-wrapper-image"
            key={`prozess-map-image-length-${loadedImageBase64String.length}`}
            alt="ProzessMap"
          />
        </BoxComponent>
      )}
      {!localProjektAkte.id && showDetailSipoc && <LoaderComponent />}
    </LayoutComponent>
  );
};

const ProjektAkteDetailPage = () => {
  const { keycloakUser } = useContext(KeycloakUserContext);
  const { setUserCompany, userCompany, companyUser } =
    useContext(CompanyContext);
  const {
    leanAdminTreeData,
    leanProdTreeData,
    pdcaTreeData,
    projektAkteTreeData,
  } = useContext(TreeDataContext);
  const {
    isViewerState,
    toggleViewerState,
    isDesktopMenuOpen,
    toggleIsDesktopMenuOpen,
  } = useContext(GlobaleApplicationSettings);
  const { t } = useTranslation();

  return keycloakUser ? (
    <Suspense
      fallback={
        <LoaderComponent inFront showText text={t("general.loading")} />
      }
    >
      <Page
        isDesktopMenuOpen={isDesktopMenuOpen}
        toggleIsDesktopMenuOpen={toggleIsDesktopMenuOpen}
        isViewerState={isViewerState}
        toggleViewerState={toggleViewerState}
        keycloakUser={keycloakUser}
        userCompany={userCompany}
        setUserCompany={setUserCompany}
        leanAdminTreeData={leanAdminTreeData}
        pdcaTreeData={pdcaTreeData}
        leanProdTreeData={leanProdTreeData}
        projektAkteTreeData={projektAkteTreeData}
        companyUser={companyUser}
      />
    </Suspense>
  ) : (
    <LoaderComponent inFront showText text={t("general.loading")} />
  );
};

export default ProjektAkteDetailPage;
