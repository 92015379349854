import React, { Suspense, useContext, useEffect, useState } from "react";
import {
  LayoutComponent,
  LoaderComponent,
  TreeConfig,
} from "beelean-component-library";
import {
  getCorrectLanguageImage,
  toggleLanguage,
  useLayoutConfiguration,
} from "../utils/NavigationUtil";
import {
  CompanyContext,
  GlobaleApplicationSettings,
  KeycloakUserContext,
  TreeDataContext,
} from "./App";
import { Company, KeycloakUser } from "../utils/Interfaces";
import { isModuleValidInLicense } from "../utils/LicenseUtil";
import { useHistory, useLocation } from "react-router";
import LeanAdminSelection from "../components/modules/LeanAdminSelection";
import { useTranslation } from "react-i18next";
import { LicenseModuleType } from "../utils/Enums";
import { ProzessBereich } from "../utils/LeanAdmin/LeanAdmin.types";

interface LeanAdminProzessMappingPageProps {
  keycloakUser: KeycloakUser;
  userCompany: Company;
  leanAdminTreeData?: TreeConfig;
  pdcaTreeData?: TreeConfig;
  leanProdTreeData?: TreeConfig;
  isViewerState: boolean;
  toggleViewerState: Function;
  isDesktopMenuOpen: boolean;
  toggleIsDesktopMenuOpen(isOpen: boolean): void;
  leanAdminProzessbereiche: ProzessBereich[];
  projektAkteTreeData?: TreeConfig;
}

const Page: React.FC<LeanAdminProzessMappingPageProps> = ({
  keycloakUser,
  userCompany,
  leanAdminTreeData,
  pdcaTreeData,
  leanProdTreeData,
  isViewerState,
  toggleViewerState,
  isDesktopMenuOpen,
  toggleIsDesktopMenuOpen,
  leanAdminProzessbereiche,
  projektAkteTreeData,
}) => {
  const {
    text,
    isAdmin,
    username,
    homeFunction,
    logoutFunction,
    showToggleViewEdit,
    isPlatformAdmin,
    profileFunction,
    accountListFunction,
    isTest,
    lng,
  } = useLayoutConfiguration(keycloakUser);
  const { t } = useTranslation();

  // export state
  const [currentExportFunction, setCurrentExportFunction] = useState<Function>(
    () => () => {}
  );

  // Location for expanding or collapse tree
  const [openNodes, setOpenNodes] = useState<string[]>([]);
  const history = useHistory();
  const [lastKey, setLastKey] = useState<string>("");
  const [localCompanyId, setLocalCompanyId] = useState<string>("");
  const [localProzessBereichId, setLocalProzessBereichId] =
    useState<string>("");
  const location = useLocation<{
    openNodes: string[];
    lastKey: string;
    companyId: string;
    prozessBereichId: string;
  }>();

  useEffect(() => {
    if (location?.state !== undefined) {
      setOpenNodes(location.state.openNodes);
      setLastKey(location.state.lastKey);
      setLocalProzessBereichId(location.state.prozessBereichId);
      setLocalCompanyId(location.state.companyId);
    } else {
      history.push(`/`);
    }
    // eslint-disable-next-line
  }, [location, location.state]);

  /**
   * fetches correct name of current ProzessBereich
   *
   * @returns correct name of current ProzessBereich
   */
  const fetchCorrectProzessBereichName = (): string => {
    if (!localProzessBereichId) return t("modules.prozessMapping.headline");
    let foundIndex: number = leanAdminProzessbereiche.findIndex(
      (prozessBereich) => prozessBereich.id === localProzessBereichId
    );
    if (foundIndex === -1) return t("modules.prozessMapping.headline");
    return leanAdminProzessbereiche[foundIndex].bereich;
  };

  return (
    <LayoutComponent
      noDataInTree={t("tree.noData")}
      adminProjektAkteTreeData={projektAkteTreeData}
      isTest={isTest}
      showToggleViewEdit={showToggleViewEdit}
      onExportFunction={() => currentExportFunction && currentExportFunction()}
      isDesktopMenuOpen={isDesktopMenuOpen}
      onToogleDesktopMenuOpen={() =>
        toggleIsDesktopMenuOpen(!isDesktopMenuOpen)
      }
      key={`${localCompanyId}-${localProzessBereichId}`}
      viewEditStatus={isViewerState}
      onToggleViewEditFunction={() => toggleViewerState(!isViewerState)}
      showLeanAdminTree={
        isPlatformAdmin ||
        isModuleValidInLicense(
          LicenseModuleType.ADMINISTRATION,
          userCompany.license
        )
      }
      showLeanProdTree={
        isPlatformAdmin ||
        isModuleValidInLicense(
          LicenseModuleType.PRODUCTION,
          userCompany.license
        )
      }
      text={text}
      title={fetchCorrectProzessBereichName()}
      leanProdTreeData={leanProdTreeData}
      // integrate the current location state
      leanAdminTreeData={{
        ...leanAdminTreeData!,
        openNodes: openNodes,
        lastSelectedKey: lastKey,
      }}
      adminPdcaTreeData={pdcaTreeData}
      username={username}
      homeFunction={() => homeFunction()}
      logoutFunction={() => logoutFunction()}
      onUserAdminFunction={() => accountListFunction()}
      onCreateLineFunction={() => console.log("Cretae Line!")}
      isAdmin={isAdmin}
      profileFunction={() => profileFunction()}
      version={t("general.version")}
      languageImage={getCorrectLanguageImage(lng)}
      onChangeLanguage={toggleLanguage}
    >
      <LeanAdminSelection
        prozessBereichId={localProzessBereichId}
        companyId={localCompanyId}
        setExportFunction={setCurrentExportFunction}
      />
    </LayoutComponent>
  );
};

const LeanAdminProzessMappingPage = () => {
  // needed context
  const { keycloakUser } = useContext(KeycloakUserContext);
  const { userCompany } = useContext(CompanyContext);
  const {
    leanAdminTreeData,
    leanProdTreeData,
    pdcaTreeData,
    leanAdminProzessbereiche,
    projektAkteTreeData,
  } = useContext(TreeDataContext);
  const {
    isViewerState,
    toggleViewerState,
    isDesktopMenuOpen,
    toggleIsDesktopMenuOpen,
  } = useContext(GlobaleApplicationSettings);
  const { t } = useTranslation();

  return keycloakUser ? (
    <Suspense
      fallback={
        <LoaderComponent inFront showText text={t("general.loading")} />
      }
    >
      <Page
        leanAdminProzessbereiche={leanAdminProzessbereiche}
        isDesktopMenuOpen={isDesktopMenuOpen}
        toggleIsDesktopMenuOpen={toggleIsDesktopMenuOpen}
        isViewerState={isViewerState}
        toggleViewerState={toggleViewerState}
        keycloakUser={keycloakUser}
        pdcaTreeData={pdcaTreeData}
        userCompany={userCompany}
        leanAdminTreeData={leanAdminTreeData}
        leanProdTreeData={leanProdTreeData}
        projektAkteTreeData={projektAkteTreeData}
      />
    </Suspense>
  ) : (
    <LoaderComponent inFront showText text={t("general.loading")} />
  );
};

export default LeanAdminProzessMappingPage;
