import { AxiosInstance } from "axios";
import { Company } from "./Interfaces";

/**
 * Adds a company to the list
 *
 * @param company
 * @param companyList
 * @returns
 * @tested
 */
export const addCompanyToList = (
  company: Company,
  companyList: Company[]
): Company[] => {
  let localTempCompanies: Company[] = companyList;
  localTempCompanies.push(company);
  return [...localTempCompanies];
};

/**
 * Fetches the correct name of a company from company list
 *
 * @param id
 * @param listOfCompanies
 * @returns
 * @tested
 */
export const getNameOfCompany = (
  id: string,
  listOfCompanies: Company[]
): string => {
  return listOfCompanies.find((value: Company) => value.id === id)
    ? listOfCompanies.find((value: Company) => value.id === id)!.name
    : "n/a";
};

/**
 * Checks if company is valid for insertion
 *
 * @param company
 * @tested
 */
export const isCompanyValid = (company: Company): boolean => {
  const zipCodeRegEx: RegExp = new RegExp(
    /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{2,3}$/
  );

  return (
    !!company.name &&
    !!company.address &&
    zipCodeRegEx.test(company.zipCode.toString()) &&
    !!company.city
  );
};

/**
 * Creates a new company on the server
 *
 * @param newCompany
 * @param axios
 */
export const createNewCompany = async (
  newCompany: Company,
  axios: AxiosInstance
): Promise<Company> => {
  return axios
    .post("/user/company/", newCompany)
    .then((companyResponse) => companyResponse.data)
    .catch((exc) => {
      console.error("Error during company creation!", exc);
      return null;
    });
};

/**
 * fetches all companies on server
 *
 * @param axios
 */
export const fetchAllCompanies = async (
  axios: AxiosInstance
): Promise<Company[]> => {
  return axios
    .get("/user/company/all/")
    .then((companyResponse) => companyResponse.data)
    .catch((exc) => {
      console.error("Error during all companies fetching!", exc);
      return [];
    });
};

/**
 * fetches one company by id
 *
 * @param axios
 * @param companyId
 */
export const fetchCompany = async (
  axios: AxiosInstance,
  companyId: string
): Promise<Company> => {
  return axios
    .get("/user/company/", { params: { companyId: companyId } })
    .then((companyResponse) => companyResponse.data)
    .catch((exc) => {
      console.error("Error during company fetching!", exc);
      return null;
    });
};

/**
 * Updates a company on the server
 *
 * @param axios
 */
export const updateCompanyOnServer = async (
  axios: AxiosInstance,
  updateCompany: Company
): Promise<number> => {
  return axios
    .post("/user/company/update/", updateCompany)
    .then((companyResponse) => companyResponse.status)
    .catch((exc) => {
      console.error("Error during company creation!", exc);
      return -1;
    });
};

/**
 * Deletes one company on server
 *
 * @param companyId
 * @param axios
 */
export const deleteCompany = async (
  companyId: string,
  axios: AxiosInstance
): Promise<string[]> => {
  return axios
    .post("/user/company/delete/", companyId)
    .then((companyResponse) => companyResponse.data)
    .catch((exc) => {
      console.error("Error during company creation!", exc);
      return null;
    });
};

/**
 * incerases the license counter for a company
 *
 * @param companyId
 * @param axios
 * @returns if it was sucessful or not
 */
export const increaseProcessLicenseCounter = async (
  companyId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/user/company/process/add/", companyId)
    .then((companyResponse) => companyResponse.status === 200)
    .catch((exc) => {
      console.error("Error during company license increase!", exc);
      return false;
    });
};

/**
 * decerases the license counter for a company
 *
 * @param companyId
 * @param axios
 * @returns if it was sucessful or not
 */
export const decreaseProcessLicenseCounter = async (
  companyId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/user/company/process/remove/", companyId)
    .then((companyResponse) => companyResponse.status === 200)
    .catch((exc) => {
      console.error("Error during company license decrease!", exc);
      return false;
    });
};

/**
 * fetches the current company logo from the server
 *
 * @param fileName
 * @param axios
 * @returns image as blob
 */
export const getCurrentCompanyLogo = (
  fileName: string,
  axios: AxiosInstance
): Promise<Blob> => {
  return axios
    .get("/user/company/logo/", {
      params: { fileName: fileName },
      responseType: "blob",
    })
    .then((logoResponse) => logoResponse.data)
    .catch((exc) => {
      console.error("Error during fetching company logo!", exc);
      return null;
    });
};

/**
 * uploads the new image blob to server
 *
 * @param axios
 * @param companyId
 * @param logo
 * @returns the new filename to save it on the company object
 */
export const uploadNewCompanyLogo = (
  axios: AxiosInstance,
  companyId: string,
  logo: File
): Promise<string> => {
  // build the request
  let uploadFormData = new FormData();
  const blob = new Blob([companyId], {
    type: "application/json",
  });
  uploadFormData.append("companyId", blob);
  uploadFormData.append("logoImage", logo);
  // run the axios command
  return axios
    .post("/user/company/logo/", uploadFormData)
    .then((imageUpload) => imageUpload.data)
    .catch((exc) => {
      console.error("Error during uploading new company logo!", exc);
      return null;
    });
};

/**
 * updates an existign image blob on the server
 *
 * @param axios
 * @param companyId
 * @param logo
 * @param oldLogoName
 * @returns the new filename to save it on the company object
 */
export const updateNewCompanyLogo = (
  axios: AxiosInstance,
  companyId: string,
  logo: File,
  oldLogoName: string
): Promise<string> => {
  // build the request
  let uploadFormData = new FormData();
  // build blobs
  const companyIdBlob = new Blob([companyId], {
    type: "application/json",
  });
  const oldLogoNameBlob = new Blob([oldLogoName], {
    type: "application/json",
  });
  // build data request
  uploadFormData.append("companyId", companyIdBlob);
  uploadFormData.append("oldLogoName", oldLogoNameBlob);
  uploadFormData.append("logoImage", logo);
  // run the axios command
  return axios
    .post("/user/company/logo/update/", uploadFormData)
    .then((imageUpload) => imageUpload.data)
    .catch((exc) => {
      console.error("Error during uploading new company logo!", exc);
      return null;
    });
};
