import {
  InputMailRequest,
  ProzessSchrittEndDateMappingEntry,
  UserRoleMappingEntry,
} from "./ProjektAkte.types";
import { MilestoneBarEntryDefinition, Option } from "beelean-component-library";
import { AxiosInstance } from "axios";
import i18next from "i18next";
import { generateNotification } from "../NotificationUtil";
import { ProjektAkte } from "./ProjektAkte.types";
import {
  ProzessBereich,
  ProzessMappingEntry,
  Prozessschritt,
  SipocChecklistEval,
} from "./LeanAdmin.types";
import {
  generateProzessschrittIdAndNeededCheckedElementsAmount,
  getAmountOfCheckedElementsForProzessschritt,
  getAmountOfCheckedElementsForProzessschritte,
  getTotalAmountOfElementsToCheck,
} from "./SipocChecklistUtils";

/**
 * Util method that maps the given ProzessSchritte on dropdown options
 *
 * @param ProzessSchritte The loaded ProzessSchritte
 * @param fallback Fallback in case ProzessSchritt has no name or displayName
 * @returns Array of ProzessSchritte dropdown options
 * @tested
 */
export const createSelectOptionsForProzessSchritte = (
  fallback: string,
  ProzessSchritte: Prozessschritt[]
): Option[] => {
  return ProzessSchritte.map((prozessschritt) => ({
    label: getLabelForProzessSchritt(fallback, prozessschritt),
    value: prozessschritt.id!,
  }));
};

/**
 * Util method that returns label for ProzessSchritt
 *
 * @param ProzessSchritt The loaded ProzessSchritt
 * @param fallback Fallback in case ProzessSchritt has no name or displayName
 * @returns Label, which is name, displayName or fallback
 * @tested
 */
export const getLabelForProzessSchritt = (
  fallback: string,
  prozessschritt: Prozessschritt
): string => {
  return prozessschritt.name || prozessschritt.displayName || fallback;
};

/**
 * Filters ProzessSchritte and returns only those, that are
 * not already mapped in prozessSchrittEndDateMapping
 *
 * @param prozessSchritte all ProzessSchritte
 * @param prozessSchrittEndDateMapping
 * @returns filtered prozessSchritte
 * @tested
 */
export const filterAlreadyUsedProzessSchritte = (
  prozessSchritte: Prozessschritt[],
  prozessSchrittEndDateMapping: ProzessSchrittEndDateMappingEntry[]
): Prozessschritt[] => {
  if (prozessSchrittEndDateMapping.length === 0) return prozessSchritte;
  return prozessSchritte.filter(
    (ProzessSchritt) =>
      !prozessSchrittEndDateMapping.find(
        (entry) => entry.prozessSchrittId === ProzessSchritt.id
      )
  );
};

/**
 * Creates a UserRoleMapping according to the roles in ProjektAkte
 * with first value from dropdown select options
 *
 * @param roles
 * @param userSelectOptions
 * @returns UserRoleMapping
 * @tested
 */
export const getUserRoleMappingWithFirstSelectOption = (
  prozessBereich: ProzessBereich
): UserRoleMappingEntry[] => {
  let mapping: UserRoleMappingEntry[] = [];
  if (prozessBereich.version! >= 2)
    mapping = prozessBereich.bereichRoles.map((role) => {
      let mappingEntry: UserRoleMappingEntry = {
        roleName: role.name,
        roleId: role.id,
        userId: "",
      };
      return mappingEntry;
    });
  else
    mapping = prozessBereich.roles.map((roleName) => {
      let mappingEntry: UserRoleMappingEntry = {
        roleName: roleName,
        userId: "",
      };
      return mappingEntry;
    });

  return mapping;
};

/**
 * Updates an Entry in UserRoleMapping
 *
 * @param roleName
 * @param newUserId
 * @param currentUserRoleMapping
 * @returns updated UserRoleMapping
 * @tested
 */
export const updateUserRoleMapping = (
  roleName: string,
  roleId: string,
  newUserId: string,
  currentUserRoleMapping: UserRoleMappingEntry[]
): UserRoleMappingEntry[] => {
  let userMapping: UserRoleMappingEntry[] = currentUserRoleMapping.map(
    (entry) => {
      return entry.roleName === roleName
        ? {
            roleName: roleName,
            roleId: roleId,
            userId: newUserId,
          }
        : entry;
    }
  );
  return userMapping;
};

/**
 * Deletes an Entry in ProzessSchrittEndDateMapping
 *
 * @param prozessSchrittId
 * @param currentProzessSchrittEndDateMapping
 * @returns updated ProzessSchrittEndDateMapping
 * @tested
 */
export const deleteEntryInProzessSchrittEndDateMapping = (
  prozessSchrittId: string,
  currentProzessSchrittEndDateMapping: ProzessSchrittEndDateMappingEntry[]
): ProzessSchrittEndDateMappingEntry[] => {
  return currentProzessSchrittEndDateMapping.filter(
    (entry) => entry.prozessSchrittId !== prozessSchrittId
  );
};

/**
 * Helper to fetch all ProjektAkten for a company
 *
 * @param companyId
 * @param axios
 * @returns list of ProjektAkten
 */
export const fetchAllProjektAktenForCompany = async (
  companyId: string,
  axios: AxiosInstance
): Promise<ProjektAkte[]> => {
  return axios
    .get("/data/akte/all/", { params: { companyId: companyId } })
    .then((projektAkteList) => projektAkteList.data)
    .catch((error) => {
      console.error(
        "Error during fetching all ProjektAkte for company!",
        error
      );
      generateNotification(
        i18next.t("notifications.projektakte.title"),
        i18next.t("notifications.projektakte.errorCompanyFetch", {
          status: error.response ? error.response.status : "-1",
        }),
        "danger",
        -1
      );
      return [];
    });
};

/**
 * Helper to fetch all ProjektAkten for an user
 * @param axios
 * @returns list of ProjektAkten
 */
export const fetchAllProjektAktenForAdmin = async (
  axios: AxiosInstance
): Promise<ProjektAkte[]> => {
  return axios
    .get("/data/akte/admin/all/")
    .then((projektAkteList) => projektAkteList.data)
    .catch((error) => {
      console.error("Error during fetching all ProjektAkte for admin!", error);
      generateNotification(
        i18next.t("notifications.projektakte.title"),
        i18next.t("notifications.projektakte.errorAdminFetch", {
          status: error.response ? error.response.status : "-1",
        }),
        "danger",
        -1
      );
      return [];
    });
};

/**
 * Helper to fetch single ProjektAkte by id
 *
 * @param axios
 * @param projektAkteId
 * @returns ProjektAkte
 */
export const fetchProjektAkteById = async (
  axios: AxiosInstance,
  projektAkteId: string
): Promise<ProjektAkte> => {
  return axios
    .get("/data/akte/", { params: { projektAkteId: projektAkteId } })
    .then((projektAkteList) => projektAkteList.data)
    .catch((error) => {
      console.error("Error during fetching ProjektAkte!", error);
      generateNotification(
        i18next.t("notifications.projektakte.title"),
        i18next.t("notifications.projektakte.errorSingleFetch", {
          status: error.response ? error.response.status : "-1",
        }),
        "danger",
        -1
      );
      return null;
    });
};

/**
 * Helper to update projektAkte
 *
 * @param axios
 * @param projektAkte
 * @returns ProjektAkte
 */
export const updateProjektAkte = async (
  axios: AxiosInstance,
  projektAkte: ProjektAkte
): Promise<number> => {
  return axios
    .post("/data/akte/update/", projektAkte)
    .then((resp) => {
      generateNotification(
        i18next.t("notifications.projektAkteUpdate.successTitle"),
        i18next.t("notifications.projektAkteUpdate.successContent"),
        "success"
      );
      return resp.status;
    })
    .catch((error) => {
      console.error("Error during updating ProjektAkte!", error);
      generateNotification(
        i18next.t("notifications.projektAkteUpdate.errorTitle"),
        i18next.t("notifications.projektAkteUpdate.errorContent", {
          status: error.response ? error.response.status : "-1",
        }),
        "danger",
        -1
      );
      return -1;
    });
};

/**
 * API method to create a ProjektAkte entry on the backend
 *
 * @param projektAkteEntry The ProjektAkte entry to create
 * @param axios The axios instance
 * @returns Promise of the created ProjektAkte or generates notification
 * and retuns null
 */
export const createProjektAkteEntry = async (
  projektAkteEntry: ProjektAkte,
  axios: AxiosInstance
): Promise<ProjektAkte> =>
  axios
    .post("/data/akte/", projektAkteEntry)
    .then((resp) => {
      generateNotification(
        i18next.t("notifications.projektAkteCreate.successTitle"),
        i18next.t("notifications.projektAkteCreate.successContent"),
        "success"
      );
      return resp.data;
    })
    .catch((exc) => {
      switch (exc.response.status) {
        case 409:
          generateNotification(
            i18next.t("notifications.projektAkteCreate.errorLicenseTitle"),
            i18next.t("notifications.projektAkteCreate.errorLicenseContent"),
            "danger",
            -1
          );
          break;
        default:
          generateNotification(
            i18next.t("notifications.projektAkteCreate.errorTitle"),
            i18next.t("notifications.projektAkteCreate.errorContent", {
              status: exc.response.status,
            }),
            "danger",
            -1
          );
          break;
      }
      console.error("Error during ProjektAkte entry creation", exc);
      return null;
    });

/**
 * Helper to delete the associated ProjektAkte to the incoming ProjektAkteId
 * @param axios
 * @param projektAkteId //id of ProjektAkte to delete
 * @returns projektAkte
 */
export const deleteProjektAkte = async (
  axios: AxiosInstance,
  projektAkteId: string
): Promise<void> => {
  return axios
    .post("/data/akte/delete/", projektAkteId)
    .then(() => {
      generateNotification(
        i18next.t("notifications.projektAkteDelete.successTitle"),
        i18next.t("notifications.projektAkteDelete.successContent"),
        "success"
      );
    })
    .catch((exc) => {
      console.error("Error during ProjektAkte entry deletion", exc);
      generateNotification(
        i18next.t("notifications.projektAkteDelete.errorTitle"),
        i18next.t("notifications.projektAkteDelete.errorContent", {
          status: exc.response.status,
        }),
        "danger",
        -1
      );
    });
};

/**
 * checks if the searchString is found in the title or in the creator
 *
 * @param projektAkte
 * @param searchString
 * @returns boolean
 * @tested
 */
export const doesSearchStringApply = (
  projektAkte: ProjektAkte,
  searchString: string
): boolean => {
  let doesApply: boolean = false;
  const upperCaseSearchString: string = searchString.toUpperCase();
  switch (true) {
    case projektAkte.title.toUpperCase().indexOf(upperCaseSearchString) !== -1:
      doesApply = true;
      break;
    case projektAkte.creator.toUpperCase().indexOf(upperCaseSearchString) !==
      -1:
      doesApply = true;
      break;
  }
  return doesApply;
};

/**
 * checks if given Date is between given minDate and maxDate
 * without considering hours
 *
 * @param checkDate
 * @param minDate
 * @param maxDate
 * @returns boolean
 * @tested
 */
export const validateDate = (
  checkDate: Date,
  minDate: Date,
  maxDate: Date
): boolean => {
  const localDate: Date = new Date(new Date(checkDate).setHours(0, 0, 0, 0));
  const localMinDate: Date = new Date(new Date(minDate).setHours(0, 0, 0, 0));
  const localMaxDate: Date = new Date(new Date(maxDate).setHours(0, 0, 0, 0));
  return localDate >= localMinDate && localDate <= localMaxDate;
};

/**
 * checks if the milestones / ProzessSchrittEndDateMapping of the given
 * projektAkte are all within the projektAktes startDate and endDate
 *
 * @param projektAkte
 * @returns boolean
 * @tested
 */
export const validateMilestones = (projektAkte: ProjektAkte): boolean => {
  let valid: boolean = true;
  projektAkte.prozessSchrittEndDateMapping.some((entry) => {
    valid = validateDate(
      entry.endDate,
      projektAkte.startDate,
      projektAkte.endDate
    );
    return !valid;
  });
  return valid;
};

/**
 * checks if a milestone is on schedule, which means that
 * the end date is either not passed yet and when it's passed,
 * the mapped ProzessSchritt is finished
 *
 * @param milestone
 * @param sipocChecklist
 * @param totalNeededCheckedElements
 * @returns boolean
 * @tested
 */
export const isMilestoneOnSchedule = (
  milestone: ProzessSchrittEndDateMappingEntry,
  sipocChecklist: SipocChecklistEval,
  prozessMapping: ProzessMappingEntry
): boolean => {
  const totalNeededCheckedElements: Map<string, number> =
    generateProzessschrittIdAndNeededCheckedElementsAmount(
      prozessMapping,
      true
    );
  let milestoneHasPassed: boolean = validateDate(
    milestone.endDate,
    milestone.endDate,
    new Date()
  );
  if (milestoneHasPassed) {
    const neededCheckedElements: number = totalNeededCheckedElements.get(
      milestone.prozessSchrittId
    )!;
    const foundCheckedElements: number =
      getAmountOfCheckedElementsForProzessschritt(
        sipocChecklist!,
        milestone.prozessSchrittId,
        prozessMapping.prozessSchritte
      );
    if (neededCheckedElements > foundCheckedElements) return false;
  }
  return true;
};

/**
 * formats projektAkte milestones as MilestoneBarEntryDefinition
 * for MilestoneBar
 *
 * @param mapping
 * @param prozessSchritte
 * @param prozessMapping
 * @param loadedChecklist
 * @returns milestones for MilestoneBar
 */
export const buildMilestonesForMilestoneBar = (
  mapping: ProzessSchrittEndDateMappingEntry[],
  prozessSchritte: Prozessschritt[],
  prozessMapping: ProzessMappingEntry,
  loadedChecklist: SipocChecklistEval
): MilestoneBarEntryDefinition[] => {
  let milestones: MilestoneBarEntryDefinition[] = [];
  mapping.forEach((entry) => {
    let finished = isMilestoneOnSchedule(
      entry,
      loadedChecklist,
      prozessMapping!
    );
    milestones.push({
      title: getLabelForProzessSchritt(
        i18next.t("projektAkte.create.milestone.noNameSet"),
        prozessSchritte.find(
          (ProzessSchritt) => ProzessSchritt.id === entry.prozessSchrittId
        )!
      ),
      date: new Date(entry.endDate),
      finished: finished,
    });
  });
  return milestones;
};

/**
 * creates a milestone for the finishedDate of the ProjektAkte
 * @param projektAkte
 * @returns milestone for finishedDate
 * @tested
 */
export const buildFinishedMilestoneForMilestoneBar = (
  projektAkte: ProjektAkte
): MilestoneBarEntryDefinition | undefined => {
  return projektAkte.finishedDate
    ? {
        title: i18next.t("projektAkte.update.actualEnd"),
        date: new Date(projektAkte.finishedDate),
      }
    : undefined;
};

/**
 * Helper to filter the userId for current working line
 *
 * @param roleIdMapping
 * @param prozessSchrittRoleOrID
 * @returns userId or ""
 */
export const getUserIdForRoleName = (
  roleIdMapping: UserRoleMappingEntry[],
  prozessSchrittRoleOrID: string,
  prozessBereich: ProzessBereich
): string => {
  let foundIndex: number = -1;
  if (prozessBereich.version! >= 2) {
    foundIndex = roleIdMapping.findIndex(
      (entry) => entry.roleId === prozessSchrittRoleOrID
    );
  } else
    foundIndex = roleIdMapping.findIndex(
      (entry) => entry.roleName === prozessSchrittRoleOrID
    );
  if (foundIndex === -1) return "";
  return roleIdMapping[foundIndex].userId;
};

/**
 * API METHOD - sends E-mail for projektAkte according to given InputMailRequest
 *
 * @param inputMailRequest
 * @param axios
 * @returns void
 */
export const sendEMailReminderForProjektAkte = async (
  inputMailRequest: InputMailRequest,
  axios: AxiosInstance
): Promise<any> => {
  return axios.post("/data/akte/mail/", inputMailRequest).catch((error) => {
    generateNotification(
      i18next.t("notifications.eMail.errorTitle"),
      i18next.t("notifications.eMail.errorContent"),
      "danger",
      -1
    );
    console.error("Error during sending Mail!", error);
  });
};

/**
 * API to store the ProzessMap image name on the server
 *
 * @param projektAktenId
 * @param prozessMapImageFileName
 * @param axios
 * @returns void
 */
export const saveProzessMapImageNameOnServer = async (
  projektAktenId: string,
  prozessMapImageFileName: string,
  axios: AxiosInstance
): Promise<any> => {
  return axios
    .get("/data/akte/map/", {
      params: {
        projektAktenId: projektAktenId,
        prozessMapImageFileName: prozessMapImageFileName,
      },
    })
    .catch((error) => {
      generateNotification(
        i18next.t("notifications.projektAkteCreate.errorTitle"),
        i18next.t("notifications.projektAkteCreate.errorProzessImageUpload"),
        "danger",
        -1
      );
      console.error("Error during uploading ProzessMap Image!", error);
    });
};

/**
 * API METHOD - sends E-Mail to inform that ProjektAkte with given Id is finished
 * @param projektAkteId
 * @param axios
 * @returns finishedDate of the projektakte for given id
 */
export const sendMailForFinishedProjektAkte = async (
  projektAkteId: string,
  axios: AxiosInstance
): Promise<Date> => {
  return axios
    .post("/data/akte/mail/finished/", projektAkteId)
    .then((response) => response.data)
    .catch((error) => {
      generateNotification(
        i18next.t("notifications.eMail.errorTitle"),
        i18next.t("notifications.eMail.errorContent"),
        "danger",
        -1
      );
      console.error("Error during sending finished mail!", error);
    });
};

/**
 * Helper method to check if Checklist is fulfilled
 * @param projektAkte
 * @param sipocChecklist
 * @returns true if the checklist is fulfilled, else false
 */
export const checkIfChecklistIsFullfilled = (
  projektAkte: ProjektAkte,
  sipocChecklist: SipocChecklistEval
): boolean => {
  const localNeededCheckedElementsWithInputs: number =
    getTotalAmountOfElementsToCheck(projektAkte.prozessMapping!);
  const localFoundCheckedElementsWithInputs: number =
    getAmountOfCheckedElementsForProzessschritte(
      sipocChecklist,
      projektAkte.prozessMapping!.prozessSchritte
    );
  return (
    localFoundCheckedElementsWithInputs === localNeededCheckedElementsWithInputs
  );
};

/**
 * API METHOD - reopens projektAkte and clears finishDate on backend
 * @param projektAkteId
 * @param axios
 * @returns if reopening was successful
 */
export const reopenProjektAkte = async (
  projektAkteId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  return axios
    .post("/data/akte/reopen/", projektAkteId)
    .then((response) => response.status === 200)
    .catch((error) => {
      if (error.response.status === 409)
        generateNotification(
          i18next.t("notifications.projektAkteCreate.errorLicenseTitle"),
          i18next.t("notifications.projektAkteCreate.errorLicenseContent"),
          "danger",
          -1
        );
      else
        generateNotification(
          i18next.t("notifications.projektAkteCreate.errorLicenseTitle"),
          i18next.t(
            "notifications.projektAkteUpdate.errorContent",
            error.response.status
          ),
          "danger",
          -1
        );
      console.error("Error during reopening ProjektAkte!", error);
      return false;
    });
};
