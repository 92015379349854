import {
  DropdownComponent,
  InputComponent,
  ButtonComponent,
  TimeCalculateInputComponent,
  InputTimeUnit,
} from "beelean-component-library";
import React, { ReactNode, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Prozesslinie,
  ProzesslinieType,
} from "../../../../utils/LeanAdmin/LeanAdmin.types";

interface ProzesslinieEditMetaTagProps {
  prozessLinie: Prozesslinie;
  onChange: Function;
  onDelete: Function;
  hasConflicts: (prozesslinie: Prozesslinie) => boolean;
}

const ProzesslinieEditMetaTag: React.FC<ProzesslinieEditMetaTagProps> = ({
  prozessLinie,
  onChange,
  onDelete,
  hasConflicts,
}) => {
  const { t } = useTranslation();
  const [localProzesslinie, setLocalProzesslinie] =
    useState<Prozesslinie>(prozessLinie);

  useEffect(() => {
    onChange(localProzesslinie);
    // eslint-disable-next-line
  }, [localProzesslinie]);

  const getCorrectInputs = (): ReactNode => {
    switch (localProzesslinie.type) {
      case ProzesslinieType.DIENSTLEISTUNGSLIEFERUNG_MATERIAL:
        return (
          <>
            <TimeCalculateInputComponent
              placeholder={t("modules.prozessMapping.prozessLine.deliveryTime")}
              type="number"
              onChange={(value: number) =>
                setLocalProzesslinie({
                  ...localProzesslinie,
                  lieferzeit: {
                    ...localProzesslinie.lieferzeit,
                    value: value,
                  },
                })
              }
              unit={localProzesslinie.lieferzeit.unit as InputTimeUnit}
              onUnitChange={(newUnit: string) =>
                setLocalProzesslinie({
                  ...localProzesslinie,
                  lieferzeit: {
                    ...localProzesslinie.lieferzeit,
                    unit: parseInt(newUnit) as InputTimeUnit,
                  },
                })
              }
              value={
                !!localProzesslinie.lieferzeit.value
                  ? localProzesslinie.lieferzeit.value
                  : ""
              }
            />
            <InputComponent
              placeholder={t(
                "modules.prozessMapping.prozessLine.additionalEffort"
              )}
              type="number"
              onChange={(value: number) =>
                setLocalProzesslinie({
                  ...localProzesslinie,
                  zusaetzlicherAufwand: value,
                })
              }
              value={
                !!localProzesslinie.zusaetzlicherAufwand
                  ? localProzesslinie.zusaetzlicherAufwand
                  : ""
              }
            />
            <InputComponent
              placeholder={t("modules.prozessMapping.prozessLine.incidents")}
              type="number"
              onChange={(value: number) =>
                setLocalProzesslinie({
                  ...localProzesslinie,
                  vorfaelle: value,
                })
              }
              value={
                !!localProzesslinie.vorfaelle ? localProzesslinie.vorfaelle : ""
              }
            />
          </>
        );
      case ProzesslinieType.INFORMATIONSFLUSS_PAPIER:
        return (
          <TimeCalculateInputComponent
            placeholder={t("modules.prozessMapping.prozessLine.handoverTime")}
            type="number"
            onChange={(value: number) =>
              setLocalProzesslinie({
                ...localProzesslinie,
                uebergabeZeit: {
                  ...localProzesslinie.uebergabeZeit,
                  value: value,
                },
              })
            }
            unit={localProzesslinie.uebergabeZeit.unit as InputTimeUnit}
            onUnitChange={(newUnit: string) =>
              setLocalProzesslinie({
                ...localProzesslinie,
                uebergabeZeit: {
                  ...localProzesslinie.uebergabeZeit,
                  unit: parseInt(newUnit) as InputTimeUnit,
                },
              })
            }
            value={
              !!localProzesslinie.uebergabeZeit.value
                ? localProzesslinie.uebergabeZeit.value
                : ""
            }
          />
        );
      case ProzesslinieType.RUECKFRAGE:
        return (
          <TimeCalculateInputComponent
            placeholder={t("modules.prozessMapping.prozessLine.duration")}
            type="number"
            onChange={(value: number) =>
              setLocalProzesslinie({
                ...localProzesslinie,
                dauer: {
                  ...localProzesslinie.dauer,
                  value: value,
                },
              })
            }
            unit={localProzesslinie.dauer.unit as InputTimeUnit}
            onUnitChange={(newUnit: string) =>
              setLocalProzesslinie({
                ...localProzesslinie,
                dauer: {
                  ...localProzesslinie.dauer,
                  unit: parseInt(newUnit) as InputTimeUnit,
                },
              })
            }
            value={
              !!localProzesslinie.dauer.value
                ? localProzesslinie.dauer.value
                : ""
            }
          />
        );
      case ProzesslinieType.QUALITAETSMANGEL:
      case ProzesslinieType.INFORMATIONSFLUSS_EDV:
      default:
        return (
          <p>
            <Trans i18nKey="modules.prozessMapping.prozessLine.noData">
              Keine Metatags für diesen Typ
            </Trans>
          </p>
        );
    }
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      {getCorrectInputs()}
      <DropdownComponent
        onChange={(option) => {
          const updatedLinie: Prozesslinie = {
            ...localProzesslinie,
            type: option as ProzesslinieType,
          };
          if (!hasConflicts(updatedLinie)) setLocalProzesslinie(updatedLinie);
        }}
        selectedOption={localProzesslinie.type as string}
        options={Object.keys(ProzesslinieType).map((type) => {
          return {
            label: t(`modules.prozessMapping.lines.${type}`),
            value: type,
          };
        })}
      />

      <ButtonComponent
        title={t("general.buttons.delete")}
        background="#ee4f4b"
        type="button"
        onClick={() => onDelete(prozessLinie.id)}
      />
    </form>
  );
};

export default ProzesslinieEditMetaTag;
