import {
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
  LoaderComponent,
  PopupComponent,
  PopupComponentProps,
} from "beelean-component-library";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { ReactComponent as DumpsterIcon } from "../../../../assets/icons/dumpster.svg";
import { useAxios } from "../../../../utils/AxiosUtil";
import {
  ProzessTaskType,
  Prozessschritt,
  Prozessschritttype,
  SipocChecklistEvalEntry,
  SipocChecklistEvalOutputData,
  SipocOutputConfigurationEntry,
  SipocOutputType,
} from "../../../../utils/LeanAdmin/LeanAdmin.types";
import { generateIdForCheckbox } from "../../../../utils/LeanAdmin/SipocChecklistUtils";
import {
  checkIsFileTooBig,
  deleteProjektAkteOutputFile,
  downloadProjektAkteFile,
  getFileNameWithoutId,
  isValidUrl,
  uploadProjektAkteOutputFile,
} from "../../../../utils/LeanAdmin/SipocOutputConfigurationUtils";
import "../SipocOutputConfiguration.scss";

interface SipocOutputConfigurationCheckProps {
  prozessSchritt: Prozessschritt;
  detailViewProzessSchritt: Prozessschritt;
  sipocChecklistEvalEntryId: string;
  nextSipocChecklistEvalEntryId: string;
  sipocChecklistItems: SipocChecklistEvalEntry[];
  onClickToUpdate(
    id: string,
    nextSipocChecklistEvalEntryId?: string,
    checked?: boolean,
    fileList?: string[],
    outputConfig?: SipocChecklistEvalOutputData[]
  ): void;
  companyId: string;
  type: ProzessTaskType;
  index: number;
  initialChecked?: boolean;
  popupProps: PopupComponentProps;
}

const SipocOutputConfigurationCheck: React.FC<
  SipocOutputConfigurationCheckProps
> = ({
  prozessSchritt,
  detailViewProzessSchritt,
  sipocChecklistEvalEntryId,
  nextSipocChecklistEvalEntryId,
  sipocChecklistItems,
  onClickToUpdate,
  companyId,
  type,
  index,
  popupProps,
}) => {
  const { t } = useTranslation();
  const axios = useAxios();
  const location = useLocation<{
    procjectId: string;
  }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loaderText, setLoaderText] = useState<string>("");
  const [localProjektAkteId, setLocalProjektAkteId] = useState<string>("");
  const canHaveOutputConfig: boolean =
    type === ProzessTaskType.OUTPUT ||
    prozessSchritt.type === Prozessschritttype.KUNDEN ||
    prozessSchritt.type === Prozessschritttype.EXTERN;

  const [localOutputValues, setLocalOutputValues] = useState<
    SipocChecklistEvalOutputData[]
  >(
    sipocChecklistItems.find(
      (entry) =>
        entry.id ===
        (!canHaveOutputConfig
          ? generateIdForCheckbox(
              ProzessTaskType.OUTPUT,
              index,
              prozessSchritt,
              detailViewProzessSchritt
            )
          : sipocChecklistEvalEntryId)
    )?.outputValues || []
  );
  const [localOutputFiles, setLocalOutputFiles] = useState<string[]>(
    sipocChecklistItems.find(
      (entry) =>
        entry.id ===
        (!canHaveOutputConfig
          ? generateIdForCheckbox(
              ProzessTaskType.OUTPUT,
              index,
              prozessSchritt,
              detailViewProzessSchritt
            )
          : sipocChecklistEvalEntryId)
    )?.fileList || []
  );

  //initially set configs according to type
  const localOutputConfig: SipocOutputConfigurationEntry[] =
    (!canHaveOutputConfig
      ? detailViewProzessSchritt.outputConfig
      : prozessSchritt.outputConfig
    ).filter(
      (outputConfig: SipocOutputConfigurationEntry) =>
        outputConfig.id.split("_")[0] ===
        (canHaveOutputConfig ? detailViewProzessSchritt.id : prozessSchritt.id)
    ) || [];

  const localOutputFileList: string[] =
    (!canHaveOutputConfig
      ? detailViewProzessSchritt.outputFileList
      : prozessSchritt.outputFileList
    ).filter(
      (fileName: string) =>
        fileName.split("_")[0] ===
        (canHaveOutputConfig ? detailViewProzessSchritt.id : prozessSchritt.id)
    ) || [];

  //get projektAkteId from location
  useEffect(() => {
    if (location?.state !== undefined) {
      setLocalProjektAkteId(location.state.procjectId);
    } else {
      history.push(`/`);
    }
    // eslint-disable-next-line
  }, [location]);

  /**
   * Helper to get information about current state of checkbox
   * @returns true. when checkbox is checked, false otherwise
   */
  const getCurrentCheckedValue = (): boolean => {
    let checked: boolean = true;
    //not enough files
    if (localOutputFileList.length > localOutputFiles.length) checked = false;
    //output not complete
    if (localOutputConfig.length > localOutputValues.length) checked = false;
    if (checked)
      //check for empty values
      checked = !localOutputValues.some((entry) => {
        if (!entry.value) return true;
        else return false;
      });
    if (checked)
      //check for valid URLs in Link Output
      checked = !localOutputConfig.some(
        (outputConfigEntry: SipocOutputConfigurationEntry) => {
          return !isInputValid(outputConfigEntry);
        }
      );
    return checked;
  };

  /**
   * saves changes in sipoc checklist
   */
  const saveChanges = (): void => {
    if (localOutputFileList.length === 0 && localOutputConfig.length === 0)
      return;
    //validation
    const checked: boolean = getCurrentCheckedValue();
    //perform update
    onClickToUpdate(
      sipocChecklistEvalEntryId,
      nextSipocChecklistEvalEntryId,
      checked,
      localOutputFiles,
      localOutputValues
    );
  };

  //performs initial update of SipocChecklistEvalEntry when config opened and no config to fill out
  useEffect(() => {
    if (localOutputFileList.length === 0 && localOutputConfig.length === 0)
      if (canHaveOutputConfig) {
        onClickToUpdate(
          sipocChecklistEvalEntryId,
          nextSipocChecklistEvalEntryId
        );
      }
    // eslint-disable-next-line
  }, [localOutputConfig.length, localOutputFileList.length]);

  //checks if the given config Id already has an output value
  const hasValue = (configurationId: string): boolean => {
    return localOutputValues.find((entry) => entry.id === configurationId)
      ?.value
      ? true
      : false;
  };

  /**
   * Helper to upload file and afterwards update local states accordingly
   * @param incomingFile from upload
   */
  const locallyUploadFile = (incomingFile: File): void => {
    if (checkIsFileTooBig(incomingFile.size)) return;
    setLoaderText(
      t("modules.SIPOC.outputConfiguration.loadingTexts.uploading")
    );
    setIsLoading(true);
    uploadProjektAkteOutputFile(
      incomingFile,
      companyId,
      localProjektAkteId,
      axios
    ).then((fileName: string) => {
      if (!fileName) {
        setIsLoading(false);
        return;
      }
      let currentFileStrings: string[] = localOutputFiles;
      currentFileStrings.push(detailViewProzessSchritt.id + "_" + fileName);
      setLocalOutputFiles([...currentFileStrings]);
      setIsLoading(false);
    });
  };

  //checks if the inpout is a link and if so, if the link is a valid url
  const isInputValid = (
    outputConfigurationEntry: SipocOutputConfigurationEntry
  ): boolean => {
    if (outputConfigurationEntry.type !== SipocOutputType.LINK) return true;
    return isValidUrl(
      localOutputValues[
        localOutputValues.findIndex(
          (entry) => entry.id === outputConfigurationEntry.id
        )
      ]?.value || ""
    );
  };
  //gets given Value as date or creates one, if there isn't one already
  const getValueAsDate = (configurationId: string): Date | null => {
    let valueIndex: number = localOutputValues.findIndex(
      (entry) => entry.id === configurationId
    );
    if (valueIndex === -1) {
      return null;
    } else return new Date(localOutputValues[valueIndex].value);
  };

  //returns the proper component for the configuration
  const getProperComponent = (
    outputConfigurationEntry: SipocOutputConfigurationEntry,
    outputConfigurationEntryIndex: number
  ): JSX.Element => {
    const disabled: boolean = !canHaveOutputConfig;
    switch (outputConfigurationEntry.type) {
      case SipocOutputType.TEXT:
      case SipocOutputType.LINK:
        return (
          <InputComponent
            className={
              isInputValid(outputConfigurationEntry) ? undefined : "false-input"
            }
            key={`config-entry-${outputConfigurationEntryIndex}-${outputConfigurationEntry.title}`}
            required
            disabled={disabled}
            onChange={(value: string) => {
              let currentResults: SipocChecklistEvalOutputData[] =
                localOutputValues.filter(
                  (entry) => entry.id !== outputConfigurationEntry.id
                );
              currentResults.push({
                id: outputConfigurationEntry.id,
                value: value,
              });
              setLocalOutputValues([...currentResults]);
            }}
            value={
              localOutputValues.find(
                (entry) => entry.id === outputConfigurationEntry.id
              )?.value || ""
            }
            placeholder={outputConfigurationEntry.title}
            type="text"
          />
        );
      case SipocOutputType.CHECKBOX:
        return (
          <CheckboxComponent
            key={`config-entry-${outputConfigurationEntryIndex}-${outputConfigurationEntry.title}`}
            disabled={disabled}
            checked={hasValue(outputConfigurationEntry.id)}
            label={outputConfigurationEntry.title}
            onClick={() => {
              let currentResults: SipocChecklistEvalOutputData[] =
                localOutputValues.filter(
                  (entry) => entry.id !== outputConfigurationEntry.id
                );
              currentResults.push({
                id: outputConfigurationEntry.id,
                value: `${
                  hasValue(outputConfigurationEntry.id)
                    ? ""
                    : "---CHECKBOX-TRUE---"
                }`,
              });
              setLocalOutputValues([...currentResults]);
            }}
          />
        );
      case SipocOutputType.DATE:
        return (
          <InputComponent
            key={`config-entry-${outputConfigurationEntryIndex}-${outputConfigurationEntry.title}`}
            disabled={disabled}
            required
            onChange={(value: Date) => {
              let currentResults: SipocChecklistEvalOutputData[] =
                localOutputValues.filter(
                  (entry) => entry.id !== outputConfigurationEntry.id
                );
              if (value) {
                currentResults.push({
                  id: outputConfigurationEntry.id,
                  value: value.toString(),
                });
              }

              setLocalOutputValues([...currentResults]);
            }}
            value={getValueAsDate(outputConfigurationEntry.id) || ""}
            placeholder={outputConfigurationEntry.title}
            type="date"
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <PopupComponent
      {...popupProps}
      closeFunction={() => {
        saveChanges();
        popupProps.closeFunction();
      }}
    >
      <div className="sipoc-configuration-list-title">
        <p>{t("modules.SIPOC.outputConfiguration.files")}</p>
      </div>
      <ul className="sipoc-configuration-list">
        {localOutputFileList.map((fileName: string) => (
          <li key={`sipoc-output-file-${fileName}`}>
            <div>{getFileNameWithoutId(fileName, false)}</div>
            <div
              className={"sipoc-configuration-download"}
              onClick={() => {
                setLoaderText(
                  t(
                    "modules.SIPOC.outputConfiguration.loadingTexts.downloading"
                  )
                );
                setIsLoading(true);
                downloadProjektAkteFile(
                  fileName,
                  localProjektAkteId,
                  axios
                ).then(() => setIsLoading(false));
              }}
            >
              <DownloadIcon />
            </div>
          </li>
        ))}
      </ul>
      <div className="sipoc-configuration-list-title">
        <p>{t("modules.SIPOC.outputConfiguration.finishedFiles")}</p>
      </div>
      <ul className="sipoc-configuration-list">
        {localOutputFiles.map((fileName: string) => (
          <li key={`sipoc-output-file-${fileName}`}>
            <div>{getFileNameWithoutId(fileName, false)}</div>
            <div
              className={"sipoc-configuration-download"}
              onClick={() => {
                setLoaderText(
                  t(
                    "modules.SIPOC.outputConfiguration.loadingTexts.downloading"
                  )
                );
                setIsLoading(true);
                downloadProjektAkteFile(
                  fileName,
                  localProjektAkteId,
                  axios
                ).then(() => setIsLoading(false));
              }}
            >
              <DownloadIcon />
            </div>
            {type === ProzessTaskType.OUTPUT && (
              <div
                onClick={() => {
                  setLoaderText(
                    t("modules.SIPOC.outputConfiguration.loadingTexts.deleting")
                  );
                  setIsLoading(true);
                  deleteProjektAkteOutputFile(
                    fileName,
                    companyId,
                    localProjektAkteId,
                    axios
                  ).then((success: boolean) => {
                    if (!success) {
                      setIsLoading(false);
                      return;
                    }
                    let currentFileStrings: string[] = localOutputFiles;
                    currentFileStrings = localOutputFiles.filter(
                      (localFile) => localFile !== fileName
                    );
                    setLocalOutputFiles([...currentFileStrings]);
                    setIsLoading(false);
                  });
                }}
                title={t("general.buttons.delete")}
                className="sipoc-configuration-remove"
              >
                <DumpsterIcon />
              </div>
            )}
          </li>
        ))}
      </ul>
      {canHaveOutputConfig && (
        <ButtonComponent
          acceptedFiles="*"
          isFileUpload
          setFiles={(incomingFiles: File[]) =>
            locallyUploadFile(incomingFiles[0])
          }
          title={t("modules.SIPOC.outputConfiguration.addFile")}
        />
      )}
      {localOutputConfig.map(
        (entry: SipocOutputConfigurationEntry, entryIndex: number) =>
          getProperComponent(entry, entryIndex)
      )}
      {isLoading && <LoaderComponent inFront showText text={loaderText} />}
    </PopupComponent>
  );
};

export default SipocOutputConfigurationCheck;
