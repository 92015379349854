import {
  DropdownComponent,
  InputComponent,
  ButtonComponent,
  TimeCalculateInputComponent,
  Option,
  InputTimeUnit,
} from "beelean-component-library";
import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { GlobaleApplicationSettings } from "../../../../pages/App";
import {
  ProzessBereich,
  ProzessBereichRole,
  Prozessschritt,
  Prozessschritttype,
} from "../../../../utils/LeanAdmin/LeanAdmin.types";

interface ProzessschrittEditMetaTagProps {
  prozessSchritt: Prozessschritt;
  prozessBereich: ProzessBereich;
  onChange: Function;
  onDelete: Function;
  isReleased?: boolean;
}

const ProzessschrittEditMetaTag: React.FC<ProzessschrittEditMetaTagProps> = ({
  prozessSchritt,
  onChange,
  onDelete,
  prozessBereich,
  isReleased,
}) => {
  const { t } = useTranslation();
  const [localProzessschritt, setLocalProzessschritt] =
    useState<Prozessschritt>(prozessSchritt);
  const { isViewerState } = useContext(GlobaleApplicationSettings);
  const disabled: boolean = isReleased || isViewerState;

  useEffect(() => {
    if (!disabled) onChange(localProzessschritt);
    // eslint-disable-next-line
  }, [localProzessschritt]);

  const getCorrectInputs = (): ReactNode => {
    switch (localProzessschritt.type) {
      case Prozessschritttype.KAIZEN:
        return (
          <>
            <InputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.displayName"
              )}
              onChange={(value: string) => {
                if (value.length < 6)
                  setLocalProzessschritt({
                    ...localProzessschritt,
                    displayName: value,
                  });
              }}
              disabled={disabled}
              value={localProzessschritt.displayName || ""}
            />
            <InputComponent
              required
              disabled={disabled}
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.description"
              )}
              onChange={(value: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  beschreibung: value,
                })
              }
              value={localProzessschritt.beschreibung || ""}
            />
            <InputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.priority"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  priorisierung: value,
                })
              }
              value={
                !!localProzessschritt.priorisierung
                  ? localProzessschritt.priorisierung
                  : ""
              }
            />
          </>
        );

      case Prozessschritttype.EXTERN:
        return (
          <>
            <InputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.designation"
              )}
              disabled={disabled}
              onChange={(value: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  bezeichnung: value,
                })
              }
              value={localProzessschritt.bezeichnung || ""}
            />
            <TimeCalculateInputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.duration"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  dauer: {
                    ...localProzessschritt.dauer,
                    value: value,
                  },
                })
              }
              unit={localProzessschritt.dauer.unit as InputTimeUnit}
              onUnitChange={(newUnit: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  dauer: {
                    ...localProzessschritt.dauer,
                    unit: parseInt(newUnit) as InputTimeUnit,
                  },
                })
              }
              value={
                !!localProzessschritt.dauer.value
                  ? localProzessschritt.dauer.value
                  : ""
              }
            />
          </>
        );

      case Prozessschritttype.KUNDEN:
        return (
          <>
            <InputComponent
              required
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.displayNameCustomer"
              )}
              disabled={disabled}
              onChange={(value: string) => {
                if (value.length < 8)
                  setLocalProzessschritt({
                    ...localProzessschritt,
                    displayName: value,
                  });
              }}
              value={localProzessschritt.displayName || ""}
            />
            <InputComponent
              required
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.name"
              )}
              disabled={disabled}
              onChange={(value: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  name: value,
                })
              }
              value={localProzessschritt.name || ""}
            />
            <InputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.order"
              )}
              disabled={disabled}
              onChange={(value: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  auftrag: value,
                })
              }
              value={localProzessschritt.auftrag || ""}
            />
          </>
        );

      case Prozessschritttype.PROCESSSIMPLE:
        return (
          <>
            <InputComponent
              required
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.displayNameProcess"
              )}
              disabled={disabled}
              onChange={(value: string) => {
                if (value.length < 12)
                  setLocalProzessschritt({
                    ...localProzessschritt,
                    displayName: value,
                  });
              }}
              value={localProzessschritt.displayName || ""}
            />
            <InputComponent
              required
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.name"
              )}
              disabled={disabled}
              onChange={(value: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  name: value,
                })
              }
              value={localProzessschritt.name || ""}
            />
            <InputComponent
              required
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.workerAmount"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  workerAmount: value,
                })
              }
              value={
                !!localProzessschritt.workerAmount
                  ? localProzessschritt.workerAmount
                  : ""
              }
            />
            <InputComponent
              required
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.amountProcessstep"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  prozessSchritte: value,
                })
              }
              value={
                !!localProzessschritt.prozessSchritte
                  ? localProzessschritt.prozessSchritte
                  : ""
              }
            />
            <TimeCalculateInputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.processTime"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  prozessZeit: {
                    ...localProzessschritt.prozessZeit,
                    value: value,
                  },
                })
              }
              unit={localProzessschritt.prozessZeit.unit as InputTimeUnit}
              onUnitChange={(newUnit: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  prozessZeit: {
                    ...localProzessschritt.prozessZeit,
                    unit: parseInt(newUnit) as InputTimeUnit,
                  },
                })
              }
              value={
                !!localProzessschritt.prozessZeit.value
                  ? localProzessschritt.prozessZeit.value
                  : ""
              }
            />
            <DropdownComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.type"
              )}
              disabled={disabled}
              onChange={(option) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  type: option as Prozessschritttype,
                })
              }
              options={[
                {
                  label: t("modules.prozessMapping.prozessSchritte.PROCESS"),
                  value: Prozessschritttype.PROCESS,
                },
                {
                  label: t(
                    "modules.prozessMapping.prozessSchritte.PROCESSSIMPLE"
                  ),
                  value: Prozessschritttype.PROCESSSIMPLE,
                },
              ]}
              selectedOption={{
                label: t(
                  `modules.prozessMapping.prozessSchritte.${localProzessschritt.type}`
                ),
                value: localProzessschritt.type,
              }}
            />
          </>
        );
      case Prozessschritttype.PROCESS:
        return (
          <>
            <InputComponent
              required
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.displayNameProcess"
              )}
              disabled={disabled}
              onChange={(value: string) => {
                if (value.length < 12)
                  setLocalProzessschritt({
                    ...localProzessschritt,
                    displayName: value,
                  });
              }}
              value={localProzessschritt.displayName || ""}
            />
            <InputComponent
              required
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.name"
              )}
              onChange={(value: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  name: value,
                })
              }
              disabled={disabled}
              value={localProzessschritt.name || ""}
            />
            <InputComponent
              required
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.workerAmount"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  workerAmount: value,
                })
              }
              value={
                !!localProzessschritt.workerAmount
                  ? localProzessschritt.workerAmount
                  : ""
              }
            />
            <InputComponent
              required
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.amountProcessstep"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  prozessSchritte: value,
                })
              }
              value={
                !!localProzessschritt.prozessSchritte
                  ? localProzessschritt.prozessSchritte
                  : ""
              }
            />
            <TimeCalculateInputComponent
              required
              disabled={disabled}
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.processTime"
              )}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  prozessZeit: {
                    ...localProzessschritt.prozessZeit,
                    value: value,
                  },
                })
              }
              unit={localProzessschritt.prozessZeit.unit as InputTimeUnit}
              onUnitChange={(newUnit: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  prozessZeit: {
                    ...localProzessschritt.prozessZeit,
                    unit: parseInt(newUnit) as InputTimeUnit,
                  },
                })
              }
              value={
                !!localProzessschritt.prozessZeit.value
                  ? localProzessschritt.prozessZeit.value
                  : ""
              }
            />
            <TimeCalculateInputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.minProcessTime"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  minProzessZeit: {
                    ...localProzessschritt.minProzessZeit,
                    value: value,
                  },
                })
              }
              unit={localProzessschritt.minProzessZeit.unit as InputTimeUnit}
              onUnitChange={(newUnit: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  minProzessZeit: {
                    ...localProzessschritt.minProzessZeit,
                    unit: parseInt(newUnit) as InputTimeUnit,
                  },
                })
              }
              value={
                !!localProzessschritt.minProzessZeit.value
                  ? localProzessschritt.minProzessZeit.value
                  : ""
              }
            />
            <TimeCalculateInputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.maxProcessTime"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  maxProzessZeit: {
                    ...localProzessschritt.maxProzessZeit,
                    value: value,
                  },
                })
              }
              unit={localProzessschritt.maxProzessZeit.unit as InputTimeUnit}
              onUnitChange={(newUnit: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  maxProzessZeit: {
                    ...localProzessschritt.maxProzessZeit,
                    unit: parseInt(newUnit) as InputTimeUnit,
                  },
                })
              }
              value={
                !!localProzessschritt.maxProzessZeit.value
                  ? localProzessschritt.maxProzessZeit.value
                  : ""
              }
            />
            <TimeCalculateInputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.dwellTime"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  verweilZeit: {
                    ...localProzessschritt.verweilZeit,
                    value: value,
                  },
                })
              }
              unit={localProzessschritt.verweilZeit.unit as InputTimeUnit}
              onUnitChange={(newUnit: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  verweilZeit: {
                    ...localProzessschritt.verweilZeit,
                    unit: parseInt(newUnit) as InputTimeUnit,
                  },
                })
              }
              value={
                !!localProzessschritt.verweilZeit.value
                  ? localProzessschritt.verweilZeit.value
                  : ""
              }
            />
            <InputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.consultationRate"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  rueckfrageQuote: value,
                })
              }
              value={
                !!localProzessschritt.rueckfrageQuote
                  ? localProzessschritt.rueckfrageQuote
                  : ""
              }
            />
            <InputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.qualityQuota"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  qualitaetsQuote: value,
                })
              }
              value={
                !!localProzessschritt.qualitaetsQuote
                  ? localProzessschritt.qualitaetsQuote
                  : ""
              }
            />
            <DropdownComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.type"
              )}
              disabled={disabled}
              onChange={(option) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  type: option as Prozessschritttype,
                })
              }
              options={[
                {
                  label: t("modules.prozessMapping.prozessSchritte.PROCESS"),
                  value: Prozessschritttype.PROCESS,
                },
                {
                  label: t(
                    "modules.prozessMapping.prozessSchritte.PROCESSSIMPLE"
                  ),
                  value: Prozessschritttype.PROCESSSIMPLE,
                },
              ]}
              selectedOption={{
                label: t(
                  `modules.prozessMapping.prozessSchritte.${localProzessschritt.type}`
                ),
                value: localProzessschritt.type,
              }}
            />
          </>
        );

      case Prozessschritttype.BESTAND:
      case Prozessschritttype.BESTANDEDV:
      case Prozessschritttype.BESTANDPHYSISCH:
      case Prozessschritttype.BESTANDPHYSISCHEDV:
        return (
          <>
            <TimeCalculateInputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.layTime"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  durchlaufZeit: {
                    ...localProzessschritt.durchlaufZeit,
                    value: value,
                  },
                })
              }
              unit={localProzessschritt.durchlaufZeit.unit as InputTimeUnit}
              onUnitChange={(newUnit: string) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  durchlaufZeit: {
                    ...localProzessschritt.durchlaufZeit,
                    unit: parseInt(newUnit) as InputTimeUnit,
                  },
                })
              }
              value={
                !!localProzessschritt.durchlaufZeit.value
                  ? localProzessschritt.durchlaufZeit.value
                  : ""
              }
            />
            <InputComponent
              placeholder={t(
                "modules.prozessMapping.prozessSchritte.metaTag.operations"
              )}
              disabled={disabled}
              type="number"
              onChange={(value: number) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  vorgaenge: value,
                })
              }
              value={
                !!localProzessschritt.vorgaenge
                  ? localProzessschritt.vorgaenge
                  : ""
              }
            />
            <DropdownComponent
              onChange={(option) =>
                setLocalProzessschritt({
                  ...localProzessschritt,
                  type: option as Prozessschritttype,
                })
              }
              disabled={disabled}
              options={[
                {
                  label: t("modules.prozessMapping.prozessSchritte.BESTAND"),
                  value: Prozessschritttype.BESTAND,
                },
                {
                  label: t("modules.prozessMapping.prozessSchritte.BESTANDEDV"),
                  value: Prozessschritttype.BESTANDEDV,
                },
                {
                  label: t(
                    "modules.prozessMapping.prozessSchritte.BESTANDPHYSISCH"
                  ),
                  value: Prozessschritttype.BESTANDPHYSISCH,
                },
                {
                  label: t(
                    "modules.prozessMapping.prozessSchritte.BESTANDPHYSISCHEDV"
                  ),
                  value: Prozessschritttype.BESTANDPHYSISCHEDV,
                },
              ]}
              selectedOption={{
                label: t(
                  `modules.prozessMapping.prozessSchritte.${localProzessschritt.type}`
                ),
                value: localProzessschritt.type,
              }}
            />
          </>
        );

      default:
        return (
          <p>
            <Trans i18nKey="modules.prozessMapping.prozessSchritte.metaTag.noData">
              Keine Metatags für diesen Typ
            </Trans>
          </p>
        );
    }
  };

  /**
   * Helper to generate dropdown entries
   * @returns generated dropdown entries
   */
  const generateOptionsForDropdown = (): Option[] => {
    if (prozessBereich.version! >= 2) {
      return prozessBereich.bereichRoles
        .filter((item) => item.id !== process.env.REACT_APP_FIRST_ROW_ID!)
        .map((role: ProzessBereichRole) => {
          return { label: role.name, value: role.id };
        });
    } else
      return prozessBereich.roles.map((role: string) => {
        return { label: role, value: role };
      });
  };

  /**
   * Get the correct role for the dropdown
   * @param role  role to check
   * @returns   correct role as dropdown option
   */
  const getCorrectRole = (role: string): Option => {
    if (prozessBereich.version! >= 2) {
      const foundRole = prozessBereich.bereichRoles.find(
        (item) => item.id === role
      );
      if (foundRole)
        return {
          label: foundRole.name,
          value: foundRole.id,
        };
      else
        return {
          label: "",
          value: "",
        };
    } else
      return {
        label: role,
        value: role,
      };
  };

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      {getCorrectInputs()}
      {localProzessschritt.type !== Prozessschritttype.KAIZEN && (
        <>
          <InputComponent
            placeholder={t(
              "modules.prozessMapping.prozessSchritte.metaTag.position"
            )}
            disabled={disabled}
            type="number"
            min={-1}
            onChange={(value: number) =>
              setLocalProzessschritt({
                ...localProzessschritt,
                position: value,
              })
            }
            value={
              localProzessschritt.position === -1
                ? ""
                : localProzessschritt.position
            }
          />
          {localProzessschritt.type !== Prozessschritttype.KUNDEN && (
            <DropdownComponent
              onChange={(option) => {
                setLocalProzessschritt({
                  ...localProzessschritt,
                  role: option,
                });
              }}
              disabled={disabled}
              options={generateOptionsForDropdown()}
              selectedOption={getCorrectRole(localProzessschritt.role)}
            />
          )}
          <InputComponent
            placeholder={t(
              "modules.prozessMapping.prozessSchritte.metaTag.note"
            )}
            disabled={disabled}
            onChange={(value: string) => {
              setLocalProzessschritt({
                ...localProzessschritt,
                note: value,
              });
            }}
            value={localProzessschritt.note}
          />
        </>
      )}
      {disabled || (
        <ButtonComponent
          title={t("general.buttons.delete")}
          background="#ee4f4b"
          type="button"
          onClick={() => onDelete(prozessSchritt.id)}
        />
      )}
    </form>
  );
};

export default ProzessschrittEditMetaTag;
