/**
 * Groups all items in an array of objects `T` where the value of property `K` is the same
 * @param array Items to group
 * @param key Key of `T` to group by
 */
export const groupBy = <T, K extends keyof T>(array: T[], key: K) => {
  let map = new Map<T[K], T[]>();
  array.forEach((item) => {
    let itemKey = item[key];
    if (!map.has(itemKey)) {
      map.set(
        itemKey,
        array.filter((i) => i[key] === item[key])
      );
    }
  });
  return map;
};

/**
 * helper method to format datetime to string
 * @param date to format, can be undefined, will return empty string then
 * @returns string representation of date, or emptry string
 */
export const getFormattedDateTimeString = (date?: Date): string =>
  date
    ? date.toLocaleString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })
    : "";
