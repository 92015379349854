import { AxiosInstance } from "axios";
import { InputTimeUnit } from "beelean-component-library";
import i18next from "i18next";
import { Vector2d } from "konva/types/types";
import { RefObject, useEffect, useState } from "react";
import i18n from "../../i18n";
import { increaseProcessLicenseCounter } from "../CompanyUtil";
import { Company } from "../Interfaces";
import { generateNotification } from "../NotificationUtil";
import {
  ProzessBereich,
  ProzessBereichRole,
  ProzessMappingEntry,
  Prozesslinie,
  ProzesslinieType,
  Prozessschritt,
  Prozessschritttype,
  createEmptyProzess,
  createEmptyProzessMappingEntry,
} from "./LeanAdmin.types";
import { updateLocalEg } from "./LeanAdminModulUtils";
import { getAmountOfCorrectEgSipocAndLength } from "./SipocEvalUtils";

/**
 * Fetches Prozess Mapping on server
 *
 * @param companyId
 * @param prozessBereichId
 * @param axios
 * @returns
 */
export const getProzessMappingEntry = async (
  companyId: string,
  prozessBereichId: string,
  axios: AxiosInstance
): Promise<ProzessMappingEntry> => {
  return axios
    .get("/data/processmapping/", {
      params: {
        companyId: companyId,
        prozessBereichId: prozessBereichId,
      },
    })
    .then((prozessMappingEntryResponse) => prozessMappingEntryResponse.data)
    .catch((exc) => {
      console.error("Error during Prozess Mapping fetching!", exc);
      return null;
    });
};

/**
 * Creates Prozess Mapping on server
 *
 * @param newProzessMappingEntry
 * @param axios
 * @returns
 */
export const createProzessMappingEntry = async (
  newProzessMappingEntry: ProzessMappingEntry,
  updaterId: string,
  axios: AxiosInstance
): Promise<ProzessMappingEntry> => {
  return axios
    .post(
      "/data/processmapping/",
      fillLastUpdatedBy({ ...newProzessMappingEntry }, updaterId)
    )
    .then((prozessMappingEntryResponse) => prozessMappingEntryResponse.data)
    .catch((exc) => {
      console.error("Error during Prozess Mapping fetching!", exc);
      return null;
    });
};

/**
 * Updates Prozess Mapping on server
 *
 * @param updateProzessMappingEntry
 * @param axios
 * @returns
 */
export const updateProzessMappingEntry = async (
  updateProzessMappingEntry: ProzessMappingEntry,
  updaterId: string,
  axios: AxiosInstance
): Promise<number> => {
  return axios
    .post("/data/processmapping/update/", {
      updatedMappingEntry: updateProzessMappingEntry,
      updatedBy: updaterId,
    })
    .then((prozessMappingEntryResponse) => prozessMappingEntryResponse.status)
    .catch((exc) => {
      console.error("Error during Prozess Mapping fetching!", exc);
      return -1;
    });
};

/**
 * Generates copy of a Prozess Mapping on server
 *
 * @param originalProzessbereichId
 * @param newProzessbereichId
 * @param updaterId
 * @param axios
 * @param companyId
 * @returns
 */
export const copyProzessMappingEntry = async (
  originalProzessbereichId: string,
  newProzessbereichId: string,
  updaterId: string,
  axios: AxiosInstance,
  companyId?: string
): Promise<number> => {
  return axios
    .post("/data/processmapping/copy/", undefined, {
      params: {
        oldProzessBereichsId: originalProzessbereichId,
        newProzessBereichsId: newProzessbereichId,
        updaterId: updaterId,
        companyId: companyId,
      },
    })
    .then((prozessMappingEntryResponse) => prozessMappingEntryResponse.status)
    .catch((error) => {
      console.error("Error during copying Prozess Mapping!", error);
      return error.response.status;
    });
};

/**
 * converts a number to correct unit string
 *
 * @param unit
 * @returns s | m | h | d
 * @tested
 */
export const mapTimeUnitToText = (unit: InputTimeUnit): string => {
  switch (unit) {
    case 1:
      return "s";
    case 60:
      return "m";
    case 3600:
      return "h";
    case 28800:
      return "d";

    default:
      return "";
  }
};

/**
 * Hook to listen for resizes and recalculate the width and height.
 *
 * @param myRef The ref to resize
 */
export const useResize = (myRef: React.RefObject<any>) => {
  const [width, setWidth] = useState<number>(0);
  const [height, setHeight] = useState<number>(0);
  const [windowHeight, setWindowHeight] = useState<number>(0);

  const reloadStageSize = (): void => {
    setWidth(myRef.current.offsetWidth);
    setHeight(myRef.current.offsetHeight);
    setWindowHeight(window.innerHeight);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(myRef.current.offsetWidth);
      setHeight(myRef.current.offsetHeight);
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return { width, height, windowHeight, reloadStageSize };
};

/**
 * Prevents a not working right click
 *
 * @param event
 * @returns
 */
export const isRightClick = (event: any): boolean => {
  return event.evt.button === 2;
};

/**
 * fetches the current coords of a Prozessschritt by id
 *
 * @param processSchrittId
 * @param stageRefObject
 * @returns found coords ot [0,0]
 */
export const getAbsolutePositionOfProzessschritt = (
  processSchrittId: string,
  stageRefObject: RefObject<any>
): number[] => {
  if (!stageRefObject) return [0, 0];
  let foundNode = stageRefObject.current.find("#" + processSchrittId);
  // if not found dont do it
  if (!foundNode[0]) return [0, 0];
  return [foundNode[0].attrs.x + 40, foundNode[0].attrs.y + 40];
};

/**
 * Calulate the drag bounce for the stage
 *
 * @param position
 * @param width
 * @param actualWidth
 * @param height
 * @param SwimmlaneCount
 * @returns new position
 */
export const calculateDragBoundariesForStage = (
  position: Vector2d,
  width: number,
  actualWidth: number,
  height: number,
  SwimmlaneCount: number
): number[] => {
  let localX: number = position.x;
  let localY: number = position.y;
  // no swiping to left or right
  if (actualWidth > width) {
    if (position.x > 0) {
      localX = 0;
    } else if (position.x < (actualWidth - width) * -1) {
      localX = -1 * (actualWidth - width);
    }
  } else {
    localX = 0;
  }

  // no swiping to up or down
  if (height < SwimmlaneCount * 100) {
    if (position.y > 0) {
      localY = 0;
    } else if (position.y < (SwimmlaneCount * 100 - height) * -1) {
      localY = -1 * (SwimmlaneCount * 100 - height);
    }
  } else {
    localY = 0;
  }
  // normal swipe
  return [localX, localY];
};

/**
 * calulates the needed min width for canvas
 *
 * @param prozessschritte
 * @param width
 * @returns width for canvas
 * @tested
 */
export const getCorrectWidthForContent = (
  prozessschritte: Prozessschritt[],
  width: number
): number => {
  let highestPosition: number = 0;
  prozessschritte.forEach((p: Prozessschritt) => {
    if (p.position > highestPosition) {
      highestPosition = p.position;
    }
  });
  // calculate result widths
  const prozessLengthWidth: number = prozessschritte.length * 100 + 40;
  const highestWidth: number = (highestPosition + 1) * 100 + 40;

  if (width > highestWidth && width > prozessLengthWidth) {
    return width;
  } else if (highestWidth > prozessLengthWidth) {
    return highestWidth;
  } else {
    return prozessLengthWidth;
  }
};

/**
 * Helper returns the right x offset for x axis
 *
 * @param prozessSchritt
 * @param xIndex
 * @returns right number of x offset
 * @tested
 */
export const getCurrentXOffset = (prozessSchritt: Prozessschritt): number => {
  return prozessSchritt.type === Prozessschritttype.KAIZEN
    ? prozessSchritt.coords![0]
    : prozessSchritt.position! * 100 + 50;
};

/**
 * Helper to get the right swimmlane in Stage
 *
 * @param prozessBereichRoles
 * @param prozessSchritt
 * @returns index of string in array
 * @tested
 */
export const getCurrentYOffset = (
  prozessBereich: ProzessBereich,
  prozessSchritt: Prozessschritt
): number => {
  if (prozessSchritt.type === Prozessschritttype.KAIZEN)
    return prozessSchritt.coords![1];
  if (prozessBereich.version! >= 2) {
    const localProzessBereichRoleList: ProzessBereichRole[] = [
      ...prozessBereich.bereichRoles,
    ];
    const foundIndex: number = localProzessBereichRoleList.findIndex(
      (item) => item.id === prozessSchritt.role
    );
    if (foundIndex === -1) return 10;
    return (localProzessBereichRoleList[foundIndex].position + 1) * 100 + 10;
  }
  let currentRoleIndex: number = [
    i18next.t("modules.prozessMapping.roles.customer"),
    ...prozessBereich.roles,
  ].indexOf(prozessSchritt.role);
  if (prozessSchritt.type === Prozessschritttype.KUNDEN || currentRoleIndex < 0)
    currentRoleIndex = 0;
  return currentRoleIndex * 100 + 10;
};

/**
 * Helper to get the amount of {@link Prozessline}s which
 * start or stop at this {@link Prozesschritt}
 * @param prozessLinies all loaded {@link Prozessline}s
 * @param prozessSchrittId current id to check for
 * @returns number array with calculated data
 */
const getStartStopAmountInProzessLinies = (
  prozessLinies: Prozesslinie[],
  prozessSchrittId: string
) => {
  const startCount: number = prozessLinies.filter(
    (prozessLinie: Prozesslinie) =>
      prozessLinie.startProcessId === prozessSchrittId
  ).length;
  const stopCount: number = prozessLinies.filter(
    (prozessLinie: Prozesslinie) =>
      prozessLinie.stopProcessId === prozessSchrittId
  ).length;
  return [startCount, stopCount];
};

/**
 * Checks if a Prozessschritt is valid by needed data from Excel sheet
 *
 * @returns Prozessschritt valid status
 * @tested
 */
export const isProzessschrittValid = (
  prozessSchritt: Prozessschritt,
  prozessLinies: Prozesslinie[]
): boolean => {
  const inputOutputProzessLinieAmount: number[] =
    getStartStopAmountInProzessLinies(prozessLinies, prozessSchritt.id);
  switch (prozessSchritt.type) {
    case Prozessschritttype.PROCESS:
      return (
        inputOutputProzessLinieAmount[0] > 0 &&
        inputOutputProzessLinieAmount[1] > 0 &&
        !!prozessSchritt.name &&
        !!prozessSchritt.displayName &&
        prozessSchritt.workerAmount! > 0 &&
        prozessSchritt.prozessSchritte! > 0 &&
        prozessSchritt.prozessZeit!.value > 0
      );
    case Prozessschritttype.KUNDEN:
      return !!prozessSchritt.name;
    case Prozessschritttype.KAIZEN:
      return !!prozessSchritt.beschreibung;
    case Prozessschritttype.PROCESSSIMPLE:
      return (
        inputOutputProzessLinieAmount[0] > 0 &&
        inputOutputProzessLinieAmount[1] > 0 &&
        !!prozessSchritt.name &&
        !!prozessSchritt.displayName &&
        prozessSchritt.workerAmount! > 0 &&
        prozessSchritt.prozessSchritte! > 0
      );
    case Prozessschritttype.BESTAND:
    case Prozessschritttype.BESTANDEDV:
    case Prozessschritttype.BESTANDPHYSISCH:
    case Prozessschritttype.EXTERN:
    case Prozessschritttype.BESTANDPHYSISCHEDV:
    default:
      return true;
  }
};

/**
 * validates if a selected type of Prozessschritt is allowed to be created
 * in a swimmlane
 *
 * @param swimmlaneIndex
 * @param selectedType
 * @returns true if it is allowed false if otherwise
 * @tested
 */
export const isAllowedToCreateProzessschritt = (
  swimmlaneIndex: number,
  selectedType: Prozessschritttype
): boolean => {
  if (
    swimmlaneIndex === 0 &&
    selectedType !== Prozessschritttype.KUNDEN &&
    selectedType !== Prozessschritttype.KAIZEN
  ) {
    return false;
  } else if (
    selectedType === Prozessschritttype.KUNDEN &&
    swimmlaneIndex !== 0
  ) {
    return false;
  }
  return true;
};

/**
 * generates a new Prozessschritt in the swimmlanes by type
 *
 * @param event
 * @param role
 * @param swimmlaneIndex
 * @param xIndex
 * @param selectedType
 * @param loadedProzessMappingEntry
 * @param stageRefObject
 * @returns
 */
export const generateNewEmptyProzessschritt = async (
  event: any,
  role: string,
  swimmlaneIndex: number,
  xIndex: number,
  selectedType: Prozessschritttype,
  loadedProzessMappingEntry: ProzessMappingEntry,
  stageRefObject: RefObject<any>,
  axios: AxiosInstance,
  companyId: string,
  userCompany: Company,
  setUserCompany: Function
): Promise<ProzessMappingEntry> => {
  let localLoadedProzessMappingEntry: ProzessMappingEntry =
    loadedProzessMappingEntry;
  // just do something if it is allowed
  if (isAllowedToCreateProzessschritt(swimmlaneIndex, selectedType)) {
    let localProzessschritte: Prozessschritt[] =
      localLoadedProzessMappingEntry.prozessSchritte;

    switch (selectedType) {
      case Prozessschritttype.PROCESSSIMPLE:
      case Prozessschritttype.PROCESS:
        // Dont create something if not allowed
        if (!(await increaseProcessLicenseCounter(companyId, axios))) {
          generateNotification(
            i18next.t("notifications.stammdatumCreate.errorTitle"),
            i18next.t("notifications.stammdatumCreate.errorContentLicense"),
            "danger",
            -1
          );
          return localLoadedProzessMappingEntry;
        }
        setUserCompany({
          ...userCompany,
          createdProcesses: userCompany.createdProcesses + 1,
        });
        let prozessSchritt = createEmptyProzess(role, selectedType, xIndex);
        prozessSchritt.prozessSchritte = 1;
        prozessSchritt.workerAmount = 1;
        localProzessschritte.push(prozessSchritt);
        break;
      case Prozessschritttype.KUNDEN:
      case Prozessschritttype.EXTERN:
        localProzessschritte.push(
          createEmptyProzess(role, selectedType, xIndex)
        );
        break;
      case Prozessschritttype.KAIZEN:
        if (
          event.target.attrs &&
          event.target.attrs.id !== stageRefObject.current.attrs.id
        )
          return localLoadedProzessMappingEntry;
        let localProzess: Prozessschritt;
        let currentCoords = stageRefObject.current.getPointerPosition();
        localProzess = createEmptyProzess(role, selectedType, xIndex);
        localProzess.coords = [currentCoords.x, currentCoords.y];
        localProzessschritte.push(localProzess);
        break;
      case Prozessschritttype.BESTAND:
      case Prozessschritttype.BESTANDEDV:
      case Prozessschritttype.BESTANDPHYSISCH:
      case Prozessschritttype.BESTANDPHYSISCHEDV:
        // Dont create something if not allowed
        if (!(await increaseProcessLicenseCounter(companyId, axios))) {
          generateNotification(
            i18next.t("notifications.stammdatumCreate.errorTitle"),
            i18next.t("notifications.stammdatumCreate.errorContentLicense"),
            "danger",
            -1
          );
          return localLoadedProzessMappingEntry;
        }
        let initialBestandProzessschritt: Prozessschritt = createEmptyProzess(
          role,
          selectedType,
          xIndex
        );
        let followingProzessschritt: Prozessschritt = createEmptyProzess(
          role,
          Prozessschritttype.PROCESS,
          xIndex + 1
        );
        setUserCompany({
          ...userCompany,
          createdProcesses: userCompany.createdProcesses + 1,
        });
        // set link to Bestand and Prozess
        followingProzessschritt.bestandLink = initialBestandProzessschritt.id;
        initialBestandProzessschritt.processLink = followingProzessschritt.id;
        localProzessschritte.push(initialBestandProzessschritt);
        localProzessschritte.push(followingProzessschritt);
        break;
      default:
        console.log("no type selected", selectedType);
        return localLoadedProzessMappingEntry;
    }

    localLoadedProzessMappingEntry.prozessSchritte = localProzessschritte;
    return localLoadedProzessMappingEntry;
  } else return localLoadedProzessMappingEntry;
};

/**
 * Calulates the correct numbers for eg calculation of ProzessMapping
 *
 * @param prozessSchritte
 * @param prozessLinies
 * @returns array of number [correctProzessschritte, checkedProzessschritteAmount]
 * @tested
 */
export const getAmountOfCorrectEgProzessMappingAndLength = (
  prozessSchritte: Prozessschritt[],
  prozessLinies: Prozesslinie[]
): number[] => {
  let egProzessMapping: number = 0;
  let localProzessSchritte: Prozessschritt[] = prozessSchritte;
  localProzessSchritte = prozessSchritte.filter(
    (prozessSchritt: Prozessschritt) =>
      prozessSchritt.type === Prozessschritttype.PROCESS ||
      prozessSchritt.type === Prozessschritttype.PROCESSSIMPLE
  );
  localProzessSchritte.forEach((prozessSchritt: Prozessschritt) => {
    if (isProzessschrittValid(prozessSchritt, prozessLinies))
      egProzessMapping = egProzessMapping + 1;
  });
  return [egProzessMapping, localProzessSchritte.length];
};

/**
 * save it to the server
 *
 * @param currentProzessMapping
 * @param axios
 */
export const saveProzessMappingToServer = (
  loadedProzessBereiche: ProzessBereich[],
  updateProzessBereicheFunction: Function,
  currentProzessMapping: ProzessMappingEntry,
  updaterId: string,
  axios: AxiosInstance
) => {
  // update EG
  updateEgOnServer(
    loadedProzessBereiche,
    updateProzessBereicheFunction,
    currentProzessMapping,
    axios
  );
  updateProzessMappingEntry(currentProzessMapping, updaterId, axios);
};

/**
 * checks if a movement is so small that it can be ignored
 *
 * @param fixedX static x coord from image
 * @param fixedY static y coord from image
 * @param movedX x coord from the event
 * @param movedY y coord from the event
 * @returns true if it is above the tolerance false if not
 * @tested
 */
export const isMovementIsAboveTolerance = (
  fixedX: number,
  fixedY: number,
  movedX: number,
  movedY: number
): boolean => {
  return (
    Math.abs(fixedX - movedX) >
      Number(process.env.REACT_APP_MOVEMENT_TOLERANCE) ||
    Math.abs(fixedY - movedY) > Number(process.env.REACT_APP_MOVEMENT_TOLERANCE)
  );
};

/**
 * Calculates and updates the whole eg for sipoc and prozess mapping
 *
 * @param loadedProzessBereiche
 * @param updateProzessBereicheFunction
 * @param currentProzessMapping
 * @param axios
 * @returns statuscode
 */
export const updateEgOnServer = (
  loadedProzessBereiche: ProzessBereich[],
  updateProzessBereicheFunction: Function,
  currentProzessMapping: ProzessMappingEntry,
  axios: AxiosInstance
): Promise<number> => {
  const neededProzesslinien: Prozesslinie[] =
    currentProzessMapping.prozessLinie.filter(
      (prozessLinie: Prozesslinie) =>
        prozessLinie.type !== ProzesslinieType.QUALITAETSMANGEL &&
        prozessLinie.type !== ProzesslinieType.RUECKFRAGE
    );

  const egProzessMappingCalculations: number[] =
    getAmountOfCorrectEgProzessMappingAndLength(
      currentProzessMapping.prozessSchritte,
      currentProzessMapping.prozessLinie
    );
  const egSipocCalculations: number[] = getAmountOfCorrectEgSipocAndLength(
    currentProzessMapping.prozessSchritte,
    neededProzesslinien
  );
  return axios
    .post("/data/prozessbereich/update/eg/", {
      prozessBereichId: currentProzessMapping.prozessBereichId,
      correcEgSipoc: egSipocCalculations[0],
      totalEgSipoc: egSipocCalculations[1],
      correcEgProzessMapping: egProzessMappingCalculations[0],
      totalEgProzessMapping: egProzessMappingCalculations[1],
    })
    .then((prozessBereichResponse) => {
      if (prozessBereichResponse.status === 200)
        // do it locally too
        updateLocalEg(
          loadedProzessBereiche,
          updateProzessBereicheFunction,
          currentProzessMapping.prozessBereichId,
          egProzessMappingCalculations[0],
          egProzessMappingCalculations[1],
          egSipocCalculations[0],
          egSipocCalculations[1]
        );
      return prozessBereichResponse.status || -1;
    })
    .catch((exc) => {
      console.error("Error while EG update", exc);
      return exc.response?.status || -1;
    });
};

/**
 * load and sets ProzessMappingEntry
 *
 * @param companyId
 * @param prozessBereichId
 * @param axios
 * @param loadedProzessBereiche
 * @param setLeanAdminProzessbereiche
 * @returns right Prozess Mappign Entry
 */
export const fetchNeededProzessMappingEntry = async (
  companyId: string,
  prozessBereichId: string,
  axios: AxiosInstance,
  loadedProzessBereiche: ProzessBereich[],
  setLeanAdminProzessbereiche: Function,
  updaterId: string
): Promise<ProzessMappingEntry> => {
  let localProzessMappingEntry: ProzessMappingEntry =
    createEmptyProzessMappingEntry(companyId, prozessBereichId);
  return getProzessMappingEntry(companyId, prozessBereichId, axios).then(
    (prozessMappingEntryResponse) => {
      // just set the data if there is data
      if (prozessMappingEntryResponse) return prozessMappingEntryResponse;
      // if ProcessMappignEntry is not found just create a new one
      else {
        return createProzessMappingEntry(
          localProzessMappingEntry,
          updaterId,
          axios
        ).then((newCreatedProzessMappingEntry) =>
          // add Prozessschritt Kunde is needed
          {
            const foundIndex: number = loadedProzessBereiche.findIndex(
              (item) => item.id === prozessBereichId
            );

            // set definition role
            let usedRole: string = "";
            if (
              foundIndex !== -1 &&
              loadedProzessBereiche[foundIndex].version! < 2
            ) {
              usedRole = i18next.t("modules.prozessMapping.roles.customer");
            } else if (
              foundIndex !== -1 &&
              loadedProzessBereiche[foundIndex].version! >= 2
            ) {
              usedRole = process.env.REACT_APP_FIRST_ROW_ID!;
            }

            newCreatedProzessMappingEntry = {
              ...newCreatedProzessMappingEntry,
              prozessSchritte: [
                createEmptyProzess(usedRole, Prozessschritttype.KUNDEN, 0),
              ],
            };
            saveProzessMappingToServer(
              loadedProzessBereiche,
              setLeanAdminProzessbereiche,
              newCreatedProzessMappingEntry,
              updaterId,
              axios
            );
            return newCreatedProzessMappingEntry;
          }
        );
      }
    }
  );
};

/**
 * Updates a Prozesslinie in the ProzessMapping Entry
 *
 * @param updatedProzessLinie
 * @param loadedProzessMappingEntry
 * @returns changed Prozess Mapping Object
 * @tested
 */
export const updateProzessLinie = (
  updatedProzessLinie: Prozesslinie,
  loadedProzessMappingEntry: ProzessMappingEntry
): ProzessMappingEntry => {
  let localProzesslinie: Prozesslinie[] =
    loadedProzessMappingEntry.prozessLinie;
  let foundIndex: number = localProzesslinie.findIndex(
    (value: Prozesslinie) => value.id === updatedProzessLinie.id
  );
  if (foundIndex !== -1) localProzesslinie[foundIndex] = updatedProzessLinie;
  return {
    ...loadedProzessMappingEntry,
    prozessLinie: localProzesslinie,
  };
};

/**
 * Removes a Prozesschritt from Prozess Mapping with all Prozesslinien
 *
 * @param prozessSchrittId
 * @param loadedProzessMappingEntry
 * @returns changed Prozess Mapping Object
 * @tested
 */
export const removeProzessschrittInProzessMappingEntry = (
  prozessSchrittId: string,
  loadedProzessMappingEntry: ProzessMappingEntry
): ProzessMappingEntry => {
  const newProzesslinieListe: Prozesslinie[] =
    loadedProzessMappingEntry.prozessLinie.filter(
      (value: Prozesslinie) =>
        value.startProcessId !== prozessSchrittId &&
        value.stopProcessId !== prozessSchrittId
    );
  const newProzessschrittListe: Prozessschritt[] =
    loadedProzessMappingEntry.prozessSchritte.filter(
      (value: Prozessschritt) => value.id !== prozessSchrittId
    );
  return {
    ...loadedProzessMappingEntry,
    prozessLinie: newProzesslinieListe,
    prozessSchritte: newProzessschrittListe,
  };
};

/**
 * Updates a Prozessschritt in the ProzessMapping Entry
 *
 * @param updatedProzessSchritt
 * @param loadedProzessMappingEntry
 * @returns changed Prozess Mapping Object
 * @tested
 */
export const updateProzessschrittInProzessMappingEntry = (
  updatedProzessSchritt: Prozessschritt,
  loadedProzessMappingEntry: ProzessMappingEntry
): ProzessMappingEntry => {
  let localProzessschritte: Prozessschritt[] =
    loadedProzessMappingEntry.prozessSchritte;
  let foundIndex: number = localProzessschritte.findIndex(
    (value: Prozessschritt) => value.id === updatedProzessSchritt.id
  );
  if (foundIndex !== -1)
    localProzessschritte[foundIndex] = updatedProzessSchritt;
  return {
    ...loadedProzessMappingEntry,
    prozessSchritte: localProzessschritte,
  };
};

/**
 * Helper for the configuration of a Prozesslinie
 *
 * @param type
 * @returns configuration data for Prozesslinie
 */
export const getCorrectConfigurationForLinie = (
  type: ProzesslinieType
): {
  color: string;
  dash: number[];
  strokeWidth: number;
  border: boolean;
  curvy: boolean;
  bezier: boolean;
} => {
  switch (type) {
    case ProzesslinieType.RUECKFRAGE:
      return {
        color: "#00000080",
        dash: [10, 10],
        strokeWidth: 2,
        border: false,
        curvy: true,
        bezier: false,
      };
    case ProzesslinieType.DIENSTLEISTUNGSLIEFERUNG_MATERIAL:
      return {
        color: "#00000080",
        dash: [],
        strokeWidth: 8,
        border: true,
        curvy: false,
        bezier: false,
      };
    case ProzesslinieType.INFORMATIONSFLUSS_EDV:
      return {
        color: "#00000080",
        dash: [],
        strokeWidth: 2,
        border: false,
        curvy: false,
        bezier: false,
      };
    case ProzesslinieType.QUALITAETSMANGEL:
      return {
        color: "#00000080",
        dash: [],
        strokeWidth: 2,
        border: false,
        curvy: false,
        bezier: true,
      };
    case ProzesslinieType.INFORMATIONSFLUSS_PAPIER:
    default:
      return {
        color: "#00000080",
        dash: [],
        strokeWidth: 2,
        border: false,
        curvy: false,
        bezier: false,
      };
  }
};

/**
 * Helper to get the correct Tooltip for a prozess linie
 *
 * @param prozessLinie
 * @returns the correct text for a possible Tooltip
 */
export const getCorrectProzessLinieTooltipText = (
  prozessLinie: Prozesslinie
): string => {
  switch (prozessLinie.type) {
    case ProzesslinieType.DIENSTLEISTUNGSLIEFERUNG_MATERIAL:
      return (
        (prozessLinie.lieferzeit.value === 0
          ? ""
          : `${i18next.t(
              "modules.prozessMapping.prozessLine.deliveryTimeShort"
            )}: ${
              prozessLinie.lieferzeit.value / prozessLinie.lieferzeit.unit
            }${mapTimeUnitToText(prozessLinie.lieferzeit.unit)}\n`) +
        (prozessLinie.zusaetzlicherAufwand === 0
          ? ""
          : prozessLinie.zusaetzlicherAufwand + "\n") +
        (prozessLinie.vorfaelle === 0 ? "" : prozessLinie.vorfaelle)
      );
    case ProzesslinieType.INFORMATIONSFLUSS_PAPIER:
      return prozessLinie.uebergabeZeit.value === 0
        ? ""
        : `${i18next.t("modules.prozessMapping.prozessLine.handoverTime")}: ${
            prozessLinie.uebergabeZeit.value / prozessLinie.uebergabeZeit.unit
          }${mapTimeUnitToText(prozessLinie.uebergabeZeit.unit)}`;
    case ProzesslinieType.RUECKFRAGE:
      return prozessLinie.dauer.value === 0
        ? ""
        : `${i18next.t("modules.prozessMapping.prozessLine.duration")}: ${
            prozessLinie.dauer.value / prozessLinie.dauer.unit
          }${mapTimeUnitToText(prozessLinie.dauer.unit)}`;
    case ProzesslinieType.QUALITAETSMANGEL:
    case ProzesslinieType.INFORMATIONSFLUSS_EDV:
    default:
      return "";
  }
};

/**
 * Helper to get the visible text over the prozess linie
 *
 * @param prozessLinie
 * @returns text for displaying in stage
 */
export const getVisibleProzessLinieText = (
  prozessLinie: Prozesslinie
): string => {
  switch (prozessLinie.type) {
    case ProzesslinieType.RUECKFRAGE:
      return prozessLinie.dauer.value === 0
        ? ""
        : prozessLinie.dauer.value / prozessLinie.dauer.unit +
            mapTimeUnitToText(prozessLinie.dauer.unit);
    case ProzesslinieType.INFORMATIONSFLUSS_PAPIER:
      return prozessLinie.uebergabeZeit.value === 0
        ? ""
        : prozessLinie.uebergabeZeit.value / prozessLinie.uebergabeZeit.unit +
            mapTimeUnitToText(prozessLinie.uebergabeZeit.unit);
    case ProzesslinieType.DIENSTLEISTUNGSLIEFERUNG_MATERIAL:
      return (
        (prozessLinie.lieferzeit.value === 0
          ? ""
          : prozessLinie.lieferzeit.value / prozessLinie.lieferzeit.unit +
            mapTimeUnitToText(prozessLinie.lieferzeit.unit) +
            " - ") +
        (prozessLinie.zusaetzlicherAufwand === 0
          ? ""
          : prozessLinie.zusaetzlicherAufwand + " - ") +
        (prozessLinie.vorfaelle === 0 ? "" : prozessLinie.vorfaelle)
      );
    case ProzesslinieType.QUALITAETSMANGEL:
    case ProzesslinieType.INFORMATIONSFLUSS_EDV:
    default:
      return "";
  }
};

/**
 * Helper to fetch all ids of Kunden Prozessschritte
 *
 * @param loadedProzessschritte
 * @returns list of ids of Kunden Prozesschritte
 * @tested
 */
export const generatelistOfAllKundenIds = (
  loadedProzessschritte: Prozessschritt[]
): string[] => {
  let targetArray: string[] = [];
  loadedProzessschritte
    .filter(
      (prozessSchritt) => prozessSchritt.type === Prozessschritttype.KUNDEN
    )
    .forEach((prozessSchritt) => targetArray.push(prozessSchritt.id));
  return targetArray;
};

/**
 * Helper to calculate the needed width for the amount of roles
 * @returns calculated width
 */
export const getCorrectWidthForRoles = (
  prozessBereich: ProzessBereich
): number => {
  if (prozessBereich.version! >= 2) {
    return prozessBereich.bereichRoles.length;
  } else return prozessBereich.roles.length;
};

/**
 * Helper to filter Prozesschritt from Prozessmap
 * @param prozessSchrittId id of prozessschritt to fetch
 * @param map prozessmap data
 * @returns found prozesschritt or empty one
 * @tested
 */
export const getProzessschrittByIdFromProzessMap = (
  prozessSchrittId: string,
  map: ProzessMappingEntry
): Prozessschritt => {
  const foundIndex: number = map.prozessSchritte.findIndex(
    (item) => item.id === prozessSchrittId
  );
  if (foundIndex === -1)
    return createEmptyProzess("", Prozessschritttype.PROCESS, 1);
  return map.prozessSchritte[foundIndex];
};

/**
 * Helper to fill last updated by into prozessmapping
 * @param prozessmapping to add last updater
 * @param userId to set as last updater
 * @returns given prozessmapping with updated userId
 */
export const fillLastUpdatedBy = (
  prozessmapping: ProzessMappingEntry,
  userId: string
): ProzessMappingEntry => {
  prozessmapping.lastUpdatedBy = userId;
  return prozessmapping;
};

/**
 * Helper to check if prozesslinientype can be changed or if it would cause conflict
 * and generates according notification for user
 *
 * @param updatedProzessLinie new value of linie
 * @param loadedProzessMappingEntry to check corresponding schritte
 * @param hideNotification optional, necessary for test
 * @returns true, when type cannot be updtaed, false when everything is okay
 * @tested
 */
export const isNewProzesslinientypeConflicting = (
  updatedProzessLinie: Prozesslinie,
  loadedProzessMappingEntry: ProzessMappingEntry,
  hideNotification?: boolean
): boolean => {
  let hasConflict: boolean = false;
  let type: Prozessschritttype = Prozessschritttype.KUNDEN;
  const stopProzessschritt: Prozessschritt =
    loadedProzessMappingEntry.prozessSchritte.find(
      (schritt) => schritt.id === updatedProzessLinie.stopProcessId
    )!;
  const startProzessschritt: Prozessschritt =
    loadedProzessMappingEntry.prozessSchritte.find(
      (schritt) => schritt.id === updatedProzessLinie.startProcessId
    )!;
  if (stopProzessschritt.type === Prozessschritttype.KUNDEN) {
    const desiredTypes: ProzesslinieType[] = [
      ProzesslinieType.INFORMATIONSFLUSS_EDV,
      ProzesslinieType.INFORMATIONSFLUSS_PAPIER,
      ProzesslinieType.INFORMATIONSFLUSS_DEFAULT,
    ];
    if (
      !desiredTypes.includes(updatedProzessLinie.type) &&
      (stopProzessschritt.outputConfig.length > 0 ||
        stopProzessschritt.outputFileList.length > 0)
    ) {
      hasConflict = true;
    }
  }
  if (
    startProzessschritt.type === Prozessschritttype.KUNDEN ||
    startProzessschritt.type === Prozessschritttype.EXTERN
  ) {
    const desiredTypes: ProzesslinieType[] = [
      ProzesslinieType.INFORMATIONSFLUSS_EDV,
      ProzesslinieType.INFORMATIONSFLUSS_PAPIER,
      ProzesslinieType.INFORMATIONSFLUSS_DEFAULT,
    ];
    if (
      startProzessschritt.type === Prozessschritttype.KUNDEN &&
      !desiredTypes.includes(updatedProzessLinie.type) &&
      (startProzessschritt.outputConfig.length > 0 ||
        startProzessschritt.outputFileList.length > 0)
    ) {
      hasConflict = true;
    }
    if (
      startProzessschritt.type === Prozessschritttype.EXTERN &&
      updatedProzessLinie.type !==
        ProzesslinieType.DIENSTLEISTUNGSLIEFERUNG_MATERIAL &&
      (startProzessschritt.outputConfig.length > 0 ||
        startProzessschritt.outputFileList.length > 0)
    ) {
      hasConflict = true;
      type = Prozessschritttype.EXTERN;
    }
  }
  if (hasConflict && !hideNotification)
    generateNotification(
      i18n.t("notifications.SIPOC.outputConfiguration.conflictTitle"),
      i18n.t("notifications.SIPOC.outputConfiguration.lineTypeConflict", {
        replace: {
          type: i18n.t(`modules.prozessMapping.prozessSchritte.${type}`),
        },
      }),
      "warning",
      8000
    );
  return hasConflict;
};

/**
 * Helper to determine initial type for a new linie
 * @param startId of new linie
 * @param stopId  of new linie
 * @returns type that new linie should have
 * @tested
 */
export const determineLinieType = (
  startId: string,
  stopId: string,
  prozessMapping: ProzessMappingEntry
): ProzesslinieType => {
  let createsLoop: boolean = false;
  const validIds: string[] = prozessMapping.prozessSchritte
    .filter((schritt) =>
      [Prozessschritttype.PROCESS, Prozessschritttype.PROCESSSIMPLE].includes(
        schritt.type
      )
    )
    .map((schritt) => schritt.id);
  const linieForRecursionCheck = prozessMapping.prozessLinie.filter(
    (linie) =>
      ![
        ProzesslinieType.RUECKFRAGE,
        ProzesslinieType.QUALITAETSMANGEL,
      ].includes(linie.type) &&
      validIds.includes(linie.startProcessId) &&
      validIds.includes(linie.stopProcessId)
  );
  let following: string[] = linieForRecursionCheck
    .filter((linie) => linie.startProcessId === stopId)
    .map((linie) => linie.stopProcessId);
  while (following.length > 0 && !createsLoop) {
    if (following.includes(startId)) createsLoop = true;
    following = linieForRecursionCheck
      // eslint-disable-next-line
      .filter((linie) => following.includes(linie.startProcessId))
      .map((linie) => linie.stopProcessId);
  }
  if (createsLoop) return ProzesslinieType.RUECKFRAGE;

  const defaultDienstleistungslieferungMaterial: boolean =
    prozessMapping.prozessSchritte.some(
      (schritt) =>
        schritt.type === Prozessschritttype.EXTERN && schritt.id === startId
    );
  if (defaultDienstleistungslieferungMaterial)
    return ProzesslinieType.DIENSTLEISTUNGSLIEFERUNG_MATERIAL;
  return ProzesslinieType.INFORMATIONSFLUSS_DEFAULT;
};
