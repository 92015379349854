export const quarterCirle0 =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAeUExURQAAAKenp29vb0lJSTU1NZSUlDMzM1xcXHJycgAAAAuVifYAAAAKdFJOU////////////wCyzCzPAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAm0lEQVQ4T+2T2xaFIAgFUxD1/3/4WHICuq29em6eKCdAw6WDfOIj78WUiZly0sedg1hYFC76SoliFWkzaiJ1RkoQyS9WIY02vFj/6SYt5HRiORQbH7o+nciiwY6wBgMTUyy80sROycR8SjhSZg28SFeibdzEc4uhyRciXBreDHw88IHjvxAeCnzM1kVscPGrMAAv1z2f+Ago9v4DyLs2Pf7p9S4AAAAASUVORK5CYII=";
export const quarterCirle1 =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAeUExURQAAAKGhoWxsbEhISDU1NZCQkDMzM1paWm9vbwAAANmSaB4AAAAKdFJOU////////////wCyzCzPAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAtklEQVQ4T+2T0Q4EEQxFF1X8/w+vcKetTYns85yXGXVCVX3aJa945H8xxESUYsBQ+BEzMaCMEFjFwlz7Z6plxsAiJkxOkdMYACuWsVwH4rKmEbNMwGM2eRqRGD8qEgIdFcOzsRFZq6RilAWNGBGxYvJEPbiKmqIRNUlXFFzRbC24W5vDCO5hTHkeqlseJ0m/4OYKQdlcoTbFpO6aQttsUPZtJo3bqcfGvX8KncvHtecVj1yKrX0BzUY1dR8P2hAAAAAASUVORK5CYII=";
export const quarterCirle2 =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAeUExURQAAAKenp29vb0lJSTU1NZSUlDMzM1xcXHJycgAAAAuVifYAAAAKdFJOU////////////wCyzCzPAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAnUlEQVQ4T+2T0Q7DIAhFpyDW///hmnoruHXNzbLHnhcrniBSfTWSR7zlQ0xZVCUnTCdvYlEDWhACq1jNtj4MtY4YWETB4hBNjgmIYj3SdSAuOYNY5gI8s1BnENXw4aIi0HExnRsH0bxLLuaZMIgZkSjKlegHd9FLDKIX+YNIb00fhm4P3XD+F9KXgr9m/MXln0KHfFzfecRb/i22tgM6njSt3lMilgAAAABJRU5ErkJggg==";
export const quarterCirle3 =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAeUExURQAAAKGhoWxsbEhISDU1NZCQkDMzM1paWm9vbwAAANmSaB4AAAAKdFJOU////////////wCyzCzPAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAs0lEQVQ4T+2TyxbDIAgFqyjW///h2ngrENHjosvMJhHmIL5e9ZBH3DKJIVJKFAOGg5uYE4OUEQJWLMzv9ulq6TFgREKyi0zXAGixXOUaEE1NJeaRgMes+lRiYvyImBBoiBh+EyuRZZdEjKOgEiMiWiRPlIWLKC0qUZrUooMnEnIGb+qInMFbTEDO4G2P16S74e0IJ/wj/F4yy+JSTAtfXrNbzc3FPX8KjcPHteYRt/xbrPUDFIAz5eaOG2EAAAAASUVORK5CYII=";
export const quarterCirle4 =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAMAAAC7IEhfAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAbUExURQAAAKenp29vb0lJSTU1NZSUlDMzM1xcXAAAAJTfupcAAAAJdFJOU///////////AFNPeBIAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACNSURBVDhP7ZPRDoAgCEVTMf3/L87sTsCVu/O58xacGSAeleQXl+yLISaRFAM+B5OY5QSSEQJeLLA6BcEHJyYoICHcsaI778aeacSMtMHUacTRhyJINVQMSDp0SipG5BwRSStOLT9o4yq+lGiL3BDpX9PN0OOhB85fIb0U/Jrxi8s/hQb5uL75xSWkWOsFp2QuZZo6MoAAAAAASUVORK5CYII=";
