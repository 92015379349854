import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { BoxComponent } from "beelean-component-library";
import { ReactComponent as WelcomeIcon } from "../../assets/icons/welcome.svg";
import "./IntroStyle.scss";
import IntroLeanAdminCheck from "./IntroLeanAdminCheck";
import IntroLicenseOverview from "./IntroLicenseOverview";
import { IntroProps } from "./Intro.types";
import { isModuleValidInLicense } from "../../utils/LicenseUtil";
import { LicenseModuleType, UserRole } from "../../utils/Enums";

const Intro: React.FC<IntroProps> = ({
  keycloakUser,
  company,
  prozessBereiche,
  loadedServerCompanies,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <BoxComponent
        subtext={
          <p>
            <Trans i18nKey="intro.welcome.subText">
              Wir freuen uns, dass Sie wieder da sind.
            </Trans>
          </p>
        }
        icon={<WelcomeIcon />}
        title={t("intro.welcome.title")}
      >
        <p>
          <Trans
            i18nKey="intro.welcome.text"
            values={{
              name: `${keycloakUser.firstname} ${keycloakUser.lastname}`,
            }}
          >
            Hallo Benutzer
          </Trans>
        </p>
      </BoxComponent>

      <IntroLicenseOverview company={company} keycloakUser={keycloakUser} />
      {(isModuleValidInLicense(
        LicenseModuleType.PROZESSBEREICHE,
        company.license
      ) ||
        keycloakUser.userRole === UserRole.PLATFORMADMIN) && (
        <IntroLeanAdminCheck
          company={company}
          keycloakUser={keycloakUser}
          loadedServerCompanies={loadedServerCompanies}
          prozessBereiche={prozessBereiche}
        />
      )}
    </>
  );
};

export default Intro;
