import { DragNDropComponent } from "beelean-component-library";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as Add } from "../../../../assets/icons/add.svg";
import { ReactComponent as ChevronLeft } from "../../../../assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../../../assets/icons/chevron-right.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import { GlobaleApplicationSettings } from "../../../../pages/App";
import {
  createEmptyProzess,
  createEmptyProzessTaskItem,
  ProzessBereich,
  ProzessBereichRole,
  ProzesslinieType,
  Prozessschritt,
  Prozessschritttype,
  ProzessTask,
  ProzessTaskItem,
  ProzessTaskType,
  SipocChecklistEval,
  SipocChecklistEvalOutputData,
  Taetigkeit,
} from "../../../../utils/LeanAdmin/LeanAdmin.types";
import { UserRoleMappingEntry } from "../../../../utils/LeanAdmin/ProjektAkte.types";
import { getUserIdForRoleName } from "../../../../utils/LeanAdmin/ProjektAkteUtils";
import { getCorrectWidthForRoles } from "../../../../utils/LeanAdmin/ProzessMappingUtil";
import {
  addUpdatedProzessTaskItem,
  updatePositionValues,
} from "../../../../utils/LeanAdmin/SipocEvalUtils";
import "../SipocEvalStyle.scss";
import SipocDetailviewInputLine from "./SipocDetailviewInputLine";

interface SipocDetailviewProps {
  isExport?: boolean;
  selectedSipocChecklist?: SipocChecklistEval;
  prozessSchritt: Prozessschritt;
  closeFunction?(): void;
  nextFunction?(): void;
  preFunction?(): void;
  prozessBereich: ProzessBereich;
  indexNumber: string;
  previousProzessschritte: Prozessschritt[];
  nextProzessschritte: Prozessschritt[];
  updateFunction?(
    updateProzessschritt: Prozessschritt,
    uploadOnServer: boolean
  ): void;
  onClickToUpdate?(
    id: string,
    nextSipocChecklistEvalEntryId?: string,
    checked?: boolean,
    fileList?: string[],
    outputConfig?: SipocChecklistEvalOutputData[]
  ): void;
  companyId: string;
  projektManagerId: string;
  roleIdMapping: UserRoleMappingEntry[];
  isInputComplete?(prozessschritt: Prozessschritt): boolean;
  projektAkteTitle?: string;
  hasProzesslinieDesiredType?: (
    startProcessId: string,
    stopProcessId: string,
    desiredTypes: ProzesslinieType[]
  ) => boolean;
  isReleased?: boolean;
}

const SipocDetailview: React.FC<SipocDetailviewProps> = ({
  projektManagerId,
  prozessSchritt,
  closeFunction,
  prozessBereich,
  indexNumber,
  previousProzessschritte,
  nextProzessschritte,
  nextFunction,
  preFunction,
  updateFunction,
  isExport,
  selectedSipocChecklist,
  onClickToUpdate,
  companyId,
  roleIdMapping,
  isInputComplete,
  projektAkteTitle,
  hasProzesslinieDesiredType,
  isReleased,
}) => {
  const { t } = useTranslation();
  const { isViewerState } = useContext(GlobaleApplicationSettings);
  const [roleHeight, setRoleHeight] = useState<number>(0);
  const [localProzessschritt, setLocalProzessschritt] =
    useState<Prozessschritt>(
      createEmptyProzess("", Prozessschritttype.KUNDEN, 0)
    );
  const [version, setVersion] = useState<number>(0);
  const [dragItemPosition, setDragItemPosition] = useState<number>(-1);
  const [dropzonePosition, setDropzonePosition] = useState<number>(-1);

  /**
   * Configure ProzessTasks if they are not set.
   *
   * Especially, when in ProzessTaskType.PROCESS is a given min
   * Prozessschritte amount
   */
  useEffect(() => {
    // dont do anything if prozessSchritt is not set
    if (!prozessSchritt) return;
    // create local copy
    let internProzessschritt: Prozessschritt = prozessSchritt;
    /* Process section
     * always add min needed Prozessschritte */
    if (prozessSchritt.process.items.length === 0) {
      let localProzessProcessTask: ProzessTask = prozessSchritt.process;
      // add inputs until the amount of ProzessSchritte is reached
      for (let index = 0; index < prozessSchritt.prozessSchritte; index++) {
        localProzessProcessTask.items.push(createEmptyProzessTaskItem());
      }
      internProzessschritt.process = localProzessProcessTask;
      // add missing inputs if the length has changed
    } else if (
      prozessSchritt.process.items.length < prozessSchritt.prozessSchritte
    ) {
      let localProzessProcessTask: ProzessTask = prozessSchritt.process;
      for (
        let index = prozessSchritt.process.items.length;
        index < prozessSchritt.prozessSchritte;
        index++
      ) {
        localProzessProcessTask.items.push(createEmptyProzessTaskItem());
      }
      localProzessProcessTask.items.sort(
        (itemOne, itemTwo) => (itemOne.position || 0) - (itemTwo.position || 0)
      );
      internProzessschritt.process = localProzessProcessTask;
    }
    // update Prozessschritt
    setLocalProzessschritt(internProzessschritt);
  }, [prozessSchritt]);

  /**
   * adds a new ProzessTaskItem in Prozessschritt
   */
  const addNewProzessTaskItem = (): void => {
    let copyLocalProzessschritt: Prozessschritt = localProzessschritt;
    copyLocalProzessschritt.process?.items.push(createEmptyProzessTaskItem());
    updatePositionValues(copyLocalProzessschritt.process?.items);
    setLocalProzessschritt(copyLocalProzessschritt);
    updateFunction && updateFunction(copyLocalProzessschritt, true);
    // it has to be there, because of rerender things
    setVersion(version + 1);
  };

  /**
   * Removes a ProzessTaskItem from the array by the index
   *
   * @param index
   */
  const removeProzessTaskItem = (index: number): void => {
    let copyLocalProzessschritt: Prozessschritt = localProzessschritt;
    copyLocalProzessschritt.process?.items.splice(index, 1);
    updatePositionValues(copyLocalProzessschritt.process?.items);
    setLocalProzessschritt(copyLocalProzessschritt);
    updateFunction && updateFunction(copyLocalProzessschritt, true);
    // it has to be there, because of rerender things
    setVersion(version + 1);
  };

  /**
   * helper to calc and set role height for name
   * @param roleName to calc height for
   */
  const calcAndSetRoleHeight = (roleName: string): void => {
    let necessaryHeight: number = roleName.length * 10 + 10;
    const minHeight: number = 40;
    if (roleHeight < necessaryHeight) {
      if (necessaryHeight < minHeight) necessaryHeight = minHeight;
      setRoleHeight(necessaryHeight);
    }
  };

  /**
   * Helper to generate role line height for detaulview
   * @returns calculated elements
   */
  const getRoleLine = (): JSX.Element[] => {
    if (prozessBereich.version! >= 2) {
      return prozessBereich.bereichRoles.map(
        (role: ProzessBereichRole, roleIndex: number) => {
          // set height of roles for title min-height
          calcAndSetRoleHeight(role.name);

          return (
            <div
              key={`sipoc-detailview-role-${roleIndex}`}
              className="sipoc-detailview-role-line"
              style={{
                left: `${(roleIndex - 1) * 20}px`,
                top: `${role.name.length * 10 - 20}px `,
              }}
            >
              <div
                style={{
                  width: `${role.name.length * 10}px `,
                }}
              >
                <div
                  className={
                    prozessSchritt.role === role.id
                      ? "current-verantwortlicher-role"
                      : undefined
                  }
                >
                  {role.name}
                </div>
              </div>
            </div>
          );
        }
      );
    } else
      return prozessBereich.roles.map((role: string, roleIndex: number) => {
        // set height of roles for title min-height
        calcAndSetRoleHeight(role);

        return (
          <div
            key={`sipoc-detailview-role-${roleIndex}`}
            className="sipoc-detailview-role-line"
            style={{
              left: `${(roleIndex - 1) * 20}px`,
              top: `${role.length * 10 - 20}px `,
            }}
          >
            <div
              style={{
                width: `${role.length * 10}px `,
              }}
            >
              <div
                className={
                  prozessSchritt.role === role
                    ? "current-verantwortlicher-role"
                    : undefined
                }
              >
                {role}
              </div>
            </div>
          </div>
        );
      });
  };

  /**
   * Helper method to update position of item at oldPos or dragItemPosition
   * @param newPos
   * @param oldPos optional, will use dragItemPosition when not filled
   * @param save optional, will save changes
   */
  const updateItemOrder = (newPos: number, oldPos?: number): void => {
    const updatedItems: ProzessTaskItem[] = [
      ...localProzessschritt.process.items,
    ];
    let indexToMove: number = 0;
    if (dragItemPosition === -1) indexToMove = oldPos || 0;
    else indexToMove = dragItemPosition || 0;
    const item = updatedItems[indexToMove];
    item.position = newPos;
    updatedItems.splice(indexToMove, 1);
    updatedItems.splice(newPos, 0, item);
    updatePositionValues(updatedItems);
    const updatedProzessschritt: Prozessschritt = {
      ...localProzessschritt,
      process: { ...localProzessschritt.process, items: [...updatedItems] },
    };
    setLocalProzessschritt(updatedProzessschritt);
    updateFunction?.(updatedProzessschritt, true);
  };

  return (
    <div className="sipoc-detailview-content-wrapper">
      <div className="sipoc-detailview-text-content-wrapper">
        {/* Header */}
        <div className="sipoc-detailview-flex-wrapper">
          <div className="sipoc-detailview-role-wrapper-wrapper">
            {/* Roles */}
            <div
              className="sipoc-detailview-role-wrapper"
              style={{
                width: `${getCorrectWidthForRoles(prozessBereich) * 30 + 10}px`,
              }}
            >
              {getRoleLine()}
            </div>
          </div>

          {/* Header Content */}
          <div
            className="sipoc-detailview-title-wrapper"
            style={{ height: `${roleHeight}px` }}
          >
            <div className="sipoc-detailview-title">
              {indexNumber}){" "}
              {localProzessschritt.name || t("modules.SIPOC.detail.noName")} (
              {localProzessschritt.displayName || ""})
            </div>

            <div
              className="sipoc-detailview-close"
              title={t("general.buttons.back")}
              onClick={() => {
                updateFunction &&
                  !isViewerState &&
                  updateFunction(localProzessschritt, true);
                preFunction && preFunction();
              }}
            >
              <ChevronLeft />
            </div>
            <div
              className="sipoc-detailview-close"
              title={t("general.buttons.next")}
              onClick={() => {
                updateFunction &&
                  !isViewerState &&
                  updateFunction(localProzessschritt, true);
                nextFunction && nextFunction();
              }}
            >
              <ChevronRight />
            </div>
            <div
              title={t("general.buttons.close")}
              className="sipoc-detailview-close"
              onClick={() => {
                updateFunction &&
                  !isViewerState &&
                  updateFunction(localProzessschritt, true);
                closeFunction && closeFunction();
              }}
            >
              <CloseIcon />
            </div>
          </div>
        </div>

        {/* Input */}
        <div className="sipoc-detailview-flex-wrapper">
          <div className="sipoc-detailview-input-wrapper">
            <div className="sipoc-detailview-input-title">
              <div>
                <Trans i18nKey="modules.SIPOC.detail.inputTitle">Input</Trans>
              </div>
            </div>

            {previousProzessschritte.map(
              (
                preProzessSchritt: Prozessschritt,
                preProzessSchrittIndex: number
              ) => (
                <SipocDetailviewInputLine
                  isReleased={isReleased}
                  responsibleUserId={getUserIdForRoleName(
                    roleIdMapping,
                    prozessSchritt.role,
                    prozessBereich
                  )}
                  projektManagerId={projektManagerId}
                  selectedSipocChecklist={selectedSipocChecklist}
                  onClickToUpdate={onClickToUpdate}
                  isExport={isExport}
                  updateProzessschrittInProzessMapping={
                    updateFunction ? updateFunction : () => {}
                  }
                  type={ProzessTaskType.INPUT}
                  key={`input-list-${preProzessSchrittIndex}`}
                  index={preProzessSchrittIndex + 1}
                  prozessBereich={prozessBereich}
                  prozessSchritt={preProzessSchritt}
                  detailViewProzessSchritt={localProzessschritt}
                  companyId={companyId}
                  isInputComplete={isInputComplete}
                  projektAkteTitle={projektAkteTitle}
                  hasProzesslinieDesiredType={hasProzesslinieDesiredType}
                />
              )
            )}
          </div>
        </div>

        {/* Process */}
        <div className="sipoc-detailview-flex-wrapper" key={version}>
          <div className="sipoc-detailview-process-wrapper">
            <div className="sipoc-detailview-process-title">
              <div>
                <Trans i18nKey="modules.SIPOC.detail.processTitle">
                  Prozess
                </Trans>
              </div>
              {!isViewerState &&
                !isReleased &&
                !isExport &&
                !selectedSipocChecklist && (
                  <div
                    title={t("general.buttons.add")}
                    onClick={addNewProzessTaskItem}
                  >
                    <Add />
                  </div>
                )}
            </div>
            {localProzessschritt.process?.items.map(
              (item: ProzessTaskItem, itemIndex: number) => {
                return (
                  <DragNDropComponent
                    key={`sipoc-detailsview-line-${itemIndex}`}
                    activeItem={dragItemPosition}
                    setActiveItem={setDragItemPosition}
                    position={itemIndex}
                    moveItemTo={updateItemOrder}
                    disabled={
                      isViewerState ||
                      isReleased ||
                      isExport ||
                      !!selectedSipocChecklist
                    }
                    setShowDropzone={setDropzonePosition}
                    showDropzone={dropzonePosition}
                  >
                    <div className="sipoc-detailview-process-line-draggable">
                      <SipocDetailviewInputLine
                        isReleased={isReleased}
                        responsibleUserId={getUserIdForRoleName(
                          roleIdMapping,
                          prozessSchritt.role,
                          prozessBereich
                        )}
                        projektManagerId={projektManagerId}
                        detailViewProzessSchritt={localProzessschritt}
                        onClickToUpdate={onClickToUpdate}
                        selectedSipocChecklist={selectedSipocChecklist}
                        isExport={isExport}
                        updateProzessschrittInProzessMapping={
                          updateFunction ? updateFunction : () => {}
                        }
                        onDelete={() => removeProzessTaskItem(itemIndex)}
                        type={ProzessTaskType.PROCESS}
                        key={`process-list-${itemIndex}`}
                        item={item}
                        isDeleteable={
                          itemIndex >= localProzessschritt.prozessSchritte
                        }
                        index={itemIndex + 1}
                        onChange={(value: ProzessTaskItem) => {
                          const updatedProzessschritt: Prozessschritt =
                            addUpdatedProzessTaskItem(
                              value,
                              itemIndex,
                              localProzessschritt,
                              ProzessTaskType.PROCESS
                            );
                          updateFunction?.({ ...updatedProzessschritt }, false);
                          setLocalProzessschritt(updatedProzessschritt);
                        }}
                        prozessBereich={prozessBereich}
                        companyId={companyId}
                      />
                    </div>
                  </DragNDropComponent>
                );
              }
            )}
          </div>
        </div>

        {/* Output */}
        <div className="sipoc-detailview-flex-wrapper">
          <div className="sipoc-detailview-output-wrapper">
            <div className="sipoc-detailview-output-title">
              <div>
                <Trans i18nKey="modules.SIPOC.detail.outputTitle">Output</Trans>
              </div>
            </div>

            {nextProzessschritte.map(
              (
                nextProzessSchritt: Prozessschritt,
                nextProzessSchrittIndex: number
              ) => {
                return (
                  <SipocDetailviewInputLine
                    isReleased={isReleased}
                    responsibleUserId={getUserIdForRoleName(
                      roleIdMapping,
                      prozessSchritt.role,
                      prozessBereich
                    )}
                    projektManagerId={projektManagerId}
                    selectedSipocChecklist={selectedSipocChecklist}
                    onClickToUpdate={onClickToUpdate}
                    isExport={isExport}
                    updateProzessschrittInProzessMapping={
                      updateFunction ? updateFunction : () => {}
                    }
                    type={ProzessTaskType.OUTPUT}
                    key={`output-list-${nextProzessSchrittIndex}`}
                    index={nextProzessSchrittIndex + 1}
                    prozessBereich={prozessBereich}
                    prozessSchritt={nextProzessSchritt}
                    detailViewProzessSchritt={localProzessschritt}
                    companyId={companyId}
                    isInputComplete={isInputComplete}
                    projektAkteTitle={projektAkteTitle}
                    hasProzesslinieDesiredType={hasProzesslinieDesiredType}
                  />
                );
              }
            )}
          </div>
        </div>
      </div>

      {/* Definition Example line */}
      <div className="type-definition-example-line">
        {Object.keys(Taetigkeit).map((dotTyp, dotTypIndex) => (
          <div key={`definition-dot-${dotTypIndex}`}>
            <div className={`definition-dot dot-${dotTyp}`} />
            <div className="type-definition-example-line-text">
              {t(`modules.SIPOC.detail.dotType.${dotTyp}`)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SipocDetailview;
