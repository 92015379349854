import { ProzessMappingEntry } from "../LeanAdmin/LeanAdmin.types";

export interface Pdca {
  id?: string;
  companyId: string;
  createDate: Date;
  createdBy: string;
  description?: string;
  actions?: string;
  followUpDate?: Date;
  targetDate?: Date;
  status?: number;
  responsibleUser?: string;
  responsibleRole?: string;
  prio?: number;
  prozessBereich?: string;
}

export const createNewPdcaFromProzessMappingEntry = (
  entry: ProzessMappingEntry,
  description: string,
  createUser: string
): Pdca => ({
  companyId: entry.companyId,
  createDate: new Date(),
  createdBy: createUser,
  description: description,
  prozessBereich: entry.prozessBereichId,
});

export const createNewMinimalPdcaEntry = (
  createUser: string,
  companyId: string
): Pdca => ({
  companyId: companyId,
  createDate: new Date(),
  createdBy: createUser,
});
