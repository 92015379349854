import { BoxComponent } from "beelean-component-library";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAxios } from "../../utils/AxiosUtil";
import { createEmptyUser, User } from "../../utils/Interfaces";
import { getOneUser, updateUserOnServer } from "../../utils/UserUtil";
import "./AccountListStyle.scss";
import UserMetatag from "./UserMetatag";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { KeycloakUserContext } from "../../pages/App";
import { UserRole } from "../../utils/Enums";
import { generateNotification } from "../../utils/NotificationUtil";
import { useHistory } from "react-router-dom";

interface ProfileProps {
  userId: string;
}

const Profile: React.FC<ProfileProps> = ({ userId }) => {
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [loadedUser, setLoadedUser] = useState<User>(createEmptyUser);
  const axios = useAxios();
  const { updateKeycloakUser } = useContext(KeycloakUserContext);
  const history = useHistory();

  useEffect(() => {
    if (axios) {
      getOneUser(userId, axios).then((userResponse: User) => {
        if (userResponse) {
          setLoadedUser(userResponse);
        } else {
          generateNotification(
            t("notifications.profile.errorTitle"),
            t("notifications.profile.errorContent", { status: "unknown" }),
            "danger",
            -1
          );
        }
      });
    }
    // eslint-disable-next-line
  }, [axios]);

  return (
    <>
      {loadedUser.userRole !== UserRole.NONE && (
        <BoxComponent
          subtext={
            <p>
              <Trans i18nKey="profile.subText">
                Hier können Sie Ihre Profildaten verändern
              </Trans>
            </p>
          }
          title={t("profile.title")}
          icon={<UserIcon />}
        >
          <p>
            <Trans i18nKey="profile.description">
              Hier können Sie Ihr Profil bearbeiten und aktualisieren.
            </Trans>
          </p>
          <UserMetatag
            isLoading={isUpdating}
            onAdd={(updateUser: User) => {
              // check PWD
              setIsUpdating(true);
              updateUserOnServer(updateUser, axios).then((status: number) => {
                if (status === 200) {
                  setLoadedUser({ ...updateUser, password: undefined });
                  updateKeycloakUser();
                  // called because after update we can go back to work.
                  history.push("/");
                } else {
                  generateNotification(
                    t("notifications.profile.errorTitle"),
                    t("notifications.profile.errorContent", {
                      status: status,
                    }),
                    "danger",
                    -1
                  );
                }
                setIsUpdating(false);
              });
            }}
            updateUser={loadedUser}
          />
        </BoxComponent>
      )}
    </>
  );
};

export default Profile;
