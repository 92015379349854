import { HorizontalSubMenuComponent } from "beelean-component-library";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ProzessOverviewComponent from "./overview/ProzessOverviewComponent";
import Prozessmapping from "./prozessmapping/Prozessmapping";
import SipocEval from "./sipoc/SipocEval";

interface LeanAdminSelectionProps {
  companyId: string;
  prozessBereichId: string;
  setExportFunction(exportFunction: Function): void;
}

const LeanAdminSelection: React.FC<LeanAdminSelectionProps> = ({
  companyId,
  prozessBereichId,
  setExportFunction,
}) => {
  const [currentSection, setCurrentSection] = useState<JSX.Element>(
    <Prozessmapping
      companyId={companyId}
      prozessBereichId={prozessBereichId}
      setExportFunction={setExportFunction}
    />
  );
  const { t } = useTranslation();

  return (
    <>
      <HorizontalSubMenuComponent
        preselectedIndex={0}
        items={[
          {
            label: t("modules.prozessMapping.menuTitle"),
            onClick: () =>
              setCurrentSection(
                <Prozessmapping
                  key={`prozessmapping`}
                  setExportFunction={setExportFunction}
                  companyId={companyId}
                  prozessBereichId={prozessBereichId}
                />
              ),
          },
          {
            label: t("modules.SIPOC.menuTitle"),
            onClick: () =>
              setCurrentSection(
                <SipocEval
                  key={`sipoc-eval`}
                  setExportFunction={setExportFunction}
                  companyId={companyId}
                  prozessBereichId={prozessBereichId}
                />
              ),
          },
          {
            label: t("modules.overview.menuTitle"),
            onClick: () =>
              setCurrentSection(
                <ProzessOverviewComponent
                  setExportFunction={setExportFunction}
                  companyId={companyId}
                  prozessBereichId={prozessBereichId}
                />
              ),
          },
          {
            label: t("modules.prozessMappingReleased.menuTitle"),
            onClick: () =>
              setCurrentSection(
                <Prozessmapping
                  key={`released-prozessmapping`}
                  setExportFunction={setExportFunction}
                  companyId={companyId}
                  prozessBereichId={prozessBereichId}
                  isReleased
                />
              ),
          },
          {
            label: t("modules.SIPOCReleased.menuTitle"),
            onClick: () =>
              setCurrentSection(
                <SipocEval
                  key={`released-sipoc-eval`}
                  setExportFunction={setExportFunction}
                  companyId={companyId}
                  prozessBereichId={prozessBereichId}
                  isReleased
                />
              ),
          },
        ]}
      />

      <div>{currentSection}</div>
    </>
  );
};

export default LeanAdminSelection;
