import {
  BoxComponent,
  ButtonComponent,
  ConfirmPopupComponent,
  DropdownComponent,
  InputComponent,
  PopupComponent,
} from "beelean-component-library";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as LeanAdminModulIcon } from "../../../assets/icons/leanadmin.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { CompanyContext, GlobaleApplicationSettings } from "../../../pages/App";
import { useAxios } from "../../../utils/AxiosUtil";
import { ModuleShowType, UserRole } from "../../../utils/Enums";
import { KeycloakUser } from "../../../utils/Interfaces";
import {
  ProzessBereich,
  createEmptyProzessBereich,
} from "../../../utils/LeanAdmin/LeanAdmin.types";
import {
  copyProzessBereich,
  createNewProzessBereich,
  deleteProzessBereich,
  getAllProzessBereichForCompany,
  shouldProzessBereichBeShown,
} from "../../../utils/LeanAdmin/LeanAdminModulUtils";
import { copyProzessMappingEntry } from "../../../utils/LeanAdmin/ProzessMappingUtil";
import { generateNotification } from "../../../utils/NotificationUtil";
import { isUserAllowedToDoThis } from "../../../utils/UserUtil";
import LeanAdminModulEntry from "./LeanAdminModulEntry";
import "./LeanAdminModulStyle.scss";

interface LeanAdminModulProps {
  keycloakUser: KeycloakUser;
  companyId: string;
  companyName: string;
  onCreateSuccess: Function;
  onDeleteSuccess: Function;
  onUpdate: Function;
}

const LeanAdminModul: React.FC<LeanAdminModulProps> = ({
  keycloakUser,
  companyId,
  companyName,
  onCreateSuccess,
  onDeleteSuccess,
  onUpdate,
}) => {
  const { setUserCompany, userCompany, allCompanies } =
    useContext(CompanyContext);

  const { isViewerState } = useContext(GlobaleApplicationSettings);
  const { t } = useTranslation();
  const axios = useAxios();

  const [newProzessBereich, setNewProzessBereich] = useState<ProzessBereich>(
    createEmptyProzessBereich(companyId)
  );
  const [selectedProzessBereich, setSelectedProzessBereich] =
    useState<ProzessBereich>(createEmptyProzessBereich(companyId));
  const [loadedProzessBereiche, setLoadedProzessBereiche] = useState<
    ProzessBereich[]
  >([]);
  const [filteredProzessBereiche, setFilteredProzessBereiche] = useState<
    ProzessBereich[]
  >([]);

  const [searchString, setSearchString] = useState<string>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const [showChooseCompanyPopup, setShowChooseCompanyPopup] =
    useState<boolean>(false);
  const [showDeleteProzessBereichPopup, setShowDeleteProzessBereichPopup] =
    useState<boolean>(false);
  const [companyForCopy, setCompanyForCopy] = useState<string>("");
  const [prozessBereichForCopy, setProzessBereichForCopy] =
    useState<ProzessBereich>();

  /**
   * Creates new ProzessBereich on the server
   */
  const createNewProzessBereichOnServer = (): void => {
    if (!newProzessBereich.bereich) return;
    setIsSending(true);
    createNewProzessBereich(
      newProzessBereich,
      keycloakUser.serviceId,
      axios
    ).then((newUploadedProzessBereich: ProzessBereich) => {
      if (!newUploadedProzessBereich) {
        setIsSending(false);
        return;
      }
      let localProzessBereiche: ProzessBereich[] = [...loadedProzessBereiche];
      localProzessBereiche.push(newUploadedProzessBereich);
      setLoadedProzessBereiche(localProzessBereiche);
      setNewProzessBereich(createEmptyProzessBereich(companyId));
      setUserCompany({
        ...userCompany,
        createdProzessBereiche: userCompany.createdProzessBereiche + 1,
      });
      generateNotification(
        t("notifications.stammdatumCreate.successTitle"),
        t("notifications.stammdatumCreate.successContent", {
          taetigkeit: newUploadedProzessBereich.bereich,
        }),
        "success"
      );
      onCreateSuccess(newUploadedProzessBereich);
      setIsSending(false);
    });
  };

  /**
   * Helper to delete a ProzessBereich on the server
   */
  const deleteProzessBereichOnServer = (): void => {
    deleteProzessBereich(selectedProzessBereich.id!, axios).then(
      (amountToDecrease: number) => {
        if (amountToDecrease === -1) {
          generateNotification(
            t("notifications.stammdatumDelete.errorTitle"),
            t("notifications.stammdatumDelete.errorContent"),
            "danger",
            -1
          );
        } else {
          let localProzessBereiche: ProzessBereich[] =
            loadedProzessBereiche.filter(
              (value: ProzessBereich) => value.id !== selectedProzessBereich.id
            );
          setLoadedProzessBereiche([...localProzessBereiche]);
          // if any Prozessmapping was there
          setUserCompany({
            ...userCompany,
            createdProzessBereiche: userCompany.createdProzessBereiche - 1,
            createdProcesses:
              userCompany.createdProcesses - amountToDecrease < 0
                ? 0
                : userCompany.createdProcesses - amountToDecrease,
          });
          generateNotification(
            t("notifications.stammdatumDelete.successTitle"),
            t("notifications.stammdatumDelete.successContent", {
              name: selectedProzessBereich.bereich,
            }),
            "success"
          );
          onDeleteSuccess(selectedProzessBereich.id!);
          setShowDeleteProzessBereichPopup(false);
        }
      }
    );
  };

  /**
   * Copies a ProzessBereich on the Server
   * It is ok if the original ProzessBereich is not found
   * you dont have to copy something. So 404 is ok, because a new ProzessMapping is
   * created on click
   *
   * @param newProzessBereich
   * @param originalProzessbereichId
   */
  const copyProzessbereichOnServer = (
    newProzessBereich: ProzessBereich,
    originalProzessbereichId: string,
    newCompanyId?: string
  ): void => {
    setIsSending(true);
    if (newCompanyId) newProzessBereich.companyId = newCompanyId;
    copyProzessBereich(newProzessBereich, keycloakUser.serviceId, axios).then(
      (newUploadedProzessBereich: ProzessBereich) => {
        if (newUploadedProzessBereich) {
          // normal doing
          if (!newCompanyId || newCompanyId === companyId) {
            let localProzessBereiche: ProzessBereich[] = loadedProzessBereiche;
            localProzessBereiche.push(newUploadedProzessBereich);
            setLoadedProzessBereiche([...localProzessBereiche]);
            setNewProzessBereich(createEmptyProzessBereich(companyId));
          }
          onCreateSuccess(newUploadedProzessBereich);
          // Copy the data of prozess mapping too
          copyProzessMappingEntry(
            originalProzessbereichId,
            newUploadedProzessBereich.id!,
            keycloakUser.serviceId,
            axios,
            newCompanyId
          ).then((status: number) => {
            if (status === 201 || status === 404) {
              if (keycloakUser.userRole !== UserRole.PLATFORMADMIN)
                setUserCompany({
                  ...userCompany,
                  createdProzessBereiche:
                    userCompany.createdProzessBereiche + 1,
                });
              generateNotification(
                t("notifications.stammdatumCreate.successTitle"),
                t("notifications.stammdatumCreate.successContent", {
                  taetigkeit: newUploadedProzessBereich.bereich,
                }),
                "success"
              );
              setShowChooseCompanyPopup(false);
            } else if (status === 406) {
              generateNotification(
                t("notifications.stammdatumCreate.errorTitle"),
                t("notifications.stammdatumCreate.errorContentLicense"),
                "danger",
                -1
              );
            }
          });
        }
        setIsSending(false);
      }
    );
  };

  /**
   * load all prozessberiche for specific company
   */
  useEffect(() => {
    if (!axios) return;
    getAllProzessBereichForCompany(companyId, axios).then(
      (prozessBereiche: ProzessBereich[]) => {
        setLoadedProzessBereiche(prozessBereiche);
        setFilteredProzessBereiche(prozessBereiche);
      }
    );
    // eslint-disable-next-line
  }, [axios, companyId]);

  //sets currently opened Company as default for copying as plattformAdmin
  useEffect(() => {
    if (keycloakUser.userRole !== UserRole.PLATFORMADMIN) return;
    setCompanyForCopy(companyId);
    // eslint-disable-next-line
  }, []);

  /**
   * filter local list by searchstring to prevent rerender
   */
  useEffect(() => {
    if (!searchString) setFilteredProzessBereiche([...loadedProzessBereiche]);
    let localFilteredList: ProzessBereich[] = [...loadedProzessBereiche];
    let localAgainFilteredList = localFilteredList.filter(
      (oneProzessbereich: ProzessBereich) =>
        shouldProzessBereichBeShown(oneProzessbereich, searchString)
    );
    setFilteredProzessBereiche([...localAgainFilteredList]);
    // eslint-disable-next-line
  }, [searchString, loadedProzessBereiche]);

  /**
   * Helper to update the updated Prozessberiech in correct index
   * @param updatedProzessBereich data to update
   */
  const updateOneProzessBerieich = (updatedProzessBereich: ProzessBereich) => {
    let localProzessBereiche: ProzessBereich[] = [...loadedProzessBereiche];
    let foundIndex = localProzessBereiche.findIndex(
      (curProzessbereich) => curProzessbereich.id === updatedProzessBereich.id
    );
    if (foundIndex === -1) return;
    localProzessBereiche[foundIndex] = updatedProzessBereich;
    setLoadedProzessBereiche([...localProzessBereiche]);
    // get update back to Dashboard
    onUpdate(updatedProzessBereich);
  };

  return (
    <>
      {showDeleteProzessBereichPopup && (
        <ConfirmPopupComponent
          content={t("popup.leanAdminModul.delete.prozessBereich", {
            name: selectedProzessBereich.bereich,
          })}
          title={t("general.buttons.delete")}
          closeFunction={() => setShowDeleteProzessBereichPopup(false)}
          noText={t("general.buttons.no")}
          yesText={t("general.buttons.yes")}
          onYesFunction={deleteProzessBereichOnServer}
        />
      )}
      {showChooseCompanyPopup && (
        <PopupComponent
          title={t("popup.leanAdminModul.chooseCompanyForCopy")}
          closeFunction={() => setShowChooseCompanyPopup(false)}
          closeText={t("general.buttons.close")}
        >
          <DropdownComponent
            placeholder={t("modules.leanAdminModul.targetCompany")}
            onChange={(option) => setCompanyForCopy(option)}
            options={allCompanies.map((company) => ({
              label: company.name,
              value: company.id!,
            }))}
            selectedOption={companyForCopy}
          />
          <p className="target-company-info">
            {t("modules.leanAdminModul.targetCompanyInfoText")}
          </p>
          <ButtonComponent
            isLoading={isSending}
            title={t("general.buttons.copy")}
            onClick={() =>
              copyProzessbereichOnServer(
                {
                  ...prozessBereichForCopy!,
                  bereich: prozessBereichForCopy!.bereich + " (Copy)",
                  id: undefined,
                },
                prozessBereichForCopy!.id!,
                companyForCopy
              )
            }
          />
        </PopupComponent>
      )}
      <div className="flex-inline-box-component-wrapper">
        {isUserAllowedToDoThis(
          keycloakUser.userRole,
          ModuleShowType.PROZESSBEREICH_CREATE
        ) &&
          !isViewerState && (
            <BoxComponent
              subtext={
                <p>
                  <Trans i18nKey="modules.leanAdminModul.subText">
                    Legen Sie hier einen Prozessbereich an
                  </Trans>
                </p>
              }
              icon={<LeanAdminModulIcon />}
              title={t("modules.leanAdminModul.addTitle")}
            >
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  createNewProzessBereichOnServer();
                }}
              >
                <InputComponent
                  onChange={(value: string) =>
                    setNewProzessBereich({
                      ...newProzessBereich,
                      bereich: value,
                    })
                  }
                  value={newProzessBereich.bereich}
                  placeholder={t("modules.leanAdminModul.addPlaceholder")}
                  required
                />
                <ButtonComponent
                  isLoading={isSending}
                  title={t("general.buttons.add")}
                />
              </form>
            </BoxComponent>
          )}

        <BoxComponent
          icon={<SearchIcon />}
          subtext={
            <p>
              <Trans i18nKey="modules.leanAdminModul.searchSubText">
                Hier können Sie in den Prozessbereichen suchen
              </Trans>
            </p>
          }
        >
          <InputComponent
            onChange={(value: string) => setSearchString(value)}
            value={searchString}
            placeholder={t("general.buttons.search")}
          />
        </BoxComponent>
      </div>

      <div className="lean-admin-modul-prozess-bereiche-wrapper">
        {filteredProzessBereiche &&
          filteredProzessBereiche.map(
            (prozessBereich: ProzessBereich, prozessBereichIndex: number) => (
              <LeanAdminModulEntry
                onCopy={(copyProzessBereich) => {
                  if (keycloakUser.userRole === UserRole.PLATFORMADMIN) {
                    setShowChooseCompanyPopup(true);
                    setProzessBereichForCopy(copyProzessBereich);
                  } else
                    copyProzessbereichOnServer(
                      {
                        ...copyProzessBereich,
                        bereich: `${copyProzessBereich.bereich} (Copy)`,
                        history: [],
                        prozessOverviewId: "",
                        id: undefined,
                      },
                      copyProzessBereich.id!
                    );
                }}
                key={companyId + "-" + prozessBereichIndex}
                userRole={keycloakUser.userRole}
                companyName={companyName}
                onDelete={() => {
                  setSelectedProzessBereich(prozessBereich);
                  setShowDeleteProzessBereichPopup(true);
                }}
                onChange={(p) => updateOneProzessBerieich(p)}
                prozessBereich={prozessBereich}
              />
            )
          )}
      </div>
    </>
  );
};

export default LeanAdminModul;
