import { BoxComponent, LoaderComponent } from "beelean-component-library";
import React, { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { ReactComponent as BlockedIcon } from "../../../assets/icons/blocked.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as HourglassHalfIcon } from "../../../assets/icons/hourglass-half.svg";
import { ReactComponent as SipocIcon } from "../../../assets/icons/sipoc_icon.svg";
import i18n from "../../../i18n";
import {
  GlobaleApplicationSettings,
  KeycloakUserContext,
  TreeDataContext,
} from "../../../pages/App";
import { useAxios } from "../../../utils/AxiosUtil";
import { fetchCompany } from "../../../utils/CompanyUtil";
import { Language } from "../../../utils/Enums";
import { getFormattedDateTimeString } from "../../../utils/GeneralUtil";
import { Company, createEmptyCompany } from "../../../utils/Interfaces";
import {
  HistoryEntry,
  ProzessBereich,
  ProzessMappingEntry,
  Prozesslinie,
  ProzesslinieType,
  Prozessschritt,
  Prozessschritttype,
  SipocChecklistEval,
  SipocChecklistEvalOutputData,
  createEmptyProzess,
  createEmptyProzessBereich,
  createEmptyProzessMappingEntry,
} from "../../../utils/LeanAdmin/LeanAdmin.types";
import { getProzessBereichById } from "../../../utils/LeanAdmin/LeanAdminModulUtils";
import { ProjektAkte } from "../../../utils/LeanAdmin/ProjektAkte.types";
import {
  fillLastUpdatedBy,
  getProzessMappingEntry,
  updateEgOnServer,
  updateProzessMappingEntry,
} from "../../../utils/LeanAdmin/ProzessMappingUtil";
import {
  generateProzessschrittIdAndNeededCheckedElementsAmount,
  getAmountOfCheckedElementsForProzessschritt,
  isInputComplete,
} from "../../../utils/LeanAdmin/SipocChecklistUtils";
import {
  extractPreviousAndNextProzessSchritt,
  filterJustForNeededProzessschritte,
  generateProzesschrittNumbers,
  getPreNextProzessschritt,
} from "../../../utils/LeanAdmin/SipocEvalUtils";
import { generateNotification } from "../../../utils/NotificationUtil";
import { generateSipocExport } from "../../../utils/PdfUtil";
import { getNameForUserId } from "../../../utils/UserUtil";
import "./SipocEvalStyle.scss";
import SipocDetailview from "./subcomponents/SipocDetailview";
import SipocOverview from "./subcomponents/SipocOverview";

interface SipocEvalProps {
  companyId: string;
  prozessBereichId: string;
  setExportFunction(exportFunction: Function): void;
  selectedSipocChecklist?: SipocChecklistEval;
  onClickToUpdate?(
    id: string,
    nextSipocChecklistEvalEntryId?: string,
    checked?: boolean,
    fileList?: string[],
    outputConfig?: SipocChecklistEvalOutputData[]
  ): void;
  projektAkte?: ProjektAkte;
  isReleased?: boolean;
}

const SipocEval: React.FC<SipocEvalProps> = ({
  companyId,
  prozessBereichId,
  setExportFunction,
  selectedSipocChecklist,
  onClickToUpdate,
  projektAkte,
  isReleased,
}) => {
  const { leanAdminProzessbereiche, setLeanAdminProzessbereiche } =
    useContext(TreeDataContext);
  const axios = useAxios();
  const { t } = useTranslation();
  const { keycloakUser } = useContext(KeycloakUserContext);
  // states for the export
  const [isExportGenerating, setIsExportGenerating] = useState<boolean>(false);
  const [fetchedCompany, setFetchedCompany] = useState<Company>(
    createEmptyCompany()
  );
  const { isViewerState } = useContext(GlobaleApplicationSettings);

  // states for the detailview
  const [showDetailView, setShowDetailView] = useState<boolean>(false);
  const [selectedProzessschritt, setSelectedProzessschritt] =
    useState<Prozessschritt>(
      createEmptyProzess("", Prozessschritttype.PROCESS, 0)
    );
  const [selectedIndexNumber, setSelectedIndexNumber] = useState<string>("");
  // other states
  const [loadedProzessBereich, setLoadedProzessBereich] =
    useState<ProzessBereich>(createEmptyProzessBereich(companyId));
  const [loadedProzessschritte, setLoadedProzessschritte] = useState<
    Prozessschritt[]
  >([]);
  const [neededProzessschritte, setNeededProzessschritte] = useState<
    Prozessschritt[]
  >([]);
  const [
    previousAndNextSelectedProzessschritt,
    setPreviousAndNextSelectedProzessschritt,
  ] = useState<Prozessschritt[][]>([]);
  const [prozesschritteNumbers, setProzesschritteNumbers] = useState<string[]>(
    []
  );
  const [loadedProzesslinien, setLoadedProzesslinien] = useState<
    Prozesslinie[]
  >([]);
  const [neededProzesslinien, setNeededProzesslinien] = useState<
    Prozesslinie[]
  >([]);
  const [loadedProzessMappingEntry, setLoadedProzessMappingEntry] =
    useState<ProzessMappingEntry>(
      createEmptyProzessMappingEntry(companyId, prozessBereichId)
    );
  const [hasUnsavedChanges, toggleHasUnsavedChanged] = useState<boolean>(false);
  const [lastUpdaterName, setlastUpdaterName] = useState<string>(
    t("modules.SIPOC.unknown")
  );

  //helper to get name of last updater
  useEffect(() => {
    if (!loadedProzessMappingEntry?.lastUpdatedBy || !axios || !t) return;
    getNameForUserId(loadedProzessMappingEntry.lastUpdatedBy, axios).then(
      (name) => setlastUpdaterName(name ? name : t("modules.SIPOC.unknown"))
    );
  }, [loadedProzessMappingEntry?.lastUpdatedBy, axios, t]);

  /**
   * Helper to save Prozessmap if component is unmounted (tab switch)
   */
  useEffect(() => {
    return () => {
      if (
        !axios ||
        !keycloakUser ||
        isViewerState ||
        isReleased ||
        !!projektAkte ||
        !loadedProzessMappingEntry.id ||
        !hasUnsavedChanges
      )
        return;
      updateProzessMappingEntry(
        { ...loadedProzessMappingEntry },
        keycloakUser.serviceId,
        axios
      );
    };
    // eslint-disable-next-line
  }, [
    loadedProzessMappingEntry,
    axios,
    hasUnsavedChanges,
    keycloakUser,
    isViewerState,
    isReleased,
    projektAkte,
  ]);

  /**
   * sets the current export function
   */
  useEffect(() => {
    setExportFunction(() => () => {
      setIsExportGenerating(true);
      generateSipocExport(
        neededProzessschritte.length,
        loadedProzessBereich.bereich,
        fetchedCompany.name,
        fetchedCompany.logoName,
        axios,
        selectedSipocChecklist?.name
      ).then(() =>
        // export is done
        setIsExportGenerating(false)
      );
    });
    // eslint-disable-next-line
  }, [
    neededProzessschritte.length,
    loadedProzessBereich.bereich,
    fetchedCompany,
    selectedSipocChecklist,
  ]);

  /**
   * This useEffect updates the sorting of the Prozesse
   */
  useEffect(() => {
    if (loadedProzessschritte.length > 0) {
      const localNeededProzessschritte: Prozessschritt[] =
        filterJustForNeededProzessschritte(loadedProzessschritte);
      setProzesschritteNumbers(
        generateProzesschrittNumbers(localNeededProzessschritte)
      );
      setNeededProzessschritte(localNeededProzessschritte);
    }
  }, [loadedProzessschritte]);

  /**
   * This useEffect filters always the needed Prozesslinien
   */
  useEffect(() => {
    if (loadedProzesslinien.length > 0) {
      // ignore QUALITAETSMANGEL and RUECKFRAGE Prozesslinien
      setNeededProzesslinien(
        loadedProzesslinien.filter(
          (prozessLinie: Prozesslinie) =>
            prozessLinie.type !== ProzesslinieType.QUALITAETSMANGEL &&
            prozessLinie.type !== ProzesslinieType.RUECKFRAGE
        )
      );
    }
  }, [loadedProzesslinien]);

  /**
   * updates the previous and next Prozessschritt for the detailview
   */
  useEffect(() => {
    if (selectedProzessschritt) {
      setPreviousAndNextSelectedProzessschritt(
        extractPreviousAndNextProzessSchritt(
          selectedProzessschritt,
          neededProzesslinien,
          loadedProzessschritte
        )
      );
    }
    // eslint-disable-next-line
  }, [selectedProzessschritt]);

  // updates just the needed information if a Projektakte is present
  useEffect(() => {
    if (projektAkte && !!axios && !!companyId) {
      setLoadedProzessBereich(projektAkte.prozessBereich!);
      setLoadedProzessMappingEntry(projektAkte.prozessMapping!);
      setLoadedProzessschritte(projektAkte.prozessMapping!.prozessSchritte);
      setLoadedProzesslinien(projektAkte.prozessMapping!.prozessLinie);

      fetchCompany(axios, companyId).then((fetchedCompany: Company) =>
        setFetchedCompany(fetchedCompany)
      );
    }
  }, [projektAkte, companyId, axios]);

  /**
   * This useEffect loads all needed data from the server
   */
  useEffect(() => {
    if (
      !projektAkte &&
      !!axios &&
      !!companyId &&
      loadedProzessschritte.length === 0 &&
      !!prozessBereichId
    ) {
      setLoadedProzessMappingEntry(
        createEmptyProzessMappingEntry(companyId, prozessBereichId)
      );
      Promise.all([
        fetchCompany(axios, companyId),
        getProzessBereichById(prozessBereichId, axios),
        getProzessMappingEntry(companyId, prozessBereichId, axios),
      ]).then(
        ([
          fetchedCompany,
          fetchedProzessBereich,
          prozessMappingEntryResponse,
        ]) => {
          setFetchedCompany(fetchedCompany);
          setLoadedProzessBereich(fetchedProzessBereich);

          // just set the data if there is data
          if (prozessMappingEntryResponse) {
            let entryToUse: ProzessMappingEntry | undefined;

            if (isReleased) {
              const historyEntries: HistoryEntry[] = [
                ...fetchedProzessBereich.history,
              ].sort((entryA, entryB) => entryB.version - entryA.version);
              const latestRelease: HistoryEntry | undefined =
                historyEntries.find((entry) => !!entry.released);
              if (latestRelease?.prozessMappingSnapshot)
                entryToUse = latestRelease.prozessMappingSnapshot;
            } else entryToUse = prozessMappingEntryResponse;
            if (entryToUse) {
              // that it is initially set
              updateEgOnServer(
                leanAdminProzessbereiche,
                setLeanAdminProzessbereiche,
                entryToUse,
                axios
              );

              // for update on server
              setLoadedProzessMappingEntry(entryToUse);
              // set just the Prozessschritte
              setLoadedProzessschritte(entryToUse.prozessSchritte);
              // set just the Prozesslinien
              setLoadedProzesslinien(entryToUse.prozessLinie);
            }
          } else {
            // show error message
            generateNotification(
              t("modules.SIPOC.title"),
              t("notifications.SIPOC.no-data"),
              "danger",
              -1
            );
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [axios, companyId, isReleased, prozessBereichId]);

  /**
   * Updates the Prozessmapping Entry on the Server with the new data
   *
   * @param updateProzessschritt
   */
  const updateProzessMappingWithOptionalUpload = (
    updateProzessschritt: Prozessschritt,
    uploadToServer: boolean
  ): void => {
    const prozessSchrittIndex: number =
      loadedProzessMappingEntry.prozessSchritte.findIndex(
        (prozessSchritt: Prozessschritt) =>
          prozessSchritt.id === updateProzessschritt.id
      );
    if (prozessSchrittIndex === -1) {
      // show error message if needed
      generateNotification(
        t("modules.SIPOC.title"),
        t("notifications.SIPOC.errorUpdate"),
        "danger",
        -1
      );
    } else {
      let localProzessMappingEntry: ProzessMappingEntry =
        loadedProzessMappingEntry;
      localProzessMappingEntry.prozessSchritte[prozessSchrittIndex] =
        updateProzessschritt;

      setLoadedProzessMappingEntry(
        fillLastUpdatedBy(localProzessMappingEntry, keycloakUser!.serviceId)
      );
      setLoadedProzessschritte([...localProzessMappingEntry.prozessSchritte]);
      if (!projektAkte) {
        updateEgOnServer(
          leanAdminProzessbereiche,
          setLeanAdminProzessbereiche,
          { ...localProzessMappingEntry },
          axios
        );
        if (uploadToServer) {
          updateProzessMappingEntry(
            { ...localProzessMappingEntry },
            keycloakUser!.serviceId,
            axios
          );
          toggleHasUnsavedChanged(false);
        } else toggleHasUnsavedChanged(true);
      }
    }
  };

  /**
   * creates an element which defines the state of the checked checklist elements
   *
   * @param prozessSchrittId
   * @returns JSX.Element which defines the current state
   */
  const isFullfilled = (prozessSchrittId: string): JSX.Element => {
    //evaluation is only needed, when we are in projektAkte, else it is configuration
    if (!projektAkte)
      return (
        <div className="sipoc-overview-processes-entry-checklist-wrapper"></div>
      );
    const localNeededCheckedElements: number =
      generateProzessschrittIdAndNeededCheckedElementsAmount(
        loadedProzessMappingEntry,
        true
      )!.get(prozessSchrittId)!;
    const localFoundCheckedElements: number =
      getAmountOfCheckedElementsForProzessschritt(
        selectedSipocChecklist!,
        prozessSchrittId,
        loadedProzessMappingEntry.prozessSchritte
      );
    const localNeededCheckedElementsWithInputs: number =
      generateProzessschrittIdAndNeededCheckedElementsAmount(
        loadedProzessMappingEntry
      )!.get(prozessSchrittId)!;
    const localFoundCheckedElementsWithInputs: number =
      getAmountOfCheckedElementsForProzessschritt(
        selectedSipocChecklist!,
        prozessSchrittId,
        loadedProzessMappingEntry.prozessSchritte,
        true
      );
    let icon: JSX.Element = (
      <HourglassHalfIcon width="16px" height="16px" className="yellow" />
    );
    switch (true) {
      case localNeededCheckedElementsWithInputs ===
        localFoundCheckedElementsWithInputs:
        icon = <CheckIcon width="16px" height="16px" className="ok" />;
        break;
      case localFoundCheckedElementsWithInputs === 0:
        icon = <BlockedIcon width="16px" height="16px" className="red" />;
        break;
    }

    return (
      <div className="sipoc-overview-processes-entry-checklist-wrapper">
        {localFoundCheckedElements}/{localNeededCheckedElements}
        &nbsp;
        {icon}
      </div>
    );
  };

  /**
   * Helper to check if prozesslinie between two processes has the desired type
   * @param startProcessId to find linie in mapping
   * @param stopProcessId to find linie in mapping
   * @param desiredTypes has to contain type of linie for method to return true
   * @returns true, when linie has one of desired types
   */
  const hasProzesslinieDesiredType = (
    startProcessId: string,
    stopProcessId: string,
    desiredTypes: ProzesslinieType[]
  ): boolean => {
    const linie: Prozesslinie | undefined =
      loadedProzessMappingEntry.prozessLinie.find(
        (linie) =>
          linie.startProcessId === startProcessId &&
          linie.stopProcessId === stopProcessId
      );
    if (!linie) return false;
    return desiredTypes.includes(linie.type);
  };

  if (!loadedProzessMappingEntry.id && isReleased) return <></>;
  return (
    <>
      <BoxComponent
        icon={<SipocIcon className="box-special-icon" />}
        title={
          showDetailView
            ? `${t("modules.SIPOC.title")} ${t("modules.SIPOC.detail.title")}`
            : `${t("modules.SIPOC.title")} ${t("modules.SIPOC.overview.title")}`
        }
        subtext={
          <p>
            {`${t("modules.SIPOC.title")} ${t("modules.SIPOC.lastUpdate", {
              replace: {
                date: getFormattedDateTimeString(
                  loadedProzessMappingEntry?.lastUpdated ||
                    loadedProzessMappingEntry?.createDate
                ),
                by: lastUpdaterName,
              },
            })}`}
          </p>
        }
      >
        {showDetailView && (
          // Detailview when Prozessschritt is selected
          <SipocDetailview
            isReleased={isReleased}
            roleIdMapping={projektAkte?.userRoleMapping || []}
            projektManagerId={projektAkte?.projectManager || ""}
            onClickToUpdate={onClickToUpdate}
            selectedSipocChecklist={selectedSipocChecklist}
            updateFunction={updateProzessMappingWithOptionalUpload}
            key={selectedProzessschritt.id}
            nextFunction={() => {
              let newIndex: number = getPreNextProzessschritt(
                true,
                selectedProzessschritt.id,
                neededProzessschritte
              );
              if (newIndex !== -1) {
                setSelectedProzessschritt(neededProzessschritte[newIndex]);
                setSelectedIndexNumber(prozesschritteNumbers[newIndex]);
              }
            }}
            preFunction={() => {
              let newIndex: number = getPreNextProzessschritt(
                false,
                selectedProzessschritt.id,
                neededProzessschritte
              );
              if (newIndex !== -1) {
                setSelectedProzessschritt(neededProzessschritte[newIndex]);
                setSelectedIndexNumber(prozesschritteNumbers[newIndex]);
              }
            }}
            nextProzessschritte={previousAndNextSelectedProzessschritt[1]}
            previousProzessschritte={previousAndNextSelectedProzessschritt[0]}
            prozessBereich={loadedProzessBereich}
            prozessSchritt={selectedProzessschritt}
            closeFunction={() => setShowDetailView(false)}
            indexNumber={selectedIndexNumber}
            companyId={companyId}
            isInputComplete={(prozessschritt: Prozessschritt) =>
              isInputComplete(
                prozessschritt,
                neededProzesslinien,
                selectedSipocChecklist!
              )
            }
            projektAkteTitle={projektAkte && projektAkte.title}
            hasProzesslinieDesiredType={hasProzesslinieDesiredType}
          />
        )}

        <>
          <div
            id="sipoc-overview-export-wrapper"
            className={
              showDetailView ? "sipoc-overview-export-wrapper-hidden" : ""
            }
          >
            <div className="sipoc-overview-processes-wrapper">
              <div
                className={[
                  "sipoc-overview-rotate-text",
                  i18n.language === Language.en ? "additional-left" : undefined,
                ].join(" ")}
              >
                <div>
                  <Trans i18nKey="modules.SIPOC.overview.content">Inhalt</Trans>
                </div>
              </div>
              <div>
                {neededProzessschritte.map(
                  (
                    prozessSchritt: Prozessschritt,
                    prozessSchrittIndex: number
                  ) => {
                    return (
                      <p
                        key={`sipoc-overview-processes-${prozessSchrittIndex}`}
                        className="sipoc-overview-processes-entry"
                        onClick={() => {
                          setSelectedProzessschritt(prozessSchritt);
                          setSelectedIndexNumber(
                            prozesschritteNumbers[prozessSchrittIndex]
                          );
                          setShowDetailView(true);
                        }}
                      >
                        {prozesschritteNumbers[prozessSchrittIndex]})
                        {prozessSchritt.name} ({prozessSchritt.displayName})
                        {selectedProzessschritt &&
                          isFullfilled(prozessSchritt.id)}
                      </p>
                    );
                  }
                )}
              </div>
            </div>

            <SipocOverview
              allProzessschritte={loadedProzessschritte}
              neededProzesslinien={neededProzesslinien}
              toggleDetailView={(
                prozessSchritt: Prozessschritt,
                indexNumber: string
              ) => {
                setSelectedProzessschritt(prozessSchritt);
                setSelectedIndexNumber(indexNumber);
                setShowDetailView(true);
              }}
              prozessBereich={loadedProzessBereich}
              indexNumbers={prozesschritteNumbers}
              neededProzessschritte={neededProzessschritte}
            />
          </div>
        </>
      </BoxComponent>

      {/* Detailview for export */}
      {isExportGenerating &&
        neededProzessschritte.map(
          (prozessSchritt: Prozessschritt, prozessSchrittIndex: number) => {
            return (
              <div
                className="sipoc-detail-export-wrapper-export"
                id={`sipoc-detail-export-wrapper-${prozessSchrittIndex}`}
                key={prozessSchritt.id}
              >
                <SipocDetailview
                  roleIdMapping={projektAkte?.userRoleMapping || []}
                  projektManagerId={projektAkte?.projectManager || ""}
                  isExport
                  selectedSipocChecklist={selectedSipocChecklist}
                  nextProzessschritte={
                    extractPreviousAndNextProzessSchritt(
                      prozessSchritt,
                      neededProzesslinien,
                      loadedProzessschritte
                    )[1]
                  }
                  previousProzessschritte={
                    extractPreviousAndNextProzessSchritt(
                      prozessSchritt,
                      neededProzesslinien,
                      loadedProzessschritte
                    )[0]
                  }
                  prozessBereich={loadedProzessBereich}
                  prozessSchritt={prozessSchritt}
                  indexNumber={prozesschritteNumbers[prozessSchrittIndex]}
                  companyId={companyId}
                />
              </div>
            );
          }
        )}

      {isExportGenerating && (
        <LoaderComponent inFront showText text={t("general.buttons.export")} />
      )}
    </>
  );
};

export default SipocEval;
