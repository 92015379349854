import {
  BoxComponent,
  InputComponent,
  LayoutComponent,
  LoaderComponent,
  TreeConfig,
} from "beelean-component-library";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { LicenseModuleType, ModuleShowType, UserRole } from "../utils/Enums";
import { Company, KeycloakUser, User } from "../utils/Interfaces";
import { isModuleValidInLicense } from "../utils/LicenseUtil";
import {
  getCorrectLanguageImage,
  toggleLanguage,
  useLayoutConfiguration,
} from "../utils/NavigationUtil";
import "../styles/ProjektAktePage.scss";
import {
  CompanyContext,
  GlobaleApplicationSettings,
  KeycloakUserContext,
  TreeDataContext,
} from "./App";
import { useHistory, useLocation } from "react-router";
import { ReactComponent as SearchIcon } from "../assets/icons/search.svg";
import { ReactComponent as TasksIcon } from "../assets/icons/tasks.svg";
import { ProzessBereich } from "../utils/LeanAdmin/LeanAdmin.types";
import ProjektAkteCreateWorkflow from "../components/modules/projektakte/ProjektAkteCreateWorkflow";
import { ProjektAkte } from "../utils/LeanAdmin/ProjektAkte.types";
import ProjektAkteOverview from "../components/modules/projektakte/ProjektAkteOverview";
import { isUserAllowedToDoThis } from "../utils/UserUtil";

interface ProjektAkteProps {
  keycloakUser: KeycloakUser;
  userCompany: Company;
  leanAdminTreeData?: TreeConfig;
  pdcaTreeData?: TreeConfig;
  leanProdTreeData?: TreeConfig;
  projektAkteTreeData?: TreeConfig;
  isViewerState: boolean;
  toggleViewerState(viewState: boolean): void;
  isDesktopMenuOpen: boolean;
  toggleIsDesktopMenuOpen(desktopOpenState: boolean): void;
  leanAdminProzessbereiche: ProzessBereich[];
  companyUser: User[];
  projektAkten: ProjektAkte[];
  setProjektAkten(projektAkten: ProjektAkte[]): void;
  setUserCompany(userCompany: Company): void;
}

const Page: React.FC<ProjektAkteProps> = ({
  keycloakUser,
  userCompany,
  leanAdminTreeData,
  pdcaTreeData,
  leanProdTreeData,
  isViewerState,
  toggleViewerState,
  isDesktopMenuOpen,
  toggleIsDesktopMenuOpen,
  leanAdminProzessbereiche,
  companyUser,
  projektAkteTreeData,
  projektAkten,
  setProjektAkten,
  setUserCompany,
}) => {
  const {
    text,
    isAdmin,
    username,
    homeFunction,
    logoutFunction,
    profileFunction,
    accountListFunction,
    showToggleViewEdit,
    isTest,
    lng,
  } = useLayoutConfiguration(keycloakUser);
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{
    openNodes: string[];
    lastKey: string;
    companyId: string;
  }>();
  const [searchString, setSearchString] = useState<string>("");
  const [localCompanyId, setLocalCompanyId] = useState<string>("");
  const [openNodes, setOpenNodes] = useState<string[]>([]);
  const [lastKey, setLastKey] = useState<string>("");
  const [companyFilteredProjektakten, setCompanyFilteredProjektakten] =
    useState<ProjektAkte[]>([]);

  // sets local company id according to location
  useEffect(() => {
    if (location?.state !== undefined) {
      setOpenNodes(location.state.openNodes);
      setLastKey(location.state.lastKey);
      setLocalCompanyId(location.state.companyId);
    } else {
      history.push(`/`);
    }
    // eslint-disable-next-line
  }, [location]);

  // filter just the needed Projektakten for companyId
  useEffect(() => {
    if (!!localCompanyId)
      setCompanyFilteredProjektakten(
        projektAkten.filter((entry) => entry.companyId === localCompanyId)
      );
    // eslint-disable-next-line
  }, [localCompanyId, projektAkten]);

  /**
   * filters the companyFilteredProjektakten ProjektAkten depending on
   * if they are finished or not
   * @param returnUnfinished
   * @returns default all finished projektAkten, but returns all unfinished
   * when returnUnfinished is true
   */
  const filterProjektAktenForFinished = (
    returnUnfinished?: boolean
  ): ProjektAkte[] => {
    if (returnUnfinished)
      return companyFilteredProjektakten.filter(
        (projektAkte) => !projektAkte.finishedDate
      );
    else
      return companyFilteredProjektakten.filter(
        (projektAkte) => !!projektAkte.finishedDate
      );
  };

  return (
    <LayoutComponent
      noDataInTree={t("tree.noData")}
      isTest={isTest}
      showToggleViewEdit={showToggleViewEdit}
      isDesktopMenuOpen={isDesktopMenuOpen}
      onToogleDesktopMenuOpen={() =>
        toggleIsDesktopMenuOpen(!isDesktopMenuOpen)
      }
      viewEditStatus={isViewerState}
      onToggleViewEditFunction={() => toggleViewerState(!isViewerState)}
      showLeanAdminTree={isModuleValidInLicense(
        LicenseModuleType.ADMINISTRATION,
        userCompany.license
      )}
      showLeanProdTree={isModuleValidInLicense(
        LicenseModuleType.PRODUCTION,
        userCompany.license
      )}
      text={text}
      title={t("projektAkte.title")}
      adminProjektAkteTreeData={{
        ...projektAkteTreeData!,
        openNodes: openNodes,
        lastSelectedKey: lastKey,
      }}
      leanProdTreeData={leanProdTreeData}
      leanAdminTreeData={leanAdminTreeData}
      adminPdcaTreeData={pdcaTreeData}
      username={username}
      homeFunction={() => homeFunction()}
      logoutFunction={() => logoutFunction()}
      onUserAdminFunction={() => accountListFunction()}
      onCreateLineFunction={() => {}}
      isAdmin={isAdmin}
      profileFunction={() => profileFunction()}
      key={openNodes.length}
      version={t("general.version")}
      languageImage={getCorrectLanguageImage(lng)}
      onChangeLanguage={toggleLanguage}
    >
      <div className="projektakte-wrapper-add-search">
        {keycloakUser &&
          isUserAllowedToDoThis(
            keycloakUser.userRole,
            ModuleShowType.PROJEKTAKTE_CREATE
          ) &&
          !isViewerState && (
            <BoxComponent
              title={t("projektAkte.create.title")}
              icon={<TasksIcon />}
              subtext={
                <p>
                  <Trans i18nKey="projektAkte.create.addSubText">
                    Legen Sie hier ihre neue Projektakte an
                  </Trans>
                </p>
              }
            >
              <ProjektAkteCreateWorkflow
                key={`create-workflow-update-${localCompanyId}`}
                companyUser={companyUser}
                companyId={localCompanyId}
                leanAdminProzessbereiche={leanAdminProzessbereiche}
                keycloakUser={keycloakUser}
                isViewerState={isViewerState}
                projektAkten={projektAkten}
                setProjektAkten={setProjektAkten}
                userCompany={userCompany}
                setUserCompany={setUserCompany}
              />
            </BoxComponent>
          )}
        <BoxComponent
          title={t("general.buttons.search")}
          icon={<SearchIcon />}
          subtext={
            <p>
              <Trans i18nKey="projektAkte.search.addSubText">
                Suchen Sie hier Ihre Projektakte
              </Trans>
            </p>
          }
        >
          <InputComponent
            onChange={(value: string) => setSearchString(value)}
            value={searchString}
            placeholder={t("general.buttons.search")}
          />
        </BoxComponent>
      </div>
      <BoxComponent
        title={t("projektAkte.section.current")}
        icon={<TasksIcon />}
      >
        <ProjektAkteOverview
          isViewerState={isViewerState}
          projektAkten={filterProjektAktenForFinished(true)}
          setProjektAkten={(changedOpenProjektaktenList) =>
            setProjektAkten([
              ...changedOpenProjektaktenList,
              ...filterProjektAktenForFinished(),
            ])
          }
          userCompany={userCompany}
          setUserCompany={setUserCompany}
          searchString={searchString}
          keycloakUser={keycloakUser}
        />
      </BoxComponent>
      <BoxComponent
        title={t("projektAkte.section.finished")}
        icon={<TasksIcon />}
      >
        <ProjektAkteOverview
          isViewerState={isViewerState}
          projektAkten={filterProjektAktenForFinished()}
          setProjektAkten={(changedFinsihedProjektaktenList) =>
            setProjektAkten([
              ...changedFinsihedProjektaktenList,
              ...filterProjektAktenForFinished(true),
            ])
          }
          userCompany={userCompany}
          setUserCompany={setUserCompany}
          searchString={searchString}
          keycloakUser={keycloakUser}
        />
      </BoxComponent>
    </LayoutComponent>
  );
};

const ProjektAktePage = () => {
  // needed context
  const { keycloakUser } = useContext(KeycloakUserContext);
  const { userCompany, companyUser, setUserCompany } =
    useContext(CompanyContext);
  const {
    leanAdminTreeData,
    leanProdTreeData,
    pdcaTreeData,
    leanAdminProzessbereiche,
    projektAkteTreeData,
    projektAkten,
    setProjektAkten,
  } = useContext(TreeDataContext);
  const {
    isViewerState,
    toggleViewerState,
    isDesktopMenuOpen,
    toggleIsDesktopMenuOpen,
  } = useContext(GlobaleApplicationSettings);
  const { t } = useTranslation();

  return keycloakUser &&
    (!!userCompany.id || keycloakUser.userRole === UserRole.PLATFORMADMIN) ? (
    <Suspense
      fallback={
        <LoaderComponent inFront showText text={t("general.loading")} />
      }
    >
      <Page
        isDesktopMenuOpen={isDesktopMenuOpen}
        toggleIsDesktopMenuOpen={toggleIsDesktopMenuOpen}
        isViewerState={isViewerState}
        toggleViewerState={toggleViewerState}
        keycloakUser={keycloakUser}
        userCompany={userCompany}
        leanAdminTreeData={leanAdminTreeData}
        pdcaTreeData={pdcaTreeData}
        leanProdTreeData={leanProdTreeData}
        leanAdminProzessbereiche={leanAdminProzessbereiche}
        companyUser={companyUser}
        projektAkteTreeData={projektAkteTreeData}
        projektAkten={projektAkten}
        setProjektAkten={setProjektAkten}
        setUserCompany={setUserCompany}
      />
    </Suspense>
  ) : (
    <LoaderComponent inFront showText text={t("general.loading")} />
  );
};

export default ProjektAktePage;
