import React, { useState } from "react";
import { Trans } from "react-i18next";
import {
  ProzessBereich,
  ProzessBereichRole,
  Prozesslinie,
  Prozessschritt,
} from "../../../../utils/LeanAdmin/LeanAdmin.types";
import { isSipocProzessschrittValid } from "../../../../utils/LeanAdmin/SipocEvalUtils";
import "../SipocEvalStyle.scss";

interface SipocOverviewProps {
  prozessBereich: ProzessBereich;
  indexNumbers: string[];
  neededProzessschritte: Prozessschritt[];
  allProzessschritte: Prozessschritt[];
  neededProzesslinien: Prozesslinie[];
  toggleDetailView(
    selectedProzessschritt: Prozessschritt,
    indexNumber: string
  ): void;
  isReleased?: boolean;
}

const SipocOverview: React.FC<SipocOverviewProps> = ({
  prozessBereich,
  indexNumbers,
  neededProzessschritte,
  toggleDetailView,
  allProzessschritte,
  neededProzesslinien,
}) => {
  // just for better displaying the distance between the roles and the lines
  const [maxRoleNameWidth, setMaxRoleNameWidth] = useState<number>(0);

  const getRoleLines = (): JSX.Element[] => {
    if (prozessBereich.version! >= 2) {
      return prozessBereich.bereichRoles.map(
        (role: ProzessBereichRole, roleIndex: number) => {
          if (role.name.length * 10 > maxRoleNameWidth) {
            setMaxRoleNameWidth(role.name.length * 10);
          }

          return (
            <div
              key={`sipoc-role-list-${roleIndex}`}
              className="sipoc-overview-role-line-wrapper"
            >
              <div
                className="sipoc-overview-role-line-name"
                style={{ width: `${maxRoleNameWidth}px` }}
              >
                {role.name}
              </div>
              <div className="sipoc-overview-role-line">
                <div
                  style={{ width: `${indexNumbers.length * 40 + 10}px` }}
                  className={[
                    "sipoc-overview-role-line-line",
                    roleIndex % 2 === 1
                      ? "sipoc-overview-role-line-line-dash"
                      : "",
                  ].join(" ")}
                />

                {/* draw the dot */}
                {neededProzessschritte.map(
                  (
                    prozessSchritt: Prozessschritt,
                    prozessSchrittIndex: number
                  ) => {
                    const isCurrentProzessSchrittValid: boolean =
                      isSipocProzessschrittValid(
                        allProzessschritte,
                        prozessSchritt,
                        neededProzesslinien
                      );

                    return prozessSchritt.role === role.id ? (
                      <div
                        onClick={() =>
                          toggleDetailView(
                            neededProzessschritte[prozessSchrittIndex],
                            indexNumbers[prozessSchrittIndex]
                          )
                        }
                        className={[
                          "sipoc-overview-role-line-dot",
                          isCurrentProzessSchrittValid
                            ? "sipoc-overview-role-line-dot-black"
                            : "sipoc-overview-role-line-dot-red",
                        ].join(" ")}
                        key={`${role.name}-${prozessSchrittIndex}-${roleIndex}`}
                        style={{
                          left: `${prozessSchrittIndex * 40 + 10}px`,
                        }}
                      />
                    ) : (
                      ""
                    );
                  }
                )}
              </div>
            </div>
          );
        }
      );
    } else
      return prozessBereich.roles.map((roleName: string, roleIndex: number) => {
        if (roleName.length * 10 > maxRoleNameWidth) {
          setMaxRoleNameWidth(roleName.length * 10);
        }

        return (
          <div
            key={`sipoc-role-list-${roleIndex}`}
            className="sipoc-overview-role-line-wrapper"
          >
            <div
              className="sipoc-overview-role-line-name"
              style={{ width: `${maxRoleNameWidth}px` }}
            >
              {roleName}
            </div>
            <div className="sipoc-overview-role-line">
              <div
                style={{ width: `${indexNumbers.length * 40 + 10}px` }}
                className={[
                  "sipoc-overview-role-line-line",
                  roleIndex % 2 === 1
                    ? "sipoc-overview-role-line-line-dash"
                    : "",
                ].join(" ")}
              />

              {/* draw the dot */}
              {neededProzessschritte.map(
                (
                  prozessSchritt: Prozessschritt,
                  prozessSchrittIndex: number
                ) => {
                  const isCurrentProzessSchrittValid: boolean =
                    isSipocProzessschrittValid(
                      allProzessschritte,
                      prozessSchritt,
                      neededProzesslinien
                    );

                  return prozessSchritt.role === roleName ? (
                    <div
                      onClick={() =>
                        toggleDetailView(
                          neededProzessschritte[prozessSchrittIndex],
                          indexNumbers[prozessSchrittIndex]
                        )
                      }
                      className={[
                        "sipoc-overview-role-line-dot",
                        isCurrentProzessSchrittValid
                          ? "sipoc-overview-role-line-dot-black"
                          : "sipoc-overview-role-line-dot-red",
                      ].join(" ")}
                      key={`${roleName}-${prozessSchrittIndex}-${roleIndex}`}
                      style={{
                        left: `${prozessSchrittIndex * 40 + 10}px`,
                      }}
                    />
                  ) : (
                    ""
                  );
                }
              )}
            </div>
          </div>
        );
      });
  };

  /**
   * Helper to generate nedded elements to display the correct index numbers
   * @returns generates elements
   */
  const getCorrectIndexNumbers = (): JSX.Element[] => {
    return indexNumbers.map((indexNumber: string, indexNumberIndex: number) => {
      return (
        <div
          onClick={() =>
            toggleDetailView(
              neededProzessschritte[indexNumberIndex],
              indexNumber
            )
          }
          className="sipoc-overview-role-index-number-text"
          key={`sipoc-overview-role-index-number-${indexNumberIndex}`}
          style={{ left: `${indexNumberIndex * 40 + 7}px` }}
        >
          {indexNumber}
        </div>
      );
    });
  };

  return (
    <div className="sipoc-overview-wrapper">
      <div className="sipoc-overview-rotate-text">
        <div>
          <Trans i18nKey="modules.SIPOC.overview.lead">Lead</Trans>
        </div>
      </div>
      <div className="sipoc-overview-role-wrapper">
        <div
          style={{ marginLeft: `${maxRoleNameWidth}px` }}
          className="sipoc-overview-role-index-number-wrapper"
        >
          {getCorrectIndexNumbers()}
        </div>
        {getRoleLines()}
      </div>
    </div>
  );
};

export default SipocOverview;
