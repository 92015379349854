import { AxiosInstance } from "axios";
import i18next from "i18next";
import { generateNotification } from "../NotificationUtil";
import { Option } from "beelean-component-library";
import { SipocOutputType } from "./LeanAdmin.types";

/**
 * fetches the current output file from server
 *
 * @fileName
 * @companyId
 * @axios
 * @returns image as blob
 */
export const getOutputFile = (
  fileName: string,
  companyId: string,
  axios: AxiosInstance
): Promise<Blob> => {
  const fileNameForServer = getFileNameWithoutId(fileName, true);
  return axios
    .get("/data/file/", {
      params: { fileName: fileNameForServer, companyId: companyId },
      responseType: "blob",
    })
    .then((FileResponse) => FileResponse.data)
    .catch((exc) => {
      generateNotification(
        i18next.t("notifications.SIPOC.outputConfiguration.title"),
        i18next.t("notifications.SIPOC.outputConfiguration.fetchFailed"),
        "danger",
        -1
      );
      console.error("Error during fetching demanded file!", exc);
      return null;
    });
};

/**
 * uploads the new image to server
 *
 * @file
 * @companyId
 * @axios
 * @returns filename to save in outputFileList in ProzessSchritt
 */
export const uploadOutputFile = async (
  file: File,
  companyId: string,
  axios: AxiosInstance
): Promise<string> => {
  // build the request
  let uploadFormData = new FormData();
  const blob = new Blob([companyId], {
    type: "application/json",
  });
  uploadFormData.append("companyId", blob);
  uploadFormData.append("fileData", file);
  // run the axios command
  return axios
    .post("/data/file/", uploadFormData)
    .then((fileResponse) => fileResponse.data)
    .catch((exc) => {
      generateNotification(
        i18next.t("notifications.SIPOC.outputConfiguration.title"),
        i18next.t("notifications.SIPOC.outputConfiguration.uploadFailed"),
        "danger",
        -1
      );
      console.error("Error during uploading file!", exc);
      return null;
    });
};

/**
 * deletes image on server
 *
 * @fileName
 * @companyId
 * @axios
 * @returns
 */
export const deleteOutputFile = (
  fileName: string,
  companyId: string,
  axios: AxiosInstance,
  prozessBereichId: string
): Promise<boolean> => {
  return axios
    .post("/data/file/delete/", undefined, {
      params: {
        fileName: fileName,
        companyId: companyId,
        prozessBereichId: prozessBereichId,
      },
    })
    .then((response) => {
      if (response.status === 200) return true;
      else return false;
    })
    .catch((exc) => {
      //status 409 means file is used somewhere else, this doesn't mean we can't delete it from current use tho
      if (exc.response.status === 409) return true;
      generateNotification(
        i18next.t("notifications.SIPOC.outputConfiguration.title"),
        i18next.t("notifications.SIPOC.outputConfiguration.deleteFailed"),
        "danger",
        -1
      );
      console.error("Error during deleting file!", exc);
      return false;
    });
};

/**
 * returns filename for display in list
 *
 * @param fileName
 * @returns the filename without the Id
 * @tested
 */
export const getFileNameWithoutId = (
  fileName: string,
  keepFileId: boolean
): string => {
  let splittedFileName: string[] = fileName.split("_");
  splittedFileName.shift();
  const fileNameWithoutProzessschrittId: string = splittedFileName.join("_");
  if (keepFileId) return fileNameWithoutProzessschrittId;
  splittedFileName = fileNameWithoutProzessschrittId.split("-");
  splittedFileName.shift();
  return splittedFileName.join("-");
};

/**
 * performs download of the demanded file
 *
 * @fileName
 * @companyId
 * @axios
 * @returns
 */
export const download = async (
  fileName: string,
  companyId: string,
  axios: AxiosInstance
): Promise<void> => {
  return getOutputFile(fileName, companyId, axios).then((response: Blob) => {
    if (!response) return;
    const Url = URL.createObjectURL(response);
    const createdATagElement = document.createElement("a");
    createdATagElement.setAttribute(
      "download",
      `${getFileNameWithoutId(fileName, false)}`
    );
    createdATagElement.href = Url;
    document.body.appendChild(createdATagElement);
    createdATagElement.click();
    createdATagElement.remove();
  });
};

/**
 * returns select options based on SipocOutputType enum
 *
 * @returns
 */
export const buildTypeSelectOptions = (): Option[] => {
  let outputTypeSelectOptions: Option[] = [];
  for (const sipocOutputTypeEntry in SipocOutputType) {
    outputTypeSelectOptions.push({
      value: sipocOutputTypeEntry,
      label: i18next.t(
        `modules.SIPOC.outputConfiguration.sipocOutputType.${sipocOutputTypeEntry}`
      ),
    });
  }
  return outputTypeSelectOptions;
};

/**
 * checks if an URL is valid
 *
 * @returns boolean if valid
 * @tested
 */
export const isValidUrl = (url: string): boolean => {
  let trimmedUrl: string = url.trim();
  if (trimmedUrl.split(/[ "'`´]+/).length > 1) return false;
  const urlPattern: RegExp =
    // eslint-disable-next-line
    /((https?:\/\/)|[-a-zA-Z0-9@:%._\+~#=]{2,256}\.)[-a-zA-Z0-9@:%._\+~#=]{1,256}[\.][a-zA-Z0-9()]{2,3}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)/;
  return urlPattern.test(trimmedUrl);
};

/**
 * checks if the incoming file Size is bigger than 5mb
 * @param fileSize
 * @returns
 */
export const checkIsFileTooBig = (fileSize: number): boolean => {
  if (
    fileSize > Number(process.env.REACT_APP_MAX_FILE_SIZE_FOR_OUTPUT_CONFIG)
  ) {
    generateNotification(
      i18next.t("notifications.SIPOC.outputConfiguration.title"),
      i18next.t("notifications.SIPOC.outputConfiguration.fileTooBig"),
      "warning",
      3000
    );
    return true;
  }
  return false;
};

/**
 * fetches the current output file belonging to projektakte with the given id on server
 *
 * @fileName
 * @companyId
 * @axios
 * @returns image as blob
 */
export const getProjektAkteOutputFile = (
  fileName: string,
  projektAkteId: string,
  axios: AxiosInstance,
  useOriginalFileName?: boolean
): Promise<Blob> => {
  let fileNameForServer: string = "";
  if (useOriginalFileName) {
    fileNameForServer = fileName;
  } else {
    fileNameForServer = getFileNameWithoutId(fileName, true);
  }
  return axios
    .get("/data/file/akte/", {
      params: { fileName: fileNameForServer, projektAktenId: projektAkteId },
      responseType: "blob",
    })
    .then((FileResponse) => FileResponse.data)
    .catch((exc) => {
      generateNotification(
        i18next.t("notifications.SIPOC.outputConfiguration.title"),
        i18next.t("notifications.SIPOC.outputConfiguration.fetchFailed"),
        "danger",
        -1
      );
      console.error("Error during fetching demanded file!", exc);
      return null;
    });
};

/**
 * uploads the new image to projektakte with the given id on server
 *
 * @file
 * @companyId
 * @axios
 * @returns filename to save in outputFileList in ProzessSchritt
 */
export const uploadProjektAkteOutputFile = async (
  file: File,
  companyId: string,
  projektAkteId: string,
  axios: AxiosInstance
): Promise<string> => {
  // build the request
  let uploadFormData = new FormData();
  const companyIdBlob = new Blob([companyId], {
    type: "application/json",
  });
  uploadFormData.append("companyId", companyIdBlob);
  const projektAkteIdBlob = new Blob([projektAkteId], {
    type: "application/json",
  });
  uploadFormData.append("projektAktenId", projektAkteIdBlob);
  uploadFormData.append("fileData", file);
  // run the axios command
  return axios
    .post("/data/file/akte/", uploadFormData)
    .then((fileResponse) => fileResponse.data)
    .catch((exc) => {
      generateNotification(
        i18next.t("notifications.SIPOC.outputConfiguration.title"),
        i18next.t("notifications.SIPOC.outputConfiguration.uploadFailed"),
        "danger",
        -1
      );
      console.error("Error during uploading file!", exc);
      return null;
    });
};

/**
 * deletes image from projektakte with the given id on server
 *
 * @fileName
 * @companyId
 * @axios
 * @returns
 */
export const deleteProjektAkteOutputFile = (
  fileName: string,
  companyId: string,
  projektAkteId: string,
  axios: AxiosInstance
): Promise<boolean> => {
  const fileNameForServer = getFileNameWithoutId(fileName, true);
  return axios
    .post("/data/file/akte/delete/", undefined, {
      params: {
        fileName: fileNameForServer,
        companyId: companyId,
        projektAktenId: projektAkteId,
      },
    })
    .then((response) => {
      if (response.status === 200) return true;
      else return false;
    })
    .catch((exc) => {
      generateNotification(
        i18next.t("notifications.SIPOC.outputConfiguration.title"),
        i18next.t("notifications.SIPOC.outputConfiguration.deleteFailed"),
        "danger",
        -1
      );
      console.error("Error during deleting file!", exc);
      return false;
    });
};

/**
 * performs download of the demanded file
 *
 * @fileName
 * @companyId
 * @axios
 * @returns
 */
export const downloadProjektAkteFile = async (
  fileName: string,
  projektAkteId: string,
  axios: AxiosInstance
): Promise<void> => {
  return getProjektAkteOutputFile(fileName, projektAkteId, axios).then(
    (response: Blob) => {
      if (!response) return;
      const Url = URL.createObjectURL(response);
      const createdATagElement = document.createElement("a");
      createdATagElement.setAttribute(
        "download",
        `${getFileNameWithoutId(fileName, false)}`
      );
      createdATagElement.href = Url;
      document.body.appendChild(createdATagElement);
      createdATagElement.click();
      createdATagElement.remove();
    }
  );
};
