import { ProzessMappingEntry, ProzessBereich } from "./LeanAdmin.types";
/**
 * Interface for a ProjektAkte
 */
export interface ProjektAkte {
  id?: string;
  companyId: string;
  createDate?: Date;
  prozessBereich?: ProzessBereich;
  prozessMapping?: ProzessMappingEntry;
  userRoleMapping: UserRoleMappingEntry[];
  startDate: Date;
  endDate: Date;
  prozessSchrittEndDateMapping: ProzessSchrittEndDateMappingEntry[];
  creator: string;
  projectManager: string;
  title: string;
  prozessMapImageName: string;
  finishedDate?: Date;
  note: string;
}

/**
 * Helper to create a new ProjektAkte
 *
 * @param creator
 * @param companyId
 * @returns
 */
export const createEmptyProjektAkte = (
  creator: string,
  companyId: string
): ProjektAkte => ({
  companyId: companyId,
  creator: creator,
  startDate: new Date(),
  endDate: new Date(),
  projectManager: "",
  prozessSchrittEndDateMapping: [],
  userRoleMapping: [],
  title: "",
  prozessMapImageName: "",
  note: "",
});

/**
 * List of roleName with userId to
 * identify the responsible person
 */
export interface UserRoleMappingEntry {
  roleName: string;
  roleId?: string;
  userId: string;
}

/**
 * List of prozessSchrittId and date for milestone mapping
 */
export interface ProzessSchrittEndDateMappingEntry {
  prozessSchrittId: string;
  endDate: Date;
}

/**
 * needed information to send EMail Reminder from ProjektAkte
 */
export interface InputMailRequest {
  userId: string;
  prozessSchrittName: string;
  projektAkteName: string;
}
